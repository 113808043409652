import React, { useMemo, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { DataGrid } from "@material-ui/data-grid";
import { Link } from "@material-ui/core";
import { useQuery } from "react-query";
import PropTypes from "prop-types";

import { getAllTeamSales, getDirectTeamSales } from "../../http/agents";
import renderCellExpand from "../../utils/renderCellExpand";
import checkAccessStore from "../../utils/checkAccessStore";
import { positiveEurColumn } from "../../utils/dataGridColumns";

const TeamListV2 = ({ fromAgent, cgpId, oneLevel }) => {
  const year = new Date().getFullYear();

  const [pageSize, setPageSize] = useState(oneLevel ? 10 : 100);

  const salesQuery = useQuery({
    queryKey: ["teams-sales-v2", { oneLevel, cgpId, year }],
    queryFn: () =>
      oneLevel
        ? getDirectTeamSales({ year, cgpId })
        : getAllTeamSales({ year, cgpId }),
    staleTime: 1000 * 30,
  });

  const columns = useMemo(() => {
    return [
      {
        field: "fullName",
        headerName: "Consultant",
        flex: 1,
        renderCell: (params) =>
          renderCellExpand(
            params,
            true,
            checkAccessStore("team_detail_page") ? (
              <Link
                component={RouterLink}
                to={
                  fromAgent
                    ? `/my-team/${params.row.cgpId}`
                    : `/team/${params.row.cgpId}`
                }
              >
                {params.value}
              </Link>
            ) : (
              params.value
            ),
          ),
      },
      {
        field: "sponsor",
        headerName: "Parrain",
        flex: 1,
        renderCell: (params) =>
          renderCellExpand(
            params,
            true,
            checkAccessStore("team_detail_page") ? (
              <Link
                component={RouterLink}
                to={
                  fromAgent
                    ? `/my-team/${params.row.sponsorCgpId}`
                    : `/team/${params.row.sponsorCgpId}`
                }
              >
                {params.value}
              </Link>
            ) : (
              params.value
            ),
          ),
      },
      {
        field: "currentImmo",
        headerName: "Encours Immobilier (hors options)",
        valueGetter: (params) => params.row.currentImmo,
        flex: 1,
        ...positiveEurColumn,
        renderCell: renderCellExpand,
      },
      {
        field: "finishImmo",
        headerName: "Acté Immobilier",
        valueGetter: (params) => params.row.finishImmo,
        flex: 1,
        ...positiveEurColumn,
        renderCell: renderCellExpand,
      },
      {
        field: "currentInvest",
        headerName: "Encours Placement",
        valueGetter: (params) => params.row.currentInvest,
        flex: 1,
        ...positiveEurColumn,
        renderCell: renderCellExpand,
      },
      {
        field: "finishInvest",
        headerName: "Placement Validé",
        valueGetter: (params) => params.row.finishInvest,
        flex: 1,
        ...positiveEurColumn,
        renderCell: renderCellExpand,
      },
      {
        field: "vlp",
        headerName: "VLP",
        valueGetter: (params) => params.row.vlp,
        flex: 1,
        ...positiveEurColumn,
        renderCell: renderCellExpand,
      },
      {
        field: "vave",
        headerName: "Total Encours",
        flex: 1,
        ...positiveEurColumn,
        renderCell: renderCellExpand,
      },
      {
        field: "vavc",
        headerName: "Total Validé",
        flex: 1,
        ...positiveEurColumn,
        renderCell: renderCellExpand,
      },
      {
        field: "currentPlusFinish",
        headerName: "VA Total Encours + Acté ",
        valueGetter: (params) => params.row.vavc + params.row.vave,
        flex: 1,
        ...positiveEurColumn,
        renderCell: renderCellExpand,
      },
    ];
  }, []);

  if (cgpId && oneLevel) {
    columns.splice(1, 1);
  }

  const sortModel = [{ field: "fullName", sort: "asc" }];

  return (
    <DataGrid
      loading={salesQuery.isLoading}
      rows={salesQuery.isSuccess ? salesQuery.data : []}
      columns={columns}
      pageSize={pageSize}
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize.pageSize)}
      autoHeight
      disableColumnFilter
      disableSelectionOnClick
      disableColumnMenu
      disableColumnReorder
      pagination
      sortModel={sortModel}
      getRowId={(row) => row.id}
    />
  );
};

TeamListV2.defaultProps = {
  fromAgent: false,
  cgpId: null,
  oneLevel: false,
};

TeamListV2.propTypes = {
  oneLevel: PropTypes.bool,
  fromAgent: PropTypes.bool,
  cgpId: PropTypes.string,
};

export default TeamListV2;
