import { LoadingButton } from "@material-ui/lab";
import React, { useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  InputLabel,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  Stack,
  Tooltip,
} from "@material-ui/core";
import {
  Download as DownloadIcon,
  Search as SearchIcon,
  HelpOutline,
} from "@material-ui/icons";
import { useMutation } from "react-query";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import FilterPanel from "../../components/FilterPanel";
import GuardedComponent from "../../components/GuardedComponent";
import TeamToolbar from "../../components/team/TeamToolbar";
import {
  getExportSponsorsData,
  getExportSponsorsEstate,
  getExportSponsorsInvestment,
  getExportSponsorsV2,
} from "../../http/agents";
import CgpSalesSummary from "../../components/team/CgpSalesSummary";
import TeamListV2 from "../../components/team/TeamListV2";
import { useTrackingContext } from "../../contexts/TrackingContext";
import { ACTION_CLICK } from "../../utils/trackingConstant";

const AgentTeam = ({ cgpId }) => {
  const history = useHistory();
  const { agentId } = useParams();
  const actualYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(actualYear);
  const [filters, setFilters] = useState();
  const [filter, setFilter] = useState({ filters, exercice: actualYear });
  const rangeYears = 5;
  const years = Array(rangeYears)
    .fill("")
    .map((v, idx) => actualYear + idx - (rangeYears - 1));

  const consultantId = agentId ?? cgpId;

  const track = useTrackingContext()

  const onFilter = (filters) => {
    const data = {
      ...filters,
    };
    setFilters(data);
  };

  const onSubmit = () => {
    track.click(ACTION_CLICK.SET_FILTER)
    setFilter({ ...filters, exercice: selectedYear });
  };

  const handleExportExcel = () => {
    track.click(ACTION_CLICK.EXPORT_TEAM_VA)
    mutation.mutate(filters, consultantId);
  };

  const handleExportExcelData = () => {
    track.click(ACTION_CLICK.EXPORT_TEAM_INFOS)
    mutationData.mutate(filters, consultantId);
  };

  const handleExportExcelEstate = async () => {
    track.click(ACTION_CLICK.EXPORT_TEAM_IMMO)
    const data = {
      filters: filter,
      cgpId: consultantId,
    };
    mutationEstate.mutate(data);
  };

  const handleExportExceInvestment = async () => {
    track.click(ACTION_CLICK.EXPORT_TEAM_INVEST)
    const data = {
      filters: filter,
      cgpId: consultantId,
    };
    mutationInvestment.mutate(data);
  };

  const mutation = useMutation(getExportSponsorsV2);
  const mutationData = useMutation(getExportSponsorsData);
  const mutationEstate = useMutation(getExportSponsorsEstate);
  const mutationInvestment = useMutation(getExportSponsorsInvestment);

  const goBack = () => {
    history.goBack();
  };

  return (
    <Container maxWidth={false}>
      <Breadcrumbs />

      <CgpSalesSummary agentId={agentId} />

      <GuardedComponent componentName="team_export">
        <Stack direction="row" spacing={2}>
          <LoadingButton
            loading={mutation.isLoading}
            color="primary"
            variant="contained"
            startIcon={<DownloadIcon />}
            onClick={handleExportExcel}
          >
            Export VA
          </LoadingButton>
          <LoadingButton
            loading={mutationData.isLoading}
            color="primary"
            variant="contained"
            startIcon={<DownloadIcon />}
            onClick={handleExportExcelData}
          >
            Export informations équipes
          </LoadingButton>
          <LoadingButton
            loading={mutationEstate.isLoading}
            color="primary"
            variant="contained"
            startIcon={<DownloadIcon />}
            onClick={handleExportExcelEstate}
          >
            Export suivi immobilier
          </LoadingButton>
          <LoadingButton
            loading={mutationInvestment.isLoading}
            color="primary"
            variant="contained"
            startIcon={<DownloadIcon />}
            onClick={handleExportExceInvestment}
          >
            Export suivi placement
          </LoadingButton>
        </Stack>
      </GuardedComponent>
      <Box sx={{ mt: 3, mb: 3 }}>
        <InputLabel id="sale-step-label" shrink={true}>
          Exercice
        </InputLabel>

        <ButtonGroup
          variant="outlined"
          aria-label="outlined primary button group"
        >
          {years.map((year) => (
            <Button
              variant={selectedYear === year ? "contained" : "outlined"}
              key={year}
              onClick={() => setSelectedYear(year)}
            >
              {year}
            </Button>
          ))}
          <Button
            variant={!selectedYear ? "contained" : "outlined"}
            onClick={() => setSelectedYear()}
          >
            Tous
          </Button>
        </ButtonGroup>
        <Button
          variant="contained"
          startIcon={<SearchIcon />}
          onClick={onSubmit}
          type="submit"
        >
          Filtrer
        </Button>
        <Tooltip title={"L'exercice ne s'applique que pour les exports"}>
          <HelpOutline sx={{ marginLeft: "2px", fontSize: "20px" }} />
        </Tooltip>
      </Box>

      <FilterPanel sx={{ mt: 2 }}>
        <TeamToolbar
          onFilter={onFilter}
          agentId={agentId}
          exercice={selectedYear}
        />
      </FilterPanel>

      <GuardedComponent componentName="team_direct_godchild">
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Card>
            <CardHeader
              title={agentId ? "Ses filleuls directs" : "Mes filleuls directs"}
            />
            <Divider />
            <CardContent
              sx={{
                p: 1,
              }}
            >
              <TeamListV2 oneLevel cgpId={consultantId} />
            </CardContent>
          </Card>
        </Grid>
      </GuardedComponent>

      <GuardedComponent componentName="team_godchild">
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Card>
            <CardHeader
              title={agentId ? "Toutes ses équipes" : "Toutes mes équipes"}
            />
            <Divider />
            <CardContent
              sx={{
                p: 1,
              }}
            >
              <TeamListV2 fromAgent cgpId={agentId} />
            </CardContent>
          </Card>
        </Grid>
      </GuardedComponent>

      {agentId && (
        <Stack
          direction={{ xs: "column-reverse", sm: "row" }}
          spacing={2}
          sx={{
            p: 2,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button variant="outlined" onClick={goBack}>
            Retour
          </Button>
        </Stack>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  cgpId: state.auth.cgpId,
});

export default connect(mapStateToProps)(AgentTeam);
