import { LoadingButton } from "@material-ui/lab";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Alert, Button, DialogActions, DialogContent, Divider } from "@material-ui/core";
import { FolderOpen } from "@material-ui/icons";
import { useMutation, useQueryClient } from "react-query";
import AppDialog from "../AppDialog";
import { patchProspect } from "../../http/prospects";
import dayjs from "dayjs";
import { dateToStringYmd } from "../../utils/formatters";

const ProspectsUploadFileModal = ({ open, onClose, prospect, type }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [file, setFile] = useState(null);
  const queryClient = useQueryClient();

  const handleClose = () => {
    setFile(null);
    onClose();
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();

    let now = dayjs();
    now = dateToStringYmd(now);
    const fullName = `${prospect.lastName}-${prospect.firstName}`;
    if (type === "ricFile") {
      const fileExt = file.name.split(".").pop();
      formData.append("ricFile", file, `${now}_RIC_${fullName}.${fileExt}`);
    }
    if (type === "derFile") {
      const fileExt = file.name.split(".").pop();
      formData.append("derFile", file, `${now}_DER_${fullName}.${fileExt}`);
    }

    mutation.mutate({ prospect, data: formData });
  };

  const mutation = useMutation(patchProspect, {
    onSuccess: () => {
      queryClient.invalidateQueries("getProspects");
      enqueueSnackbar("Le fichier à été importé.", { variant: "success" });
      handleClose();
    },
  });

  const handleChangeFile = (event) => {
    setFile(event.target.files[0]);
  };

  return (
    <AppDialog open={open} onClose={handleClose} title="Uploader un nouveau fichier">
      <form noValidate onSubmit={onSubmit}>
        <DialogContent>
          {mutation.isError ? <Alert severity="error">Une erreur est survenue : {mutation.error.message}</Alert> : null}

          <Button variant="contained" component="label" startIcon={<FolderOpen />}>
            Télécharger un fichier
            <input type="file" hidden accept=".pdf, .jpg, .png, .jpeg, .doc, .docx, .odt" onChange={handleChangeFile} />
          </Button>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Annuler
          </Button>
          <LoadingButton loading={mutation.isLoading} type="submit" variant="contained" disabled={!file}>
            Enregistrer
          </LoadingButton>
        </DialogActions>
      </form>
    </AppDialog>
  );
};

ProspectsUploadFileModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  prospect: PropTypes.object,
  type: PropTypes.string,
};

export default ProspectsUploadFileModal;
