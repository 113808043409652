import PropTypes from "prop-types";
import React, { useState } from "react";
import { Button, Grid, InputLabel, MenuItem, Select, TextField, FormControl } from "@material-ui/core";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Refresh as RefreshIcon, Search as SearchIcon } from "@material-ui/icons";
import { useForm, Controller } from "react-hook-form";
import useEventByTypeData from "../../hooks/events/useEventByTypeData";
import { useTrackingContext } from "../../contexts/TrackingContext";
import { ACTION_CLICK } from "../../utils/trackingConstant";

const TrainingToolbar = ({ onFilter, exercice }) => {
  const [resetFields, setResetFields] = useState(false);

  const eventsNames = useEventByTypeData("event");
  const tutorialsNames = useEventByTypeData("tutorial");
  const participantStatusList = ["Consultant", "Invité", "Co-animateur", "Animateur", "Formateur"];

  const track = useTrackingContext()

  const defaultValues = {
    formation: "",
    evenement: "",
    validationFormation: "",
    statutParticipant: "",
    exercice: exercice,
    ville: "",
  };

  const schema = yup.object().shape({
    formation: yup.string().oneOf(["", ...tutorialsNames]),
    evenement: yup.string().oneOf(["", ...eventsNames]),
    ville: yup.string(),
    statutParticipant: yup.string().oneOf(["", ...participantStatusList]),
    validationFormation: yup.string().oneOf(["", "Validé", "Non Validé"]),
  });

  const { control, handleSubmit, reset, setValue } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const filters = {
      ...data,
      exercice: exercice,
    };

    onFilter(filters);
    track.click(ACTION_CLICK.SET_FILTER)
  };

  const onReset = () => {
    setResetFields(!resetFields);
    reset();
    onFilter({
      ...defaultValues,
    });
    track.click(ACTION_CLICK.RESET_FILTER)
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={6} lg={4}>
          <FormControl fullWidth variant="filled">
            <InputLabel id="formation" shrink={true}>
              Formation
            </InputLabel>
            <Controller
              name="formation"
              control={control}
              render={({ field }) => (
                <Select {...field} labelId="formation">
                  <MenuItem value="">
                    <em>Toutes</em>
                  </MenuItem>
                  {tutorialsNames.map((tutorialName, index) => {
                    return (
                      <MenuItem key={index} value={tutorialName}>
                        {tutorialName}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} lg={4}>
          <FormControl fullWidth variant="filled">
            <InputLabel id="evenement" shrink={true}>
              Evènement
            </InputLabel>
            <Controller
              name="evenement"
              control={control}
              render={({ field }) => (
                <Select {...field} labelId="evenement">
                  <MenuItem value="">
                    <em>Toutes</em>
                  </MenuItem>
                  {eventsNames.map((eventName, index) => {
                    return (
                      <MenuItem key={index} value={eventName}>
                        {eventName}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} lg={4}>
          <Controller
            name="ville"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Ville"
                variant="filled"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} lg={4}>
          <FormControl fullWidth variant="filled">
            <InputLabel id="statutParticipant" shrink={true}>
              Statut du participant
            </InputLabel>
            <Controller
              name="statutParticipant"
              control={control}
              render={({ field }) => (
                <Select {...field} labelId="statutParticipant">
                  <MenuItem value="">
                    <em>Tous</em>
                  </MenuItem>
                  {participantStatusList.map((participantStatus, index) => {
                    return (
                      <MenuItem key={index} value={participantStatus}>
                        {participantStatus}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} lg={4}>
          <FormControl fullWidth variant="filled">
            <InputLabel id="validationFormation" shrink={true}>
              Validation de la formation
            </InputLabel>
            <Controller
              name="validationFormation"
              control={control}
              render={({ field }) => (
                <Select {...field} labelId="validationFormation">
                  <MenuItem value="">
                    <em>Tous</em>
                  </MenuItem>
                  <MenuItem value="Validé">Validé</MenuItem>
                  <MenuItem value="Non Validé">Non Validé</MenuItem>
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} lg={4} container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <Button fullWidth variant="contained" startIcon={<SearchIcon />} type="submit">
              Filtrer
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button fullWidth variant="outlined" startIcon={<RefreshIcon />} onClick={() => onReset()}>
              Réinitialiser
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

TrainingToolbar.defaultProps = {
  agentId: null,
};

TrainingToolbar.propTypes = {
  agentId: PropTypes.string,
};
export default TrainingToolbar;
