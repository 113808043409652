import { Card, CardContent, CardHeader, Divider, Grid, IconButton } from "@material-ui/core";
import { ArrowForward as ArrowForwardIcon } from "@material-ui/icons";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import React from "react";
import { useQuery } from "react-query";
import { Link as RouterLink } from "react-router-dom";
import { getSalesInvestmentSummary } from "../../http/salesInvestment";
import { toEuros } from "../../utils/formatters";
import isAgent from "../../utils/isAgent";
import GuardedComponent from "../GuardedComponent";
import StatsCardValue from "../StatsCardValue";
import { getSalesVlpSummary } from "../../http/salesVlp";

const SalesInvestmentSummary = ({ agentId, fromInvestment }) => {
  const { isFetching, data: sales } = useQuery(
    ["getSalesInvestmentSummary", agentId],
    () => getSalesInvestmentSummary(agentId),
    { keepPreviousData: true }
  );

  const { isLoading, data: salesVLP } = useQuery(["getSalesVlpSummary", agentId], () => getSalesVlpSummary(agentId), {
    keepPreviousData: true,
  });
  const now = dayjs();
  const year = now.year();

  return (
    <Card sx={{ height: "100%" }}>
      <CardHeader
        title={`${fromInvestment ? "Total des ventes par étape" : "Suivi placement"} ${year}`}
        action={
          agentId && (
            <GuardedComponent componentName="team_detail_real_estate_page">
              <IconButton
                aria-label="Accéder au suivi placement du mandataire"
                component={RouterLink}
                to={isAgent() ? `/my-team/${agentId}/investment` : `/team/${agentId}/investment`}
              >
                <ArrowForwardIcon />
              </IconButton>
            </GuardedComponent>
          )
        }
      />
      <Divider />
      <CardContent
        sx={{
          p: 3,
          height: "calc(100% - 64px)",
          display: "flex",
        }}
      >
        <Grid container justify="space-around" spacing={2} justifyContent="center">
          <Grid container justify="space-around" spacing={2} sx={{ flex: { xs: "initial", xl: "1" }, marginTop: 0 }}>
            <StatsCardValue title="Encours" value={toEuros(sales?.optionsTotal)} loading={isFetching && !sales} />
            <StatsCardValue title="Synthèse VLP" value={toEuros(salesVLP?.total)} loading={isLoading && !salesVLP} />
            <StatsCardValue
              title="Souscriptions validées"
              value={toEuros(sales?.deedsTotal)}
              loading={isFetching && !sales}
              tooltip="Montants non pondérés. L'encours correspond aux dossiers dont les délais de rétractation ne sont pas échus, et à ce titre, pas encore intégrés dans votre VAVC."
            />
          </Grid>
          <Grid container justify="space-around" spacing={2} sx={{ flex: { xs: "initial", xl: "0.5" }, marginTop: 0 }}>
            <StatsCardValue
              title="Total"
              value={toEuros(sales?.total + salesVLP?.total)}
              loading={isFetching && !sales}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

SalesInvestmentSummary.propTypes = {
  fromInvestment: PropTypes.bool,
  agentId: PropTypes.string,
};

export default SalesInvestmentSummary;
