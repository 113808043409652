import dayjs from "dayjs";
import { dateToIsoString } from "../utils/formatters";
import http from "../utils/http";

export const getNewsAll = async (fromAgent, page, filters) => {
  let url = `/news?page=${page}`;

  if (filters?.title) {
    url += `&title=${filters.title}`;
  }
  if (filters?.terms) {
    url += `&terms=${filters.terms}`;
  }
  if (filters?.createdAt && filters?.createdAt[0]) {
    url += `&date[after]=${dateToIsoString(filters.createdAt[0])}`;
  }
  const now = dayjs();
  if (filters?.createdAt && filters?.createdAt[1]) {
    let dateTo = filters.createdAt[1];
    if (fromAgent && now < dateTo) {
      dateTo = now;
    }
    url += `&date[before]=${dateToIsoString(dateTo)}`;
  } else if (fromAgent) {
    url += `&date[before]=${dateToIsoString(now)}`;
  }

  if (fromAgent) {
    url += "&displayed=true";
  }

  if (filters.sliderDisplayed) {
    url += "&sliderDisplayed=true";
  }

  const response = await http.get(url);

  return response.data;
};

export const getNews = async (id) => {
  const response = await http.get(`/news/${id}`);

  return response.data;
};

export const postNews = async (data) => {
  if (data.news) {
    return patchNews(data);
  }

  return await http.post("/news", data);
};

export const patchNews = async (data) => {
  return await http.patch(data.news["@id"], data);
};

export const deleteNews = async (data) => {
  const response = await http.delete(data.news["@id"]);

  return response.data;
};

export const getNewsPopup = async () => {
  const response = await http.get("/news_list/user");

  return response.data;
};

export const patchNewsReadUser = async (data) => {
  return await http.patch(`/news_lists/${data.id}`, data);
};
