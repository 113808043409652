import { Card, CardContent, CardHeader, Divider, Grid } from "@material-ui/core";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import React from "react";
import { toEuros } from "../../utils/formatters";
import StatsCardValue from "../StatsCardValue";

const CgpSalesInvestmentSummary = ({ isFetching, sales }) => {
  const now = dayjs();
  const year = now.year();

  return (
    <Card sx={{ height: "100%" }}>
      <CardHeader title={`Suivi placement ${year}`} />
      <Divider />
      <CardContent
        sx={{
          p: 3,
          height: "calc(100% - 64px)",
          display: "flex",
        }}
      >
        <Grid container justify="space-around" spacing={2} justifyContent="center" alignContent="center">
          <Grid container justify="space-around" spacing={2} sx={{ flex: { xs: "initial", xl: "1" }, marginTop: 0 }}>
            <StatsCardValue title="Encours" value={toEuros(sales?.optionsTotal)} loading={isFetching && !sales} />
            <StatsCardValue title="Synthèse VLP" value={toEuros(sales?.vlpTotal)} loading={isFetching && !sales} />
            <StatsCardValue
              title="Souscriptions validées"
              value={toEuros(sales?.deedsTotal)}
              loading={isFetching && !sales}
              tooltip="Montants non pondérés. L'encours correspond aux dossiers dont les délais de rétractation ne sont pas échus, et à ce titre, pas encore intégrés dans votre VAVC."
            />
          </Grid>
          <Grid
            container
            justify="space-around"
            spacing={2}
            sx={{ flex: { xs: "initial", xl: "0.5" }, marginTop: 0 }}
            alignContent="center"
          >
            <StatsCardValue title="Total" value={toEuros(sales?.total)} loading={isFetching && !sales} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

CgpSalesInvestmentSummary.propTypes = {
  isFetching: PropTypes.bool,
  sales: PropTypes.object,
};

export default CgpSalesInvestmentSummary;
