import PropTypes from "prop-types";
import React, { useState } from "react";
import { Button, Grid } from "@material-ui/core";
import { Refresh as RefreshIcon, Search as SearchIcon } from "@material-ui/icons";
import { useForm } from "react-hook-form";
import AutocompleteAgentsCgpField from "../AutocompleteAgentsCgpField";
import { useTrackingContext } from "../../contexts/TrackingContext";
import { ACTION_CLICK } from "../../utils/trackingConstant";

const TeamToolbar = ({ onFilter, agentId }) => {
  const [agent, setAgent] = useState("");
  const [sponsor, setSponsor] = useState("");
  const [reset, setReset] = useState(false);

  const track = useTrackingContext()

  const { handleSubmit } = useForm({
    defaultValues: {},
  });

  const onSubmit = () => {
    const filters = {
      agent: agent,
      sponsor: sponsor,
    };

    track.click(ACTION_CLICK.SET_FILTER)
    onFilter(filters);
  };

  const resetFilters = () => {
    setAgent("");
    setSponsor("");
    setReset(!reset);
    const filters = {
      agent: "",
      sponsor: "",
    };

    track.click(ACTION_CLICK.RESET_FILTER)
    onFilter(filters);
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={6} lg={4}>
          <AutocompleteAgentsCgpField setAgent={setAgent} reset={reset} agentId={agentId} />
        </Grid>
        <Grid item xs={6} lg={4}>
          <AutocompleteAgentsCgpField setAgent={setSponsor} label="Parrain" reset={reset} agentId={agentId} />
        </Grid>
        <Grid item xs={6} lg={4} container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <Button fullWidth variant="contained" startIcon={<SearchIcon />} type="submit">
              Filtrer
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button fullWidth variant="outlined" startIcon={<RefreshIcon />} onClick={() => resetFilters()}>
              Réinitialiser
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

TeamToolbar.defaultProps = {
  agentId: null,
};

TeamToolbar.propTypes = {
  agentId: PropTypes.string,
};

export default TeamToolbar;
