import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { NavLink as RouterLink, matchPath, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { Button, ListItem, Collapse, List, Box } from "@material-ui/core";
import { ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon } from "@material-ui/icons";
import checkAccess from "../../utils/checkAccess";
import { ACTION_CLICK } from "../../utils/trackingConstant";
import { useTrackingContext } from "../../contexts/TrackingContext";

const NavItem = ({ href, icon, title, children, externalLink, onClick, userComponents, ...rest }) => {
  const location = useLocation();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (children) {
      const found = children.map((child) => child?.href).filter((href) => href === location?.pathname).length > 0;

      setOpen(open || found);
    }
    // eslint-disable-next-line
  }, [location]);

  const track = useTrackingContext();

  if (!href) {
    return (
      <>
        <ListItem
          disableGutters
          sx={{
            display: "flex",
            py: 0,
          }}
        >
          <Button
            onClick={() => setOpen(!open)}
            sx={{
              color: "text.primary",
              fontWeight: "medium",
              justifyContent: "flex-start",
              letterSpacing: 0,
              py: 1.5,
              textAlign: "left",
              textTransform: "none",
              width: "100%",
              "& svg": {
                mr: 1.25,
              },
              "& span": {
                flexGrow: 1,
              },
            }}
          >
            {icon}
            <span>{title}</span>
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Button>
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box pl={4}>
            <List component="div" disablePadding>
              {children?.map((item) =>
                item ? (
                  checkAccess(item.name, userComponents) ? (
                    <NavItem
                      href={item.href}
                      key={item.title}
                      title={item.title}
                      icon={item.icon}
                      externalLink={item.type}
                      onClick={onClick}
                    />
                  ) : null
                ) : null
              )}
            </List>
          </Box>
        </Collapse>
      </>
    );
  }

  let childAttributes = {
    target: "_blank",
    href: href,
  };

  if (externalLink === "login_oziolab") {
    childAttributes = {
      onClick: onClick,
    };
  }

  const active = href
    ? !!matchPath(location.pathname, {
        path: href,
        exact: true,
      })
    : false;

  return (
    <ListItem
      disableGutters
      sx={{
        display: "flex",
        py: 0,
      }}
    >
      {externalLink ? (
        <Button
          sx={{
            color: "text.primary",
            fontWeight: "medium",
            justifyContent: "flex-start",
            letterSpacing: 0,
            py: 1.5,
            textAlign: "left",
            textTransform: "none",
            width: "100%",
            ...(active && {
              color: "primary.main",
            }),
            "& svg": {
              mr: 1.25,
            },
          }}
          onClick={() => track.click(ACTION_CLICK.EXTERNAL_LINK)}
          {...childAttributes}
        >
          {icon}
          <span>{title}</span>
        </Button>
      ) : (
        <Button
          sx={{
            color: "text.primary",
            fontWeight: "medium",
            justifyContent: "flex-start",
            letterSpacing: 0,
            py: 1.5,
            textAlign: "left",
            textTransform: "none",
            width: "100%",
            ...(active && {
              color: "primary.main",
            }),
            "& svg": {
              mr: 1.25,
            },
          }}
          component={RouterLink}
          to={href}
        >
          {icon}
          <span>{title}</span>
        </Button>
      )}
    </ListItem>
  );
};

NavItem.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.object,
  title: PropTypes.string,
  children: PropTypes.array,
  onClick: PropTypes.func,
};

const mapStateToProps = (state) => ({
  userComponents: state.auth.components,
});

export default connect(mapStateToProps)(NavItem);
