import React from "react";
import { connect } from "react-redux";
import { Redirect, Route, useLocation } from "react-router-dom";

import * as actions from "../store/actions";
import checkAccess from "../utils/checkAccess";

const GuardedRoute = ({ componentName, isAuthenticated, userComponents, onAuthSetRedirectPath, ...props }) => {
  const location = useLocation();

  if (!isAuthenticated) {
    const { pathname, search, hash } = location;
    onAuthSetRedirectPath(pathname + search + hash);

    return <Redirect to="/login" />;
  }

  if (!checkAccess(componentName, userComponents)) {
    return <Redirect to="/forbidden" />;
  }

  return <Route {...props} />;
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.token !== null,
  userComponents: state.auth.components,
});

const mapDispatchToProps = (dispatch) => ({
  onAuthSetRedirectPath: (redirectPath) => dispatch(actions.authSetRedirectPath(redirectPath)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GuardedRoute);
