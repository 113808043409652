import PropTypes from "prop-types";
import React from "react";
import { makeStyles } from "@material-ui/core";

const NewsSummarySlide = ({ news }) => {
  const classes = useStyles();

  return (
    <>
      {news && (
        <>
          <img
            alt={news?.imageBank?.title}
            src={
              process.env.REACT_APP_API_URL +
              news?.imageBank?.mediaObject?.contentUrl
            }
            className={classes.picture}
          />
        </>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  picture: {
    width: "100%",
    minHeight: "100%",
    maxHeight: "50vh",
    objectFit: "fill",
  },
  newsText: {
    overflowWrap: "break-word",
    "& .MuiListItemText-primary": {
      [theme.breakpoints.down("lg")]: {
        fontSize: "0.8rem",
      },
    },
    "& .MuiListItemText-secondary": {
      [theme.breakpoints.down("lg")]: {
        fontSize: "0.6rem",
      },
    },
  },
}));

NewsSummarySlide.propTypes = {
  news: PropTypes.object,
};

export default NewsSummarySlide;
