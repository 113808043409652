import PropTypes from "prop-types";
import React from "react";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
  Typography,
} from "@material-ui/core";
import {
  CalendarToday as CalendarTodayIcon,
  Email as EmailIcon,
  Person as PersonIcon,
  Phone as PhoneIcon,
  Room as RoomIcon,
  History as HistoryIcon,
} from "@material-ui/icons";
import { Link as RouterLink } from "react-router-dom";
import checkAccessStore from "../../utils/checkAccessStore";
import { dateToString } from "../../utils/formatters";

const CustomerInfo = ({ customer, loading, fromAgent }) => (
  <Card>
    <CardHeader title={<Typography variant="h2">Client</Typography>} />
    <Divider />
    <CardContent>
      <Grid container spacing={0}>
        <Grid item xs={12} md={fromAgent ? 4 : 6}>
          <List>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Nom" secondary={loading ? <Skeleton width={300} /> : customer?.clientLastName} />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Prénom"
                secondary={loading ? <Skeleton width={300} /> : customer?.clientFirstName}
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <CalendarTodayIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Date de naissance"
                secondary={loading ? <Skeleton width={300} /> : dateToString(customer?.birthDate)}
              />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12} md={fromAgent ? 4 : 6}>
          <List>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <RoomIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Adresse"
                secondary={
                  loading ? (
                    <>
                      <Skeleton width={300} />
                      <Skeleton width={300} />
                    </>
                  ) : (
                    <>
                      <br />
                    </>
                  )
                }
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <EmailIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Adresse mail" secondary={loading ? <Skeleton width={300} /> : customer?.email} />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <PhoneIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Telephone" secondary={loading ? <Skeleton width={300} /> : customer?.phone} />
            </ListItem>
          </List>
        </Grid>
        {fromAgent && (customer?.lastReminder || customer?.nextReminder) && (
          <Grid item xs={12} md={4}>
            <List>
              {customer?.lastReminder && (
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <HistoryIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Dernier rappel"
                    secondary={
                      loading ? (
                        <Skeleton width={300} />
                      ) : checkAccessStore("reminders_detail_page") ? (
                        <Link component={RouterLink} to={`/my/reminders/${customer?.lastReminder?.id}`}>
                          {dateToString(customer?.lastReminder?.date)} - {customer?.lastReminder?.title}
                        </Link>
                      ) : (
                        `${dateToString(customer?.lastReminder?.date)} - ${customer?.lastReminder?.title}`
                      )
                    }
                  />
                </ListItem>
              )}
              {customer?.nextReminder && (
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <HistoryIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Prochain rappel"
                    secondary={
                      loading ? (
                        <Skeleton width={300} />
                      ) : checkAccessStore("reminders_detail_page") ? (
                        <Link component={RouterLink} to={`/my/reminders/${customer?.nextReminder?.id}`}>
                          {dateToString(customer?.nextReminder?.date)} - {customer?.nextReminder?.title}
                        </Link>
                      ) : (
                        `${dateToString(customer?.nextReminder?.date)} - ${customer?.nextReminder?.title}`
                      )
                    }
                  />
                </ListItem>
              )}
            </List>
          </Grid>
        )}
      </Grid>
    </CardContent>
  </Card>
);

CustomerInfo.propTypes = {
  customer: PropTypes.object,
  loading: PropTypes.bool,
  fromAgent: PropTypes.bool,
};

export default CustomerInfo;
