import React from "react";
import PropTypes from "prop-types";
import { Box, Typography, Badge } from "@material-ui/core";
import { Notifications as Icon } from "@material-ui/icons";

const NotificationIcon = ({ count, onClick }) => {
  return (
    <Badge
      onClick={onClick}
      overlap="circular"
      sx={{ cursor: typeof onClick === "undefined" ? "unset" : "pointer" }}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      badgeContent={
        count > 0 ? (
          <Box
            sx={{
              border: "0.05rem white solid",
              backgroundColor: "red",
              minWidth: "1rem",
              height: "1rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "0.1rem",
            }}
          >
            <Typography
              sx={{
                fontSize: "0.6rem",
                fontWeight: "700",
                color: "white",
                userSelect: "none",
              }}
            >
              {count < 99 ? count : "99+"}
            </Typography>
          </Box>
        ) : null
      }
    >
      <Icon fontSize="large" />
    </Badge>
  );
};

NotificationIcon.propTypes = {
  onClick: PropTypes.func,
  count: PropTypes.number.isRequired,
};

export default NotificationIcon;
