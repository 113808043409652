import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { useForm } from "react-hook-form";
import {
  Refresh as RefreshIcon,
  Search as SearchIcon,
} from "@material-ui/icons";

import AgentReportingRegisterFlow from "../../components/team/AgentReportingRegisterFlow";
import renderCellExpand from "../../utils/renderCellExpand";
import {
  getReportingRegisterStep,
  getTeamReportingRegister,
} from "../../http/reportingRegister";
import FilterPanel from "../../components/FilterPanel";
import store from "../../store";

const AgentStateToolBar = ({ onFilter, steps }) => {
  const defaultValues = {
    firstName: "",
    lastName: "",
    step: "",
    sponsor: "",
  };

  const form = useForm({
    defaultValues,
  });

  const handleReset = () => {
    form.reset();
    onFilter({});
  };

  const handleSubmit = form.handleSubmit((values) => {
    if (values.step === "all") values.step = "";

    onFilter({ ...values });
  });

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={4}>
        <Grid item xs={4} lg={2}>
          <TextField
            fullWidth
            variant="filled"
            name="lastName"
            label="Nom"
            {...form.register("lastName")}
          />
        </Grid>
        <Grid item xs={4} lg={2}>
          <TextField
            fullWidth
            variant="filled"
            name="firstName"
            label="Prénom"
            {...form.register("firstName")}
          />
        </Grid>
        <Grid item xs={4} lg={2}>
          <TextField
            fullWidth
            variant="filled"
            name="sponsor"
            label="Parrain"
            {...form.register("sponsor")}
          />
        </Grid>
        <Grid item xs={4} lg={2}>
          <Select defaultValue="all" {...form.register("step")}>
            <MenuItem value="all">
              <em>Tous</em>
            </MenuItem>
            {steps.map((step, index) => (
              <MenuItem key={index} value={step.id}>
                {step.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={4} lg={1} display={{ xs: "block", lg: "block" }} />
        <Grid item xs={4} lg={3} container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <Button
              fullWidth
              variant="contained"
              startIcon={<SearchIcon />}
              type="submit"
            >
              Filtrer
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant="outlined"
              startIcon={<RefreshIcon />}
              onClick={handleReset}
            >
              Réinitialiser
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

const AgentReportingRegister = () => {
  const [selected, setSelected] = useState(null);
  const [filters, setFilters] = useState({});

  const { auth } = store.getState();

  const agentQuery = useQuery({
    queryKey: ["reporting_register_team", { filters }],
    queryFn: () => getTeamReportingRegister(filters).then((resp) => resp.data),
  });

  const directChildren = useMemo(() => {
    if (!agentQuery.data) return [];

    return agentQuery.data.filter(
      (agent) => +agent.sponsorId === +auth.consultantId,
    );
  }, [agentQuery.data]);

  const stepsQuery = useQuery({
    queryKey: ["reporting_register_team", "steps"],
    queryFn: () => getReportingRegisterStep().then((resp) => resp.data),
  });

  useEffect(() => {
    if (selected) document.getElementById("agent-flow").scrollIntoView();
  }, [selected]);

  return (
    <Box display="flex" flexDirection="column" gap="2rem" p="2rem">
      <FilterPanel>
        <AgentStateToolBar
          onFilter={setFilters}
          steps={stepsQuery.data ?? []}
        />
      </FilterPanel>
      <Card>
        <CardHeader title="Mes filleuls directs"></CardHeader>
        <CardContent>
          <TeamFlowList
            agents={directChildren}
            isLoading={agentQuery.isLoading}
            onSelect={(agent) => setSelected(agent)}
          />
        </CardContent>
      </Card>
      <Card title="Mes Fieuls direct">
        <CardHeader title="Toutes mes équipes" />
        <CardContent>
          <TeamFlowList
            agents={agentQuery.data ?? []}
            isLoading={agentQuery.isLoading}
            onSelect={(agent) => setSelected(agent)}
          />
        </CardContent>
      </Card>
      {selected && (
        <Card id="agent-flow" sx={{ height: "500px", position: "relative" }}>
          <AgentReportingRegisterFlow
            agent={selected}
            steps={stepsQuery.data}
          />
        </Card>
      )}
    </Box>
  );
};

const TeamFlowList = ({ agents, isLoading, onSelect }) => {
  const columns = [
    {
      field: "lastName",
      headerName: "Nom",
      valueGetter: (params) => params.row.lastName,
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: "firstName",
      headerName: "Prénom",
      valueGetter: (params) => params.row.firstName,
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: "sponsor",
      headerName: "Parrain",
      valueGetter: (params) =>
        params.row.sponsorLastName + " " + params.row.sponsorFirstName,
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: "step",
      headerName: "Étape",
      valueGetter: (params) => params.row.stepName,
      flex: 1,
      renderCell: renderCellExpand,
    },
  ];

  const [pageSize, setPageSize] = useState(10);

  return (
    <DataGrid
      columns={columns}
      rows={agents}
      loading={isLoading}
      autoHeight
      disableColumnFilter
      disableSelectionOnClick
      disableColumnMenu
      disableColumnReorder
      pagination
      getRowId={(row) => row.id}
      onRowClick={({ row }) => onSelect(row)}
      rowsPerPageOptions={[5, 10, 25, 50, 100]}
      pageSize={pageSize}
      onPageSizeChange={(params) => setPageSize(params.pageSize)}
    />
  );
};
export default AgentReportingRegister;
