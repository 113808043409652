import formatFileName from "../utils/formatFileName";
import http from "../utils/http";
import openFile from "../utils/openFile";
import store from "../store";

export const getProspects = async (filters, page, order) => {
  let url = "/prospects";

  url = applyFilters(filters, order, url, page);

  const response = await http.get(url);

  return response.data;
};

export const postProspects = async (data) => {
  const state = store.getState();
  data.append("consultant", `consultants/${state.auth.consultantId}`);

  return await http.post("/prospects", data);
};

export const patchProspect = async (data) => {
  return await http.post(`/prospect/${data.prospect.id}/files`, data.data);
};

export const getProspectDetails = async (id) => {
  const url = `/prospects/${id}`;
  const response = await http.get(url);

  return response.data;
};

export const getExportProspects = async (data) => {
  const filters = data.filters;
  const order = data.order;
  let url = "/prospects/export";
  url = applyFilters(filters, order, url);

  let fileName = await formatFileName("prospects");

  return await openFile(url, `${fileName}.xlsx`, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
};

const applyFilters = (filters, order, url, page) => {
  let hasFilter = false;

  const filtersUrl = [];
  if (page) {
    filtersUrl.push(`page=${page + 1}`);
    hasFilter = true;
  }
  if (filters?.lastName) {
    filtersUrl.push(`lastName=${filters.lastName}`);
    hasFilter = true;
  }
  if (filters?.firstName) {
    filtersUrl.push(`firstName=${filters.firstName}`);
    hasFilter = true;
  }
  if (order) {
    order.forEach((orderField) => {
      filtersUrl.push(`order[${orderField.field}]=${orderField.sort}`);
    });
    hasFilter = true;
  }

  if (hasFilter) {
    url += "?";
  }

  filtersUrl.forEach((filter, index) => {
    url += filter;
    if (index < filtersUrl.length - 1) url += "&";
  });

  return url;
};
