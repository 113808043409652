import React from "react";
import { Typography } from "@material-ui/core";

const Disclaimer = () => (
  <Typography component="div" variant="body1">
    <p style={{ marginBottom: 10 }}>
      Le réseau Hubn’UP regroupe des consultants labellisés dont la vocation est de préconiser auprès des particuliers
      et des professionnels des solutions patrimoniales dans le domaine de l’immobilier et des placements financiers.
    </p>
    <p style={{ marginBottom: 10 }}>Pour répondre aux attentes des français :</p>
    <ul style={{ marginBottom: 10, paddingLeft: 25 }}>
      <li>préparer leur retraite,</li>
      <li>optimiser leur fiscalité,</li>
      <li>sécuriser leur avenir...</li>
    </ul>
    <p style={{ marginBottom: 10 }}>
      Le réseau de consultants Hubn’UP se développe par le Marketing Relationnel sur l’ensemble du territoire national
      avec comme seul objectif :
    </p>
    <p style={{ marginBottom: 10, fontWeight: 700 }}>
      LA SATISFACTION DE SES CLIENTS, SEUL GAGE DE CONFIANCE ET DE RELATIONS DURABLES.
    </p>
  </Typography>
);

export default Disclaimer;
