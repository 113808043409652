import { useState } from "react";

const useToggle = () => {
  const [isDisplayed, setIsDisplayed] = useState(false);

  const toggleAction = () => { 
    setIsDisplayed( state => !state );
  }
  
  return {
    isDisplayed, 
    setIsDisplayed,
    toggleAction
  };
};

export default useToggle;
