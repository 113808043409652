import React from "react";
import { Card, CardHeader, Container, Divider, Typography } from "@material-ui/core";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import AppCircularProgress from "../../components/AppCircularProgress";
import Breadcrumbs from "../../components/Breadcrumbs";
import HubnupUsersFormContent from "../../components/user/HubnupUsersFormContent";
import { getProfiles } from "../../http/profiles";
import { getUser } from "../../http/user";

const HubnupUsersForm = () => {
  const { id } = useParams();
  const { isFetched: isFetchedProfiles, data: profiles } = useQuery(["getProfiles"], () => getProfiles());

  const { isFetching, data: user } = useQuery(
    ["getUser", id],
    async () => {
      const data = await getUser(id);

      return {
        ...data,
        profile: data.roles[0],
      };
    },
    {
      enabled: !!id,
    }
  );

  return (
    <Container maxWidth={false}>
      <Breadcrumbs
        append={<Typography color="textPrimary">{id ? "Editer un utilisateur" : "Créer un utilisateur"}</Typography>}
      />

      <Card>
        <CardHeader title={id ? "Editer un utilisateur" : "Créer un utilisateur"} />
        <Divider />
        {isFetchedProfiles ? (
          <>
            {id ? (
              !isFetching ? (
                <HubnupUsersFormContent user={user} profiles={profiles} />
              ) : (
                <AppCircularProgress />
              )
            ) : (
              <HubnupUsersFormContent profiles={profiles} />
            )}
          </>
        ) : (
          <AppCircularProgress />
        )}
      </Card>
    </Container>
  );
};

export default HubnupUsersForm;
