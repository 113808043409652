import { faClone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Button, CardContent, Checkbox, Divider, FormControlLabel, Grid, Stack, TextField } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { DatePicker, LoadingButton } from "@material-ui/lab";
import { Check as CheckIcon, Delete as DeleteIcon } from "@material-ui/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { postReminders } from "../../http/reminders";
import RemindersDeleteDialog from "./RemindersDeleteDialog";
import RemindersDuplicateModal from "./RemindersDuplicateModal";
import { useTrackingContext } from "../../contexts/TrackingContext";
import { ACTION_CLICK } from "../../utils/trackingConstant";

const HubnupRemindersFormContent = ({ reminder }) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openDuplicateModal, setOpenDuplicateModal] = useState(false);
  const track = useTrackingContext()

  const handleClickOpenDuplicateModal = () => {
    setOpenDuplicateModal(true);
  };
  const handleCloseDuplicateModal = () => {
    setOpenDuplicateModal(false);
  };

  const schema = yup.object().shape({
    title: yup.string().required(),
    content: yup.string(),
    date: yup.date().nullable().required(),
    displayed: yup.boolean(),
  });

  const { control, handleSubmit } = useForm({
    defaultValues: {
      title: reminder?.title || "",
      content: reminder?.content || "",
      date: reminder?.date || null,
      displayed: reminder?.displayed || false,
    },
    resolver: yupResolver(schema),
  });

  const goBack = () => {
    if (history.location.state) {
      history.go(history.location.state.goBack);
    }
    history.goBack();
  };

  const onSubmit = async (data) => {
    track.click(ACTION_CLICK.EDIT_REMINDER)
    mutation.mutate({
      ...data,
      reminder,
    });
  };

  const mutation = useMutation(postReminders, {
    onSuccess: () => {
      if (reminder) {
        enqueueSnackbar("Le rappel a bien été modifié.", { variant: "success" });
        goBack();
      } else {
        enqueueSnackbar("Le rappel a bien été créé.", { variant: "success" });
        goBack();
      }
    },
  });

  return (
    <>
      <CardContent>
        <form noValidate id="edit-form" key="edit-form" onSubmit={handleSubmit(onSubmit)}>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Controller
                name="date"
                control={control}
                render={({ field, formState: { errors } }) => (
                  <DatePicker
                    {...field}
                    label="Date du rappel"
                    inputFormat="DD/MM/YYYY"
                    shouldUnregister
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="dense"
                        error={Boolean(errors.date?.message)}
                        helperText={errors.date?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ mt: { xs: 1, sm: 1.5 } }}>
              <Controller
                name="displayed"
                control={control}
                shouldUnregister
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox onChange={(e) => field.onChange(e.target.checked)} checked={field.value} />}
                    label="Accessible aux mandataires"
                  />
                )}
              />
            </Grid>
          </Grid>
          <Controller
            name="title"
            control={control}
            shouldUnregister
            render={({ field, formState: { errors } }) => (
              <TextField
                {...field}
                fullWidth
                label="Titre rappel"
                margin="normal"
                error={Boolean(errors.title?.message)}
                helperText={errors.title?.message}
              />
            )}
          />
          <Controller
            name="content"
            control={control}
            shouldUnregister
            render={({ field, formState: { errors } }) => (
              <TextField
                {...field}
                fullWidth
                label="Rappel (texte libre)"
                margin="normal"
                multiline
                minRows={10}
                error={Boolean(errors.content?.message)}
                helperText={errors.content?.message}
              />
            )}
          />
        </form>
      </CardContent>
      <Divider />
      <Stack
        direction={{ xs: "column-reverse", sm: "row" }}
        spacing={2}
        sx={{
          p: 2,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button variant="outlined" onClick={goBack}>
          Annuler
        </Button>
        {reminder && (
          <>
            <Button
              variant="contained"
              color="hubnup"
              startIcon={<DeleteIcon />}
              onClick={() => setOpenDeleteModal(true)}
            >
              Supprimer
            </Button>
            <Button
              variant="contained"
              startIcon={<FontAwesomeIcon icon={faClone} />}
              onClick={handleClickOpenDuplicateModal}
            >
              Dupliquer
            </Button>
          </>
        )}
        <LoadingButton
          loading={mutation.isLoading}
          variant="contained"
          type="submit"
          form="edit-form"
          startIcon={<CheckIcon />}
        >
          Valider
        </LoadingButton>

        {reminder && (
          <RemindersDeleteDialog
            isOpen={openDeleteModal}
            hasMany={false}
            remindersToDelete={[`/reminders/${reminder.id}`]}
            onClose={() => setOpenDeleteModal(false)}
            goBack="/communication/reminders"
          />
        )}

        {openDuplicateModal && (
          <RemindersDuplicateModal reminder={reminder} open={openDuplicateModal} onClose={handleCloseDuplicateModal} />
        )}
      </Stack>
    </>
  );
};

HubnupRemindersFormContent.propTypes = {
  reminder: PropTypes.object,
};

export default HubnupRemindersFormContent;
