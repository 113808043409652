export const TRACKING_TYPE = {
  CLICK: "CLICK",
  PAGE: "PAGE",
  DOCUMENT: "DOCUMENT",
};

export const ACTION_CLICK = {
  SET_FILTER: "SET_FILTER",
  RESET_FILTER: "RESET_FILTER",
  PAGINATION: "PAGINATION",

  ADMIN_RESET_PASSWORD: "ADMIN_RESET_PASSWORD",
  ADMIN_DISABLED_USER: "ADMIN_DISABLED_USER",
  ADMIN_DELETE_USER: "ADMIN_DELETE_USER",

  CREATE_PROFIL: "CREATE_PROFIL",
  DELETE_PROFIL: "DELETE_PROFIL",
  UPDATE_PROFIL: "UPDATE_PROFIL",

  DOCUMENT_OPEN_FOLDER: "DOCUMENT_OPEN_FOLDER",
  DOCUMENT_CREATE_FOLDER: "DOCUMENT_CREATE_FOLDER",
  DOCUMENT_EDIT_FOLDER: "DOCUMENT_EDIT_FOLDER",
  DOCUMENT_COPY_LINK: "DOCUMENT_COPY_LINK",
  DOCUMENT_ADD: "DOCUMENT_ADD",
  DOCUMENT_DOWNLOAD: "DOCUMENT_DOWNLOAD",
  DOCUMENT_CREATE_REQUEST: "DOCUMENT_CREATE_REQUEST",

  EDIT_MY_EMAIL: "EDIT_MY_EMAIL",
  EDIT_MY_PASSWORD: "EDIT_MY_PASSWORD",
  EDIT_MY_PICTURE: "EDIT_MY_PICTURE",
  SEND_MY_REQUEST: "SEND_MY_REQUEST",

  CREATE_REMINDER: "CREATE_REMINDER",
  UPDATE_REPORTING: "UPDATE_REPORTING",
  DELETE_REMINDER: "DELETE_REMINDER",
  EDIT_REMINDER: "EDIT_REMINDER",
  DUPLICATE_REMINDER: "DUPLICATE_REMINDER",

  EXPORT_MY_TRAINING: "EXPORT_MY_TRAINING",
  EXPORT_MY_SALES_IMMO: "EXPORT_MY_SALES_IMMO",
  EXPORT_MY_SALES_IMMO_CLIENT: "EXPORT_MY_SALES_IMMO_CLIENT",
  EXPORT_MY_SALES_INVEST: "EXPORT_MY_SALES_INVEST",
  EXPORT_MY_SALES_VLP: "EXPORT_MY_SALES_VLP",

  EXPORT_TEAM_VA: "EXPORT_TEAM_VA",
  EXPORT_TEAM_INFOS: "EXPORT_TEAM_INFOS",
  EXPORT_TEAM_IMMO: "EXPORT_TEAM_IMMO",
  EXPORT_TEAM_INVEST: "EXPORT_TEAM_INVEST",

  EXPORT_TEAM_TRAINING: "EXPORT_TEAM_TRAINING",
  EXPORT_TEAM_DETAILS: "EXPORT_TEAM_DETAILS",

  EXPORT_MY_CUSTOMERS: "EXPORT_MY_CUSTOMERS",
  EXPORT_MY_PROSPECTS: "EXPORT_MY_PROSPECTS",

  EXPORT_SALES_INVEST: "EXPORT_SALES_INVEST",
  EXPORT_SALES_IMMO: "EXPORT_SALES_IMMO",
  EXPORT_SALES_VLP: "EXPORT_SALES_VLP",

  EXPORT_CUSTOMERS: "EXPORT_CUSTOMERS",
  EXPORT_PROSPECTS: "EXPORT_PROSPECTS",

  CREATE_PROSPECT: "CREATE_PROSPECT",
  CREATE_NEWS: "CREATE_NEWS",

  ADD_PICTURE: "ADD_PICTURE",

  NOTY_TOGGLE_RULE: "NOTY_TOGGLE_RULE",
  NOTY_EDIT: "NOTY_EDIT",

  EXTERNAL_LINK: "EXTERNAL_LINK",
};
