import PropTypes from "prop-types";
import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

const SelectYearsInput = ({ value, setValue }) => {
  const year = new Date().getFullYear();
  const years = Array.from(new Array(20), (val, index) => year - index);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <FormControl fullWidth sx={{ minWidth: "200px" }}>
      <InputLabel id="sale-state-label">Années</InputLabel>
      <Select labelId="select-years" multiple MenuProps={MenuProps} value={value} onChange={handleChange}>
        {years.map((year, index) => {
          return (
            <MenuItem key={`year${index}`} value={year}>
              {year}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

SelectYearsInput.propTypes = {
  control: PropTypes.object,
  name: PropTypes.string,
};

export default SelectYearsInput;
