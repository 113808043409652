import { Card, CardContent, CardHeader, Divider, Grid } from "@material-ui/core";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import React from "react";
import { toEuros } from "../../utils/formatters";
import StatsCardValue from "../StatsCardValue";

const CgpSalesRealEstateSummary = ({ isFetching, sales }) => {
  const now = dayjs();
  const year = now.year();

  return (
    <Card>
      <CardHeader title={`Suivi immobilier ${year}`} />
      <Divider />
      <CardContent
        sx={{
          p: 3,
        }}
      >
        <Grid container justify="space-around" spacing={2} justifyContent="center">
          <Grid container justify="space-around" spacing={2} sx={{ flex: { xs: "initial" }, marginTop: 0 }}>
            <StatsCardValue title="Option" value={toEuros(sales?.optionsTotal)} loading={isFetching && !sales} />
            <StatsCardValue title="Réservation" value={toEuros(sales?.bookingsTotal)} loading={isFetching && !sales} />
            <StatsCardValue title="Financé" value={toEuros(sales?.finance)} loading={isFetching && !sales} />
          </Grid>
          <Grid container justify="space-around" spacing={2} sx={{ flex: { xs: "initial" }, marginTop: 0 }}>
            <StatsCardValue
              title="Encours"
              value={toEuros(sales?.encours)}
              loading={isFetching && !sales}
              tooltip="L'encours immobilier correspond aux dossiers aux étapes Réservation et accord bancaire. Il ne comprend pas les options immobilières."
            />
            <StatsCardValue title="Acté" value={toEuros(sales?.deedsTotal)} loading={isFetching && !sales} />
            <StatsCardValue title="Total" value={toEuros(sales?.total)} loading={isFetching && !sales} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

CgpSalesRealEstateSummary.propTypes = {
  isFetching: PropTypes.bool,
  sales: PropTypes.object,
};

export default CgpSalesRealEstateSummary;
