import React from "react";
import { Card, Container, Typography } from "@material-ui/core";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import AppCircularProgress from "../../components/AppCircularProgress";
import Breadcrumbs from "../../components/Breadcrumbs";
import HubnupUsersDetailsContent from "../../components/user/HubnupUsersDetailsContent";
import { getProfiles } from "../../http/profiles";
import { getUser } from "../../http/user";

const HubnupUsersDetails = () => {
  const { id } = useParams();
  const { isFetched: isFetchedProfiles, data: profiles } = useQuery(["getProfiles"], () => getProfiles());

  const { isFetched, data: user } = useQuery(["getUser", id], async () => {
    const data = await getUser(id);

    return {
      ...data,
      profile: data.roles[0],
    };
  });

  return (
    <Container maxWidth={false}>
      <Breadcrumbs append={<Typography color="textPrimary">Détails utilisateur</Typography>} />

      <Card>
        {isFetchedProfiles && isFetched ? (
          <HubnupUsersDetailsContent user={user} profiles={profiles} />
        ) : (
          <AppCircularProgress />
        )}
      </Card>
    </Container>
  );
};

export default HubnupUsersDetails;
