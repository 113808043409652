import PropTypes from "prop-types";
import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { Controller } from "react-hook-form";

const ControlledSelectYearsInput = ({ control, name }) => {
  const year = new Date().getFullYear();
  const years = Array.from(new Array(20), (val, index) => year - index);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <FormControl fullWidth variant="filled">
      <InputLabel id="sale-state-label" shrink={true}>
        Années
      </InputLabel>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Select {...field} labelId="select-years" multiple MenuProps={MenuProps}>
            {years.map((year, index) => {
              return (
                <MenuItem key={`year${index}`} value={year}>
                  {year}
                </MenuItem>
              );
            })}
          </Select>
        )}
      />
    </FormControl>
  );
};

ControlledSelectYearsInput.propTypes = {
  control: PropTypes.object,
  name: PropTypes.string,
};

export default ControlledSelectYearsInput;
