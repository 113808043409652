import PropTypes from "prop-types";
import React from "react";
import { Button, Grid, TextField } from "@material-ui/core";
import { Refresh as RefreshIcon, Search as SearchIcon } from "@material-ui/icons";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTrackingContext } from "../../contexts/TrackingContext";
import { ACTION_CLICK } from "../../utils/trackingConstant";

const PicturesToolbar = ({ onFilter }) => {
  const schema = yup.object().shape({
    title: yup.string(),
  });
  const defaultValues = {
    title: "",
  };

  const track = useTrackingContext()

  const { control, handleSubmit, reset } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  const onReset = () => {
    reset();
    onFilter(defaultValues);
    track.click(ACTION_CLICK.RESET_FILTER)
  };

  const onSubmit = (values) => {
    track.click(ACTION_CLICK.SET_FILTER)
    onFilter(values);
  }

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs>
          <Controller
            name="title"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Titre"
                variant="filled"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <Button fullWidth variant="contained" startIcon={<SearchIcon/>} type="submit">
              Filtrer
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button fullWidth variant="outlined" startIcon={<RefreshIcon/>} onClick={() => onReset()}>
              Réinitialiser
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

PicturesToolbar.propTypes = {
  onFilter: PropTypes.func,
};

export default PicturesToolbar;
