import PropTypes from "prop-types";
import React from "react";
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@material-ui/core";
import { Controller } from "react-hook-form";

const ControlledSelectInputField = ({ control, name, title, list, disabled }) => {

  return (
    <FormControl fullWidth variant="filled">
      <InputLabel id="sale-state-label" shrink={true}>
        {title}
      </InputLabel>
      <Controller
        name={name}
        control={control}
        render={({ field, formState: { errors } }) => {
          const isError = Boolean(errors[name]?.message);

          return (
            <>
              <Select 
                {...field} 
                labelId={`select-${title}`}
                autoWidth
                disabled={disabled}
              >
                {list.map((item, index) => {
                  return (
                    <MenuItem key={`year${index}`} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
              {isError && <FormHelperText error>{errors[name]?.message}</FormHelperText>}
            </>
        )}}
      />
    </FormControl>
  );
};

ControlledSelectInputField.propTypes = {
  control: PropTypes.object,
  name: PropTypes.string,
  list: PropTypes.array,
  title: PropTypes.string
};

export default ControlledSelectInputField;
