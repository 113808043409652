import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, TextField } from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";

const LoginForm = (props) => {
  const schema = yup.object().shape({
    email: yup.string().required("Ce champ est obligatoire.").email("Cette valeur n'est pas une adresse email valide."),
    password: yup.string().required("Ce champ est obligatoire."),
  });

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async ({ email, password }) => {
    try {
      await props.onSubmit(email, password);
    } catch (e) {
      props.onError(e);
    }
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <TextField
        error={Boolean(errors.email?.message)}
        fullWidth
        helperText={errors.email?.message}
        label="Adresse email"
        margin="normal"
        type="email"
        variant="outlined"
        autoComplete="email"
        autoFocus
        defaultValue=""
        {...register("email")}
      />
      <TextField
        error={Boolean(errors.password?.message)}
        fullWidth
        helperText={errors.password?.message}
        label="Mot de passe"
        margin="normal"
        type="password"
        variant="outlined"
        autoComplete="current-password"
        defaultValue=""
        {...register("password")}
      />
      <Box py={2}>
        <LoadingButton color="primary" loading={isSubmitting} fullWidth size="large" type="submit" variant="contained">
          Connexion
        </LoadingButton>
      </Box>
    </form>
  );
};

export default LoginForm;
