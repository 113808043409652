import React from "react";
import { Button, Grid, TextField, Typography, FormControlLabel, Checkbox } from "@material-ui/core";
import {
  Refresh as RefreshIcon,
  Search as SearchIcon,
} from "@material-ui/icons";
import { MobileDateRangePicker } from "@material-ui/lab";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTrackingContext } from "../../contexts/TrackingContext";
import { ACTION_CLICK } from "../../utils/trackingConstant";

const schema = yup.object().shape({
  title: yup.string(),
  createdAt: yup.array().of(yup.date().nullable()),
  terms: yup.string(),
  sliderDisplayed: yup.boolean(),
});

const NewsToolbar = ({ onFilter }) => {
  const defaultValues = {
    title: "",
    createdAt: [null, null],
    terms: "",
    sliderDisplayed: false,
  };

  const track = useTrackingContext()

  const { control, handleSubmit, reset, setValue } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  const onSubmit = (values) => {
    track.click(ACTION_CLICK.SET_FILTER)
    onFilter(values)
  }

  const onReset = () => {
    reset();
    track.click(ACTION_CLICK.RESET_FILTER)
    onFilter(defaultValues);
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs>
          <Controller
            name="title"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Titre"
                variant="filled"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs>
          <Controller
            name="terms"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Contient"
                variant="filled"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs>
          <Controller
            name="createdAt"
            control={control}
            onChange={(event, newValue) => setValue("createdAt", newValue)}
            render={({ field }) => (
              <MobileDateRangePicker
                {...field}
                startText="Date de publication du"
                endText="au"
                inputFormat="DD/MM/YYYY"
                allowSameDateSelection
                okText="Valider"
                cancelText="Annuler"
                toolbarTitle="Choisissez une plage de date"
                renderInput={(startProps, endProps) => (
                  <>
                    <TextField
                      {...startProps}
                      fullWidth
                      variant="filled"
                      InputLabelProps={{ shrink: true }}
                      helperText=""
                    />
                    <Typography sx={{ mx: 2 }} variant="body1">
                      {" "}
                      -{" "}
                    </Typography>
                    <TextField
                      {...endProps}
                      fullWidth
                      variant="filled"
                      InputLabelProps={{ shrink: true }}
                      helperText=""
                    />
                  </>
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs>
          <Controller
            name="sliderDisplayed"
            control={control}
            shouldUnregister
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => field.onChange(e.target.checked)}
                    checked={field.value}
                  />
                }
                label="Affiché en Carousel"
              />
            )}
          />
        </Grid>
        <Grid item xs container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <Button
              fullWidth
              variant="contained"
              startIcon={<SearchIcon />}
              type="submit"
            >
              Filtrer
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant="outlined"
              startIcon={<RefreshIcon />}
              onClick={onReset}
            >
              Réinitialiser
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default NewsToolbar;
