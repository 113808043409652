import PropTypes from "prop-types";
import React, { useState } from "react";
import { Button, Container, Box, DialogActions, Divider } from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import AppDialog from "../AppDialog";
import AddPicture from "../picturesLibrary/AddPicture";
import PicturesLibraryContent from "../picturesLibrary/PicturesLibraryContent";

const NewsAddPictureModal = ({ open, title, onClose, onClick }) => {
  const [openCreateModal, setOpenCreateModal] = useState(false);

  const handleClickOpenCreateModal = () => setOpenCreateModal(true);
  const handleCloseCreateModal = (result) => {
    setOpenCreateModal(false);
    if (result && result.id) {
      onClick(result);
      onClose();
    }
  };

  return (
    <AppDialog open={open} onClose={onClose} title={title} maxWidth="lg">
      <Container>
        <Box sx={{ mt: 3, mb: 3 }}>
          <Button variant="contained" startIcon={<AddIcon />} onClick={handleClickOpenCreateModal}>
            Ajouter une image
          </Button>
          <AddPicture open={openCreateModal} onClose={handleCloseCreateModal} title="Ajouter une image" />
        </Box>

        <PicturesLibraryContent modalMode={true} onClick={onClick} />
      </Container>
      <Divider />
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Annuler
        </Button>
      </DialogActions>
    </AppDialog>
  );
};

NewsAddPictureModal.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func,
  onClick: PropTypes.func,
};

export default NewsAddPictureModal;
