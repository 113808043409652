import { yupResolver } from "@hookform/resolvers/yup";
import { DatePicker, LoadingButton } from "@material-ui/lab";
import PropTypes from "prop-types";
import React from "react";
import { Button, DialogActions, DialogContent, Divider, TextField, Alert } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import * as yup from "yup";
import { postNews } from "../../http/news";
import AppDialog from "../AppDialog";

const NewsDuplicateModal = ({ open, onClose, news }) => {
  const queryClient = useQueryClient();
  const schema = yup.object().shape({
    title: yup.string().required("Ce champ est obligatoire."),
    date: yup.date().nullable().required("Ce champ est obligatoire."),
  });

  const { handleSubmit, control, unregister } = useForm({
    defaultValues: {
      title: "",
      date: null,
    },
    resolver: yupResolver(schema),
  });

  const handleClose = (reset = false) => {
    unregister("title");
    unregister("date");
    onClose(reset);
  };

  const onSubmit = async (data) => {
    const duplicatedNews = {
      title: data.title,
      date: data.date,
      imageBank: news.imageBank["@id"],
      displayed: false,
      content: news.content,
    };

    mutationDuplicate.mutate(duplicatedNews);
  };

  const mutationDuplicate = useMutation(postNews, {
    onSuccess: (result) => {
      queryClient.invalidateQueries("getNewsAll");
      handleClose(true);
    },
  });

  return (
    <AppDialog open={open} onClose={handleClose} title="Dupliquer une actualité">
      <form id="duplicate-form" noValidate onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          {mutationDuplicate.isError ? (
            <Alert severity="error">Une erreur est survenue : {mutationDuplicate.error.message}</Alert>
          ) : null}

          <Controller
            name="title"
            control={control}
            variant="outlined"
            shouldUnregister
            render={({ field, formState: { errors } }) => (
              <TextField
                {...field}
                error={Boolean(errors.title?.message)}
                helperText={errors.title?.message}
                label="Titre"
                margin="normal"
                autoComplete="title"
                fullWidth
              />
            )}
          />
          <Controller
            name="date"
            control={control}
            shouldUnregister
            render={({ field, formState: { errors } }) => (
              <DatePicker
                {...field}
                label="Date de publication"
                inputFormat="DD/MM/YYYY"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="dense"
                    error={Boolean(errors.date?.message)}
                    helperText={errors.date?.message}
                    fullWidth
                  />
                )}
              />
            )}
          />
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Annuler
          </Button>
          <LoadingButton loading={mutationDuplicate.isLoading} type="submit" variant="contained" form="duplicate-form">
            Enregistrer
          </LoadingButton>
        </DialogActions>
      </form>
    </AppDialog>
  );
};

NewsDuplicateModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  news: PropTypes.object,
};

export default NewsDuplicateModal;
