import { Card, CardContent, CardHeader, Divider, Grid } from "@material-ui/core";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import React from "react";
import { useQuery } from "react-query";
import { toEuros } from "../../utils/formatters";
import StatsCardValue from "../StatsCardValue";
import { getSalesVlpSummary } from "../../http/salesVlp";

const SalesVlpSummary = ({ agentId, fromVlp }) => {
  const { isFetching, data: sales } = useQuery(["getSalesVlpSummary", agentId], () => getSalesVlpSummary(agentId), {
    keepPreviousData: true,
  });

  const now = dayjs();
  const year = now.year();

  return (
    <Card sx={{ height: "100%" }}>
      <CardHeader title={`${fromVlp ? "Total des ventes par étape" : "Suivi Vlp"} ${year}`} />
      <Divider />
      <CardContent
        sx={{
          p: 3,
          height: "calc(100% - 64px)",
          display: "flex",
        }}
      >
        <Grid container justify="space-around" spacing={2} justifyContent="center">
          <Grid container justify="space-around" spacing={2} sx={{ flex: { xs: "initial", xl: "1" }, marginTop: 0 }}>
            <StatsCardValue title="Total VLP validés" value={toEuros(sales?.total)} loading={isFetching && !sales} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

SalesVlpSummary.propTypes = {
  fromVlp: PropTypes.bool,
  agentId: PropTypes.string,
};

export default SalesVlpSummary;
