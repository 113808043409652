import { isOverflown } from "@material-ui/data-grid";
import React, { useEffect, useState, useRef } from "react";
import { Typography, Paper, Popper, Box } from "@material-ui/core";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import "fontsource-roboto";

const GridCellExpand = ({ width, customCell, type, children }) => {
  const wrapper = useRef(null);
  const cellDiv = useRef(null);
  const cellValue = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const [showFullCell, setShowFullCell] = useState(false);
  const [showPopper, setShowPopper] = useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === "Escape" || nativeEvent.key === "Esc") {
        setShowFullCell(false);
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <div
      ref={wrapper}
      className={`${classes.root} cell-${type}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        ref={cellDiv}
        style={{
          height: 1,
          width,
          display: "block",
          position: "absolute",
          top: 0,
        }}
      />
      <div ref={cellValue} className="cellValue">
        {children}
      </div>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{ width: "fit-content", fontSize: "0.875rem" }}
        >
          <Paper elevation={1} style={{ minHeight: wrapper.current.offsetHeight - 3 }}>
            {customCell ? (
              <Box sx={{ padding: 1 }}>{children}</Box>
            ) : (
              <Typography variant="body2" style={{ padding: 8 }}>
                {children}
              </Typography>
            )}
          </Paper>
        </Popper>
      )}
    </div>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    alignItems: "center",
    lineHeight: "24px",
    width: "100%",
    height: "100%",
    position: "relative",
    display: "flex",

    "& .cellValue": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontSize: "0.875rem",
    },

    "&.cell-number": {
      justifyContent: "flex-end",
      fontSize: "0.875rem",
    },
  },
}));

GridCellExpand.defaultProps = {
  customCell: false,
};

GridCellExpand.propTypes = {
  width: PropTypes.number,
  type: PropTypes.string,
  customCell: PropTypes.bool,
};

export default GridCellExpand;
