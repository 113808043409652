import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);
dayjs.locale("fr");

export const toEuros = (value) => {
  return new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
    maximumFractionDigits: 0,
  }).format(value ?? 0);
};

export const toPercents = (value, decimals = 2) =>
  new Intl.NumberFormat("fr-FR", {
    style: "percent",
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(value);

export const toBigNumbers = (value) => {
  if (value >= 500000) {
    return `${value / 1000000}M`;
  }

  if (value >= 500) {
    return `${value / 1000}k`;
  }

  return value;
};

export const dateToString = (value) => (value && dayjs(value).isValid() ? dayjs(value).format("DD/MM/YYYY") : "");

export const dateToStringYmd = (value) => (value && dayjs(value).isValid() ? dayjs(value).format("YYYY-MM-DD") : "");

export const dateToIsoString = (value) => dayjs(value).format("DD-MM-YYYY");

export const dateToRelativeString = (value) => dayjs(value).fromNow();

export const getRatio = (value, min, max) => ((value - min) / (max - min)) * 100;

export const isDateAfterOneWeekAgo = (value) => {
  const valueDayjs = dayjs.isDayjs(value) ? value : dayjs(value);
  const oneWeekAgo = dayjs().subtract(7, "day");

  return valueDayjs.isAfter(oneWeekAgo);
}
 
/* transform rich editor WISYWIG html to clean HTML */
export const contentText = (news = "") => {
  const regex = /<[^>]{1,10}>/g;
  
  return !news?.match(regex) ? news.replace(/(?:\r\n|\r|\n)/g, "<br>") : news
}

// get only the content from HTML
/* export const stripHtml = (html) => {
  return html.replace(/<[^>]*>/g, ' ')
             .replace(/\s{2,}/g, ' ')
             .replace(/&nbsp;/g, '')
             .trim();
} */

export const getFilenameWithoutExtension = (fileNameWithExtension) => {
  if (!fileNameWithExtension) {
    return "";
  }

  const parts = fileNameWithExtension.split('.');

  if (parts.length === 1) {
    return fileNameWithExtension;
  }

  return parts.slice(0, -1).join('.');
}


// get list of years by range and actualYear
export const yearsByRange = (rangeYears, actualYear) => {

  return Array(rangeYears).fill("").map((v, idx) => actualYear + idx - (rangeYears - 1));
}
