import store from "../store";
import formatFileName from "../utils/formatFileName";
import http from "../utils/http";
import openFile from "../utils/openFile";

export const getEvents = async (filters, page, agentId) => {
  let url = "/events";

  if (agentId) {
    url += `?bddConsultant.idCgp=${agentId}`;
    if (filters) {
      url += "&";
    }
  }

  url = applyFilters(filters, url, page, agentId);

  const response = await http.get(url);

  return response.data;
};

export const getEvent = async (agentId) => {
  let url = `/events?bddConsultant.idCgp=${agentId}`;

  const response = await http.get(url);

  return response.data;
};

export const getTrainingCount = async (agentId, exercice) => {
  let url = `/events/trainingCount?cgpId=${agentId}&exercice=${exercice}`;

  const response = await http.get(url);

  return response.data;
};

export const getEventsTeam = async (filters, agentId) => {
  let url = "/events/team";

  if (agentId) {
    url += `?cgpId=${agentId}`;
    if (filters) {
      url += "&";
    }
  }

  url = applyFilters(filters, url, null, agentId);

  // add filter for pagination

  const response = await http.get(url);

  return response.data;
};

export const getEventsCount = async (filters, agentId) => {
  let url = "/events/count";

  if (agentId) {
    url += `?cgpId=${agentId}`;
    if (filters) {
      url += "&";
    }
  }

  url = applyFilters(filters, url, null, agentId);

  const response = await http.get(url);

  return response.data;
};

export const getExportTraining = async (data) => {
  let url = "/events/export";

  if (data.agentId) {
    url += `?bddConsultant.idCgp=${data.agentId}`;
    if (data.filters) {
      url += "&";
    }
  }

  url = applyFilters(data.filters, url, null, data.agentId);

  let fileName = await formatFileName("formations", data.agentId);

  return await openFile(url, `${fileName}.xlsx`, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
};

export const getExportEventsTeam = async (data) => {
  let url = "/cgps/export/events";

  if (data.agentId) {
    url += `?cgpId=${data.agentId}`;
    if (data.filters) {
      url += "&";
    }
  }

  url = applyFilters(data.filters, url, null, data.agentId);

  let fileName = await formatFileName("formation-equipe", data.agentId);

  return await openFile(url, `${fileName}.xlsx`, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
};

export const getExportEventsDetails = async (data) => {
  let url = "/cgps/export/eventsDetails";

  if (data.agentId) {
    url += `?cgpId=${data.agentId}`;
    if (data.filters) {
      url += "&";
    }
  }

  url = applyFilters(data.filters, url, null, data.agentId);

  let fileName = await formatFileName("formations-details", data.agentId);

  return await openFile(url, `${fileName}.xlsx`, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
};

const applyFilters = (filters, url, page, agentId) => {
  let hasFilter = false;

  const filtersUrl = [];
  if (page) {
    filtersUrl.push(`page=${page + 1}`);
    hasFilter = true;
  }
  if (filters?.agent) {
    filtersUrl.push(`bddConsultant.idCgp=${filters.agent}`);
    hasFilter = true;
  }
  if (filters?.exercice) {
    filtersUrl.push(`exercice=${filters.exercice}`);
    hasFilter = true;
  }
  if (filters?.ville) {
    filtersUrl.push(`ville=${filters.ville}`);
    hasFilter = true;
  }
  if (filters?.statutParticipant) {
    filtersUrl.push(`statut_participant=${filters.statutParticipant}`);
    hasFilter = true;
  }
  if (filters?.validationFormation) {
    filtersUrl.push(`statut_inscription=${filters.validationFormation}`);
    hasFilter = true;
  }
  if (filters?.formation) {
    filtersUrl.push(`type_formation_event.nom=${filters.formation}`);
    hasFilter = true;
  }
  if (filters?.evenement) {
    filtersUrl.push(`type_formation_event.nom=${filters.evenement}`);
    hasFilter = true;
  }

  if (hasFilter && !agentId) {
    url += "?";
  }

  filtersUrl.forEach((filter, index) => {
    url += filter;
    if (index < filtersUrl.length - 1) url += "&";
  });

  return url;
};

/** function that returns events of both types : Event Or Tutorial(Formation) */
export const eventTypes = async (eventType) => {
  let url = "/type_events?";

  const formattedURL = eventType === "event" ? url + "isEvent=1" : url + "isFormation=1";

  const response = await http.get(formattedURL);

  return response.data["hydra:member"];
};
