import React from "react";
import { DataGrid } from "@material-ui/data-grid";
import { dateColumn } from "../../utils/dataGridColumns";
import renderCellExpand from "../../utils/renderCellExpand";
import { Button } from "@material-ui/core";

const NotificationList = ({notifications, isFetching, setClickedNotification, toggleFormModal}) => {

  const handleRequestClick = (data) => {
    setClickedNotification(data.row)
    toggleFormModal()
  }

  const baseColumns = [
    {
      field: "createdAt",
      headerName: "Date",
      flex: 0.5,
      ...dateColumn,
      renderCell: renderCellExpand,
    },
    {
      field: "title",
      headerName: "Titre",
      flex: 1,
      renderCell: (params) => (
        <Button variant="text" onClick={() => handleRequestClick(params)}>
           {params.value}
        </Button> 
    ),
    },
    {
      field: "expireAt",
      headerName: "Date de fin de publication",
      flex: 0.5,
      ...dateColumn,
      renderCell: renderCellExpand,
    },
  ];

  const sortModel = [{ field: "createdAt", sort: "desc" }];

  return (
    <>
      <DataGrid
        loading={isFetching}
        rows={notifications ?? []}
        columns={baseColumns}
        pageSize={10}
        autoHeight
        disableColumnFilter
        disableSelectionOnClick
        disableColumnMenu
        disableColumnReorder
        sortModel={sortModel}
      />
    </>
  );
};

export default NotificationList;
