import { Create as CreateIcon, Clear as ClearIcon } from "@material-ui/icons";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Box, CardContent, CardMedia, IconButton, makeStyles, Typography } from "@material-ui/core";
import AddPicture from "./AddPicture";
import DeletePicture from "./DeletePicture";

const PictureCard = ({ picture, modalMode, onClick }) => {
  const classes = useStyles();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleClickOpenEditModal = () => setOpenEditModal(true);
  const handleCloseEditModal = () => setOpenEditModal(false);
  const handleClickOpenDeleteModal = () => setOpenDeleteModal(true);
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  const handleClick = () => {
    if (onClick) {
      onClick(picture);
    }
  };

  return (
    <>
      <CardMedia
        sx={{ height: modalMode ? 100 : 200, backgroundSize: "contain" }}
        image={process.env.REACT_APP_API_URL + picture.mediaObject.contentUrl}
        title={picture.title}
        className={modalMode ? classes.cardMedia : null}
        onClick={handleClick}
      />
      <CardContent
        className={modalMode ? `${classes.cardMedia} ${classes.boxContainer}` : classes.boxContainer}
        onClick={handleClick}
      >
        <Box className={classes.titleCardSection}>
          <Typography variant="h3" component="h2" className={classes.titleCard}>
            {picture.title}
          </Typography>

          {!modalMode && (
            <>
              <IconButton color="inherit" onClick={handleClickOpenEditModal}>
                <CreateIcon />
              </IconButton>
              <IconButton color="inherit" onClick={handleClickOpenDeleteModal}>
                <ClearIcon />
              </IconButton>
            </>
          )}
        </Box>
        {openEditModal && (
          <AddPicture
            open={openEditModal}
            title="Editer une image"
            onClose={handleCloseEditModal}
            picture={picture}
            mediaObject={picture.mediaObject}
          />
        )}
        {openDeleteModal && <DeletePicture open={openDeleteModal} onClose={handleCloseDeleteModal} picture={picture} />}
      </CardContent>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
  },
  titleCardSection: {
    display: "flex",
    alignItems: "center",
  },
  titleCard: {
    margin: "0 1rem",
  },
  cardMedia: {
    cursor: "pointer",
  },
}));

PictureCard.propTypes = {
  picture: PropTypes.object,
  modalMode: PropTypes.bool,
  onClick: PropTypes.func,
};

export default PictureCard;
