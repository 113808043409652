import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Create as CreateIcon } from "@material-ui/icons";
import PropTypes from "prop-types";
import React from "react";
import { Box, Button, Container, Divider, IconButton, makeStyles, Typography } from "@material-ui/core";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { postNews } from "../../http/news";
import { contentText, dateToRelativeString, dateToString } from "../../utils/formatters";
import GuardedComponent from "../GuardedComponent";
import NewsAction from "./NewsActions";

const NewsDetailsContent = ({ news }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleEdit = () => {
    history.push({ pathname: `/communication/news/${news.id}/edit`, state: { goBack: history.location.pathname } });
  };

  const goBack = () => {
    if (history.location.state) {
      history.go(history.location.state.goBack);
    }
    history.goBack();
  };

  const handleClickDisplayed = () => {
    news.displayed = !news.displayed;

    mutation.mutate({
      displayed: news.displayed,
      imageBank: news?.imageBank["@id"],
      news,
    });
  };

  const mutation = useMutation(postNews);

  return (
    <>
      <Box>
        <img
          src={process.env.REACT_APP_API_URL + news.imageBank.mediaObject.contentUrl}
          alt={news.imageBank.title}
          style={{ width: "100%", height: "auto", maxHeight: "400px", objectFit: "contain" }}
        />
      </Box>
      <Box sx={{ pt: 3 }}>
        <Container maxWidth="md">
          <Box sx={{ py: 2, display: "flex" }}>
            <Typography variant="h1">{news.title}</Typography>
            <GuardedComponent componentName="news_edit_page">
              <IconButton color="inherit" onClick={handleClickDisplayed} size="small" sx={{ marginLeft: "1rem" }}>
                {news.displayed ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
              </IconButton>
            </GuardedComponent>
          </Box>
          <Box sx={{ py: 2 }}>
            <Typography
              gutterBottom
              variant="body1"
              textAlign="justify"
              paragraph={true}
              dangerouslySetInnerHTML={{
                __html: contentText(news.content),
              }}
              sx={{
                "& *": { margin: "inherit", padding: "inherit", boxSizing: "inherit" },
                "& ul": { marginLeft: "15px" },
              }}
            />
          </Box>
          <Divider />
          <Box sx={{ py: 2, display: "flex", justifyContent: "flex-end" }}>
            {history.location.pathname.includes("/communication") ? (
              <Typography gutterBottom variant="subtitle2" color="text.secondary">
                {dateToString(news.date)}
              </Typography>
            ) : (
              <Typography gutterBottom variant="subtitle2" color="text.secondary">
                Mis à jour {dateToRelativeString(news.date)}
              </Typography>
            )}
          </Box>
          <Box className={classes.actionWrapper}>
            <Button variant="outlined" onClick={goBack}>
              Retour
            </Button>
            <GuardedComponent componentName="news_edit_page">
              <NewsAction news={news} goBack={history.location.pathname} fromDetails={true} />
              <Button variant="contained" startIcon={<CreateIcon />} onClick={handleEdit}>
                Editer
              </Button>
            </GuardedComponent>
          </Box>
        </Container>
      </Box>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  actionWrapper: {
    display: "flex",
    justifyContent: "flex-end",

    "& button:not(:first-child)": {
      marginLeft: "1rem",
    },
  },
}));

NewsDetailsContent.propTypes = {
  news: PropTypes.object,
};

export default NewsDetailsContent;
