import React, { useState } from "react";
import { Box, Button, Card, Container, Stack } from "@material-ui/core";
import { Add as AddIcon, Delete as DeleteIcon } from "@material-ui/icons";
import { useQuery } from "react-query";
import { Link as RouterLink, useHistory } from "react-router-dom";
import AppCircularProgress from "../../components/AppCircularProgress";
import Breadcrumbs from "../../components/Breadcrumbs";
import GuardedComponent from "../../components/GuardedComponent";
import HubnupRemindersList from "../../components/reminder/HubnupRemindersList";
import { getRemindersAll } from "../../http/reminders";
import RemindersToolbar from "../../components/reminder/RemindersToolbar";
import RemindersDeleteDialog from "../../components/reminder/RemindersDeleteDialog";
import FilterPanel from "../../components/FilterPanel";

const HubnupReminders = () => {
  const history = useHistory();
  const [selectedReminders, setSelectedReminders] = useState([]);
  const [remindersToDelete, setRemindersToDelete] = useState([]);
  const [filters, setFilters] = useState([]);
  const {
    isFetching,
    isFetched,
    data: reminders,
  } = useQuery(["getRemindersAll", filters], () => getRemindersAll(filters));

  const onRowDelete = (id) => {
    setRemindersToDelete([`/reminders/${id}`]);
  };

  const onSelectionDelete = () => {
    if (selectedReminders.length === 0) return;

    setRemindersToDelete(selectedReminders.map((id) => `/reminders/${id}`));
  };

  const closeDeleteDialog = () => {
    setRemindersToDelete([]);
  };

  const onFilter = (filters) => {
    setFilters(filters);
  };

  return (
    <Container maxWidth={false}>
      <Breadcrumbs />

      <GuardedComponent componentName="reminders_edit_page">
        <Stack direction="row" spacing={2}>
          <Button
            component={RouterLink}
            to={{ pathname: "/communication/reminders/create", state: { goBack: history.location.pathname } }}
            variant="contained"
            startIcon={<AddIcon />}
          >
            Créer un rappel
          </Button>
          <Button variant="contained" startIcon={<DeleteIcon />} onClick={onSelectionDelete} color="hubnup">
            Supprimer
          </Button>
        </Stack>
      </GuardedComponent>

      <FilterPanel sx={{ mt: 2 }}>
        <RemindersToolbar onFilter={onFilter} />
      </FilterPanel>

      <Box sx={{ mt: 2 }}>
        <Card>
          {isFetched ? (
            <HubnupRemindersList
              reminders={reminders}
              selectedReminders={selectedReminders}
              onSelect={setSelectedReminders}
              onDelete={onRowDelete}
              loading={isFetching}
            />
          ) : (
            <AppCircularProgress />
          )}
        </Card>
      </Box>

      <RemindersDeleteDialog
        isOpen={remindersToDelete.length > 0}
        hasMany={remindersToDelete.length > 1}
        remindersToDelete={remindersToDelete}
        onClose={closeDeleteDialog}
      />
    </Container>
  );
};

export default HubnupReminders;
