import PropTypes from "prop-types";
import React from "react";
import { Dialog, DialogTitle, Divider, IconButton, Typography } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";

const AppDialog = ({ open, onClose, title, maxWidth, children }) => {
  const handleClose = () => onClose();

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={maxWidth}
        aria-labelledby="add-profile-title"
      >
        <DialogTitle id="add-profile-title" disableTypography>
          <Typography variant="h4" component="div">
            {title}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider />
        {children}
      </Dialog>
    </>
  );
};

AppDialog.defaultProps = {
  maxWidth: "sm",
};

AppDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  maxWidth: PropTypes.string,
};

export default AppDialog;
