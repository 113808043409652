import { Add as AddIcon } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { Box, Button, Container, Typography } from "@material-ui/core";
import { useQuery } from "react-query";
import { Link as RouterLink} from "react-router-dom";
import AppCircularProgress from "../../components/AppCircularProgress";
import Breadcrumbs from "../../components/Breadcrumbs";
import GuardedComponent from "../../components/GuardedComponent";
import FilterPanel from "../../components/FilterPanel";
import useToggle from "../../hooks/useToggle";
import AppDialog from "../../components/AppDialog";
import ModificationRequestsForm from "../../components/documentation/ModificationRequestsForm";
import { getModificationRequestsAll } from "../../http/documentations";
import ModificationRequestsList from "../../components/documentation/ModificationRequestsList";
import isAgent from "../../utils/isAgent";
import ModificationRequestToolbar from "../../components/documentation/ModificationRequestToolbar";
import { useTrackingContext } from "../../contexts/TrackingContext";
import { ACTION_CLICK } from "../../utils/trackingConstant";

const LibraryModificationRequests = () => {
  
  const fromAgent = isAgent();
  const {isDisplayed: isFormModal, setIsDisplayed: setIsFormModal, toggleAction: toggleFormModal} = useToggle();
  
  // data for the clicked request (click on title)
  const [requestClickedData, setRequestClickedData] = useState({})

  const itemsPerPage = 9;
  const [page, setPage] = useState(1);
  const [requests, setRequests] = useState([]);
  const [filters, setFilters] = useState({});
  const [hasMoreRequests, sethasMoreRequests] = useState(true);

  const track = useTrackingContext()

 const { isFetching, data } = useQuery(["getModificationRequestsAll", page, filters], () => getModificationRequestsAll(fromAgent, page, filters), {
    keepPreviousData: true,
  });

  useEffect(() => {
    if (data) {
      if (page === 1) {
        setRequests(data["hydra:member"]);
      } else {
        setRequests((prevState) => prevState.concat(data["hydra:member"]));
      }
      sethasMoreRequests(page * itemsPerPage < data["hydra:totalItems"]);
    }
  }, [data, page]);

  const onFilter = (filters) => {
    setFilters(filters);
    setPage(1);

  };

  const handleCloseModal = () => {
    setRequestClickedData({})
    toggleFormModal()
  }

  return (
      <Container maxWidth={false}>
            <Breadcrumbs />
            <GuardedComponent componentName="suivi_demande_modification_documentation_page">
              <Box sx={{ mt: 3, mb: 3 }}>
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={toggleFormModal}
                >
                  Créer une Demande de Modification
                </Button>
              </Box>
            </GuardedComponent>

            {/* Modal: Add New request */}
            <AppDialog open={isFormModal} onClose={handleCloseModal} title="Demande de Modification de Document" maxWidth={'lg'}>
              <ModificationRequestsForm 
                toggleFormModal={handleCloseModal} 
                requestClickedData = {requestClickedData}
              />
            </AppDialog>

            <FilterPanel>
              <ModificationRequestToolbar onFilter={onFilter} fromAgent={fromAgent} />
            </FilterPanel>

            <Box sx={{ mt: 3 }}>
              <ModificationRequestsList
                requests={requests}
                isFetching={isFetching}
                fromAgent={fromAgent}
                setRequestClickedData = {setRequestClickedData}
                setIsFormModal={setIsFormModal}
              />
            </Box>

            {isFetching && <AppCircularProgress />}

            {!hasMoreRequests && (
              <Box sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
                <Typography variant="body1" color="text.disabled">
                  Il n'y a plus d'actualité à afficher.
                </Typography>
              </Box>
            )}
          </Container>
  );
};

export default LibraryModificationRequests;
