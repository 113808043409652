import PropTypes from "prop-types";
import React from "react";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
  Typography,
} from "@material-ui/core";
import { CalendarToday as CalendarTodayIcon, Person as PersonIcon } from "@material-ui/icons";
import { dateToString } from "../../utils/formatters";

const ProspectInfo = ({ prospect, loading }) => (
  <Card>
    <CardHeader title={<Typography variant="h2">Prospect</Typography>} />
    <Divider />
    <CardContent>
      <Grid container spacing={0}>
        <Grid item xs={12} md={6}>
          <List>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Nom" secondary={loading ? <Skeleton width={300} /> : prospect?.lastName} />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Prénom" secondary={loading ? <Skeleton width={300} /> : prospect?.firstName} />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <CalendarTodayIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Date de naissance"
                secondary={loading ? <Skeleton width={300} /> : dateToString(prospect?.birthday)}
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Consultant"
                secondary={loading ? <Skeleton width={300} /> : prospect?.consultant?.fullName}
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <CalendarTodayIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Date de premier contact"
                secondary={loading ? <Skeleton width={300} /> : dateToString(prospect?.createdAt)}
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

ProspectInfo.propTypes = {
  prospect: PropTypes.object,
  loading: PropTypes.bool,
};

export default ProspectInfo;
