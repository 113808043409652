import { faClone, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Box, Button, CardContent, CardHeader, Divider, Stack, Typography } from "@material-ui/core";
import { Delete as DeleteIcon, Create as CreateIcon } from "@material-ui/icons";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { dateToString } from "../../utils/formatters";
import GuardedComponent from "../GuardedComponent";
import RemindersDeleteDialog from "./RemindersDeleteDialog";
import RemindersDuplicateModal from "./RemindersDuplicateModal";

const HubnupRemindersContent = ({ reminder }) => {
  const history = useHistory();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openDuplicateModal, setOpenDuplicateModal] = useState(false);
  const handleClickOpenDuplicateModal = () => {
    setOpenDuplicateModal(true);
  };
  const handleCloseDuplicateModal = () => {
    setOpenDuplicateModal(false);
  };

  const goBack = () => {
    if (history.location.state) {
      history.go(history.location.state.goBack);
    }
    history.goBack();
  };

  return (
    <>
      <CardHeader
        title={
          <Box sx={{ py: 2, display: "flex", alignItems: "center" }}>
            <Typography variant="h1">{reminder.title}</Typography>
            <Typography variant="subtitle2" color="text.secondary" mx={3}>
              {dateToString(reminder.date)}
            </Typography>
            {reminder.displayed ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
            <Typography variant="subtitle2" color="text.secondary" ml={2}>
              Accessible aux mandataires
            </Typography>
          </Box>
        }
      />
      <Divider />
      <CardContent>
        <Box sx={{ py: 2 }}>
          <Typography
            variant="body1"
            textAlign="justify"
            paragraph={true}
            dangerouslySetInnerHTML={{
              __html: reminder.content.replace(/(?:\r\n|\r|\n)/g, "<br>"),
            }}
          />
        </Box>
      </CardContent>
      <Divider />
      <Stack
        direction={{ xs: "column-reverse", sm: "row" }}
        spacing={2}
        sx={{
          p: 2,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button variant="outlined" onClick={goBack}>
          Retour
        </Button>
        <GuardedComponent componentName="reminders_edit_page">
          <Button
            variant="contained"
            color="hubnup"
            startIcon={<DeleteIcon />}
            onClick={() => setOpenDeleteModal(true)}
          >
            Supprimer
          </Button>
          <Button
            variant="contained"
            startIcon={<FontAwesomeIcon icon={faClone} />}
            onClick={handleClickOpenDuplicateModal}
          >
            Dupliquer
          </Button>
          <Button
            variant="contained"
            startIcon={<CreateIcon />}
            component={RouterLink}
            to={{
              pathname: `/communication/reminders/${reminder.id}/edit`,
              state: { goBack: history.location.pathname },
            }}
          >
            Editer
          </Button>
        </GuardedComponent>

        <RemindersDeleteDialog
          isOpen={openDeleteModal}
          hasMany={false}
          remindersToDelete={[`/reminders/${reminder.id}`]}
          onClose={() => setOpenDeleteModal(false)}
          goBack="/communication/reminders"
        />

        <RemindersDuplicateModal reminder={reminder} open={openDuplicateModal} onClose={handleCloseDuplicateModal} />
      </Stack>
    </>
  );
};

HubnupRemindersContent.propTypes = {
  reminder: PropTypes.object,
};

export default HubnupRemindersContent;
