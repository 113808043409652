import { Visibility as VisibilityIcon } from "@material-ui/icons";
import PropTypes from "prop-types";
import React from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  Typography,
  ListItemText,
  Box,
  IconButton,
  Divider,
} from "@material-ui/core";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { dateToString } from "../../utils/formatters";
import AppDialog from "../AppDialog";
import GuardedComponent from "../GuardedComponent";

const RemindersPickerModal = ({ open, onClose, reminders, date }) => {
  const history = useHistory();

  return (
    <AppDialog
      open={open}
      onClose={onClose}
      title={`Rappel${reminders.length > 1 ? "s" : ""} du ${dateToString(date)}`}
    >
      <DialogContent>
        <List>
          {reminders.map((reminder) => (
            <ListItem key={reminder.id} dense>
              <ListItemText
                primary={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography mr={2}>{reminder.reminder.title}</Typography>
                    <GuardedComponent componentName="reminders_detail_page">
                      <IconButton
                        aria-label="Voir le rappel"
                        size="small"
                        component={RouterLink}
                        to={{
                          pathname: `/my/reminders/${reminder.reminder.id}`,
                          state: { goBack: history.location.pathname },
                        }}
                      >
                        <VisibilityIcon />
                      </IconButton>
                    </GuardedComponent>
                  </Box>
                }
                secondary={reminder.reminder.content}
              ></ListItemText>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Retour
        </Button>
      </DialogActions>
    </AppDialog>
  );
};

RemindersPickerModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  reminders: PropTypes.array,
  date: PropTypes.object,
};

export default RemindersPickerModal;
