import * as actionTypes from "./actionTypes";
import { createToken } from "../../http";
import parseJwt from "../../utils/parseJwt";

const authSuccess = (auth) => {
  const content = parseJwt(auth.token);

  return {
    type: actionTypes.AUTH_SUCCESS,
    auth: {
      ...auth,
      roles: content.roles,
    },
  };
};

export const logout = () => (dispatch) => {
  localStorage.removeItem("auth");
  dispatch(logoutSucceed());
};

const logoutSucceed = () => ({
  type: actionTypes.AUTH_LOGOUT,
});

export const auth = (email, password) => async (dispatch) => {
  try {
    const auth = await createToken(email, password);
    auth.onLoginAgent = auth.profileType === 'Mandataire';

    localStorage.setItem("auth", JSON.stringify(auth));
    dispatch(authSuccess(auth));
  } catch (error) {
    throw new Error(
      error?.response
        ? error.response.data.message
        : "Authentication request could not be processed due to a system problem."
    );
  }
};

export const authCheckState = () => (dispatch) => {
  const auth = localStorage.getItem("auth");

  if (!auth) {
    dispatch(logout());

    return;
  }

  dispatch(authSuccess(JSON.parse(auth)));
};

export const updateLoginAgent = () => {
  const auth = localStorage.getItem("auth");

  if (auth) {
    const data = JSON.parse(auth);
    localStorage.setItem(
      "auth",
      JSON.stringify({
        ...data,
        onLoginAgent: false,
      })
    );
  }

  return {
    type: actionTypes.UPDATE_LOGIN_AGENT,
  };
};

export const authSetRedirectPath = (redirectPath) => ({
  type: actionTypes.AUTH_SET_REDIRECT_PATH,
  path: redirectPath,
});

export const updatePicture = (picture) => {
  return {
    type: actionTypes.UPDATE_PICTURE,
    picture: picture,
  };
};

export const updateCircularPicture = (circularPicture) => {
  return {
    type: actionTypes.UPDATE_CIRCULAR_PICTURE,
    circularPicture: circularPicture,
  };
};

export const impersonateUser = (data) => {
  return {
    type: actionTypes.IMPERSONATE_USER,
    data: data,
  };
};

export const returnFromImpersonateUser = () => {
  return {
    type: actionTypes.RETURN_FROM_IMPERSONATE_USER,
  };
};
