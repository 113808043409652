import dayjs from "dayjs";
import http from "../utils/http";

// get custom notifications.
export const getNotificationsAll = async (page = 1, filters) => {
  let url = `/notifications?page=${page}`;

  const response = await http.get(url);

  return response.data;
};

export const getNotificationItem = async (id) => {
  const response = await http.get(`/news/${id}`);

  return response.data;
};

export const postNotification = async (data) => {
  return await http.post("/notifications", data);
};

export const patchNotification = async ({ editData, requestId }) => {
  return await http.patch(`/notifications/${requestId}`, editData);
};

export const deleteNotification = async (data) => {
  const response = await http.delete(data.news["@id"]);

  return response.data;
};

// get notifications for current user which are not expired.
export const getNotificationListByUser = async (email) => {
  const now = dayjs().format("YYYY-MM-DD HH:mm:ss");

  const response = await http.get(
    `/notification_lists?user.email=${email}&notification.expireAt[after]=${now}&notification.createdAt[before]=${now}&readByUser=false`,
  );

  return response.data["hydra:member"];
};

export const updateNotificationListCollectionStatus = async ({
  notificationListIds,
}) => {
  const response = await http.post(
    "notification_lists/user",
    notificationListIds,
  );

  return response;
};

export const getNotificationsRules = () => {
  return http.get("/notification_rules");
};

export const updateNotificationActivationMutation = (
  notificationRuleId,
  isActivate,
) => {
  return http.patch(`/notification_rules/${notificationRuleId}`, {
    isActivate,
  });
};
