import dayjs from "dayjs";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { Badge, Fade, makeStyles, TextField, Tooltip } from "@material-ui/core";
import { CalendarPickerSkeleton, PickersDay, StaticDatePicker } from "@material-ui/lab";
import { getUserReminders } from "../../http/reminders";
import RemindersPickerModal from "../reminder/RemindersPickerModal";
import PropTypes from "prop-types";

const RemindersPicker = ({ nextMonth }) => {
  const classes = useStyles();
  const initialDate = new Date();
  const [date, setDate] = useState(
    nextMonth ? new Date(initialDate.setMonth(initialDate.getMonth() + 1)) : initialDate
  );
  const [remindersDate, setRemindersDate] = useState([]);
  const [openRemindersModal, setOpenRemindersModal] = useState(false);
  const [filters, setFilters] = useState({
    remindAt: [
      nextMonth ? dayjs().add(1, "month").startOf("month") : dayjs().startOf("month"),
      nextMonth ? dayjs().add(1, "month").endOf("month") : dayjs().endOf("month"),
    ],
  });

  const { isFetching, data: reminders } = useQuery(["getRemindersAll", filters, nextMonth], () =>
    getUserReminders(filters)
  );

  const handleOpenRemindersModal = (remindersDate) => {
    setOpenRemindersModal(true);
    setRemindersDate(remindersDate);
  };
  const handleCloseRemindersModal = () => {
    setOpenRemindersModal(false);
    setRemindersDate([]);
  };

  const onMonthChange = async (date) => {
    const startDate = dayjs(date).startOf("month");
    const endDate = dayjs(date).endOf("month");
    const filters = {
      remindAt: [startDate, endDate],
    };
    setFilters(filters);
  };

  const renderDay = (date, selectedDates, pickersDayProps) => {
    let remindersDate = [];
    if (reminders) {
      remindersDate = reminders.filter((reminder) => dayjs(date).isSame(reminder?.reminder?.date?.date, "day"));
    }
    const hasReminder = remindersDate.length > 0;
    const hubnupReminders = remindersDate.filter((reminder) => reminder?.reminder.type === "hubnup");
    const hasHubnupReminder = hubnupReminders.length > 0;
    const tootltipText = remindersDate.map((reminder) => reminder.reminder.title).join(", ");

    return hasReminder ? (
      <Tooltip
        key={pickersDayProps.key}
        title={tootltipText}
        arrow
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 500 }}
        onClick={() => handleOpenRemindersModal(remindersDate)}
      >
        <Badge
          badgeContent={remindersDate.length}
          max={9}
          color={hasHubnupReminder ? "hubnup" : "secondary"}
          classes={{
            badge: hubnupReminders.length > 0 && hubnupReminders.length !== remindersDate.length ? classes.badge : "",
          }}
          overlap="circular"
        >
          <PickersDay sx={{ borderRadius: "50%" }} {...pickersDayProps} />
        </Badge>
      </Tooltip>
    ) : (
      <Badge key={pickersDayProps.key} badgeContent={null} color="secondary" overlap="circular">
        <PickersDay sx={{ borderRadius: "50%" }} {...pickersDayProps} />
      </Badge>
    );
  };

  return (
    <>
      <StaticDatePicker
        displayStaticWrapperAs="desktop"
        openTo="day"
        value={date}
        onChange={setDate}
        onMonthChange={onMonthChange}
        renderInput={(params) => <TextField {...params} />}
        loading={isFetching}
        renderLoading={() => <CalendarPickerSkeleton />}
        renderDay={renderDay}
      />

      <RemindersPickerModal
        open={openRemindersModal}
        onClose={handleCloseRemindersModal}
        reminders={remindersDate}
        date={date}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  badge: {
    background: `linear-gradient(90deg, #00a199 47%, #FA0071 53%)`,
  },
}));

RemindersPicker.defaultProps = {
  nextMonth: false,
};

RemindersPicker.propTypes = {
  nextMonth: PropTypes.bool,
};

export default RemindersPicker;
