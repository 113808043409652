import { Container } from "@material-ui/core";
import { getCurrentUser } from "../http/user";
import { useQuery } from "react-query";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import DisabledModal from "../components/user/DisabledModal";
import { Button, DialogActions, DialogContent, DialogContentText, Divider } from "@material-ui/core";
import AppDialog from "../components/AppDialog";

const Forbidden = () => {
  const history = useHistory();
  const [openDisabledModal, setOpenDisabledModal] = useState(false);

  const { isFetched, data: user } = useQuery(["getCurrentUser"], () => {
    return getCurrentUser();
  });

  useEffect(() => {
    if (isFetched && user.roles.includes("ROLE_DISABLED")) {
      setOpenDisabledModal(true);
    }
  }, [user, isFetched]);

  return (
    <Container maxWidth={false}>
      <h1>403 Forbidden</h1>

      <DisabledModal user={user} onClose={() => setOpenDisabledModal(false)} open={openDisabledModal} />
    </Container>
  );
};

Forbidden.propTypes = {
  user: PropTypes.object,
};

export default Forbidden;
