import React from "react";
import { DataGrid } from "@material-ui/data-grid";
import { dateColumn } from "../../utils/dataGridColumns";
import renderCellExpand from "../../utils/renderCellExpand";
import { Button } from "@material-ui/core";

const ModificationRequestsList = ({requests, isFetching, fromAgent, setRequestClickedData, setIsFormModal}) => {

  const handleRequestClick = (data) => {
    setRequestClickedData(data.row)
    setIsFormModal(true)
  }

  const hubnupColumns = [
    {
      field: "applicant",
      headerName: "Demandeur",
      width: 160,
      valueGetter: (params) => params.row?.applicant?.fullName,
      renderCell: (params) =>
        renderCellExpand(
          params,
          true,
            <div>
               {params.value}
            </div>
        ),
    },
  ];

  const baseColumns = [
    {
      field: "createdAt",
      headerName: "Date",
      flex: 0.5,
      ...dateColumn,
      renderCell: renderCellExpand,
    },
    {
      field: "title",
      headerName: "Titre demande",
      flex: 1,
      renderCell: (params) => (
        <Button variant="text" onClick={() => handleRequestClick(params)}>
           {params.value}
        </Button> 
    ),
    },
    {
      field: "documentLink",
      headerName: "Lien document",
      flex: 1,
      valueGetter: (params) => params.row?.mediaObject?.displayName,
      renderCell: (params) =>
        renderCellExpand(
          params,
          true,
            <div>
               {params.value}
            </div> 
        ),
    },
    {
      field: "status",
      headerName: "Statut",
      flex: 0.5,
      renderCell: renderCellExpand,
    },
    {
      field: "comment",
      headerName: "Commentaire Hubn’UP",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
              <div>{params.formattedValue}</div>
          </>
        )
      },
    },
  ];

  const columns = fromAgent ? baseColumns : baseColumns.concat(hubnupColumns);
  const sortModel = [{ field: "createdAt", sort: "desc" }];

  return (
    <>
      <DataGrid
        loading={isFetching}
        rows={requests ?? []}
        columns={columns}
        pageSize={10}
        autoHeight
        disableColumnFilter
        disableSelectionOnClick
        disableColumnMenu
        disableColumnReorder
        sortModel={sortModel}
      />
    </>
  );
};

export default ModificationRequestsList;
