import PropTypes from "prop-types";
import React, { useState } from "react";
import { DataGrid } from "@material-ui/data-grid";
import { Link, Grid } from "@material-ui/core";
import { useQuery } from "react-query";
import { Link as RouterLink } from "react-router-dom";
import { dateColumn } from "../../utils/dataGridColumns";
import renderCellExpand from "../../utils/renderCellExpand";
import { getEvents } from "../../http/trainings";

const TrainingList = ({ agentId, filters }) => {
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(0);
  const sortModel = [{ field: "exercice", sort: "asc" }];

  const { isLoading, data: training } = useQuery(["getEvents", filters, page, agentId], async () => {
    const data = await getEvents(filters, page, agentId);
    return data["hydra:member"];
  });

  const columns = [
    {
      field: "exercice",
      headerName: "Exercice",
      flex: 1,
      renderCell: renderCellExpand,
      valueGetter: (params) => params.row?.exercice,
    },
    {
      field: "reference",
      headerName: "Référence atelier",
      flex: 1,
      renderCell: renderCellExpand,
      valueGetter: (params) => params.row?.reference_atelier,
    },
    {
      field: "formation",
      headerName: "Formation",
      flex: 1,
      renderCell: renderCellExpand,
      valueGetter: (params) => {
        if (params.row?.type_formation_event?.isFormation === true) {
          return params.row?.type_formation_event?.nom;
        } else {
          return null;
        }
      },
    },
    {
      field: "event",
      headerName: "Evènement",
      flex: 1,
      renderCell: renderCellExpand,
      valueGetter: (params) => {
        if (params.row?.type_formation_event?.isEvent === true) {
          return params.row?.type_formation_event?.nom;
        } else {
          return null;
        }
      },
    },
    {
      field: "ville",
      headerName: "Ville",
      flex: 1,
      renderCell: renderCellExpand,
      valueGetter: (params) => params.row?.ville,
    },
    {
      field: "dateFormation",
      ...dateColumn,
      headerName: "Date",
      flex: 1,
      renderCell: renderCellExpand,
      valueGetter: (params) => params.row?.date_formation,
    },
    {
      field: "statutParticipant",
      headerName: "Statut du participant",
      flex: 1,
      renderCell: renderCellExpand,
      valueGetter: (params) => params.row?.statut_participant,
    },
    {
      field: "statutInscription",
      headerName: "Statut inscription",
      flex: 1,
      renderCell: renderCellExpand,
      valueGetter: (params) => params.row?.statut_inscription,
    },
  ];

  return (
    <DataGrid
      loading={isLoading}
      rows={training ? training : []}
      columns={columns}
      pageSize={pageSize}
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize.pageSize)}
      autoHeight
      disableColumnFilter
      disableSelectionOnClick
      disableColumnMenu
      disableColumnReorder
      pagination
      sortModel={sortModel}
      getRowId={(row) => row.id}
    />
  );
};

TrainingList.defaultProps = {
  fromAgent: false,
};

TrainingList.propTypes = {
  filters: PropTypes.object,
  fromAgent: PropTypes.bool,
};

export default TrainingList;
