import { getOdyssealToken } from "../http/sso";

export default async function connectToOdysseal(referer) {
  const odyssealToken = await getOdyssealToken();

  const url = new URL(
    `${process.env.REACT_APP_ODYSSEAL_PORTAL}/sso/${odyssealToken}`,
  );
  if (referer) url.searchParams.append("ref", referer);

  window.location.href = url.href;
}
