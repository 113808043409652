import PropTypes from "prop-types";
import React from "react";
import { DataGrid } from "@material-ui/data-grid";
import { CardContent, Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import checkAccessStore from "../../utils/checkAccessStore";
import { dateColumn, eurColumn } from "../../utils/dataGridColumns";
import renderCellExpand from "../../utils/renderCellExpand";
import { useTrackingContext } from "../../contexts/TrackingContext";
import { ACTION_CLICK } from "../../utils/trackingConstant";

const CustomersDetailsRealEstateList = ({ sales, isFetching, fromAgent, customer }) => {
  const salesRealEstate = sales ? Object.values(sales).filter((sale) => !!sale.id) : [];

  const track = useTrackingContext()

  const getUniteName = (params) => {
    return `${params.row?.lot || ""} - ${params.row?.operationName || ""}`;
  };

  const hubnupColumns = [
    {
      field: "agentFullName",
      headerName: "Mandataire",
      width: 160,
      valueGetter: (params) => params.row?.consultant?.fullName,
      renderCell: (params) =>
        renderCellExpand(
          params,
          true,
          checkAccessStore("team_detail_page") ? (
            <Link component={RouterLink} to={`/team/${params.row?.consultant?.idCgp}`}>
              {params.value}
            </Link>
          ) : (
            params.value
          )
        ),
    },
  ];

  const baseColumns = [
    {
      field: "lot",
      headerName: "Unité",
      flex: 1,
      valueGetter: getUniteName,
      renderCell: (params) =>
        renderCellExpand(
          params,
          true,
          checkAccessStore("sales_real_estate_unity_page") ? (
            <Link
              href={`https://valoriciel.com/sso/${params?.row?.valoricielId}/${params?.row?.consultant?.valoricielId}`}
              target="_blank"
              onClick={() => track.click(ACTION_CLICK.EXTERNAL_LINK)}
            >
              {params.value}
            </Link>
          ) : (
            params.value
          )
        ),
    },
    {
      field: "baseRemuneration",
      ...eurColumn,
      headerName: "Base de rémunération",
      flex: 1,
      renderCell: renderCellExpand,
    },
    { field: "advStatus", headerName: "État", flex: 1, renderCell: renderCellExpand },
    { field: "option", ...dateColumn, headerName: "Option", flex: 1, renderCell: renderCellExpand },
    {
      field: "bookingSignatureDate",
      ...dateColumn,
      headerName: "Signature réservation",
      flex: 1,
      renderCell: renderCellExpand,
    },
    { field: "bankAgreementDate", ...dateColumn, headerName: "Accord bancaire", flex: 1, renderCell: renderCellExpand },
    { field: "deedDate", ...dateColumn, headerName: "Acte", flex: 1, renderCell: renderCellExpand },
    {
      field: "cancellationDate",
      ...dateColumn,
      headerName: "Date d'annulation",
      flex: 1,
      renderCell: renderCellExpand,
    },
  ];

  const sortModel = [{ field: "deedDate", sort: "desc" }];
  const columns = fromAgent ? baseColumns : hubnupColumns.concat(baseColumns);

  return (
    <CardContent>
      <DataGrid
        loading={isFetching}
        rows={salesRealEstate ?? []}
        columns={columns}
        sortModel={sortModel}
        pageSize={10}
        autoHeight
        disableColumnFilter
        disableSelectionOnClick
        disableColumnMenu
        disableColumnReorder
      />
    </CardContent>
  );
};

CustomersDetailsRealEstateList.defaultProps = {
  fromAgent: false,
};

CustomersDetailsRealEstateList.propTypes = {
  sales: PropTypes.array,
  isFetching: PropTypes.bool,
  fromAgent: PropTypes.bool,
  customer: PropTypes.object,
};

export default CustomersDetailsRealEstateList;
