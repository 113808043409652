import dayjs from "dayjs";
import PropTypes from "prop-types";
import React from "react";
import { Box, List, ListItem, ListItemText, Skeleton, Typography } from "@material-ui/core";
import styled from "@emotion/styled";
import {
  Timeline,
  TimelineConnector,
  TimelineContent as MuiTimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@material-ui/lab";
import { toEuros } from "../../utils/formatters";
import { GoldStar as GoldStarIcon, SilverStar as SilverStarIcon, BronzeStar as BronzeStarIcon } from "../Stars";

const TimelineContent = styled(MuiTimelineContent)({
  margin: "auto 0",
});

const UserQualifications = ({ agent, loading }) => {
  const now = dayjs();
  const year = now.year();

  return (
    <Timeline>
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ px: 4, flex: 0.25, display: "flex", justifyContent: "flex-end" }}
          color="text.secondary"
        >
          {loading ? <Skeleton width={50} /> : year}
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <List sx={{ pt: 0 }}>
            {loading && (
              <>
                <ListItem sx={{ py: 0 }}>
                  <ListItemText
                    sx={{ mt: 0 }}
                    primary={<Skeleton width={120} />}
                    secondary={<Skeleton width={150} />}
                  />
                </ListItem>
                <ListItem sx={{ py: 0 }}>
                  <ListItemText primary={<Skeleton width={120} />} secondary={<Skeleton width={150} />} />
                </ListItem>
              </>
            )}
            {!loading && (
              <>
                <ListItem sx={{ py: 0 }}>
                  <ListItemText
                    sx={{ mt: 0 }}
                    primary="Branches actives"
                    secondary={`${agent?.branchesActives} branche${agent?.branchesActives > 1 ? "s" : ""}`}
                  />
                </ListItem>
                <ListItem sx={{ py: 0 }}>
                  <ListItemText primary="VAVC perso + équipe(s) à date" secondary={toEuros(agent?.vavcTotal)} />
                </ListItem>
                <ListItem sx={{ py: 0 }}>
                  <ListItemText
                    primary="Qualification"
                    secondary={
                      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                        {agent?.level === 1 && <GoldStarIcon />}
                        {agent?.level === 2 && <SilverStarIcon />}
                        {agent?.level === 3 && <BronzeStarIcon />}
                        <Typography variant="body2" component="span" sx={{ ml: 0.5 }}>
                          {agent?.qualification}
                        </Typography>
                      </Box>
                    }
                    secondaryTypographyProps={{
                      component: "div",
                    }}
                  />
                </ListItem>
              </>
            )}
          </List>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
};

UserQualifications.propTypes = {
  agent: PropTypes.object,
  loading: PropTypes.bool,
};

export default UserQualifications;
