import PropTypes from "prop-types";
import React from "react";
import {
  Avatar,
  Checkbox,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
} from "@material-ui/core";
import {
  Business as BusinessIcon,
  CalendarToday as CalendarTodayIcon,
  CreditCard as CreditCardIcon,
  Email as EmailIcon,
  Person as PersonIcon,
  Phone as PhoneIcon,
  Room as RoomIcon,
} from "@material-ui/icons";
import { dateToString } from "../../utils/formatters";

const UserInfo = ({ user, agent, loading }) => (
  <Grid container spacing={0}>
    <Grid item xs={12} md={6}>
      <List>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <CalendarTodayIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="Date de naissance"
            secondary={loading ? <Skeleton width={300} /> : dateToString(agent?.agent?.birthday)}
          />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <CalendarTodayIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="Date d'entrée dans le réseau"
            secondary={loading ? <Skeleton width={300} /> : dateToString(agent?.agent?.registerDate)}
          />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <PhoneIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="Téléphone"
            secondary={loading ? <Skeleton width={300} /> : agent?.agent?.phoneNumber}
          />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <EmailIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Adresse mail" secondary={loading ? <Skeleton width={300} /> : user?.email} />
        </ListItem>
        {agent?.sponsor && (
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <PersonIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Parrain" secondary={loading ? <Skeleton width={300} /> : agent?.sponsor?.fullName} />
          </ListItem>
        )}
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <BusinessIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="Société"
            secondary={
              loading ? (
                <Skeleton width={300} />
              ) : agent?.agent?.address?.company ? (
                agent?.agent?.address?.company
              ) : (
                " - "
              )
            }
          />
        </ListItem>
      </List>
    </Grid>
    <Grid item xs={12} md={6}>
      <List>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <RoomIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="Adresse"
            secondary={
              loading ? (
                <>
                  <Skeleton width={300} />
                  <Skeleton width={300} />
                </>
              ) : (
                <>
                  {agent?.agent?.address?.postalAddress}
                  <br />
                  {agent?.agent?.address?.postalCode} {agent?.agent?.address?.postalCity}
                </>
              )
            }
          />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <CreditCardIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="SIRET" secondary={loading ? <Skeleton width={300} /> : agent?.agent?.address?.siret} />
        </ListItem>
        <ListItem>
          <ListItemText
            inset
            primary="N°TVA Intracommunautaire"
            secondary={loading ? <Skeleton width={300} /> : agent?.agent?.address?.vatIdNumber}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            inset
            primary="IBAN"
            secondary={loading ? <Skeleton width={300} /> : agent?.agent?.address?.iban}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            inset
            primary={
              <FormControlLabel
                disabled
                control={<Checkbox checked={!loading && agent?.agent?.address?.microEnterprise} />}
                label="Micro entreprise (TVA non applicable)"
              />
            }
          />
        </ListItem>
      </List>
    </Grid>
  </Grid>
);

UserInfo.propTypes = {
  user: PropTypes.object,
  agent: PropTypes.object,
  loading: PropTypes.bool,
};

export default UserInfo;
