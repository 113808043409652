import { TextField, Autocomplete, CircularProgress } from "@material-ui/core";
import { useQuery } from "react-query";
import { getProfiles } from "../../http/profiles";

const AutocompleteProfilesField = ({
  field,
  errors,
  disabled,
  defaultAll = false,
}) => {
  // get all Files
  const { isFetching, data: AgentProfiles } = useQuery({
    queryKey: ["getProfilesList"],
    queryFn: () => getProfiles({ type: "Mandataire" }),
    onSuccess: (data) => {
      if (defaultAll && field.value.length === 0) field.onChange(data);
    },
  });

  return (
    <Autocomplete
      id="profiles-field"
      multiple
      disabled={disabled}
      getOptionLabel={(option) => option?.title ?? ""}
      getOptionSelected={(option, value) => option.id === value.id}
      options={AgentProfiles || []}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={field.value}
      onChange={(event, newValue) => {
        return newValue.length > 0
          ? field.onChange(newValue)
          : field.onChange([]);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Profile"
          variant="outlined"
          fullWidth
          error={Boolean(errors?.message)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isFetching ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default AutocompleteProfilesField;
