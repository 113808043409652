import styled from "@emotion/styled";
import { Star as StarIcon } from "@material-ui/icons";
import { grey, orange, yellow } from "@material-ui/core/colors";

export const GoldStar = styled(StarIcon)(({ theme }) => ({
  color: yellow[500],
}));

export const SilverStar = styled(StarIcon)(({ theme }) => ({
  color: grey[500],
}));

export const BronzeStar = styled(StarIcon)(({ theme }) => ({
  color: orange[500],
}));
