import { Card, CardContent, CardHeader, Divider, Grid, IconButton } from "@material-ui/core";
import { ArrowForward as ArrowForwardIcon } from "@material-ui/icons";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import React from "react";
import { useQuery } from "react-query";
import { Link as RouterLink } from "react-router-dom";
import { getSalesRealEstateSummary } from "../../http/salesRealEstate";
import { toEuros } from "../../utils/formatters";
import isAgent from "../../utils/isAgent";
import GuardedComponent from "../GuardedComponent";
import StatsCardValue from "../StatsCardValue";

const SalesRealEstateSummary = ({ fromRealEstate, agentId }) => {
  const { isFetching, data: sales } = useQuery(
    ["getSalesRealEstateSummary", agentId],
    () => getSalesRealEstateSummary(agentId),
    { keepPreviousData: true }
  );

  const now = dayjs();
  const year = now.year();

  return (
    <Card>
      <CardHeader
        title={`${fromRealEstate ? "Total des ventes par étape" : "Suivi immobilier"} ${year}`}
        action={
          !fromRealEstate &&
          agentId && (
            <GuardedComponent componentName="team_detail_real_estate_page">
              <IconButton
                aria-label="Accéder au suivi immobilier du mandataire"
                component={RouterLink}
                to={isAgent() ? `/my-team/${agentId}/real-estate` : `/team/${agentId}/real-estate`}
              >
                <ArrowForwardIcon />
              </IconButton>
            </GuardedComponent>
          )
        }
      />
      <Divider />
      <CardContent
        sx={{
          p: 3,
        }}
      >
        <Grid container justify="space-around" spacing={2} justifyContent="center">
          <Grid container justify="space-around" spacing={2} sx={{ flex: { xs: "initial" }, marginTop: 0 }}>
            <StatsCardValue title="Option" value={toEuros(sales?.optionsTotal)} loading={isFetching && !sales} />
            <StatsCardValue title="Réservation" value={toEuros(sales?.bookingsTotal)} loading={isFetching && !sales} />
            <StatsCardValue title="Financé" value={toEuros(sales?.finance)} loading={isFetching && !sales} />
          </Grid>
          <Grid container justify="space-around" spacing={2} sx={{ flex: { xs: "initial" }, marginTop: 0 }}>
            <StatsCardValue
              title="Encours"
              value={toEuros(sales?.encours)}
              loading={isFetching && !sales}
              tooltip="L'encours immobilier correspond aux dossiers aux étapes Réservation et accord bancaire. Il ne comprend pas les options immobilières."
            />
            <StatsCardValue title="Acté" value={toEuros(sales?.deedsTotal)} loading={isFetching && !sales} />
            <StatsCardValue title="Total" value={toEuros(sales?.total)} loading={isFetching && !sales} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

SalesRealEstateSummary.defaultProps = {
  fromRealEstate: false,
};

SalesRealEstateSummary.propTypes = {
  fromRealEstate: PropTypes.bool,
  agentId: PropTypes.string,
};

export default SalesRealEstateSummary;
