import { dateToString, toEuros, toPercents } from "./formatters";

export const eurColumn = {
  type: "number",
  valueFormatter: ({ value }) => toEuros(value),
};

export const positiveEurColumn = {
  type: "number",
  valueFormatter: ({ value }) => (value === -1 ? toEuros(0) : toEuros(value)),
};

export const percentColumn = {
  type: "number",
  valueFormatter: ({ value }) => toPercents(value),
};

export const dateColumn = {
  type: "date",
  valueFormatter: ({ value }) => (value ? dateToString(value) : ""),
};

export const booleanColumn = {
  type: "boolean",
};
