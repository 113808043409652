import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { Box, Button, Typography } from "@material-ui/core";
import store from "../../store";
import { returnFromImpersonateUser } from "../../store/actions/auth";

const ImpersonateUserMenuInfo = ({ isImpersonateUser, profilName }) => {
  const history = useHistory();
  const handleClickReturnAdmin = () => {
    store.dispatch(returnFromImpersonateUser());
    history.push("/administration/users");
  };

  return (
    <>
      {isImpersonateUser && (
        <Box sx={{ padding: 0, display: "flex", flexDirection: "column", alignItems: "center", marginRight: "24px" }}>
          <Typography>Vue {profilName}</Typography>
          <Button variant="contained" onClick={handleClickReturnAdmin} color="hubnup">
            Revenir à la vue admin
          </Button>
        </Box>
      )}
    </>
  );
};

ImpersonateUserMenuInfo.propTypes = {
  isImpersonateUser: PropTypes.bool,
  profilName: PropTypes.string,
};

const mapStateToProps = (state) => ({
  isImpersonateUser: state.auth.isImpersonateUser,
  profilName: state.auth.profilName,
});

export default connect(mapStateToProps, null)(ImpersonateUserMenuInfo);
