import PropTypes from "prop-types";
import React from "react";
import AppDialog from "../AppDialog";
import ProspectsForm from "./ProspectsForm";

const ProspectsCreateModal = ({ open, onClose }) => {
  return (
    <AppDialog open={open} onClose={onClose} title="Créer un prospect" maxWidth="md">
      <ProspectsForm onClose={onClose} />
    </AppDialog>
  );
};

ProspectsCreateModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ProspectsCreateModal;
