import { useSnackbar } from "notistack";
import React from "react";
import { Container } from "@material-ui/core";
import { useQuery } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import AppCircularProgress from "../../components/AppCircularProgress";
import { getFreshdeskUrl } from "../../http/sso";

const Freshdesk = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const useQueryParams = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQueryParams();

  const redirectUri = query.get("redirect_uri");
  const state = query.get("state");
  const nonce = query.get("nonce");
  const clientId = query.get("client_id");

  if (!clientId || !nonce || !state || !redirectUri) {
    enqueueSnackbar("Erreur dans les paramètres de la requête.", { variant: "error" });
    history.push("/");
  }

  const { isError, isFetched, data } = useQuery(["getFreshdeskUrl", clientId], async () => {
    return await getFreshdeskUrl(redirectUri, state, nonce);
  });

  if (isError) {
    enqueueSnackbar("Erreur lors de la récupération de l'utilisateur.", { variant: "error" });
    history.push("/");
  }

  if (isFetched && data) {
    window.location.assign(data);
  } else if (isFetched && !data) {
    enqueueSnackbar("Erreur lors de la récupération de l'utilisateur.", { variant: "error" });
    history.push("/");
  }

  return (
    <Container maxWidth={false}>
      <AppCircularProgress />
    </Container>
  );
};

export default Freshdesk;
