import { useMemo } from "react";
import {
  Paper,
  List,
  ListItem,
  Typography,
  Link,
  makeStyles,
  ListItemIcon,
  Box,
  Alert,
} from "@material-ui/core";
import { FiberManualRecord } from "@material-ui/icons";
import { useMutation, useQueryClient } from "react-query";
import dayjs from "dayjs";

import AppCircularProgress from "../../AppCircularProgress";
import NotificationIcon from "./NotificationIcon";
import { updateNotificationListCollectionStatus } from "../../../http/notifications";
import store from "../../../store";
import { useRouteMatch } from "react-router-dom";

function NotificationItem({ item }) {
  const classes = useStyles();

  const {
    auth: { email },
  } = store.getState();

  const queryClient = useQueryClient();

  const readNotificationMutation = useMutation({
    mutationKey: ["read-notification", { itemId: item.id, email }],
    mutationFn: () =>
      updateNotificationListCollectionStatus({
        notificationListIds: [item.id],
      }),
    onSuccess: () =>
      queryClient.invalidateQueries(["notifications-list", { email }]),
  });

  const handleRead = async () => {
    void readNotificationMutation.mutateAsync();

    if (item.notification.urlLink)
      window.open(item.notification.urlLink, "_blank");
  };

  const link = { url: item.notification.urlLink, inner: false };
  if (item.notification.urlLink) {
    try {
      const url = new URL(item.notification.urlLink);

      if (url.host === document.location.host) {
        link.url = url.pathname;
        link.inner = true;
      }
    } catch (e) {}
  }

  return (
    <ListItem
      key={item.id}
      alignItems="flex-start"
      className={classes.listStyle}
      sx={{ pl: 0, pr: "1rem", py: "0.5rem", cursor: "pointer" }}
      onClick={handleRead}
    >
      <ListItemIcon sx={{ minWidth: "unset", paddingX: ".2rem" }}>
        <FiberManualRecord
          fontSize="10px"
          sx={{
            color: "red",
            visibility: item.readByUser ? "hidden" : "visible",
          }}
        />
      </ListItemIcon>
      <div className={classes.notyContent}>
        <div>
          <Typography
            component="h6"
            sx={{ mb: "2px" }}
            className={classes.title}
          >
            {item.notification.title}
          </Typography>
          <Typography
            component="p"
            variant="body2"
            className={classes.description}
            color="textPrimary"
          >
            {item.notification.description}
            {item.notification.urlLink && (
              <>
                :{" "}
                <Typography
                  component="span"
                  sx={{
                    textDecoration: "underline",
                    ":visited": { color: "black" },
                  }}
                >
                  {link.inner ? link.url : "Suivre le Lien"}
                </Typography>
              </>
            )}
          </Typography>
        </div>
        <div className={classes.fromNow}>
          <Typography component="p" sx={{ fontSize: "0.7rem" }}>
            {dayjs(item.notification.createdAt).fromNow()}
          </Typography>
        </div>
      </div>
    </ListItem>
  );
}

function NotificationContent({ userNotificationsData, isLoading, ref }) {
  const classes = useStyles();

  const notificationsUnreadCount = useMemo(() => {
    if (!userNotificationsData?.length) return 0;

    return userNotificationsData.filter((item) => !item.readByUser).length;
  }, [userNotificationsData]);

  const queryClient = useQueryClient();

  const {
    auth: { email },
  } = store.getState();

  const readAllNotificationMutation = useMutation({
    mutationKey: ["read-notifications"],
    mutationFn: (ids) =>
      updateNotificationListCollectionStatus({ notificationListIds: ids }),
    onSuccess: () =>
      queryClient.invalidateQueries(["notifications-list", { email }]),
  });

  const handleReadAll = async () => {
    const unreadNotificationsWithNoLinks = userNotificationsData.filter(
      (item) => item.notification.urlLink === "" && !item.readByUser,
    );

    if (unreadNotificationsWithNoLinks.length > 0)
      await readAllNotificationMutation.mutateAsync(
        unreadNotificationsWithNoLinks.map((item) => item.id),
      );
  };

  const sortedNotificationList = useMemo(
    () =>
      userNotificationsData.sort(
        (a, b) =>
          new Date(b.notification.createdAt).getTime() -
          new Date(a.notification.createdAt).getTime(),
      ),
    [userNotificationsData],
  );

  return (
    <Paper
      sx={{
        position: "absolute",
        left: "10%",
        top: "75px",
        zIndex: "1201",
        backgroundColor: "white",
        maxHeight: "60vh",
        minWidth: "300px",
        maxWidth: "450px",
        overflowY: "auto",
        border: "0.1rem black solid",
        paddingRight: "5px",
      }}
      elevation={12}
      ref={ref}
    >
      {isLoading ? (
        <AppCircularProgress />
      ) : (
        <Box sx={{ display: "flex" }}>
          <Box sx={{ px: "0.5rem" }}>
            <Box sx={{ position: "sticky", top: "1.5rem" }}>
              <NotificationIcon
                count={notificationsUnreadCount}
                onClick={handleReadAll}
              />
            </Box>
          </Box>
          {sortedNotificationList.length > 0 ? (
            <Box>
              <List className={classes.root}>
                {sortedNotificationList.map((item) => (
                  <NotificationItem key={item.id} item={item} />
                ))}
              </List>
            </Box>
          ) : (
            <Box sx={{ px: "2rem", py: "1.5rem" }}>
              <Alert severity="info">
                Vous n'avez aucune nouvelle notification.
              </Alert>
            </Box>
          )}
        </Box>
      )}
    </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    "& li:not(:last-child)": {
      borderBottom: "2px solid #2a6975",
    },
  },
  notyContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "baseline",
    width: "100%",
  },
  fromNow: {
    alignSelf: "end",
  },
}));

export default NotificationContent;
