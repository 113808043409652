import PropTypes from "prop-types";
import React, { useState } from "react";
import { DataGrid } from "@material-ui/data-grid";
import { Link } from "@material-ui/core";
import { useQuery } from "react-query";
import { Link as RouterLink } from "react-router-dom";
import { getSalesInvestment } from "../../http/salesInvestment";
import checkAccessStore from "../../utils/checkAccessStore";
import { dateColumn, eurColumn, percentColumn } from "../../utils/dataGridColumns";
import renderCellExpand from "../../utils/renderCellExpand";
import AppCircularProgress from "../AppCircularProgress";

const SalesInvestmentList = ({ filters, sortModel, setSortModel, fromAgent, agentId }) => {
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(0);

  const { isFetching, data: sales } = useQuery(
    ["getSalesInvestment", filters, page, pageSize, sortModel, agentId],
    async () => {
      const data = await getSalesInvestment(filters, page, pageSize, sortModel, agentId);

      return {
        sales: data["hydra:member"],
        totalItems: data["hydra:totalItems"],
      };
    },
    { keepPreviousData: true }
  );

  const handleSortModelChange = (params) => {
    if (params.sortModel !== sortModel) {
      setSortModel(params.sortModel);
    }
  };

  const hubnupColumns = [
    {
      field: "agentFullName",
      headerName: "Mandataire",
      width: 160,
      valueGetter: (params) => params.row?.consultant?.fullName,
      renderCell: (params) =>
        renderCellExpand(
          params,
          true,
          checkAccessStore("team_detail_page") ? (
            <Link component={RouterLink} to={`/sales/investment/team/${params.row?.consultant?.idCgp}`}>
              {params.value}
            </Link>
          ) : (
            params.value
          )
        ),
    },
  ];

  const baseColumns = [
    {
      field: "clientFullName",
      headerName: "Client",
      width: 160,
      valueGetter: (params) => params.row?.client?.fullNameLastNameFirst,
      renderCell: (params) =>
        renderCellExpand(
          params,
          true,
          checkAccessStore("customers_detail_page") ? (
            <Link
              component={RouterLink}
              to={
                fromAgent
                  ? `/my-sales/investment/clients/${params.row?.client?.id}`
                  : `/sales/investment/clients/${params.row?.client?.id}`
              }
            >
              {params.value}
            </Link>
          ) : (
            params.value
          )
        ),
    },
    {
      field: "productName",
      headerName: "Produit",
      flex: 1,
      valueGetter: (params) => params.row.typeProduitsFin.productName,
      renderCell: renderCellExpand,
    },
    {
      field: "providerName",
      headerName: "Fournisseur",
      flex: 1,
      valueGetter: (params) => params.row.typeProduitsFin.providerName,
      renderCell: renderCellExpand,
    },
    { field: "amount", ...eurColumn, headerName: "Montant", flex: 1, renderCell: renderCellExpand },
    { field: "fee", ...percentColumn, headerName: "Frais", flex: 1, renderCell: renderCellExpand },
    { field: "status", headerName: "État", flex: 1, renderCell: renderCellExpand },
    {
      field: "subscriptionDate",
      ...dateColumn,
      headerName: "Souscription fournisseur",
      flex: 1,
      renderCell: renderCellExpand,
    },
    { field: "type", headerName: "Type", flex: 1, renderCell: renderCellExpand },
    { field: "operation", headerName: "Opération", flex: 1, renderCell: renderCellExpand },
    { field: "orderNumber", headerName: "N° ordre", flex: 1, renderCell: renderCellExpand },
    { field: "exercice", headerName: "Exercice", flex: 1, renderCell: renderCellExpand },
    // {field: 'invoice', headerName: 'Facture', flex: 1, renderCell: params => (
    //   <Link>{params.value}</Link>
    // )}, // Todo
  ];

  const columns = fromAgent ? baseColumns : hubnupColumns.concat(baseColumns);

  return (
    <>
      {sales ? (
        <DataGrid
          loading={isFetching}
          rows={sales.sales}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize.pageSize)}
          autoHeight
          disableColumnFilter
          disableSelectionOnClick
          disableColumnMenu
          disableColumnReorder
          pagination
          paginationMode="server"
          rowCount={sales.totalItems}
          onPageChange={(params) => setPage(params.page)}
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          getRowId={(row) => row["@id"]}
        />
      ) : (
        <AppCircularProgress />
      )}
    </>
  );
};

SalesInvestmentList.defaultProps = {
  fromAgent: false,
  agentId: null,
};

SalesInvestmentList.propTypes = {
  filters: PropTypes.object,
  sortModel: PropTypes.array,
  setSortModel: PropTypes.func,
  fromAgent: PropTypes.bool,
  agentId: PropTypes.string,
};

export default SalesInvestmentList;
