import React from "react";
import { DataGrid } from "@material-ui/data-grid";
import { Box, IconButton, Link } from "@material-ui/core";
import { useMutation, useQuery } from "react-query";
import { Link as RouterLink } from "react-router-dom";
import { Download as DownloadIcon, Visibility as VisibilityIcon } from "@material-ui/icons";
import { getInvoices } from "../../http/invoices";
import checkAccessStore from "../../utils/checkAccessStore";
import { dateColumn, eurColumn } from "../../utils/dataGridColumns";
import renderCellExpand from "../../utils/renderCellExpand";
import { getMediaObjectDownload } from "../../http/documentations";

const InvoicesList = ({ filters, fromAgent }) => {
  const { isFetching, data: invoices } = useQuery(["getInvoices", filters], async () => {
    return await getInvoices(filters);
  });

  const handleViewFile = async (document) => {
    window.open(`${process.env.REACT_APP_API_URL}media/invoices/${document.filePath}`, "_blank");
  };

  const mutation = useMutation(getMediaObjectDownload);

  const handleDownloadFile = async (document) => {
    mutation.mutate({ file: document });
  };

  const hubnupColumns = [
    {
      field: "agentFullName",
      headerName: "Mandataire",
      width: 160,
      valueGetter: (params) => params.row?.consultant?.fullName,
      renderCell: (params) =>
        renderCellExpand(
          params,
          true,
          checkAccessStore("team_detail_page") ? (
            <Link component={RouterLink} to={`/invoices/team/${params.row?.consultant?.idCgp}`}>
              {params.value}
            </Link>
          ) : (
            params.value
          )
        ),
    },
  ];

  const baseColumns = [
    {
      field: "invoiceNumber",
      headerName: "Facture",
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: "clientFullName",
      headerName: "Client",
      width: 160,
      valueGetter: (params) => params.row?.client?.fullName,
      renderCell: (params) =>
        renderCellExpand(
          params,
          true,
          checkAccessStore("customers_detail_page") ? (
            <Link
              component={RouterLink}
              to={
                fromAgent
                  ? `/my-invoices/clients/${params.row?.client?.id}`
                  : `/invoices/clients/${params.row?.client?.id}`
              }
            >
              {params.value}
            </Link>
          ) : (
            params.value
          )
        ),
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: "product",
      headerName: "Produit",
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: "dateInvoiceGeneration",
      headerName: "Date d'édition",
      flex: 1,
      ...dateColumn,
      renderCell: renderCellExpand,
    },
    {
      field: "amountInvested",
      headerName: "Montant investi",
      flex: 1,
      ...eurColumn,
      renderCell: renderCellExpand,
    },
    {
      field: "amountHt",
      headerName: "Montant facture HT",
      flex: 1,
      ...eurColumn,
      renderCell: renderCellExpand,
    },
    {
      field: "amountTtc",
      headerName: "Montant facture TTC",
      flex: 1,
      ...eurColumn,
      renderCell: renderCellExpand,
    },
    {
      field: "actions",
      headerName: " ",
      sortable: false,
      flex: 0.5,
      renderCell: (params) =>
        params.row?.mediaObject ? (
          <Box sx={{ width: "100%", display: "flex", justifyContent: "space-around" }}>
            <IconButton
              aria-label="Voir la facture"
              size="small"
              onClick={() => handleViewFile(params.row?.mediaObject)}
            >
              <VisibilityIcon />
            </IconButton>
            <IconButton
              aria-label="Télécharger la facture"
              size="small"
              onClick={() => handleDownloadFile(params.row?.mediaObject)}
            >
              <DownloadIcon />
            </IconButton>
          </Box>
        ) : (
          ""
        ),
    },
  ];

  const columns = fromAgent ? baseColumns : hubnupColumns.concat(baseColumns);
  const sortModel = [{ field: "dateInvoiceGeneration", sort: "desc" }];

  return (
    <>
      <DataGrid
        loading={isFetching}
        rows={invoices ?? []}
        columns={columns}
        pageSize={10}
        autoHeight
        disableColumnFilter
        disableSelectionOnClick
        disableColumnMenu
        disableColumnReorder
        sortModel={sortModel}
      />
    </>
  );
};

export default InvoicesList;
