import { Delete as DeleteIcon, Create as CreateIcon } from "@material-ui/icons";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { IconButton, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClone } from "@fortawesome/free-solid-svg-icons";
import NewsDeleteModal from "./NewsDeleteModal";
import NewsDuplicateModal from "./NewsDuplicateModal";

const NewsAction = ({ news, goBack, fromDetails, reinitData }) => {
  const [openDuplicateModal, setOpenDuplicateModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const history = useHistory();

  const handleClickOpenDuplicateModal = () => setOpenDuplicateModal(true);
  const handleCloseDuplicateModal = (reset) => {
    setOpenDuplicateModal(false);
    if (reset && reinitData) {
      reinitData();
    }
  };
  const handleClickOpenDeleteModal = () => setOpenDeleteModal(true);
  const handleCloseDeleteModal = (reset) => {
    setOpenDeleteModal(false);
    if (fromDetails) {
      history.push("/communication/news");
    }
    if (reset && reinitData) {
      reinitData();
    }
  };

  const handleEdit = () => {
    history.push({ pathname: `/communication/news/${news.id}/edit`, state: { goBack: goBack } });
  };

  return (
    <>
      {fromDetails ? (
        <>
          <Button
            variant="contained"
            startIcon={<FontAwesomeIcon icon={faClone} />}
            onClick={handleClickOpenDuplicateModal}
          >
            Dupliquer
          </Button>
          <Button variant="contained" color="hubnup" startIcon={<DeleteIcon />} onClick={handleClickOpenDeleteModal}>
            Supprimer
          </Button>
        </>
      ) : (
        <>
          <IconButton color="inherit" onClick={handleClickOpenDuplicateModal}>
            <FontAwesomeIcon icon={faClone} />
          </IconButton>
          <IconButton color="inherit" onClick={handleEdit}>
            <CreateIcon />
          </IconButton>
          <IconButton color="inherit" onClick={handleClickOpenDeleteModal}>
            <DeleteIcon />
          </IconButton>
        </>
      )}
      {openDeleteModal && <NewsDeleteModal news={news} open={openDeleteModal} onClose={handleCloseDeleteModal} />}
      {openDuplicateModal && (
        <NewsDuplicateModal news={news} open={openDuplicateModal} onClose={handleCloseDuplicateModal} />
      )}
    </>
  );
};

NewsAction.defaultProps = {
  fromDetails: false,
};

NewsAction.propTypes = {
  news: PropTypes.object,
  goBack: PropTypes.string,
  fromDetails: PropTypes.bool,
  reinitData: PropTypes.func,
};

export default NewsAction;
