import { yupResolver } from "@hookform/resolvers/yup";
import { DatePicker, LoadingButton } from "@material-ui/lab";
import React, { useEffect } from "react";
import {
  Alert,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Link,
  makeStyles,
  Paper,
  TextField,
} from "@material-ui/core";
import { FileDownload as FileDownloadIcon } from "@material-ui/icons";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import * as yup from "yup";
import palette from "../../theme/palette";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import AutocompleteDocumentField from "./AutocompleteDocumentsField";
import TextEditorWysiwigField from "../form/TextEditorWysiwigField";
import {
  editModificationRequest,
  postModificationRequest,
} from "../../http/documentations";
import isAgent from "../../utils/isAgent";
import { useSnackbar } from "notistack";
import { requestModificationStatus } from "../../utils/ressources/documents";
import ControlledSelectInputField from "../form/ControlledSelectInputField";
import dayjs from "dayjs";
import { useTrackingContext } from "../../contexts/TrackingContext";
import { ACTION_CLICK } from "../../utils/trackingConstant";

const schema = yup.object().shape({
  createdAt: yup.string(""),
  title: yup.string().required("Ce champ est obligatoire."),
  description: yup.string().required("Ce champ est obligatoire"),
  document: yup.object().required("Ce champ est obligatoire"),
  status: yup.string(),
  comment: yup.string().max(255),
});

/** Add, Edit and ReadOnly Form for ModificationRequest  */
const ModificationRequestsForm = ({ toggleFormModal, requestClickedData }) => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const track = useTrackingContext()

  // request Data
  const {
    id: requestId,
    title: requestTitle,
    createdAt: requestCreatedAt,
    mediaObject: requestMediaObject,
    description: requestDescription,
    status: requestStatus,
    comment: requestComment,
  } = requestClickedData;

  // if the form is opened following click on request Title in the list
  const isEditForm = requestClickedData.id ? true : false;

  const disableFieldsForAgent = isAgent() && isEditForm;

  const statusList = Object.values(requestModificationStatus);

  const queryClient = useQueryClient();

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      createdAt: "",
      title: "",
      description: "",
      document: {},
      status: "",
      comment: "",
    },
    resolver: yupResolver(schema),
  });

  // if it is an edit form, then set form values.
  useEffect(() => {
    if (requestId) {
      setValue("title", requestTitle);
      setValue("createdAt", requestCreatedAt);
      setValue("description", requestDescription);
      setValue("document", requestMediaObject);
      setValue("status", requestStatus);
      setValue("comment", requestComment);
    }
  }, [requestId, setValue]);

  const mutationAddRequest = useMutation(postModificationRequest, {
    onSuccess: () => {
      toggleFormModal();
      queryClient.invalidateQueries({
        queryKey: ["getModificationRequestsAll"],
      });
      enqueueSnackbar("la demande est envoyé", { variant: "success" });
    },
  });

  const mutationEditRequest = useMutation(editModificationRequest, {
    onSuccess: () => {
      toggleFormModal();
      queryClient.invalidateQueries({
        queryKey: ["getModificationRequestsAll"],
      });
      enqueueSnackbar("la demande est mise à jour", {
        variant: "success",
      });
    },
  });

  // On Submit we mix data from the submit, text editor and the added image
  const onSubmit = async (data, event, requestId) => {
    if (isEditForm) {
      const { document, ...editDataWithoutDocument } = data;

      const editDataWithFormattedDate = {
        ...editDataWithoutDocument,
        createdAt: dayjs(data.createdAt).format("YYYY-MM-DD"),
      };

      track.click(ACTION_CLICK.DOCUMENT_CREATE_REQUEST)
      mutationEditRequest.mutate({ editDataWithFormattedDate, requestId });
    } else {
      // if i'm adding a new Modification Request
      const postData = {
        createdAt: dayjs().format("YYYY-MM-DD"),
        title: data.title,
        description: data.description,
        mediaObject: data.document["@id"],
      };

      track.click(ACTION_CLICK.DOCUMENT_CREATE_REQUEST)
      mutationAddRequest.mutate(postData);
    }
  };

  return (
    <Container maxWidth={false}>
      <Container maxWidth="lg">
        <Paper sx={{ pb: 2, overflow: "hidden" }}>
          <>
            <form
              id="edit-form"
              key="edit-form"
              noValidate
              onSubmit={handleSubmit((data, event) =>
                onSubmit(data, event, requestId),
              )}
            >
              {mutationAddRequest.isError ? (
                <Alert severity="error">
                  Une erreur est survenue : {mutationAddRequest.error.message}
                </Alert>
              ) : null}
              <Box sx={{ pt: 3 }}>
                <Container maxWidth="md">
                  {isEditForm && (
                    <Grid container spacing={4} alignItems="center">
                      <Grid item xs={12} sm={4}>
                        <Controller
                          name="createdAt"
                          control={control}
                          shouldUnregister
                          render={({ field, formState: { errors } }) => (
                            <DatePicker
                              {...field}
                              label="Date de la demande"
                              inputFormat="DD/MM/YYYY"
                              disabled={disableFieldsForAgent}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  margin="dense"
                                  fullWidth
                                  error={Boolean(errors.createdAt?.message)}
                                  helperText={errors.createdAt?.message}
                                />
                              )}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  )}
                  <Grid container spacing={4} alignItems="center">
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="title"
                        control={control}
                        variant="outlined"
                        shouldUnregister
                        render={({ field, formState: { errors } }) => (
                          <TextField
                            {...field}
                            error={Boolean(errors.title?.message)}
                            helperText={errors.title?.message}
                            className={classes.titleField}
                            label="Titre"
                            margin="normal"
                            autoComplete="title"
                            disabled={disableFieldsForAgent}
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    my={2}
                    sx={{
                      border: "1px solid rgba(0, 0, 0, 0.23)",
                      minHeight: "300px",
                      borderRadius: "4px",
                      "&:hover": {
                        border: "1px solid #172b4d",
                      },
                      "&:focus-within": {
                        border: "2px solid #2a6775",
                      },
                    }}
                  >
                    <Controller
                      name="description"
                      control={control}
                      defaultValue={""}
                      render={({ field, formState: { errors } }) => (
                        <>
                          <TextEditorWysiwigField
                            error={Boolean(errors.title?.message)}
                            {...field}
                            disabled={disableFieldsForAgent}
                          />
                          {errors.description && (
                            <p className="error">
                              {errors.description.message}
                            </p>
                          )}
                        </>
                      )}
                      /* rules = {{
                          validate: {
                            required: (v) =>
                              (v && stripHtml(v).length > 0) ||
                              "Description is required",
                          },
                        }} */
                    />
                  </Grid>

                  <Grid container spacing={4} alignItems="center">
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="document"
                        control={control}
                        defaultValue={""}
                        render={({ field, formState: { errors } }) => (
                          <>
                            <AutocompleteDocumentField
                              field={field}
                              errors={errors}
                              disabled={disableFieldsForAgent}
                            />
                          </>
                        )}
                      />
                    </Grid>
                    {isEditForm && (
                      <Grid item xs={12} sm={6}>
                        <Link
                          target="_blank"
                          href={`${process.env.REACT_APP_API_URL}media/${requestMediaObject.folder.type}/${requestMediaObject.filePath}`}
                        >
                          <Button
                            startIcon={<FileDownloadIcon />}
                            variant="contained"
                          >
                            Voir le fichier
                          </Button>
                        </Link>
                      </Grid>
                    )}
                  </Grid>

                  {/* Admin Fields */}
                  {isEditForm && (
                    <>
                      <Divider
                        orientation="horizontal"
                        flexItem
                        sx={{ mt: "1rem" }}
                      />
                      <Grid container spacing={4} mb={3} mt={0.5}>
                        <Grid item xs={2}>
                          <ControlledSelectInputField
                            control={control}
                            name="status"
                            title="Status"
                            list={statusList}
                            disabled={disableFieldsForAgent}
                          />
                        </Grid>
                        <Grid item xs={8}>
                          <Controller
                            name="comment"
                            control={control}
                            defaultValue={""}
                            render={({ field, formState: { errors } }) => (
                              <>
                                <TextField
                                  id="outlined-textarea"
                                  label="Commentaire"
                                  placeholder="Commentaire"
                                  multiline
                                  fullWidth
                                  disabled={disableFieldsForAgent}
                                  {...field}
                                />
                              </>
                            )}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}

                  {(!isEditForm || (isEditForm && !disableFieldsForAgent)) && (
                    <Box className={classes.actionWrapper}>
                      <Button variant="outlined" onClick={toggleFormModal}>
                        Annuler
                      </Button>
                      <LoadingButton
                        loading={mutationAddRequest.isLoading}
                        form="edit-form"
                        type="submit"
                        variant="contained"
                      >
                        Envoyer
                      </LoadingButton>
                    </Box>
                  )}
                </Container>
              </Box>
            </form>
          </>
        </Paper>
      </Container>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  pictureIcon: {
    position: "absolute",
    bottom: "1rem",
    right: "1rem",
    backgroundColor: "#FFFFFF",
    color: palette.hubnup.main,
  },
  noImage: {
    minHeight: "100px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  noImageError: {
    color: "red",
  },
  imageContainer: {
    position: "relative",
  },
  imageNews: {
    width: "100%",
    height: "auto",
    maxHeight: "400px",
    objectFit: "cover",
  },
  titleField: {
    "& input": {
      fontSize: "25px",
    },
    "& label": {
      fontSize: "25px",
    },
  },
  actionWrapper: {
    display: "flex",
    justifyContent: "flex-end",

    "& button:not(:first-child)": {
      marginLeft: "1rem",
    },
  },
}));

export default ModificationRequestsForm;
