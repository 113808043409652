import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { TextField, Autocomplete, CircularProgress } from "@material-ui/core";
import { useQuery } from "react-query";
import { getAgentsCgpAutocomplete } from "../http/agents";

const AutocompleteAgentsCgpField = ({ setAgent, label, reset, agentId }) => {
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState(null);

  useEffect(() => {
    setInputValue("");
    setValue("");
  }, [reset]);

  const { isFetching, data: agents } = useQuery(
    ["getAgentsCgpAutocomplete", inputValue, agentId],
    () => getAgentsCgpAutocomplete(inputValue, agentId),
    {
      keepPreviousData: true,
      enabled: !!inputValue,
    }
  );

  useEffect(() => {
    if (agents) {
      setOptions(agents);
    }
  }, [agents]);

  return (
    <Autocomplete
      id="agent-field"
      getOptionLabel={(option) => (option ? option.agent.fullName : "")}
      getOptionSelected={(option, value) => option?.agent.id === value?.id}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(event, newValue) => {
        setAgent(newValue?.agent.id || "");
        setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.agent.id}>
            {option.agent.fullName}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isFetching ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

AutocompleteAgentsCgpField.defaultProps = {
  label: "Mandataire",
  agentId: null,
};

AutocompleteAgentsCgpField.propTypes = {
  setAgent: PropTypes.func,
  label: PropTypes.string,
  reset: PropTypes.bool,
  agentId: PropTypes.string,
};

export default AutocompleteAgentsCgpField;
