import axios from "axios";

import store from "../store";
import * as actions from "../store/actions";

const { dispatch } = store;

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

instance.interceptors.request.use(
  (config) => {
    if (config.method === "post" && (config.url === "/token" || config.url.includes("/reset_password_tokens"))) {
      return config;
    }

    if (config.method === "patch") {
      config.headers["Content-Type"] = "application/merge-patch+json";
    }

    const auth = JSON.parse(localStorage.getItem("auth"));

    config.headers["Authorization"] = `Bearer ${auth.token}`;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (config) => config,
  (error) => {
    if (error.response?.status === 401) {
      dispatch(actions.logout());
    }

    return Promise.reject(error);
  }
);

export default instance;
