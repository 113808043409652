import React from "react";
import Documentation from "../Documentation";
import { getResponsablePoleFolders } from "../../http/documentations";

const LibraryResponsablePole = (_) => {
  return (
    <Documentation
      foldersQuery={getResponsablePoleFolders}
      componentName="responsable_pole_documentation_page_edit"
      type="responsable-pole"
    />
  );
};

export default LibraryResponsablePole;
