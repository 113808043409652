import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@material-ui/lab";
import React from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  Alert,
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  makeStyles,
  TextField,
} from "@material-ui/core";
import * as yup from "yup";

import { getAdministrationSettings, updateAdministrationSettings } from "../../http/settings";

const PortailSettings = (props) => {
  const classes = useStyles();
  const queryClient = useQueryClient();

  const { isFetched, data } = useQuery("getAdministrationSettings", getAdministrationSettings);

  const schema = yup.object().shape({
    supportEmail: yup
      .string()
      .required("Ce champ est obligatoire.")
      .email("Cette valeur n'est pas une adresse email valide."),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (supportEmail) => {
    const tempData = {
      id: data && data[0] ? data[0].id : null,
      supportEmail: supportEmail.supportEmail,
    };
    mutation.mutate(tempData);
  };

  const mutation = useMutation(updateAdministrationSettings, {
    onSuccess: () => {
      queryClient.invalidateQueries("getAdministrationSettings");
    },
  });

  return (
    <Card {...props}>
      <CardHeader title="Portail" />
      <Divider />
      <CardContent>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Box>
            {mutation.isError ? (
              <Alert severity="error">Une erreur est survenue : {mutation.error.message}</Alert>
            ) : null}

            {mutation.isSuccess ? <Alert severity="success">Modification réussie.</Alert> : null}

            {isFetched ? (
              <>
                <TextField
                  error={Boolean(errors.supportEmail?.message)}
                  fullWidth
                  helperText={errors.supportEmail?.message}
                  label="Adresse du support Hubn'UP"
                  margin="normal"
                  type="email"
                  variant="outlined"
                  autoComplete="email"
                  defaultValue={data && data[0] ? data[0].supportEmail : ""}
                  {...register("supportEmail")}
                />
                <Box className={classes.actionWrapper}>
                  <LoadingButton loading={mutation.isLoading} type="submit" variant="contained">
                    Modifier
                  </LoadingButton>
                </Box>
              </>
            ) : (
              <CircularProgress className={classes.loader} />
            )}
          </Box>
        </form>
      </CardContent>
    </Card>
  );
};

const useStyles = makeStyles((theme) => ({
  actionWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
  loader: {
    margin: "1rem auto",
    display: "flex",
  },
}));

export default PortailSettings;
