import store from "../store";
import formatFileName from "../utils/formatFileName";
import http from "../utils/http";
import openFile from "../utils/openFile";

export const getAgentsAutocomplete = async (value) => {
  let url = `/consultants?fullName=${value}&pagination=false`;
  const response = await http.get(url);

  return response.data["hydra:member"];
};

export const getAgentsCgpAutocomplete = async (value, agentId) => {
  let url = `/temp_cgps?fullName=${value}&pagination=false`;
  if (agentId) {
    url += `&fromAgent=${agentId}`;
  }
  const response = await http.get(url);

  return response.data["hydra:member"];
};

export const getAgentsCGP = async (email, lastName, firstName) => {
  let actif = "actif";
  let url = `/consultants?email=${email}&lastName=${lastName}&firstName=${firstName}&status=${actif}`;
  const response = await http.get(url);

  return response.data["hydra:member"];
};

export const getCurrentAgent = async () => {
  const state = store.getState();
  const response = await http.get(`/cgp/${state.auth.cgpId}`);

  return response.data;
};

export const getAgentByCgpId = async (agentId) => {
  const response = await http.get(`/cgp/${agentId}`);

  return response.data;
};

export const postAgentRequest = async (data) => {
  return await http.post("/agent_request", data);
};

export const getUserSponsors = async () => {
  const state = store.getState();
  let url = `/temp_cgps/${state.auth.cgpId}`;
  const response = await http.get(url);

  return response.data["hydra:member"];
};

export const getTeamList = async (filters, id = null) => {
  let url = "/temp_cgps";
  if (id) {
    url += `/${id}`;
  }

  url = applyFilters(filters, url);

  const response = await http.get(url);

  return response.data;
};

export const getTeamSales = async (agentId, year) => {
  let url = `/cgps/team`;

  if (agentId) {
    url += `?cgpId=${agentId}`;
    if (year) {
      url += `&year=${year}`;
    }
  }
  if (!agentId && year) {
    url += `?year=${year}`;
  }
  const response = await http.get(url);

  return response.data["hydra:member"];
};

export const getExportSponsors = async (filters, cgpId) => {
  let url = "/cgps/export";

  url = applyFilters(filters, url, cgpId);

  let fileName = await formatFileName("team-va", cgpId);

  return await openFile(
    url,
    `${fileName}.xlsx`,
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  );
};

export const getExportSponsorsV2 = async (filters, cgpId) => {
  const url = applyFilters(filters, "/cgps/teams/sales/export", cgpId);

  let fileName = await formatFileName("team-va", cgpId);

  return await openFile(
    url,
    `${fileName}.xlsx`,
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  );
};

export const getExportSponsorsData = async (filters, cgpId) => {
  let url = "/cgps/export/data";

  url = applyFilters(filters, url, cgpId);

  let fileName = await formatFileName("team-info", cgpId);

  return await openFile(
    url,
    `${fileName}.xlsx`,
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  );
};

export const getExportSponsorsEstate = async (data) => {
  let url = "/cgps/export/estate";

  url = applyFilters(data.filters, url, data.cgpId);

  let fileName = await formatFileName("team-immobilier", data.cgpId);

  return await openFile(
    url,
    `${fileName}.xlsx`,
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  );
};

export const getExportSponsorsInvestment = async (data) => {
  let url = "/cgps/export/investment";

  url = applyFilters(data.filters, url, data.cgpId);

  let fileName = await formatFileName("team-placement", data.cgpId);

  return await openFile(
    url,
    `${fileName}.xlsx`,
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  );
};

export const getCgpSalesSummary = async (agentId) => {
  const year = new Date().getFullYear();
  let url = `/cgps/year/${year}`;

  if (agentId) {
    url += `?agentId=${agentId}`;
  }

  const response = await http.get(url);

  return response.data["hydra:member"];
};

export const getRanking = async (consultantId, status, type) => {
  const url = `/sales/ranking/${consultantId}?salesType=${type}&statut=${status}`;

  const response = await http.get(url);

  return response.data;
};

const applyFilters = (filters, url, cgpId = null) => {
  let hasFilter = false;

  const filtersUrl = [];
  if (filters?.agent) {
    filtersUrl.push(`id=${filters.agent}`);
    hasFilter = true;
  }
  if (filters?.sponsor) {
    filtersUrl.push(`sponsor=${filters.sponsor}`);
    hasFilter = true;
  }
  if (cgpId) {
    filtersUrl.push(`cgpId=${cgpId}`);
    hasFilter = true;
  }
  if (filters?.exercice) {
    filtersUrl.push(`year=${filters.exercice}`);
    hasFilter = true;
  }

  if (hasFilter) {
    url += "?";
  }

  filtersUrl.forEach((filter, index) => {
    url += filter;
    if (index < filtersUrl.length - 1) url += "&";
  });

  return url;
};

export const getDirectTeamSales = ({ year, cgpId }) => {
  return http
    .get("/cgps/teams/sales/direct", { params: { year, cgpId } })
    .then((resp) => resp.data);
};

export const getAllTeamSales = ({ year, cgpId }) => {
  return http
    .get("/cgps/teams/sales", { params: { year, cgpId } })
    .then((resp) => resp.data);
};
