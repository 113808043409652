import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Paper, Typography } from "@material-ui/core";
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";

const FilterPanel = ({ children, defaultExpanded = true, ...props }) => (
  <Paper {...props}>
    <Accordion defaultExpanded={defaultExpanded}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="filters-content">
        <Typography>Filtres</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ px: 4 }}>{children}</AccordionDetails>
    </Accordion>
  </Paper>
);

export default FilterPanel;
