import Slider from "react-slick";
import NewsSummarySlide from "./NewsSummarySlide";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import {
  makeStyles,
} from "@material-ui/core";
import {
  ArrowBackRounded,
  ArrowForwardRounded,
} from "@material-ui/icons";
import { getNewsAll } from "../../http/news";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect, useState } from "react";
import { useMemo } from "react";

// Custom Slide
// function CustomSlide(props) {
function CustomSlide({ index, newsItem, style, ...otherProps }) {

  const classes = useStyles();

  const customStyle = { ...style, position: "relative" };

  return (
    <div style={customStyle} {...otherProps}>
      <NewsSummarySlide news={newsItem} />
      {/* div that makes the center of the image clickable */}
      <Link
        componentName="news_details_page"
        to={
          newsItem
            ? { pathname: `/news/${newsItem.id}`, state: { goBack: "/" } }
            : "#"
        }
        target="_blank"
        className={classes.sliderCenterClickable}
      ></Link>
    </div>
  );
}

// Custom Arrows for the Slider
function CustomNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <ArrowForwardRounded
      className={className}
      style={{
        ...style,
        display: "block",
        background: "unset",
        color: "black",
      }}
      onClick={onClick}
    />
  );
}
function CustomPrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <ArrowBackRounded
      className={className}
      style={{
        ...style,
        display: "block",
        background: "unset",
        color: "black",
      }}
      onClick={onClick}
    />
  );
}

function NewsSliderCarousel() {
  const classes = useStyles();

  // get the news data: those who has the slide value as true
  const [news, setNews] = useState([]);
  const { isFetched, data } = useQuery(["getNewsWithSlider"], async () => {
    const data = await getNewsAll(true, 1, { sliderDisplayed: true });

    return data["hydra:member"];
  });

  useEffect(() => {
    if (isFetched && data) {
      setNews(data.slice(0, 3)); // the slider can contain only 3 news(slides)
    }
  }, [isFetched, data]);

  const sliderSettings = useMemo(() => {
    const hasNews = news && news.length > 1; // Check if news data is available and has items

    return {
      infinite: true,
      lazyLoad: true,
      autoplay: true,
      speed: 400,
      autoplaySpeed: 5000,
      dots: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: hasNews ? <CustomNextArrow /> : null,
      prevArrow: hasNews ? <CustomPrevArrow /> : null,
      adaptiveHeight: true,
      className: classes.newsSlider,
    };
  }, [news]);

  if (!news.length)
    return null

  // Slider Library: it will return sliders as much children it has, so i need to return the list of CustomSlide in this component
  return (
    <Slider {...sliderSettings}>
      {news.map((newsItem, indexItem) => (
        <CustomSlide
          key={indexItem}
          index={indexItem}
          newsItem={newsItem}
          width={100}
        />
      ))}
    </Slider>
  );
}

const useStyles = makeStyles((theme) => ({
  newsSlider: {
    paddingBottom: 30,
    "& .slick-prev, & .slick-next": {
      width: 30,
      height: 30,
    },
    "& .slick-prev": {
      left: "0 !important",
      zIndex: "100",
    },
    "& .slick-next": {
      right: "0 !important",
      zIndex: "100",
    },
    "& .slick-dots": {
      bottom: "0",
    },
    "& .slick-dots li button:before": {
      fontSize: ".75rem"
    }
  },
  sliderCenterClickable: {
    margin: 0,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    height: "100%",
  },
}));

export default NewsSliderCarousel;
