import dayjs from "dayjs";
import React, { useState } from "react";
import { Card, Container } from "@material-ui/core";
import Breadcrumbs from "../../components/Breadcrumbs";
import InvoicesList from "../../components/invoice/InvoicesList";
import InvoicesToolbar from "../../components/invoice/InvoicesToolbar";
import FilterPanel from "../../components/FilterPanel";

const HubnupInvoices = () => {
  const [filters, setFilters] = useState({ createdAt: [dayjs().startOf("year"), dayjs().endOf("year")] });

  const onFilter = (filters) => {
    setFilters(filters);
  };

  return (
    <Container maxWidth={false}>
      <Breadcrumbs />

      <FilterPanel sx={{ mt: 2 }}>
        <InvoicesToolbar onFilter={onFilter} />
      </FilterPanel>

      <Card sx={{ mt: 2 }}>
        <InvoicesList filters={filters} />
      </Card>
    </Container>
  );
};

export default HubnupInvoices;
