import formatFileName from "../utils/formatFileName";
import { dateToIsoString } from "../utils/formatters";
import http from "../utils/http";
import openFile from "../utils/openFile";

export const getSalesInvestment = async (filters, page, pageSize, order, agentId) => {
  let url = "/ventes_orions";

  url = applyFilters(filters, order, url, page, pageSize, agentId);

  const response = await http.get(url);

  return response.data;
};

export const getExportExcelInvestments = async (data) => {
  const filters = data.filters;
  const order = data.order;
  const agentId = data?.agentId;
  let url = "/ventes_orions/export";
  url = applyFilters(filters, order, url, null, agentId);

  let fileName = await formatFileName("placement", agentId);

  return await openFile(url, `${fileName}.xlsx`, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
};

export const getSalesInvestmentSummary = async (agentId) => {
  const year = new Date().getFullYear();
  let url = `/ventes_orions/year/${year}`;

  if (agentId) {
    url += `?cgpId=${agentId}`;
  }

  const response = await http.get(url);

  return response.data["hydra:member"][0];
};

const applyFilters = (filters, order, url, page, pageSize, agentId) => {
  let hasFilter = false;

  const filtersUrl = [];
  if (page) {
    filtersUrl.push(`page=${page + 1}`);
    hasFilter = true;
  }
  if (pageSize) {
    filtersUrl.push(`itemsPerPage=${pageSize}`);
    hasFilter = true;
  }
  if (filters?.agent) {
    filtersUrl.push(`consultant.id=${filters.agent}`);
    hasFilter = true;
  }
  if (filters?.client) {
    filtersUrl.push(`client.id=${filters.client}`);
    hasFilter = true;
  }
  if (filters?.product) {
    filtersUrl.push(`typeProduitsFin.productName=${filters.product}`);
    hasFilter = true;
  }
  if (filters?.provider) {
    filtersUrl.push(`typeProduitsFin.providerName=${filters.provider}`);
    hasFilter = true;
  }
  if (filters?.amount && filters?.amount[0]) {
    filtersUrl.push(`amount[gte]=${filters.amount[0]}`);
    hasFilter = true;
  }
  if (filters?.amount && filters?.amount[1]) {
    filtersUrl.push(`amount[lte]=${filters.amount[1]}`);
    hasFilter = true;
  }
  if (filters?.fees && filters?.fees[0]) {
    filtersUrl.push(`fee[gte]=${filters.fees[0]}`);
    hasFilter = true;
  }
  if (filters?.fees && filters?.fees[1]) {
    filtersUrl.push(`fee[lte]=${filters.fees[1]}`);
    hasFilter = true;
  }
  if (filters?.operation) {
    filtersUrl.push(`operation=${filters.operation}`);
    hasFilter = true;
  }
  if (filters?.status) {
    filtersUrl.push(`status=${filters.status}`);
    hasFilter = true;
  }
  if (filters?.exercice) {
    filtersUrl.push(`exercice=${filters.exercice}`);
    hasFilter = true;
  }
  if (filters?.subscriptionDate && filters?.subscriptionDate[0]) {
    filtersUrl.push(`subscriptionDate[after]=${dateToIsoString(filters.subscriptionDate[0])}`);
    hasFilter = true;
  }
  if (filters?.subscriptionDate && filters?.subscriptionDate[1]) {
    filtersUrl.push(`subscriptionDate[before]=${dateToIsoString(filters.subscriptionDate[1])}`);
    hasFilter = true;
  }
  if (order && order[0]) {
    const orderField = order[0];
    const fieldName = orderField.field;

    if (fieldName === "agentFullName") {
      filtersUrl.push(`order[consultant.lastName]=${orderField.sort}`);
      filtersUrl.push(`order[consultant.firstName]=${orderField.sort}`);
    } else if (fieldName === "clientFullName") {
      filtersUrl.push(`order[client.lastName]=${orderField.sort}`);
      filtersUrl.push(`order[client.firstName]=${orderField.sort}`);
    } else if (fieldName === "productName") {
      filtersUrl.push(`order[typeProduitsFin.productName]=${orderField.sort}`);
    } else if (fieldName === "providerName") {
      filtersUrl.push(`order[typeProduitsFin.providerName]=${orderField.sort}`);
    } else {
      filtersUrl.push(`order[${orderField.field}]=${orderField.sort}`);
    }
    hasFilter = true;
  }
  if (agentId) {
    filtersUrl.push(`cgpId=${agentId}`);
    hasFilter = true;
  }

  if (hasFilter) {
    url += "?";
  }

  filtersUrl.forEach((filter, index) => {
    url += filter;
    if (index < filtersUrl.length - 1) url += "&";
  });

  return url;
};
