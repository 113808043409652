import React from "react";
import { Container, Paper, Typography } from "@material-ui/core";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import AppCircularProgress from "../../components/AppCircularProgress";
import Breadcrumbs from "../../components/Breadcrumbs";
import NewsDetailsContent from "../../components/news/NewsDetailsContent";
import { getNews } from "../../http/news";

const NewsDetails = () => {
  const { id } = useParams();
  const { isFetched, data: news } = useQuery(["getNews", id], () => getNews(id));

  return (
    <Container maxWidth={false}>
      <Breadcrumbs append={<Typography color="textPrimary">{news?.title}</Typography>} />

      <Container maxWidth="lg">
        <Paper sx={{ pb: 2, overflow: "hidden" }}>
          {isFetched ? <NewsDetailsContent news={news} /> : <AppCircularProgress />}
        </Paper>
      </Container>
    </Container>
  );
};

export default NewsDetails;
