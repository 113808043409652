import React, { useState } from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@material-ui/core";
import { Person as PersonIcon } from "@material-ui/icons";
import { useQuery } from "react-query";
import { connect } from "react-redux";
import { getRanking } from "../../../http/agents";
import { toEuros } from "../../../utils/formatters";
import AppCircularProgress from "../../AppCircularProgress";

const SalesStatisticsRanking = ({ consultantId }) => {
  const [status, setStatus] = useState("both");
  const [type, setType] = useState("both");

  const { isFetched, data: ranking } = useQuery(
    ["getRanking", consultantId, status, type],
    () => getRanking(consultantId, status, type),
    {
      enabled: !!consultantId,
    }
  );

  return (
    <>
      <List>
        {isFetched && ranking ? (
          <>
            {ranking.map((item, i) =>
              item?.rank ? (
                <ListItem key={i} display="flex">
                  <ListItemIcon>
                    <Typography color={i === 2 ? "secondary" : "primary"}>
                      {item?.rank !== -1 ? item?.rank : ""}
                    </Typography>
                  </ListItemIcon>
                  <ListItemIcon>
                    <PersonIcon />
                  </ListItemIcon>
                  {i === 2 ? (
                    <ListItemText
                      primary={
                        <Typography color={"secondary"} sx={{ fontWeight: "bold" }}>
                          {item?.rank !== -1 ? `${toEuros(item?.totalAmount)} - ` : ""}Moi
                        </Typography>
                      }
                    />
                  ) : (
                    <ListItemText primary={<Typography color={"primary"}>{toEuros(item?.totalAmount)}</Typography>} />
                  )}
                </ListItem>
              ) : (
                ""
              )
            )}
          </>
        ) : (
          <AppCircularProgress />
        )}
      </List>
      <Stack spacing={2} direction="row">
        <FormControl fullWidth size="small" style={{ width: 150 }}>
          <InputLabel id="status-choice-label">Statut</InputLabel>
          <Select
            labelId="status-choice-label"
            id="status-choice-select"
            label="Statut"
            value={status}
            onChange={(event) => setStatus(event.target.value)}
          >
            <MenuItem value="both">Total</MenuItem>
            <MenuItem value="finished">Acté</MenuItem>
            <MenuItem value="pending">Encours</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth size="small" style={{ width: 150 }}>
          <InputLabel id="type-choice-label">Type</InputLabel>
          <Select
            labelId="type-choice-label"
            id="type-choice-select"
            label="Type"
            value={type}
            onChange={(event) => setType(event.target.value)}
          >
            <MenuItem value="both">Tout</MenuItem>
            <MenuItem value="realEstate">Immobilier</MenuItem>
            <MenuItem value="investment">Placement</MenuItem>
          </Select>
        </FormControl>
      </Stack>
      <Grid container sx={{ width: { xl: "50%", xs: "60%" }, marginTop: "10px" }}>
        <Typography sx={{ fontSize: "0.8rem" }}>
          * L’encours Immobilier comprend tous les lots ayant un statut réservé ou en accord bancaire, les options ne
          sont pas incluses.
        </Typography>
        <Typography sx={{ fontSize: "0.8rem" }}>
          * L’encours placement correspond aux dossiers dont les délais de rétractation ne sont pas échus, et à ce
          titre, pas encore intégrés dans votre VAVC.
        </Typography>
        <Typography sx={{ fontSize: "0.8rem" }}>
          * Les volumes encours et validés en placement sont indiqués en non pondérés, mais la pondération s’applique
          pour le VAVC total
        </Typography>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => ({
  consultantId: state.auth.consultantId,
});

export default connect(mapStateToProps)(SalesStatisticsRanking);
