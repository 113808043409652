import PropTypes from "prop-types";
import React from "react";
import { Card, Grid } from "@material-ui/core";
import { Waypoint } from "react-waypoint";
import PictureCard from "./PictureCard";

const PicturesList = ({ pictures, fetchPictures, hasMorePictures, modalMode, onClick }) => {
  return (
    <Grid container spacing={2}>
      {pictures.map((item, index) => (
        <Grid key={index} item xs={4}>
          <Card>
            <PictureCard picture={item} modalMode={modalMode} onClick={onClick} />
          </Card>
          {hasMorePictures && index === pictures.length - 3 && (
            <Waypoint onEnter={() => fetchPictures(pictures.length)} />
          )}
        </Grid>
      ))}
    </Grid>
  );
};

PicturesList.propTypes = {
  pictures: PropTypes.array,
  fetchPictures: PropTypes.func,
  onClick: PropTypes.func,
  hasMorePictures: PropTypes.bool,
  modalMode: PropTypes.bool,
};

export default PicturesList;
