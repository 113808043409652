import { Add as AddIcon } from "@material-ui/icons";
import React, { useState } from "react";
import { Box, Button, Container } from "@material-ui/core";
import Breadcrumbs from "../../components/Breadcrumbs";
import AddPicture from "../../components/picturesLibrary/AddPicture";
import PicturesLibraryContent from "../../components/picturesLibrary/PicturesLibraryContent";

const PicturesLibrary = () => {
  const [openCreateModal, setOpenCreateModal] = useState(false);

  const handleClickOpenCreateModal = () => setOpenCreateModal(true);
  const handleCloseCreateModal = () => setOpenCreateModal(false);

  return (
    <Container maxWidth={false}>
      <Breadcrumbs />

      <Box sx={{ mt: 3, mb: 3 }}>
        <Button variant="contained" startIcon={<AddIcon />} onClick={handleClickOpenCreateModal}>
          Ajouter une image
        </Button>
        <AddPicture open={openCreateModal} onClose={handleCloseCreateModal} title="Ajouter une image" />
      </Box>

      <PicturesLibraryContent />
    </Container>
  );
};

export default PicturesLibrary;
