import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Card, CardContent, CardHeader, Container, Divider, Grid, Typography } from "@material-ui/core";
import { useQuery } from "react-query";
import AppCircularProgress from "../../components/AppCircularProgress";
import Breadcrumbs from "../../components/Breadcrumbs";
import RemindersReportingForm from "../../components/reminder/RemindersReportingForm";
import { getCurrentUser } from "../../http/user";

const AgentRemindersReporting = () => {
  const { isFetched, data: user } = useQuery(["getCurrentUser"], () => getCurrentUser());

  return (
    <Container maxWidth={false}>
      <Breadcrumbs />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent sx={{ p: 3 }}>
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={1}
                  sx={{ display: "flex", alignItems: "center", justifyContent: "center", fontSize: 40 }}
                >
                  <FontAwesomeIcon icon={faInfo} />
                </Grid>
                <Grid item xs={11}>
                  <Typography component="div" variant="body1">
                    <p style={{ marginBottom: 10 }}>
                      Cet écran vous permet de modifier vos préférences pour le reporting de vos rappels.
                    </p>
                    <p style={{ marginBottom: 10 }}>Vous pouvez choisir :</p>

                    <ul style={{ marginBottom: 10, paddingLeft: 25 }}>
                      <li>Aucun reporting</li>
                      <li>Un reporting quotidien : vous recevrez un mail chaque jour avec vos rappels du lendemain</li>
                      <li>
                        Un reporting hebdomadaire : vous recevrez un mail chaque dimanche avec vos rappels de la semaine
                        à venir
                      </li>
                      <li>
                        Un reporting mensuel : vous recevrez un mail chaque dernier jour du mois avec vos rappels du
                        mois à venir
                      </li>
                    </ul>
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Choix du reporting" />
            <Divider />
            {isFetched ? <RemindersReportingForm user={user} /> : <AppCircularProgress />}
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AgentRemindersReporting;
