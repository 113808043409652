import PropTypes from "prop-types";
import React from "react";
import { Card, Grid, makeStyles } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { Waypoint } from "react-waypoint";
import GuardedListLinkComponent from "../GuardedNewsCardLinkComponent";

const NewsList = ({ news, fetchNews, hasMoreNews, reset, fromAgent }) => {
  const classes = useStyles();
  const location = useLocation();

  return (
    <Grid container spacing={2}>
      {news.map((item, index) => (
        <Grid key={index} item xs={4}>
          <Card className={classes.cardWrapper}>
            <GuardedListLinkComponent
              componentName="news_details_page"
              item={item}
              location={location}
              reset={reset}
              fromAgent={fromAgent}
            />
          </Card>
          {hasMoreNews && index === news.length - 3 && <Waypoint onEnter={() => fetchNews(news.length)} />}
        </Grid>
      ))}
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100%",
    justifyContent: "space-between",
  },
}));

NewsList.propTypes = {
  news: PropTypes.array,
  fetchNews: PropTypes.func,
  hasMoreNews: PropTypes.bool,
  fromAgent: PropTypes.bool,
  reset: PropTypes.func,
};

export default NewsList;
