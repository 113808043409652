import React from "react";
import { Card, CardHeader, Container, Divider, Grid, Typography } from "@material-ui/core";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import { getProspectDetails } from "../../http/prospects";
import ProspectInfo from "../../components/prospect/ProspectInfo";
import ProspectDetailsRicList from "../../components/prospect/ProspectDetailsRicList";
import ProspectDetailsDerList from "../../components/prospect/ProspectDetailsDerList";

const ProspectsDetail = () => {
  const { id } = useParams();
  const { isFetched, isFetching, data: prospect } = useQuery(["getProspect", id], () => getProspectDetails(id));

  return (
    <Container maxWidth={false}>
      <Breadcrumbs
        append={
          <Typography color="textPrimary">
            {prospect?.lastName} {prospect?.firstName}
          </Typography>
        }
      />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ProspectInfo prospect={prospect} loading={!isFetched || isFetching} />
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardHeader title="RIC" />
            <Divider />
            <ProspectDetailsRicList ricCollection={prospect?.ricCollection} isFetching={!isFetched || isFetching} />
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardHeader title="DER" />
            <Divider />
            <ProspectDetailsDerList derCollection={prospect?.derCollection} isFetching={!isFetched || isFetching} />
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ProspectsDetail;
