import { LoadingButton } from "@material-ui/lab";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React from "react";
import {
  Alert,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
} from "@material-ui/core";
import { Mail as MailIcon } from "@material-ui/icons";
import { useMutation } from "react-query";
import { resetPasswordRequest } from "../../http/user";
import AppDialog from "../AppDialog";
import { useTrackingContext } from "../../contexts/TrackingContext";
import { ACTION_CLICK } from "../../utils/trackingConstant";

const UserResetPasswordModal = ({ open, onClose, user }) => {
  const { enqueueSnackbar } = useSnackbar();
  const track = useTrackingContext();

  const onConfirm = () => {
    track.click(ACTION_CLICK.ADMIN_RESET_PASSWORD);
    mutation.mutate({ email: user.email });
  };

  const mutation = useMutation(resetPasswordRequest, {
    onSuccess: () => {
      enqueueSnackbar(
        "Un mail de réinitialisation vient d'être envoyé à l'utilisateur.",
        { variant: "success" },
      );
      onClose();
    },
  });

  return (
    <AppDialog
      open={open}
      onClose={onClose}
      title="Réinitialisation du mot de passe"
    >
      <DialogContent>
        {mutation.isError ? (
          <Alert severity="error">
            Une erreur est survenue : {mutation.error.message}
          </Alert>
        ) : null}

        {user && (
          <DialogContentText id="reset-password-dialog-description">
            Souhaitez vous réinitialiser le mot de passe de l'utilisateur{" "}
            {user.firstName} {user.lastName}
          </DialogContentText>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Annuler
        </Button>
        <LoadingButton
          loading={mutation.isLoading}
          variant="contained"
          onClick={onConfirm}
          startIcon={<MailIcon />}
        >
          Réinitialiser
        </LoadingButton>
      </DialogActions>
    </AppDialog>
  );
};

UserResetPasswordModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  user: PropTypes.object,
};

export default UserResetPasswordModal;
