import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { AppBar, Toolbar } from "@material-ui/core";

import Logo from "../Logo";

const MainNavbar = ({ homePath, children }) => (
  <AppBar color="primary" elevation={0} {...children}>
    <Toolbar>
      <RouterLink to={homePath}>
        <Logo style={{ height: 60, filter: "brightness(0) invert(1)" }} />
      </RouterLink>
    </Toolbar>
  </AppBar>
);

MainNavbar.propTypes = {
  homePath: PropTypes.string,
};

const mapStateToProps = (state) => ({
  homePath: state.auth.homePath,
});

export default connect(mapStateToProps, null)(MainNavbar);
