import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@material-ui/lab";
import PropTypes from "prop-types";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { Alert, Grid, TextField, Typography } from "@material-ui/core";
import * as yup from "yup";
import { changePassword } from "../../http/user";
import AppCircularProgress from "../AppCircularProgress";
import { useTrackingContext } from "../../contexts/TrackingContext";
import { ACTION_CLICK } from "../../utils/trackingConstant";

const AgentUserFormPassword = ({ user, isFetched }) => {
  const schema = yup.object().shape({
    password: yup
      .string()
      .required("Ce champ est obligatoire.")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
        "Ce champ doit contenir au moins 8 caractères, 1 majuscule, 1 minuscule et 1 chiffre."
      ),
    confirmPassword: yup
      .string()
      .required("Ce champ est obligatoire.")
      .oneOf([yup.ref("password"), null], "Le mot de passe doit correspondre."),
  });

  const track = useTrackingContext()

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    track.click(ACTION_CLICK.EDIT_MY_PASSWORD)
    mutation.mutate({
      password: data.password,
      user,
    });
  };

  const mutation = useMutation(changePassword, {
    onSuccess: () => {
      reset();
    },
  });

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      {isFetched ? (
        <Grid container spacing={2} alignItems="stretch" sx={{ marginTop: "16px" }}>
          {mutation.isError ? (
            <Grid item xs={12} sx={{ marginTop: "8px" }}>
              <Alert severity="error">Une erreur est survenue : {mutation.error.message}</Alert>
            </Grid>
          ) : null}

          {mutation.isSuccess ? (
            <Grid item xs={12} sx={{ marginTop: "8px" }}>
              <Alert severity="success">Modification effectuée avec succès.</Alert>
            </Grid>
          ) : null}

          <Typography component="div" variant="body1" sx={{ width: "100%", marginLeft: "16px" }}>
            <p style={{ marginBottom: 10 }}>Le nouveau mot de passe doit :</p>

            <ul style={{ marginBottom: 10, paddingLeft: 25 }}>
              <li>Être de 8 caractères minimum</li>
              <li>Contenir au moins 1 caractère minuscule</li>
              <li>Contenir au moins 1 caractère majuscule</li>
              <li>Contenir au moins 1 chiffre</li>
            </ul>
          </Typography>

          <Grid item xs={10} md={8} sx={{ paddingTop: "0 !important" }}>
            <Controller
              name="password"
              control={control}
              shouldUnregister
              render={({ field, formState: { errors } }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="Nouveau mot de passe"
                  margin="normal"
                  type="password"
                  error={Boolean(errors.password?.message)}
                  helperText={errors.password?.message}
                />
              )}
            />

            <Controller
              name="confirmPassword"
              control={control}
              shouldUnregister
              render={({ field, formState: { errors } }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="Confirmation nouveau mot de passe"
                  margin="normal"
                  type="password"
                  error={Boolean(errors.confirmPassword?.message)}
                  helperText={errors.confirmPassword?.message}
                />
              )}
            />
          </Grid>

          <Grid item xs={2} md={4} sx={{ display: "flex", flexDirection: "row-reverse", alignItems: "flex-end" }}>
            <LoadingButton loading={mutation.isLoading} type="submit" variant="contained">
              Modifier mon mot de passe
            </LoadingButton>
          </Grid>
        </Grid>
      ) : (
        <AppCircularProgress />
      )}
    </form>
  );
};

AgentUserFormPassword.propTypes = {
  user: PropTypes.object,
  isFetched: PropTypes.bool,
};

export default AgentUserFormPassword;
