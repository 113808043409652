import React from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import { Box, IconButton } from "@material-ui/core";
import { Input as InputIcon } from "@material-ui/icons";

const SocialMenuLink = ({ fromSidebar }) => {
  return (
    <>
      <Box>
        <IconButton
          color="inherit"
          href="https://hubnup.fr/"
          target="_blank"
          sx={{ padding: 0, display: "flex", "& span": { flexDirection: "column" }, fontSize: "12px" }}
        >
          <img
            src="/static/logo.png"
            alt="Hubn'UP"
            style={{ height: "24px", filter: !fromSidebar ? "brightness(0) invert(1)" : "" }}
          />
          Hubn'UP
        </IconButton>
      </Box>
      <Box sx={{ marginX: "15px" }}>
        <IconButton
          color="inherit"
          href="https://fr.linkedin.com/company/hubnup"
          target="_blank"
          sx={{ padding: 0, display: "flex", "& span": { flexDirection: "column" }, fontSize: "12px" }}
        >
          <img src="/static/LinkedIn_logo.png" alt="LinkedIn" style={{ height: "24px" }} />
          LinkedIn
        </IconButton>
      </Box>
      <Box>
        <IconButton
          color="inherit"
          component={RouterLink}
          to="/logout"
          sx={{ padding: 0, display: "flex", "& span": { flexDirection: "column" }, fontSize: "12px" }}
        >
          <InputIcon />
          Se déconnecter
        </IconButton>
      </Box>
    </>
  );
};

SocialMenuLink.defaultProps = {
  fromSidebar: false,
};

SocialMenuLink.propTypes = {
  fromSidebar: PropTypes.bool,
};

export default SocialMenuLink;
