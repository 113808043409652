import React, { useState } from "react";
import { Box, Button, Card, Container, Grid, makeStyles, Stack } from "@material-ui/core";
import { Add as AddIcon, Delete as DeleteIcon, Email as EmailIcon } from "@material-ui/icons";
import { useQuery } from "react-query";
import { Link as RouterLink, useHistory } from "react-router-dom";
import AppCircularProgress from "../../components/AppCircularProgress";
import Breadcrumbs from "../../components/Breadcrumbs";
import GuardedComponent from "../../components/GuardedComponent";
import RemindersPicker from "../../components/reminder/RemindersPicker";
import { getUserReminders } from "../../http/reminders";
import AgentRemindersList from "../../components/reminder/AgentRemindersList";
import RemindersToolbar from "../../components/reminder/RemindersToolbar";
import RemindersDeleteDialog from "../../components/reminder/RemindersDeleteDialog";
import FilterPanel from "../../components/FilterPanel";

const AgentReminders = () => {
  const classes = useStyles();
  const history = useHistory();
  const [selectedReminders, setSelectedReminders] = useState([]);
  const [remindersToDelete, setRemindersToDelete] = useState([]);
  const [filters, setFilters] = useState([]);
  const {
    isFetching,
    isFetched,
    data: reminders,
  } = useQuery(["getRemindersAll", filters], () => getUserReminders(filters));

  let remindersRow = [];
  if (reminders) {
    remindersRow = reminders.map((reminder) => {
      return {
        ...reminder,
        title: reminder.reminder?.title,
        type: reminder.reminder?.type,
        client: reminder.reminder?.client,
        date: reminder.reminder?.date?.date,
        prospect: reminder.reminder?.prospect,
        clientId: reminder.reminder?.clientId,
      };
    });
  }

  const onRowDelete = (id) => {
    const reminder = reminders.find((reminder) => reminder.id === id);
    if (reminder.reminder.type === "hubnup") {
      setRemindersToDelete([`/reminder_lists/${id}`]);
    } else {
      setRemindersToDelete([`/reminders/${reminder.reminder.id}`]);
    }
  };

  const onSelectionDelete = () => {
    if (selectedReminders.length === 0) return;

    setRemindersToDelete(
      selectedReminders.map((id) => {
        const reminder = reminders.find((reminder) => reminder.id === id);
        if (reminder.reminder.type === "hubnup") {
          return `/reminder_lists/${id}`;
        }

        return `/reminders/${reminder.reminder.id}`;
      })
    );
  };

  const closeDeleteDialog = () => {
    setRemindersToDelete([]);
  };

  const onFilter = (filters) => {
    setFilters(filters);
  };

  return (
    <Container maxWidth={false}>
      <Breadcrumbs />

      <Grid container spacing={2}>
        <Grid item md={8} xs={12}>
          <Stack direction="row" spacing={2}>
            <GuardedComponent componentName="reminders_edit_page">
              <Button
                component={RouterLink}
                to={{ pathname: "/my/reminders/create", state: { goBack: history.location.pathname } }}
                variant="contained"
                startIcon={<AddIcon />}
              >
                Créer un rappel
              </Button>
              <Button variant="contained" startIcon={<DeleteIcon />} onClick={onSelectionDelete} color="hubnup">
                Supprimer
              </Button>
            </GuardedComponent>
            <GuardedComponent componentName="reminders_reporting_page">
              <Button
                variant="contained"
                startIcon={<EmailIcon />}
                component={RouterLink}
                to={{ pathname: "/my/reminders/reporting", state: { goBack: history.location.pathname } }}
              >
                Reporting
              </Button>
            </GuardedComponent>
          </Stack>

          <FilterPanel defaultExpanded={true} sx={{ mt: 2, display: { xs: "none", md: "flex" } }}>
            <RemindersToolbar onFilter={onFilter} fromAgent />
          </FilterPanel>
        </Grid>

        <Grid item md={4} xs={12} className={classes.pickerWrapper}>
          <RemindersPicker />
        </Grid>
      </Grid>

      <FilterPanel sx={{ mt: 2, display: { md: "none", xs: "flex" } }}>
        <RemindersToolbar onFilter={onFilter} fromAgent />
      </FilterPanel>

      <Box sx={{ mt: 2 }}>
        <Card>
          {isFetched ? (
            <AgentRemindersList
              reminders={remindersRow}
              selectedReminders={selectedReminders}
              onSelect={setSelectedReminders}
              onDelete={onRowDelete}
              loading={isFetching}
            />
          ) : (
            <AppCircularProgress />
          )}
        </Card>
      </Box>

      <RemindersDeleteDialog
        isOpen={remindersToDelete.length > 0}
        hasMany={remindersToDelete.length > 1}
        onClose={closeDeleteDialog}
        remindersToDelete={remindersToDelete}
      />
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  pickerWrapper: {
    height: "100%",
    [theme.breakpoints.up("md")]: {
      paddingTop: "0 !important",
      zoom: "0.8",
    },
  },
}));

export default AgentReminders;
