import http from "../utils/http";

export const getAdministrationSettings = async () => {
  const response = await http.get("/administrations");

  return response.data["hydra:member"];
};

export const updateAdministrationSettings = async (data) => {
  if (data.id) {
    return patchAdministrationSettings(data);
  }

  return postAdministrationSettings(data.supportEmail);
};

const postAdministrationSettings = async (supportEmail) => {
  const response = await http.post("/administrations", {
    supportEmail: supportEmail,
  });

  return response.data;
};

const patchAdministrationSettings = async (data) => {
  const response = await http.patch(`/administrations/${data.id}`, {
    supportEmail: data.supportEmail,
  });

  return response.data;
};
