import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { TextField, Autocomplete, CircularProgress } from "@material-ui/core";
import { useQuery } from "react-query";
import { getAgentsAutocomplete } from "../http/agents";

const AutocompleteAgentsField = ({ setAgent, reset }) => {
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState("");

  const { isFetching, isFetched: agentsIsFetched,  data: agents } = useQuery(
    ["getAgentsAutocomplete", inputValue],
    () => getAgentsAutocomplete(inputValue),
    {
      keepPreviousData: true,
      enabled: !!inputValue,
    }
  );

  useEffect(() => {
    if (agentsIsFetched && agents) {
      setOptions(agents);
    }
  }, [agents]);

  useEffect(() => {
    setInputValue("");
    setValue("");
  }, [reset]);

  return (
    <Autocomplete
      id="agent-field"
      getOptionLabel={(option) => (option ? option?.fullName : "")}
      getOptionSelected={(option, value) => option?.id === value?.id}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(event, newValue) => {
        setAgent(newValue?.id || "");
        setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Mandataire"
          variant="outlined"
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isFetching ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

AutocompleteAgentsField.defaultProps = {
  reset: false,
};

AutocompleteAgentsField.propTypes = {
  setAgent: PropTypes.func,
  reset: PropTypes.bool,
};

export default AutocompleteAgentsField;
