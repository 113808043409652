import PropTypes from "prop-types";
import React from "react";
import {
  Avatar,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
  Typography,
} from "@material-ui/core";
import { CalendarToday as CalendarTodayIcon, Email as EmailIcon, Phone as PhoneIcon } from "@material-ui/icons";
import { dateToString, toEuros } from "../../utils/formatters";
import { BronzeStar as BronzeStarIcon, GoldStar as GoldStarIcon, SilverStar as SilverStarIcon } from "../Stars";

const AgentDetailsInfo = ({ agent, loading }) => (
  <Grid container spacing={0}>
    <Grid item xs={12} md={4}>
      <List>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <CalendarTodayIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="Date de naissance"
            secondary={loading ? <Skeleton width={300} /> : dateToString(agent?.agent?.birthday)}
          />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <CalendarTodayIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="Date d'entrée dans le réseau"
            secondary={loading ? <Skeleton width={300} /> : dateToString(agent?.agent?.registerDate)}
          />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <PhoneIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="Téléphone"
            secondary={loading ? <Skeleton width={300} /> : agent?.agent?.phoneNumber}
          />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <EmailIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Adresse mail" secondary={loading ? <Skeleton width={300} /> : agent?.agent?.email} />
        </ListItem>
      </List>
    </Grid>
    <Grid item xs={12} md={4}>
      <List>
        <ListItem
          sx={{
            justifyContent: "space-between",
            "& .MuiListItemText-root": { flex: "initial" },
            "& svg": { width: "20px" },
            fontSize: "20px",
            "& .MuiButtonBase-root": { padding: "5px" },
          }}
        >
          <ListItemText primary="Habilitations :" />
          <ListItemText
            primary={
              <>
                <FormControlLabel
                  disabled
                  control={<Checkbox checked={!loading && agent?.agent?.habilitationIobsp} />}
                  label="IOBSP"
                />
                <FormControlLabel
                  disabled
                  control={<Checkbox checked={!loading && agent?.agent?.habilitationIas} />}
                  label="IAS"
                />
                <FormControlLabel
                  disabled
                  control={<Checkbox checked={!loading && agent?.agent?.habilitationCif} />}
                  label="CIF"
                />
              </>
            }
          />
        </ListItem>
        <ListItem sx={{ justifyContent: "space-between", "& .MuiListItemText-root": { flex: "initial" } }}>
          <ListItemText primary="Numéro d'ORIAS :" />
          <ListItemText primary={loading ? <Skeleton width={300} /> : agent?.agent?.oriasNumber} />
        </ListItem>
        <ListItem sx={{ justifyContent: "space-between", "& .MuiListItemText-root": { flex: "initial" } }}>
          <ListItemText primary="Fin de validité ORIAS :" />
          <ListItemText primary={loading ? <Skeleton width={300} /> : dateToString(agent?.agent?.oriasEndOfValidity)} />
        </ListItem>
      </List>
    </Grid>
    <Grid item xs={12} md={4}>
      <List>
        {loading && (
          <>
            <ListItem sx={{ justifyContent: "space-between", "& .MuiListItemText-root": { flex: "initial" } }}>
              <ListItemText primary={<Skeleton width={120} />} secondary={<Skeleton width={150} />} />
            </ListItem>
            <ListItem sx={{ justifyContent: "space-between", "& .MuiListItemText-root": { flex: "initial" } }}>
              <ListItemText primary={<Skeleton width={120} />} secondary={<Skeleton width={150} />} />
            </ListItem>
          </>
        )}
        {!loading && (
          <>
            <ListItem sx={{ justifyContent: "space-between", "& .MuiListItemText-root": { flex: "initial" } }}>
              <ListItemText primary="Branches actives :" />
              <ListItemText
                primary={`${agent?.agent?.branchesActives} branche${agent?.agent?.branchesActives > 1 ? "s" : ""}`}
              />
            </ListItem>
            <ListItem sx={{ justifyContent: "space-between", "& .MuiListItemText-root": { flex: "initial" } }}>
              <ListItemText primary="VAVC perso + équipe(s) à date :" />
              <ListItemText primary={toEuros(agent?.agent?.vavcTotal)} />
            </ListItem>
            <ListItem sx={{ justifyContent: "space-between", "& .MuiListItemText-root": { flex: "initial" } }}>
              <ListItemText primary="Qualification :" />
              <ListItemText
                primary={
                  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                    {agent?.agent?.level === 1 && <GoldStarIcon />}
                    {agent?.agent?.level === 2 && <SilverStarIcon />}
                    {agent?.agent?.level === 3 && <BronzeStarIcon />}
                    <Typography variant="body2" component="span" sx={{ ml: 0.5 }}>
                      {agent?.agent?.qualification}
                    </Typography>
                  </Box>
                }
              />
            </ListItem>
          </>
        )}
      </List>
    </Grid>
  </Grid>
);

AgentDetailsInfo.propTypes = {
  agent: PropTypes.object,
  loading: PropTypes.bool,
};

export default AgentDetailsInfo;
