import React, { useState } from "react";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  makeStyles,
  Typography,
} from "@material-ui/core";
import {
  ContentCopy as CopyIcon,
  Download as DownloadIcon,
  Visibility as VisibilityIcon,
  Delete as DeleteIcon,
  ArrowUpward as ArrowUpwardIcon,
  Edit as EditIcon,
  ArrowDownward as ArrowDownwardIcon,
} from "@material-ui/icons";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { faFolder } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppCircularProgress from "../AppCircularProgress";
import DeleteFolderModal from "./DeleteFolderModal";
import DeleteFileModal from "./DeleteFileModal";
import EditFileModal from "./EditFileModal";
import EditFolderModal from "./EditFolderModal";
import PropTypes, { checkPropTypes } from "prop-types";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { editMediaObjectListReadByUser, getFolderDownload, getMediaObjectDownload, getMediaObjectList } from "../../http/documentations";
import GuardedComponent from "../GuardedComponent";
import { useSnackbar } from "notistack";
import { dateToString, isDateAfterOneWeekAgo } from "../../utils/formatters";
import DocumentIcon from "./DocumentIcon";
import { findMediaObjectInMediaObjectList, folderHasRecentFile, personName, readStatusForDocument, renderViewFileIcon } from "../../utils/componentHelpers/document";
import store from "../../store";
import { useTrackingContext } from "../../contexts/TrackingContext";
import { ACTION_CLICK } from "../../utils/trackingConstant";

const DocumentsList = ({
  loading,
  documentsToShow,
  handleSortChange,
  handleNavigateToFolder,
  sort,
  componentName,
  type,
  breadcrumbs,
  handleUpdateBreadCrumbs,
}) => {
  const classes = useStyles();
  const [openEditFolderModal, setOpenEditFolderModal] = useState(false);
  const [openEditFileModal, setOpenEditFileModal] = useState(false);
  const [openDeleteFolderModal, setOpenDeleteFolderModal] = useState(false);
  const [openDeleteFileModal, setOpenDeleteFileModal] = useState(false);
  const [documentToManage, setDocumentToManage] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const track = useTrackingContext()

  const handleClickEditFolderModal = (document) => {
    setOpenEditFolderModal(true);
    setDocumentToManage(document);
  };

  const handleClickEditFileModal = (document) => {
    setOpenEditFileModal(true);
    setDocumentToManage(document);
  };

  const handleCloseEditFolderModal = () => {
    setOpenEditFolderModal(false);
  };

  const handleCloseEditFileModal = () => {
    setOpenEditFileModal(false);
  };

  const handleClickDeleteFolderModal = (document) => {
    setOpenDeleteFolderModal(true);
    setDocumentToManage(document);
  };

  const handleClickDeleteFileModal = (document) => {
    setOpenDeleteFileModal(true);
    setDocumentToManage(document);
  };

  const handleCloseDeleteFolderModal = () => {
    setOpenDeleteFolderModal(false);
  };

  const handleCloseDeleteFileModal = () => {
    setOpenDeleteFileModal(false);
  };

  const handleViewFile = async (document) => {
    const mediaObjectListItem = findMediaObjectInMediaObjectList(document.id, mediaObjectList);

    if (mediaObjectListItem && mediaObjectListItem.readByUser === false) {
      mutationDocumentListReadStatus.mutate(mediaObjectListItem.id);
    }

    track.document(`media/${type}/${document.filePath}`)

    window.open(`${process.env.REACT_APP_API_URL}media/${type}/${document.filePath}`, "_blank");
  };

  const { auth : { email: userEmail } } = store.getState();

  const { loading: MediaObjectListLoading, data: mediaObjectList } = useQuery(
    ["getDocumentsList", userEmail],
    () => getMediaObjectList(userEmail),
  );

  const handleClickGenerateUrl = (document) => {
    track.click(ACTION_CLICK.DOCUMENT_COPY_LINK)

    const url = window.location.origin + "/library/" + type + "/" + document.id;
    navigator.clipboard.writeText(url);
    enqueueSnackbar("Lien copié ", { variant: "success" });
  };

  const handleClickGenerateUrlDoc = (document) => {
    track.click(ACTION_CLICK.DOCUMENT_COPY_LINK)

    const url = `${process.env.REACT_APP_API_URL}media/${type}/${document.filePath}`;
    navigator.clipboard.writeText(url);
    enqueueSnackbar("Lien copié ", { variant: "success" });
  };

  const mutationDownloadFile = useMutation(getMediaObjectDownload);

  const handleDownloadFile = async (document) => {
    track.click(ACTION_CLICK.DOCUMENT_DOWNLOAD)
    mutationDownloadFile.mutate({ file: document });
  };

  const mutationDownloadFolder = useMutation(getFolderDownload);

  const handleDownloadFolder = async (document) => {
    track.click(ACTION_CLICK.DOCUMENT_DOWNLOAD)
    mutationDownloadFolder.mutate({ file: document });
  };

  const dateDocument = (document) => {
    return document.createdAt ? dateToString(document.createdAt) : "12/07/2022";
  };

  const queryClient = useQueryClient();

  const mutationDocumentListReadStatus = useMutation(editMediaObjectListReadByUser, {
    onSuccess: () => {
      queryClient.invalidateQueries("getFolders");
      queryClient.invalidateQueries("getDocumentsList");
    },
  });

  if (loading || MediaObjectListLoading) {
    return <AppCircularProgress />;
  }

  return (
    <List
      sx={{ width: "100%", bgcolor: "background.paper", padding: "0 0 16px 0" }}
      subheader={
        <ListSubheader
          key="header"
          sx={{
            borderWidth: "0px 0px thin",
            borderStyle: "solid",
            borderColor: "rgba(0, 0, 0, 0.12)",
            paddingLeft: "36px",
            paddingRight: "36px",
            display: "flex",
            height: "55px",
            alignItems: "center",
            backgroundColor: "#00a19a",
            color: "#FFFFFF",
          }}
        >
          <ListItemText
            id="header-text"
            primary={
              <Box
                sx={{
                  display: "flex",
                  cursor: "pointer",
                  alignItems: "center",
                }}
                onClick={() => handleSortChange()}
              >
                <Typography color="inherit">Nom</Typography>
                {sort ? (
                  <IconButton aria-label="Tri ascendant" size="small">
                    <ArrowUpwardIcon sx={{ fontSize: "16px", color: "#FFFFFF" }} />
                  </IconButton>
                ) : (
                  <IconButton aria-label="Tri descendant" size="small">
                    <ArrowDownwardIcon sx={{ fontSize: "16px", color: "#FFFFFF" }} />
                  </IconButton>
                )}
              </Box>
            }
            sx={{ width: "15%" }}
          />
          <ListItemText id="date-text" sx={{ cursor: "pointer", width: "10%" }} primary="Date d'import" />
          <GuardedComponent componentName={componentName}>
            <ListItemText id="date-text" sx={{ cursor: "pointer", width: "10%" }} primary="Personne" />
          </GuardedComponent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              width: "10rem",
            }}
          />
        </ListSubheader>
      }
    >
      {documentsToShow.map((document) => {
        const labelId = `checkbox-list-label-${document.id}`;

        let isFileNotRead = document.contentUrl && isDateAfterOneWeekAgo(document.createdAt) && findMediaObjectInMediaObjectList(document.id, mediaObjectList) &&!readStatusForDocument(document.id, mediaObjectList);

        return (
          <ListItem
            key={document.id}
            sx={{
              borderWidth: "0px 0px thin",
              borderStyle: "solid",
              borderColor: "rgba(0, 0, 0, 0.12)",
              paddingLeft: "17px",
              paddingRight: "17px",
            }}
          >
            {/* if the document is a file (not a folder) */}
            {document.contentUrl ? (
              <>
                <ListItemIcon sx={{ minWidth : 24, marginRight : "1rem" }}>
                  {(isFileNotRead) && <FiberManualRecordIcon style={{ 'color': '#8B0000' }} />}
                </ListItemIcon>
                <ListItemIcon sx={{ cursor: "pointer" }} onClick={() => handleViewFile(document)}>
                  <DocumentIcon document={document} classes={classes} />
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  primary={document.displayName ?? document.filePath}
                  secondary={document.breadcrumbsString}
                  sx={{ cursor: "pointer", width: "12%" }}
                  onClick={() => handleViewFile(document)}
                />
                <ListItemText id={labelId} sx={{ cursor: "pointer", width: "10%" }} primary={dateDocument(document)} />
                <GuardedComponent componentName={componentName}>
                  <ListItemText id={labelId} sx={{ width: "10%" }} primary={personName(document)} />
                </GuardedComponent>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    width: "10rem",
                  }}
                >
                  <GuardedComponent componentName={componentName}>
                    <IconButton
                      aria-label="Editer le document"
                      size="small"
                      onClick={() => handleClickEditFileModal(document)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      aria-label="Supprimer le document"
                      size="small"
                      onClick={() => handleClickDeleteFileModal(document)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </GuardedComponent>
                  <IconButton
                    aria-label="Télécharger le document"
                    size="small"
                    onClick={() => handleDownloadFile(document)}
                  >
                    <DownloadIcon />
                  </IconButton>
                  {renderViewFileIcon(document) && (
                    <IconButton aria-label="Voir le document" size="small" onClick={() => handleViewFile(document)}>
                      <VisibilityIcon />
                    </IconButton>
                  )}
                  <IconButton
                    aria-label="Copier le document"
                    size="small"
                    onClick={() => handleClickGenerateUrlDoc(document)}
                  >
                    <CopyIcon />
                  </IconButton>
                </Box>
              </>
            ) : (
              // if it is a folder
              <>

                <ListItemIcon sx={{ minWidth : 24, marginRight : "1rem" }}>
                  {folderHasRecentFile(document, mediaObjectList) && <FiberManualRecordIcon className={classes.documentRecentIcon} />}
                </ListItemIcon>
                <ListItemIcon sx={{ cursor: "pointer" }} onClick={() => handleNavigateToFolder(document)}>
                  <FontAwesomeIcon icon={faFolder} className={classes.documentIcon} />
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  primary={document.name}
                  secondary={document.breadcrumbsString}
                  sx={{ cursor: "pointer", width: "12%" }}
                  onClick={() => handleNavigateToFolder(document)}
                />
                <ListItemText id={labelId} sx={{ cursor: "pointer", width: "10%" }} primary={dateDocument(document)} />
                <GuardedComponent componentName={componentName}>
                  <ListItemText id={labelId} sx={{ cursor: "pointer", width: "10%" }} primary={personName(document)} />
                </GuardedComponent>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    width: "10rem",
                  }}
                >
                  <GuardedComponent componentName={componentName}>
                    <IconButton
                      aria-label="Editer le dossier"
                      size="small"
                      onClick={() => handleClickEditFolderModal(document)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      aria-label="Supprimer le dossier"
                      size="small"
                      onClick={() => handleClickDeleteFolderModal(document)}
                    >
                      <DeleteIcon />
                    </IconButton>
                    <IconButton
                      aria-label="Copier le dossier"
                      size="small"
                      onClick={() => handleClickGenerateUrl(document)}
                    >
                      <CopyIcon />
                    </IconButton>
                  </GuardedComponent>
                  <IconButton
                      aria-label="Télécharger le dossier"
                      size="small"
                      onClick={() => handleDownloadFolder(document)}
                    >
                      <DownloadIcon />
                    </IconButton>
                </Box>
              </>
            )}
          </ListItem>
        );
      })}
      {documentsToShow.length === 0 && !loading && (
        <ListItem
          key="no-file"
          sx={{
            borderWidth: "0px 0px thin",
            borderStyle: "solid",
            borderColor: "rgba(0, 0, 0, 0.12)",
            paddingLeft: "36px",
            paddingRight: "36px",
            paddingY: "16px",
          }}
        >
          Aucun fichier
        </ListItem>
      )}

      {openDeleteFolderModal && (
        <DeleteFolderModal
          open={openDeleteFolderModal}
          onClose={handleCloseDeleteFolderModal}
          folder={documentToManage}
        />
      )}

      {openDeleteFileModal && (
        <DeleteFileModal open={openDeleteFileModal} onClose={handleCloseDeleteFileModal} file={documentToManage} />
      )}

      {openEditFileModal && (
        <EditFileModal
          open={openEditFileModal}
          onClose={handleCloseEditFileModal}
          file={documentToManage}
          handleNavigateToFolder={handleNavigateToFolder}
          documentsToShow={documentsToShow}
          breadcrumbs={breadcrumbs}
          handleUpdateBreadCrumbs={handleUpdateBreadCrumbs}
          renderViewFileIcon={renderViewFileIcon}
        />
      )}

      {openEditFolderModal && (
        <EditFolderModal
          open={openEditFolderModal}
          onClose={handleCloseEditFolderModal}
          file={documentToManage}
          handleNavigateToFolder={handleNavigateToFolder}
          documentsToShow={documentsToShow}
          breadcrumbs={breadcrumbs}
          handleUpdateBreadCrumbs={handleUpdateBreadCrumbs}
          renderViewFileIcon={renderViewFileIcon}
        />
      )}
    </List>
  );
};

const useStyles = makeStyles((theme) => ({
  documentIcon: {
    fontSize: "26px",
  },
  pdfIcon: {
    color: "hsl(0, 100%, 50%)",
  },
  excelIcon: {
    color: "hsl(147, 99%, 27%)",
  },
  docIcon: {
    color: "hsl(221, 44%, 59%)",
  },
  documentRecentIcon : {
    color: "#8B0000",
  }
}));

DocumentsList.propTypes = {
  documentsToShow: PropTypes.array,
  handleNavigateToFolder: PropTypes.func,
  loading: PropTypes.bool,
  sort: PropTypes.bool,
  handleSortChange: PropTypes.func,
  breadcrumbs: PropTypes.array,
  handleUpdateBreadCrumbs: PropTypes.func,
};

export default DocumentsList;
