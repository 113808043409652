import PropTypes from "prop-types";
import React from "react";
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import { Refresh as RefreshIcon, Search as SearchIcon } from "@material-ui/icons";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import AppCircularProgress from "../AppCircularProgress";
import { useTrackingContext } from "../../contexts/TrackingContext";
import { ACTION_CLICK } from "../../utils/trackingConstant";

const UsersToolbar = ({ onFilter, profiles }) => {
  const track = useTrackingContext();

  const profilesValue = profiles ? profiles.map((profile) => profile.name) : [];
  profilesValue.push("");
  const defaultValues = {
    email: "",
    lastName: "",
    firstName: "",
    profile: "",
  };

  const schema = yup.object().shape({
    email: yup.string(),
    lastName: yup.string(),
    firstName: yup.string(),
    profile: yup.string().oneOf(profilesValue),
  });

  const { control, handleSubmit, reset } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const filters = {
      ...data,
    };

    onFilter(filters);
    track.click(ACTION_CLICK.RESET_FILTER);
  };

  const onReset = () => {
    reset();
    onFilter(defaultValues);

    track.click(ACTION_CLICK.SET_FILTER);
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={4} lg={2}>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Email"
                variant="filled"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={4} lg={2}>
          <Controller
            name="lastName"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Nom"
                variant="filled"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={4} lg={2}>
          <Controller
            name="firstName"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Prénom"
                variant="filled"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={4} lg={2}>
          <FormControl fullWidth variant="filled">
            <InputLabel id="users-profile-label" shrink={true}>
              Profil
            </InputLabel>
            <Controller
              name="profile"
              control={control}
              render={({ field }) => (
                <Select {...field} labelId="users-profile-label">
                  <MenuItem value="">
                    <em>Tous</em>
                  </MenuItem>
                  {profiles ? (
                    profiles.map((profile) => (
                      <MenuItem value={profile.name} key={profile.name}>
                        {profile.title}
                      </MenuItem>
                    ))
                  ) : (
                    <AppCircularProgress />
                  )}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4} lg={1} display={{ xs: "block", lg: "block" }} />
        <Grid item xs={4} lg={3} container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <Button fullWidth variant="contained" startIcon={<SearchIcon />} type="submit">
              Filtrer
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button fullWidth variant="outlined" startIcon={<RefreshIcon />} onClick={() => onReset()}>
              Réinitialiser
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

UsersToolbar.propTypes = {
  onFilter: PropTypes.func,
  profiles: PropTypes.array,
};

export default UsersToolbar;
