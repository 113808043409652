import React from "react";
import Documentation from "../Documentation";
import { getDocumentationFolders } from "../../http/documentations";

const LibraryDocumentation = (_) => {
  return (
    <Documentation
      foldersQuery={getDocumentationFolders}
      componentName="hubnup_documentation_edit"
      type="bibliotheque"
    />
  );
};

export default LibraryDocumentation;
