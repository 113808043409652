import { LoadingButton } from "@material-ui/lab";
import React, { useState } from "react";
import { Card, Container, Stack } from "@material-ui/core";
import { Download as DownloadIcon } from "@material-ui/icons";
import { useMutation } from "react-query";
import Breadcrumbs from "../../components/Breadcrumbs";
import AgentCustomersList from "../../components/customer/AgentCustomersList";
import CustomerToolbar from "../../components/customer/CustomerToolbar";
import GuardedComponent from "../../components/GuardedComponent";
import FilterPanel from "../../components/FilterPanel";
import { getExportCustomers } from "../../http/clients";
import { useTrackingContext } from "../../contexts/TrackingContext";
import { ACTION_CLICK } from "../../utils/trackingConstant";

const AgentCustomers = () => {
  const year = new Date().getFullYear();
  const [filters, setFilters] = useState({ years: [year] });
  const [sortModel, setSortModel] = useState([
    { field: "clientLastName", sort: "asc" },
    { field: "clientFirstName", sort: "asc" },
  ]);

  const track = useTrackingContext()

  const onFilter = (filters) => {
    setFilters(filters);
  };

  const handleExportExcel = () => {
    track.click(ACTION_CLICK.EXPORT_MY_CUSTOMERS)
    mutation.mutate({
      filters,
      order: sortModel,
    });
  };

  const mutation = useMutation(getExportCustomers);

  return (
    <Container maxWidth={false}>
      <Breadcrumbs />

      <GuardedComponent componentName="customers_export">
        <Stack direction="row" spacing={2}>
          <LoadingButton
            loading={mutation.isLoading}
            color="primary"
            variant="contained"
            startIcon={<DownloadIcon />}
            onClick={handleExportExcel}
          >
            Exporter
          </LoadingButton>
        </Stack>
      </GuardedComponent>

      <FilterPanel sx={{ mt: 2 }}>
        <CustomerToolbar onFilter={onFilter} />
      </FilterPanel>

      <Card sx={{ mt: 2 }}>
        <AgentCustomersList filters={filters} sortModel={sortModel} setSortModel={setSortModel} />
      </Card>
    </Container>
  );
};

export default AgentCustomers;
