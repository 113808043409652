import { LoadingButton } from "@material-ui/lab";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Alert, Button, DialogActions, DialogContent, DialogContentText, Divider } from "@material-ui/core";
import { FolderOpen } from "@material-ui/icons";
import { useMutation, useQueryClient } from "react-query";
import { importUsers } from "../../http/user";
import AppDialog from "../AppDialog";

const UsersImportModal = ({ open, onClose, profiles }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [file, setFile] = useState(null);
  const queryClient = useQueryClient();

  const handleClose = (result) => {
    setFile(null);
    onClose(result);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("file", file);

    mutation.mutate(formData);
  };

  const mutation = useMutation(importUsers, {
    onSuccess: () => {
      queryClient.invalidateQueries("getUsersAll");
      enqueueSnackbar("Les utilisateurs ont été importés.", { variant: "success" });
      handleClose();
    },
  });

  const handleChangeFile = (event) => {
    setFile(event.target.files[0]);
  };

  return (
    <AppDialog open={open} onClose={handleClose} title="Importer des utilisateurs">
      <form noValidate onSubmit={onSubmit}>
        <DialogContent>
          <DialogContentText component="div">
            <p style={{ marginBottom: 10 }}>
              Sélectionner un fichier excel pour importer des utilisateurs. Le fichier doit contenir dans l'ordre les
              colonnes email, nom, prénom, profil, id cgp et mot de passe.
            </p>

            <p style={{ marginBottom: 10 }}>Les profils disponibles sont :</p>

            {profiles && (
              <ul style={{ marginBottom: 10, paddingLeft: 25 }}>
                {profiles.map((profile) => (
                  <li key={profile.name}>{profile.name}</li>
                ))}
              </ul>
            )}
          </DialogContentText>

          {mutation.isError ? <Alert severity="error">Une erreur est survenue : {mutation.error.message}</Alert> : null}

          <Button variant="contained" component="label" startIcon={<FolderOpen />}>
            Télécharger un fichier
            <input type="file" hidden accept=".xls, .xlsx" onChange={handleChangeFile} />
          </Button>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Annuler
          </Button>
          <LoadingButton loading={mutation.isLoading} type="submit" variant="contained" disabled={!file}>
            Enregistrer
          </LoadingButton>
        </DialogActions>
      </form>
    </AppDialog>
  );
};

UsersImportModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  profiles: PropTypes.array,
};

export default UsersImportModal;
