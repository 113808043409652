import { useQuery } from "react-query";
import { eventTypes } from "../../http/trainings";

const useEventByTypeData = (eventType) => {
  const key = `${eventType}s`;

  const events = useQuery({
    queryKey: [key],
    queryFn: () => eventTypes(eventType),
  });

  let eventsNames = [];

  if (events.data) {
    eventsNames = events.data?.map((event) => {
      return event.nom;
    });
  }

  // return list ordered alphabetically
  return eventsNames.sort();
};

export default useEventByTypeData;
