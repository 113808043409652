import { AccountBox, AccountCircle as AccountCircleIcon, Create as CreateIcon } from "@material-ui/icons";
import { LoadingButton } from "@material-ui/lab";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import {
  Alert,
  Avatar,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { patchUser } from "../../http/user";
import AppCircularProgress from "../AppCircularProgress";
import { useTrackingContext } from "../../contexts/TrackingContext";
import { ACTION_CLICK } from "../../utils/trackingConstant";

const AgentUserFormAvatar = ({ user, isFetched }) => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const [fileBase64, setFileBase64] = useState(null);
  const [circular, setCircular] = useState(true);
  const { handleSubmit } = useForm();

  const track = useTrackingContext()

  useEffect(() => {
    if (user) {
      setFileBase64(user?.picture);
      setCircular(user?.circularPicture);
    }
  }, [user]);

  const onSubmit = async () => {
    track.click(ACTION_CLICK.EDIT_MY_PICTURE)

    mutation.mutate({
      picture: fileBase64,
      user,
      circularPicture: circular,
    });
  };

  const mutation = useMutation(patchUser, {
    onSuccess: () => {
      queryClient.invalidateQueries("getCurrentUser");
    },
  });

  const uploadImage = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setFileBase64(base64);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      {mutation.isError ? <Alert severity="error">Une erreur est survenue : {mutation.error.message}</Alert> : null}

      {mutation.isSuccess ? <Alert severity="success">Modification effectuée avec succès.</Alert> : null}

      {isFetched ? (
        <Grid container spacing={2} alignItems="stretch">
          <Grid item xs={12}>
            <Typography align="center">
              Photo : Vous pouvez ici insérer une photo ou un logo. Cette photo pourra être visible par vos uplines.
              <br/>
              Choix de la photo: Si vous souhaitez mettre votre logo, nous vous préconisons d'utiliser l'affichage
              carré. Si vous souhaitez mettre une photo de vous, alors nous vous préconisons l'affichage bulle.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.imageContainer}>
              <FormControl sx={{ flexDirection: "row" }}>
                <RadioGroup
                  aria-labelledby="radio-buttons-group-label"
                  name="circularPicture"
                  sx={{ flexDirection: "row" }}
                  value={circular.toString()}
                  onChange={(event) => setCircular(event.target.value === "true")}
                >
                  <Box sx={{ textAlign: "center" }}>
                    <FormControlLabel value="true" control={<Radio/>} label="Arrondi"/>
                    <Avatar sx={{ width: 200, height: 200 }}>
                      {fileBase64 ? (
                        <img
                          alt="preview"
                          src={fileBase64}
                          style={{ width: "100%", height: "100%", objectFit: "cover" }}
                        />
                      ) : (
                        <AccountCircleIcon sx={{ fontSize: 200 }}/>
                      )}
                    </Avatar>
                  </Box>
                  <Box sx={{ marginLeft: "30px", textAlign: "center" }}>
                    <FormControlLabel value="false" control={<Radio/>} label="Carré"/>
                    <Avatar sx={{ width: 200, height: 200 }} variant="rounded">
                      {fileBase64 ? (
                        <img
                          alt="preview"
                          src={fileBase64}
                          style={{ width: "100%", height: "100%", objectFit: "cover" }}
                        />
                      ) : (
                        <AccountBox sx={{ fontSize: 200 }}/>
                      )}
                    </Avatar>
                  </Box>

                  <Button startIcon={<CreateIcon/>} className={classes.pictureIcon} component="label">
                    <input type="file" hidden onChange={uploadImage}/>
                  </Button>
                </RadioGroup>
              </FormControl>
            </Box>
          </Grid>

          <Grid item xs={12} sx={{ display: "flex", flexDirection: "row-reverse", alignItems: "flex-end" }}>
            <LoadingButton loading={mutation.isLoading} type="submit" variant="contained">
              Modifier ma photo
            </LoadingButton>
          </Grid>
        </Grid>
      ) : (
        <AppCircularProgress/>
      )}
    </form>
  );
};

const useStyles = makeStyles((theme) => ({
  pictureIcon: {
    "&:hover": {
      backgroundColor: "inherit",
    },
    "&:focus": {
      backgroundColor: "inherit",
    },
    "& .MuiTouchRipple-root": {
      display: "none",
    },
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    position: "relative",
  },
}));

AgentUserFormAvatar.propTypes = {
  user: PropTypes.object,
  isFetched: PropTypes.bool,
};

export default AgentUserFormAvatar;
