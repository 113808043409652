import { LoadingButton } from "@material-ui/lab";
import React, { useState } from "react";
import { Button, Card, Container, Stack } from "@material-ui/core";
import { Add as AddIcon, Download as DownloadIcon } from "@material-ui/icons";
import { useMutation } from "react-query";
import Breadcrumbs from "../../components/Breadcrumbs";
import GuardedComponent from "../../components/GuardedComponent";
import FilterPanel from "../../components/FilterPanel";
import ProspectsToolbar from "../../components/prospect/ProspectsToolbar";
import ProspectsList from "../../components/prospect/ProspectsList";
import ProspectsCreateModal from "../../components/prospect/ProspectsCreateModal";
import { getExportProspects } from "../../http/prospects";
import { useTrackingContext } from "../../contexts/TrackingContext";
import { ACTION_CLICK } from "../../utils/trackingConstant";

const AgentProspects = () => {
  const [openCreateProspectModal, setOpenCreateProspectModal] = useState(false);
  const [filters, setFilters] = useState({});
  const [sortModel, setSortModel] = useState([
    { field: "lastName", sort: "asc" },
    { field: "firstName", sort: "asc" },
  ]);

  const track = useTrackingContext()

  const onFilter = (filters) => {
    setFilters(filters);
  };

  const handleExportExcel = () => {
    track.click(ACTION_CLICK.EXPORT_MY_PROSPECTS)
    mutation.mutate({
      filters,
      order: sortModel,
    });
  };

  const mutation = useMutation(getExportProspects);

  const handleClickCreateProspectModal = () => {
    setOpenCreateProspectModal(true);
  };

  const handleCloseCreateProspectModal = () => {
    setOpenCreateProspectModal(false);
  };

  return (
    <Container maxWidth={false}>
      <Breadcrumbs />

      <Stack direction="row" spacing={2}>
        <GuardedComponent componentName="prospects_export">
          <LoadingButton
            loading={mutation.isLoading}
            color="primary"
            variant="contained"
            startIcon={<DownloadIcon />}
            onClick={handleExportExcel}
          >
            Exporter
          </LoadingButton>
        </GuardedComponent>

        <GuardedComponent componentName="prospects_edit">
          <Button variant="contained" startIcon={<AddIcon />} onClick={() => handleClickCreateProspectModal()}>
            Ajouter un prospect
          </Button>
        </GuardedComponent>
      </Stack>

      <FilterPanel sx={{ mt: 2 }}>
        <ProspectsToolbar onFilter={onFilter} fromAgent />
      </FilterPanel>

      <Card sx={{ mt: 2 }}>
        <ProspectsList filters={filters} sortModel={sortModel} setSortModel={setSortModel} fromAgent />
      </Card>

      {openCreateProspectModal && (
        <ProspectsCreateModal open={openCreateProspectModal} onClose={handleCloseCreateProspectModal} />
      )}
    </Container>
  );
};

export default AgentProspects;
