import { yupResolver } from "@hookform/resolvers/yup";
import { FolderOpen } from "@material-ui/icons";
import { LoadingButton } from "@material-ui/lab";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { Alert, Box, Button, Grid, Stack, TextField, Typography } from "@material-ui/core";
import * as yup from "yup";
import { postAgentRequest } from "../../http/agents";
import { useTrackingContext } from "../../contexts/TrackingContext";
import { ACTION_CLICK } from "../../utils/trackingConstant";

const AgentUserFormRequest = () => {
  const [file, setFile] = useState(null);
  const schema = yup.object().shape({
    title: yup.string().required("Ce champ est obligatoire."),
    content: yup.string(),
  });

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      title: "",
      content: "",
    },
    resolver: yupResolver(schema),
  });

  const track = useTrackingContext()

  const onSubmit = async (data) => {
    track.click(ACTION_CLICK.SEND_MY_REQUEST)

    let formData = new FormData();

    if (file) {
      formData.append("file", file);
    }

    formData.append("title", data.title);
    formData.append("content", data.content);

    mutation.mutate(formData);
  };

  const mutation = useMutation(postAgentRequest, {
    onSuccess: () => {
      reset();
      setFile(null);
    },
  });

  const handleChangeFile = (event) => {
    setFile(event.target.files[0]);
  };

  const renderFile = () => {
    let fileName = null;
    if (file) {
      fileName = file.name;
    }

    if (fileName) {
      return (
        <Typography sx={{ mx: 2 }} component="div">
          {fileName}
        </Typography>
      );
    }

    return null;
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid item xs={12}>
        {mutation.isError ? <Alert severity="error">Une erreur est survenue : {mutation.error.message}</Alert> : null}

        {mutation.isSuccess ? <Alert severity="success">Demande envoyée avec succès.</Alert> : null}

        <Typography component="div" variant="body1" sx={{ width: "100%", marginTop: "16px" }}>
          <p style={{ marginBottom: 10 }}>
            Pour toute autre demande, veuillez remplir les champs ci-dessous qui créeront un ticket automatiquement au
            support Hubn'UP :
          </p>
        </Typography>

        <Controller
          name="title"
          control={control}
          shouldUnregister
          render={({ field, formState: { errors } }) => (
            <TextField
              {...field}
              fullWidth
              label="Titre de la demande"
              margin="normal"
              type="text"
              autoComplete="title"
              error={Boolean(errors.title?.message)}
              helperText={errors.title?.message}
            />
          )}
        />
        <Box sx={{ display: "flex", alignItems: "center", marginTop: "8px" }}>
          <Button variant="contained" component="label" startIcon={<FolderOpen />}>
            Ajouter une pièce jointe
            <input type="file" hidden onChange={handleChangeFile} />
          </Button>
          {renderFile()}
        </Box>
        <Controller
          name="content"
          control={control}
          shouldUnregister
          render={({ field, formState: { errors } }) => (
            <TextField
              {...field}
              fullWidth
              label="Demande (texte libre)"
              margin="normal"
              multiline
              minRows={10}
              error={Boolean(errors.content?.message)}
              helperText={errors.content?.message}
            />
          )}
        />

        <Stack
          direction={{ xs: "column-reverse", sm: "row" }}
          spacing={2}
          sx={{
            p: 2,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <LoadingButton loading={mutation.isLoading} type="submit" variant="contained">
            Envoyer ma demande
          </LoadingButton>
        </Stack>
      </Grid>
    </form>
  );
};

export default AgentUserFormRequest;
