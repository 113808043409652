import PropTypes from "prop-types";
import React from "react";
import { Box, Button, CardContent, CardHeader, Divider, Link, Stack, Typography } from "@material-ui/core";
import { Create as CreateIcon } from "@material-ui/icons";
import { Link as RouterLink, useHistory } from "react-router-dom";
import checkAccessStore from "../../utils/checkAccessStore";
import { dateToString } from "../../utils/formatters";
import GuardedComponent from "../GuardedComponent";

const AgentRemindersContent = ({ reminder }) => {
  const history = useHistory();

  const goBack = () => {
    if (history.location.state) {
      history.go(history.location.state.goBack);
    }
    history.goBack();
  };

  return (
    <>
      <CardHeader
        title={
          <Box sx={{ py: 2, display: "flex", alignItems: "center" }}>
            <Typography variant="h1">{reminder.title}</Typography>
            <Typography variant="subtitle2" color="text.secondary" mx={3}>
              {dateToString(reminder.date)}
            </Typography>
          </Box>
        }
      />
      <Divider />
      <CardContent>
        {reminder.client && (
          <Box sx={{ py: 2 }}>
            <Typography gutterBottom variant="body1" textAlign="justify">
              {/*Client : {reminder.prospect}*/}
              Client :{" "}
              {checkAccessStore("customers_detail_page") ? (
                <Link component={RouterLink} to={reminder.client.replace("clients", "my-customers")}>
                  {reminder.prospect}
                </Link>
              ) : (
                reminder.prospect
              )}
            </Typography>
          </Box>
        )}
        <Box sx={{ py: 2 }}>
          <Typography
            variant="body1"
            textAlign="justify"
            paragraph={true}
            dangerouslySetInnerHTML={{
              __html: reminder.content.replace(/(?:\r\n|\r|\n)/g, "<br>"),
            }}
          />
        </Box>
      </CardContent>
      <Divider />
      <Stack
        direction={{ xs: "column-reverse", sm: "row" }}
        spacing={2}
        sx={{
          p: 2,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button variant="outlined" onClick={goBack}>
          Retour
        </Button>
        <GuardedComponent componentName="reminders_edit_page">
          {reminder.type !== "hubnup" && (
            <Button
              variant="contained"
              startIcon={<CreateIcon />}
              component={RouterLink}
              to={{
                pathname: `/communication/reminders/${reminder.id}/edit`,
                state: { goBack: history.location.pathname },
              }}
            >
              Editer
            </Button>
          )}
        </GuardedComponent>
      </Stack>
    </>
  );
};

AgentRemindersContent.propTypes = {
  reminder: PropTypes.object,
};

export default AgentRemindersContent;
