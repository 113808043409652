import { faFile, faFilePdf, faFileExcel, faFileImage, faFileWord } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DocumentIcon = ({ document, classes }) => {
  const fileExt = document.filePath.split(".").pop();

  if (fileExt === "pdf") {
    return <FontAwesomeIcon icon={faFilePdf} className={`${classes.documentIcon} ${classes.pdfIcon}`} />;
  }

  if (["doc", "odt", "docx"].includes(fileExt)) {
    return <FontAwesomeIcon icon={faFileWord} className={`${classes.documentIcon} ${classes.docIcon}`} />;
  }

  if (["jpeg", "png", "gif", "jpg", "svg"].includes(fileExt)) {
    return <FontAwesomeIcon icon={faFileImage} className={classes.documentIcon} />;
  }

  if (["xls", "xlsx"].includes(fileExt)) {
    return <FontAwesomeIcon icon={faFileExcel} className={`${classes.documentIcon} ${classes.excelIcon}`} />;
  }

  return <FontAwesomeIcon icon={faFile} className={classes.documentIcon} />;
};

export default DocumentIcon;
