const homePageList = {
  home_page: "/",
  account_page: "/my/account",
  reminders_page: "/my/reminders",
  training_page: "/my/training",
  sales_summary_page: "/my-sales/summary",
  sales_real_estate_page: "/my-sales/real-estate",
  sales_investment_page: "/my-sales/investment",
  hubnup_sales_summary_page: "/sales/summary",
  hubnup_sales_real_estate_page: "/sales/real-estate",
  hubnup_sales_investment_page: "/sales/investment",
  invoices_page: "/invoices",
  team_page: "/my-team",
  hubnup_team_page: "/team",
  training_team_page: "/my-training-team",
  news_page: "/news",
  documentation_page: "/library",
  support_page: "/support",
  hubnup_home_page: "/accueil",
  customers_page: "/my-customers",
  hubnup_customers_page: "/customers",
  hubnup_news_page: "/communication/news",
  hubnup_reminders_page: "/communication/reminders",
  picture_library_page: "/communication/pictures-library",
  stats_taux_uc_page: "/statistiques/taux-uc",
  stats_summary_real_estate_page: "/statistiques/real-estate",
  stats_summary_investment_page: "/statistiques/investments",
  stats_team_page: "/statistiques/team",
  users_page: "/administration/users",
  portail_page: "/administration/portail",
};

export default homePageList;
