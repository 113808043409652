import { connect } from "react-redux";

import checkAccess from "../utils/checkAccess";

const GuardedComponent = ({ componentName, isAuthenticated, userComponents, children }) => {
  if (!isAuthenticated) {
    return null;
  }

  if (!checkAccess(componentName, userComponents)) {
    return null;
  }

  return children;
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.token !== null,
  userComponents: state.auth.components,
});

export default connect(mapStateToProps)(GuardedComponent);
