import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { StyledEngineProvider, ThemeProvider } from "@material-ui/core";
import { LocalizationProvider } from "@material-ui/lab";
import AdapterDayjs from "@material-ui/lab/AdapterDayjs";
import frLocale from "dayjs/locale/fr";
import { QueryClient, QueryClientProvider } from "react-query";
import { SnackbarProvider } from "notistack";
import "fontsource-roboto";
import reportWebVitals from "./reportWebVitals";
import store from "./store";
import theme from "./theme";
import GlobalStyles from "./components/GlobalStyles";
import App from "./App";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  //<React.StrictMode>
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={1} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
          <StyledEngineProvider injectFirst>
            <LocalizationProvider dateAdapter={AdapterDayjs} locale={frLocale}>
              <GlobalStyles />
              <App />
            </LocalizationProvider>
          </StyledEngineProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </QueryClientProvider>
  </Provider>,
  //</React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
