import { TextField, Autocomplete, CircularProgress } from "@material-ui/core";
import { useQuery } from "react-query";
import { getFilesByUserComponents } from "../../http/documentations";

const AutocompleteDocumentField = ({field, errors, disabled}) => {

  // get all Files
  const { isFetched, isFetching, data: files } = useQuery(
    ["getDocumentsList"],
    getFilesByUserComponents,
  );

  return (
    <Autocomplete
      id="agent-field"
      disabled={disabled}
      getOptionLabel={(option) => (option?.displayName ?? "")}
      getOptionSelected={(option, value) => option.id === value.id}
      options={files || []}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={field.value}
      onChange={(event, newValue) => {
        newValue?.displayName ? field.onChange(newValue) : field.onChange({});
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Document"
          variant="outlined"
          fullWidth
          error={Boolean(errors?.message)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isFetching ? <CircularProgress color="inherit" size={20} /> : null}
              </>
            ),
          }}
        />
      )}
    />
    
  );
};

export default AutocompleteDocumentField;
