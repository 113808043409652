import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Link as RouterLink, withRouter } from "react-router-dom";
import { Breadcrumbs, Link, Typography } from "@material-ui/core";
import { NavigateNext as NavigateNextIcon } from "@material-ui/icons";
import { routes } from "../utils/routesBreadcumps";

const LinkRouter = (props) => <Link {...props} component={RouterLink} />;

const MyBreadcrumbs = ({ homePath, location, append }) => {
  const pathnames = location.pathname.split("/").filter((x) => x);

  const renderAccueilName = () => {
    if (homePath === "/" || homePath === "/accueil") {
      if (pathnames.length === 0) {
        return <Typography color="textPrimary">Accueil</Typography>;
      }

      return (
        <LinkRouter color="inherit" to={homePath}>
          Accueil
        </LinkRouter>
      );
    }

    return null;
  };

  return (
    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" sx={{ mb: 3 }}>
      {renderAccueilName()}
      {pathnames.map((value, index) => {
        const last = index === pathnames.length - 1;
        const to = `/${pathnames.slice(0, index + 1).join("/")}`;

        const key = Object.keys(routes).find((item) => to.match(new RegExp(`^${item}$$`)));

        if (!key) {
          return null;
        }

        return last || !routes[key]?.isLink ? (
          <Typography color="textPrimary" key={key}>
            {routes[key].label}
          </Typography>
        ) : (
          <LinkRouter color="inherit" to={to} key={key}>
            {routes[key].label}
          </LinkRouter>
        );
      })}
      {append}
    </Breadcrumbs>
  );
};

MyBreadcrumbs.propTypes = {
  homePath: PropTypes.string,
};

const mapStateToProps = (state) => ({
  homePath: state.auth.homePath,
});

export default connect(mapStateToProps)(withRouter(MyBreadcrumbs));
