import { LoadingButton } from "@material-ui/lab";
import PropTypes from "prop-types";
import React from "react";
import { Alert, Button, DialogActions, DialogContent, DialogContentText, Divider } from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";
import { useMutation, useQueryClient } from "react-query";
import AppDialog from "../AppDialog";
import { deleteFolder } from "../../http/documentations";

const DeleteFolderModal = ({ open, onClose, folder }) => {
  const queryClient = useQueryClient();

  const onConfirm = async () => {
    mutation.mutate({ folder });
  };

  const mutation = useMutation(deleteFolder, {
    onSuccess: () => {
      queryClient.invalidateQueries("getFolders");
      onClose();
    },
  });

  return (
    <AppDialog open={open} onClose={onClose} title="Supprimer un dossier">
      <DialogContent>
        {mutation.isError ? <Alert severity="error">Une erreur est survenue : {mutation.error.message}</Alert> : null}

        <DialogContentText id="delete-reminder-dialog-description">
          Confirmez-vous la suppression du dossier ? Attention, cela supprimera tous les sous-dossier et fichiers
          contenu dedans.
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Annuler
        </Button>
        <LoadingButton
          loading={mutation.isLoading}
          variant="contained"
          color="hubnup"
          onClick={onConfirm}
          startIcon={<DeleteIcon />}
        >
          Supprimer
        </LoadingButton>
      </DialogActions>
    </AppDialog>
  );
};

DeleteFolderModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  folder: PropTypes.object,
};

export default DeleteFolderModal;
