import {
  BugReport as BugReportIcon,
  Build as BuildIcon,
  Description as DescriptionIcon,
  Group as GroupIcon,
  Home as HomeIcon,
  LibraryBooks as LibraryBooksIcon,
  MenuBook as MenuBookIcon,
  Person as PersonIcon,
  RecordVoiceOver as RecordVoiceOverIcon,
  School as SchoolIcon,
  ShoppingCart as ShopIcon,
  TrendingUp as TrendingUpIcon,
} from "@material-ui/icons";
import { faAddressBook } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const agentMenusRoles = (
  consultantSharepointLink,
  addHabilitation,
  teamSponsoring,
) => [
  [
    {
      href: "/",
      icon: <HomeIcon />,
      title: "Accueil",
      name: "home_page",
    },
    {
      icon: <PersonIcon />,
      title: "Mon espace",
      name: "my_space_section",
      children: [
        {
          href: "/my/account",
          title: "Mon compte",
          name: "account_page",
        },
        {
          href: "/my/reminders",
          title: "Mes rappels",
          name: "reminders_page",
        },
        consultantSharepointLink
          ? {
              href: consultantSharepointLink ?? "#",
              title: "Mes documents",
              name: "documentation_personnal_space",
              type: "external_link",
            }
          : null,
        {
          href: "/my/training",
          title: "Mes formations",
          name: "training_page",
        },
        addHabilitation
          ? {
              href: addHabilitation?.url,
              title: addHabilitation?.displayName,
              name: addHabilitation?.name,
              type: "external_link",
            }
          : null,
      ],
    },
    {
      icon: <TrendingUpIcon />,
      title: "Mes ventes",
      name: "sales_section",
      children: [
        {
          href: "/my-sales/summary",
          title: "Synthèse",
          name: "sales_summary_page",
        },
        {
          href: "/my-sales/real-estate",
          title: "Suivi immobilier",
          name: "sales_real_estate_page",
        },
        {
          href: "/my-sales/investment",
          title: "Suivi placement",
          name: "sales_investment_page",
        },
        {
          href: "/my-sales/vlp",
          title: "Suivi VLP",
          name: "sales_vlp_page",
        },
        {
          href: "#",
          title: "Mes dossiers clients",
          name: "documentation_business",
          type: "login_oziolab",
        },
        {
          href: "#",
          title: "Mes factures",
          name: "documentation_invoice",
          type: "login_oziolab",
        },
        {
          href: "#",
          title: "Tableau de bord",
          name: "documentation_dashboard",
          type: "login_oziolab",
        },
      ],
    },
    {
      href: "/my-invoices",
      icon: <LibraryBooksIcon />,
      title: "Mes factures",
      name: "invoices_page",
    },
    {
      icon: <GroupIcon />,
      title: "Mon équipe",
      name: "team_section",
      children: [
        {
          href: "/my-team",
          title: "Suivi équipes",
          name: "team_page",
        },
        {
          href: "/my-training-team",
          title: "Formations équipe",
          name: "training_team_page",
        },
        teamSponsoring
          ? {
              href: teamSponsoring?.url,
              title: teamSponsoring?.displayName,
              name: teamSponsoring?.name,
              type: "external_link",
            }
          : null,
        {
          href: "/my-team-sponsor",
          title: "Suivi parrainage",
          name: "team_track_for_sponsor_page",
        },
      ],
    },
    {
      icon: (
        <FontAwesomeIcon
          icon={faAddressBook}
          style={{ fontSize: "24px", marginRight: "13px" }}
        />
      ),
      title: "Mes clients",
      name: "customers_section",
      children: [
        {
          href: "/my-customers",
          title: "Suivi clients",
          name: "customers_page",
        },
        {
          href: "/my-prospects",
          title: "Suivi prospects",
          name: "prospects_page",
        },
      ],
    },
  ],
  [
    {
      icon: <MenuBookIcon />,
      title: "Actualités Hubn'UP",
      name: "news_section",
      children: [
        {
          href: "/news",
          title: "Communications",
          name: "coms_page",
        },
        {
          href: "https://cshubnup.sharepoint.com/:f:/s/hubnup/Ejsl6C71HqNNnrDeGCZfK2QB0nq5tNROgxqd_ZDCHbuQ8Q?e=ec6NoD",
          title: "Évènements",
          name: "news_page",
          type: "external_link",
        },
      ],
    },
    {
      href: "https://cshubnup.sharepoint.com/:f:/s/hubnup/EvYFyWtkOjRDuLyTY79rO8MBSxvQYq7Kwi-XCoRpnM9HUw",
      icon: <DescriptionIcon />,
      title: "Ma Bibliothèque",
      name: "documentation_section",
      type: "external_link",
    },
    {
      icon: <DescriptionIcon />,
      title: "Bibliothèque",
      name: "library_documentation_page",
      children: [
        {
          href: "/library/bibliotheque",
          title: "Ma Bibliothèque",
          name: "documentation_page",
        },
        {
          href: "/library/trainers-club",
          title: "Trainers Club",
          name: "trainers_club_documentation_page",
        },
        {
          href: "/library/rac",
          title: "RAC",
          name: "rac_documentation_page",
        },
        {
          href: "/library/responsable-pole",
          title: "Responsable de pôle",
          name: "responsable_pole_documentation_page",
        },
        {
          href: "/library/demandes-modification",
          title: "Suggestion de modification",
          name: "suivi_demande_modification_documentation_page",
        },
      ],
    },
    {
      href: "https://hubnup.freshdesk.com/customer/login",
      icon: <BugReportIcon />,
      title: "Support",
      name: "support_page",
      type: "external_link",
    },
    {
      href: "https://cameleon-communication.fr/boutique/",
      icon: <ShopIcon />,
      title: "Cartes de visite",
      name: "shop_page",
      type: "external_link",
    },
  ],
];

export const hubnupMenusRoles = [
  [
    {
      href: "/accueil",
      icon: <HomeIcon />,
      title: "Accueil",
      name: "hubnup_home_page",
    },
    {
      href: "/team",
      icon: <GroupIcon />,
      title: "Mandataires",
      name: "hubnup_team_page",
    },
    {
      icon: <TrendingUpIcon />,
      title: "Ventes",
      name: "sales_section",
      children: [
        {
          href: "/sales/real-estate",
          title: "Immobilier",
          name: "hubnup_sales_real_estate_page",
        },
        {
          href: "/sales/investment",
          title: "Placement",
          name: "hubnup_sales_investment_page",
        },
        {
          href: "/sales/vlp",
          title: "VLP",
          name: "hubnup_sales_vlp_page",
        },
      ],
    },
    {
      icon: (
        <FontAwesomeIcon
          icon={faAddressBook}
          style={{ fontSize: "24px", marginRight: "13px" }}
        />
      ),
      title: "Clients",
      name: "hubnup_customers_section",
      children: [
        {
          href: "/customers",
          title: "Suivi clients",
          name: "hubnup_customers_page",
        },
        {
          href: "/prospects",
          title: "Suivi prospects",
          name: "hubnup_prospects_page",
        },
      ],
    },
    {
      href: "/invoices",
      icon: <LibraryBooksIcon />,
      title: "Factures",
      name: "hubnup_invoices_page",
    },
    {
      title: "Communication",
      name: "communication_section",
      icon: <RecordVoiceOverIcon />,
      children: [
        {
          href: "/communication/news",
          title: "Actualités",
          name: "hubnup_news_page",
        },
        {
          href: "/communication/reminders",
          title: "Rappels",
          name: "hubnup_reminders_page",
        },
        {
          href: "/communication/pictures-library",
          title: "Bibliothèque d'images",
          name: "picture_library_page",
        },
        {
          href: "/communication/notifications",
          title: "Notifications",
          name: "notifications_page",
        },
      ],
    },
    // Todo
    // {
    //   title: 'Statistiques',
    //   name: 'stats_section',
    //   icon: <EqualiserIcon />,
    //   children: [
    //     {
    //       href: '/statistiques/taux-uc',
    //       title: 'Taux UC',
    //       name: 'stats_taux_uc_page',
    //     },
    //     {
    //       href: '/statistiques/real-estate',
    //       title: 'Synthèse Immobilier',
    //       name: 'stats_summary_real_estate_page',
    //     },
    //     {
    //       href: '/statistiques/investments',
    //       title: 'Synthèse Placement',
    //       name: 'stats_summary_investment_page',
    //     },
    //     {
    //       href: '/statistiques/team',
    //       title: 'Equipe',
    //       name: 'stats_team_page',
    //     },
    //   ],
    // },
    {
      icon: <DescriptionIcon />,
      title: "Bibliothèque",
      name: "library_documentation_page",
      children: [
        {
          href: "/library/bibliotheque",
          title: "Bibliothèque",
          name: "documentation_page",
        },
        {
          href: "/library/trainers-club",
          title: "Trainers Club",
          name: "trainers_club_documentation_page",
        },
        {
          href: "/library/rac",
          title: "RAC",
          name: "rac_documentation_page",
        },
        {
          href: "/library/responsable-pole",
          title: "Responsable de pôle",
          name: "responsable_pole_documentation_page",
        },
        {
          href: "/library/demandes-modification",
          title: "Suggestion de modification",
          name: "suivi_demande_modification_documentation_page",
        },
      ],
    },
    {
      title: "Administration",
      name: "admin_section",
      icon: <BuildIcon />,
      children: [
        {
          href: "/administration/users",
          title: "Utilisateurs",
          name: "users_page",
        },
        {
          href: "/administration/portail",
          title: "Portail",
          name: "portail_page",
        },
      ],
    },
    {
      title: "Portail formation",
      name: "formation_section",
      icon: <SchoolIcon />,
      children: [
        {
          href: "/odysseal/roles",
          title: "Gestion des rôles",
          names: "formation_roles",
        },
        {
          href: `/redirect/odysseal?ref=${process.env.REACT_APP_ODYSSEAL_PORTAL}/accueil`,
          title: "Accés aux portail",
          name: "formation_access",
        },
      ],
    },
  ],
];
