import http from "../utils/http";
import openFile from "../utils/openFile";
import openFolder from "../utils/downloadFolder";
import { dateToIsoString, dateToStringYmd } from "../utils/formatters";
import checkAccessStore from "../utils/checkAccessStore";
import dayjs from "dayjs";

export const getDocumentationFolders = async () => {
  const response = await http.get("/folders?name=Bibliothèque&type=bibliotheque");

  return response.data["hydra:member"][0];
};

export const getTrainersClubFolders = async () => {
  const response = await http.get("/folders?name=Trainers Club&type=trainers-club");

  return response.data["hydra:member"][0];
};

export const getRacFolders = async () => {
  const response = await http.get("/folders?name=RAC&type=rac");

  return response.data["hydra:member"][0];
};

export const getResponsablePoleFolders = async () => {
  const response = await http.get("/folders?name=Responsable de pôle&type=responsable-pole");

  return response.data["hydra:member"][0];
};

export const postFolder = async (data) => {
  return await http.post("/folders", data);
};

export const deleteFolder = async (data) => {
  const response = await http.delete(data.folder["@id"]);

  return response.data;
};

export const deleteFile = async (data) => {
  const response = await http.delete(data.file["@id"]);

  return response.data;
};

// get all files that has folder type as Trainer's Club or RAC
export const getFilesByUserComponents = async () => {
  let url = "/media_objects?pagination=false"

  if (checkAccessStore("trainers_club_documentation_page")) {
    url += "&folder.type[]=trainers-club"
  }

  if (checkAccessStore("rac_documentation_page")) {
    url += "&folder.type[]=rac"
  }

  if (checkAccessStore("responsable_pole_documentation_page")) {
    url += "&folder.type[]=responsable-pole"
  }

  const response = await http.get(url);

  return response.data["hydra:member"]
}

export const postMediaObject = async (data) => {
  return await http.post("/media_objects", data);
};

export const getMediaObjectDownload = async (data) => {
  return await openFile(
    `/media_object/${data.file.id}/file?action=download`,
    data.file.displayName ?? data.file.filePath,
    "application/pdf"
  );
};

export const getFolderDownload = async (data) => {
  return await openFolder(`/folder/${data.file.id}/folder?action=download`, data.file.name, "application/zip");
};

export const editMediaObject = async (data) => {
  const response = await http.post(`/media_objects/edit/${data.id}?name=${data.displayName}&path=${data.path}`);
  return response.data;
};

export const editMediaObjectListReadByUser = async (MediaObjectListItemId) => {
  const response = await http.patch(`/media_object_lists/${MediaObjectListItemId}`, {
    readByUser : true
  });

  return response.data;
};

export const editFolder = async (data) => {
  const response = await http.post(`/folders/edit/${data.id}?name=${data.displayName}&path=${data.path}`);
  return response.data;
};


/** Document Modification requests */
export const postModificationRequest = async (data) => {
  const response = await http.post(`/document_modification_requests`, data);

  return response.data
}

export const getModificationRequestsAll = async (fromAgent, page = 1, filters) => {
  let url = `/document_modification_requests?page=${page}`;
  
  // Connected User
  if (filters?.userId) {
    url += `&applicant.id=${filters.userId}`;
  }

  // Document
  if (filters?.document) {
    url += `&mediaObject.displayName=${filters.document}`;
  }

  // dates
  if (filters?.createdAt && filters?.createdAt[0]) {
    url += `&createdAt[after]=${dateToIsoString(filters.createdAt[0])}`;
  }

  if (filters?.createdAt && filters?.createdAt[1]) {
    url += `&createdAt[before]=${dateToIsoString(filters?.createdAt[1])}`;
  }

  // status
  if (filters.status) {
    url += `&status=${filters.status}`
  }

  const response = await http.get(url);

  return response.data;
};

export const editModificationRequest = async (data) => {
  const response = await http.patch(`/document_modification_requests/${data.requestId}`, data.editDataWithFormattedDate);

  return response.data

}

// fetch MediaObjectLists which are associated with Documents added the last 7 days and associated with Current User with status not read
export const getMediaObjectList = async (userEmail) => {
  const oneWeekAgo = dateToStringYmd(dayjs().subtract(7, "day"));

  const response = await http.get('media_object_lists', {
    params: {
      "mediaObject.createdAt[after]" : oneWeekAgo, // Date one week ago : from function.
      "readByUser" : "false", 
      "user.email" : userEmail, // email of connected user : with params inside the component
    }
  });

  return response.data["hydra:member"];
};
