import { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  convertToRaw,
  ContentState,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { editorToolbar } from "../../utils/editorToolbarConfig";

function TextEditorWysiwigField({ onChange, value, disabled = false }) {
  // state for manage text editor.
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  // state of the content
  const [updated, setUpdated] = useState(false);

  // handle writing inside the textEditor
  const handleSetContent = (contentState) => {
      setUpdated(true);
      setEditorState(contentState);

      // convert to return the value to the form as html content
      const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
      onChange(html)
  };

  useEffect(() => {
    if (!updated) {
      const defaultValue = value ? value : "";
      if (defaultValue) {
        const blocksFromHtml = htmlToDraft(defaultValue);
        const contentState = ContentState.createFromBlockArray(
          blocksFromHtml.contentBlocks
        );
        const newEditorState = EditorState.createWithContent(contentState);
        setEditorState(newEditorState);
      }
    }
  }, [value]);

  return (
    <Editor
      editorState={editorState}
      onEditorStateChange={handleSetContent}
      toolbar={editorToolbar}
      readOnly={disabled}
    />
  );
}

export default TextEditorWysiwigField;
