import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  Skeleton,
  Typography,
} from "@material-ui/core";
import { Edit as EditIcon } from "@material-ui/icons";
import { useQuery } from "react-query";
import { Link as RouterLink, useHistory } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import GuardedComponent from "../../components/GuardedComponent";
import { getCurrentAgent } from "../../http/agents";
import UserInfo from "../../components/user/UserInfo";
import UserQualifications from "../../components/user/UserQualifications";
import UserAuthorities from "../../components/user/UserAuthorities";
import { getCurrentUser } from "../../http/user";

const AgentUserAccount = () => {
  const history = useHistory();
  const { data: user } = useQuery(["getCurrentUser"], () => {
    return getCurrentUser();
  });
  const {
    isFetched,
    isFetching,
    data: agent,
  } = useQuery(
    ["getCurrentAgent"],
    () => {
      return getCurrentAgent();
    },
    {
      enabled: !!user,
    }
  );

  return (
    <Container maxWidth={false}>
      <Breadcrumbs />

      <Box>
        <Grid container spacing={2} alignItems="stretch">
          <GuardedComponent componentName="account_modifications_page">
            <Grid item xs={12}>
              <Button
                color="primary"
                variant="contained"
                startIcon={<EditIcon />}
                component={RouterLink}
                to={{ pathname: "/my/account/edit", state: { goBack: history.location.pathname } }}
              >
                Modifier mes informations
              </Button>
            </Grid>
          </GuardedComponent>
          <GuardedComponent componentName="account_informations">
            <Grid item xs={12}>
              <Card>
                <CardHeader
                  title={
                    <Typography variant="h2">
                      {!isFetched || isFetching ? <Skeleton width="30%" /> : user.fullName}
                    </Typography>
                  }
                />
                <Divider />
                <CardContent>
                  <UserInfo user={user} agent={agent} loading={!isFetched || isFetching} />
                </CardContent>
              </Card>
            </Grid>
          </GuardedComponent>
          <GuardedComponent componentName="account_qualifications">
            <Grid item xs={12} md={6}>
              <Card sx={{ height: "100%" }}>
                <CardHeader title="Qualifications" />
                <Divider />
                <CardContent>
                  <UserQualifications agent={agent?.agent} loading={!isFetched || isFetching} />
                </CardContent>
              </Card>
            </Grid>
          </GuardedComponent>
          <GuardedComponent componentName="account_habilitations">
            <Grid item xs={12} md={6}>
              <Card sx={{ height: "100%" }}>
                <CardHeader title="Habilitations" />
                <Divider />
                <CardContent>
                  <UserAuthorities agent={agent?.agent} loading={!isFetched || isFetching} />
                </CardContent>
              </Card>
            </Grid>
          </GuardedComponent>
        </Grid>
      </Box>
    </Container>
  );
};

export default AgentUserAccount;
