import PropTypes from "prop-types";
import React from "react";
import { DataGrid } from "@material-ui/data-grid";
import { useQuery } from "react-query";
import renderCellExpand from "../../utils/renderCellExpand";
import { getEventsCount } from "../../http/trainings";

const TrainingTeamListCount = ({ agentId, filters }) => {
  const sortModel = [{ field: "exercice", sort: "asc" }];

  const { isFetching, data: training } = useQuery(["getEventsCount", filters, agentId], async () => {
    const data = await getEventsCount(filters, agentId);
    return data["hydra:member"];
  });

  const columns = [
    {
      field: "exercice",
      headerName: "Exercice",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "consultant",
      headerName: "Consultant",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "parrain",
      headerName: "Parrain",
      headerAlign: "center",
      flex: 1,
      valueGetter: (params) => params.row?.parrain,
    },
    {
      field: "registerDate",
      headerName: `Date d'entrée dans le réseau`,
      flex: 1,
      valueGetter: (params) => params.row?.registerDate,
      headerAlign: "center",
    },
    {
      field: "formation",
      headerName: "Nombre de formations",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "event",
      headerName: "Participation aux évènements",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
  ];

  return (
    <DataGrid
      loading={isFetching}
      rows={training ? training : []}
      columns={columns}
      pageSize={10}
      autoHeight
      disableColumnFilter
      disableSelectionOnClick
      disableColumnMenu
      disableColumnReorder
      pagination
      sortModel={sortModel}
      getRowId={(row) => row.id}
    />
  );
};

TrainingTeamListCount.propTypes = {
  filters: PropTypes.object,
  agentId: PropTypes.string,
};

export default TrainingTeamListCount;
