import { createTheme } from "@material-ui/core";
import { frFR } from "@material-ui/data-grid";

import palette from "./palette";
import components from "./components";
import shadows from "./shadows";
import typography from "./typography";

const theme = createTheme(
  {
    palette,
    shadows,
    typography,
    components,
  },
  frFR
);

export default theme;
