import { useQuery } from "react-query";
import { getNotificationsRules } from "../http/notifications";

export default function useNotificationRule(name) {
  const notificationRulesQuery = useQuery({
    queryKey: ["notifications_rules"],
    queryFn: () =>
      getNotificationsRules().then((resp) => resp.data["hydra:member"]),
    refetchOnMount: false,
    staleTime: 1000 * 60 * 5, // minutes de caches pour le hooks
  });

  if (notificationRulesQuery.isSuccess)
    return (
      notificationRulesQuery.data.find((rule) => rule.name === name)
        ?.isActivate ?? false
    );

  return false;
}
