import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Alert, Container } from "@material-ui/core";

import connectToOdysseal from "../utils/connectToOdysseal";
import AppCircularProgress from "../components/AppCircularProgress";

const ref = {
  odysseal: false,
};

const RedirectSSO = () => {
  const location = useLocation();
  const params = useParams();

  const [error, setErrors] = useState("");

  useEffect(() => {
    switch (params.sso) {
      case "odysseal":
        if (ref.odysseal) break;

        ref.odysseal = true;
        const search = new URLSearchParams(document.location.search);

        connectToOdysseal(search.get("ref"))
          .catch(() => setErrors("Une erreur est survenue !"))
          .finally(() => {
            ref.odysseal = false;
          });
        break;
    }
  }, [location]);

  if (error)
    return (
      <Container>
        <Alert severity="error">{error}</Alert>
      </Container>
    );

  return <AppCircularProgress />;
};

export default RedirectSSO;
