import React from "react";
import { connect } from "react-redux";
import checkAccess from "../utils/checkAccess";
import NewsCard from "./news/NewsCard";

const GuardedListLinkComponent = ({
  componentName,
  isAuthenticated,
  userComponents,
  item,
  fromAgent,
  reset,
  location,
}) => {
  if (!isAuthenticated) {
    return null;
  }

  if (!checkAccess(componentName, userComponents)) {
    return <NewsCard news={item} goBack={location.pathname} reset={reset} fromAgent={fromAgent} />;
  }

  return (
    <NewsCard
      news={item}
      detailsPath={`${location.pathname}/${item.id}`}
      goBack={location.pathname}
      reset={reset}
      fromAgent={fromAgent}
    />
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.token !== null,
  userComponents: state.auth.components,
});

export default connect(mapStateToProps)(GuardedListLinkComponent);
