import { ArrowForward as ArrowForwardIcon } from "@material-ui/icons";
import React from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Typography,
} from "@material-ui/core";
import { useQuery } from "react-query";
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import GuardedComponent from "../../components/GuardedComponent";
import SalesRealEstateSummary from "../../components/sales/SalesRealEstateSummary";
import SalesInvestmentSummary from "../../components/sales/SalesInvestmentSummary";
import AgentDetailsInfo from "../../components/team/AgentDetailInfo";
import { getAgentByCgpId } from "../../http/agents";
import checkAccessStore from "../../utils/checkAccessStore";
import isAgent from "../../utils/isAgent";
import TeamListV2 from "../../components/team/TeamListV2";

const AgentDetails = () => {
  const { agentId } = useParams();
  const history = useHistory();

  const {
    isFetched,
    isFetching,
    data: agent,
  } = useQuery(["getAgentByCgpId", agentId], () => {
    return getAgentByCgpId(agentId);
  });

  const goBack = () => {
    history.goBack();
  };

  return (
    <Container maxWidth={false}>
      <Breadcrumbs
        append={
          <Typography color="textPrimary">
            {agent?.agent?.firstname} {agent?.agent?.lastname}
          </Typography>
        }
      />

      <Grid container spacing={2}>
        <GuardedComponent componentName="team_detail_infos">
          <Grid item xs={12}>
            <Card>
              <CardHeader
                title={
                  <Typography variant="h2">
                    {!isFetched || isFetching ? (
                      <Skeleton width="30%" />
                    ) : (
                      `${agent?.agent?.firstname} ${agent?.agent?.lastname}`
                    )}
                  </Typography>
                }
              />
              <Divider />
              <CardContent>
                <AgentDetailsInfo
                  agent={agent}
                  loading={!isFetched || isFetching}
                />
              </CardContent>
            </Card>
          </Grid>
        </GuardedComponent>

        {/*// Todo*/}
        {/*<GuardedComponent componentName="team_detail_qualification">*/}
        {/*  <Grid*/}
        {/*    item*/}
        {/*    xs={12}*/}
        {/*  >*/}
        {/*    <SalesQualification*/}
        {/*      sales={sales}*/}
        {/*      loading={loading}*/}
        {/*    />*/}
        {/*  </Grid>*/}
        {/*</GuardedComponent>*/}

        <GuardedComponent componentName="team_detail_real_estate">
          <Grid
            item
            md={checkAccessStore("team_detail_investment") ? 6 : 12}
            xs={12}
          >
            <SalesRealEstateSummary agentId={agentId} />
          </Grid>
        </GuardedComponent>

        <GuardedComponent componentName="team_detail_investment">
          <Grid
            item
            md={checkAccessStore("team_detail_real_estate") ? 6 : 12}
            xs={12}
          >
            <SalesInvestmentSummary agentId={agentId} />
          </Grid>
        </GuardedComponent>

        <GuardedComponent componentName="team_detail_godchild">
          <Grid item xs={12}>
            <Card>
              <CardHeader
                title="Filleuls directs"
                action={
                  <GuardedComponent componentName="team_detail_team_page">
                    <IconButton
                      aria-label="Accéder à l'équipe du mandataire"
                      component={RouterLink}
                      to={
                        isAgent()
                          ? `/my-team/${agentId}/team`
                          : `/team/${agentId}/team`
                      }
                    >
                      <ArrowForwardIcon />
                    </IconButton>
                  </GuardedComponent>
                }
              />
              <Divider />
              <CardContent
                sx={{
                  p: 3,
                }}
              >
                <TeamListV2 oneLevel cgpId={agentId} />
              </CardContent>
            </Card>
          </Grid>
        </GuardedComponent>
      </Grid>

      <Stack
        direction={{ xs: "column-reverse", sm: "row" }}
        spacing={2}
        sx={{
          p: 2,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button variant="outlined" onClick={goBack}>
          Retour
        </Button>
      </Stack>
    </Container>
  );
};

export default AgentDetails;
