import React from "react";
import { Card, Container, Typography } from "@material-ui/core";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import AppCircularProgress from "../../components/AppCircularProgress";
import Breadcrumbs from "../../components/Breadcrumbs";
import AgentRemindersContent from "../../components/reminder/AgentRemindersContent";
import { getReminder } from "../../http/reminders";

const AgentRemindersDetail = () => {
  const { id } = useParams();
  const { isFetched, data: reminder } = useQuery(["getReminder", id], () => getReminder(id));

  return (
    <Container maxWidth={false}>
      <Breadcrumbs append={<Typography color="textPrimary">Détail rappel</Typography>} />

      <Card>{isFetched ? <AgentRemindersContent reminder={reminder} /> : <AppCircularProgress />}</Card>
    </Container>
  );
};

export default AgentRemindersDetail;
