import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@material-ui/lab";
import React, { useState } from "react";
import { Button, DialogActions, DialogContent, Divider, TextField, Alert } from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import * as yup from "yup";
import { postProfile } from "../../http/profiles";
import AppDialog from "../AppDialog";
import { useTrackingContext } from "../../contexts/TrackingContext";
import { ACTION_CLICK } from "../../utils/trackingConstant";

const AddProfile = ({ type }) => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => setOpen(true);
  const queryClient = useQueryClient();
  const track = useTrackingContext();

  const schema = yup.object().shape({
    profileName: yup.string().required("Ce champ est obligatoire."),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
    unregister,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleClose = () => {
    unregister("profileName");
    setOpen(false);
  };

  const onSubmit = async ({ profileName }) => {
    const title = type === "Mandataire" ? "Mandataire - " + profileName : profileName;
    const data = {
      title: title,
      type: type,
      name: "ROLE_" + title.toUpperCase(),
    };
    track.click(ACTION_CLICK.CREATE_PROFIL);

    mutation.mutate(data);
  };

  const mutation = useMutation(postProfile, {
    onSuccess: () => {
      queryClient.invalidateQueries("getProfiles");
      handleClose();
    },
  });

  return (
    <>
      <Button variant="contained" startIcon={<AddIcon />} onClick={handleClickOpen}>
        Ajouter un profil
      </Button>
      <AppDialog title="Ajout d'un profil" onClose={handleClose} open={open}>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            {mutation.isError ? (
              <Alert severity="error">Une erreur est survenue : {mutation.error.message}</Alert>
            ) : null}

            <TextField
              error={Boolean(errors.profileName?.message)}
              fullWidth
              helperText={errors.profileName?.message}
              label="Nom du profil"
              margin="normal"
              type="text"
              variant="outlined"
              autoComplete="name"
              autoFocus
              defaultValue=""
              {...register("profileName")}
            />
          </DialogContent>
          <Divider />
          <DialogActions>
            <LoadingButton loading={mutation.isLoading} type="submit" variant="contained">
              Enregistrer
            </LoadingButton>
          </DialogActions>
        </form>
      </AppDialog>
    </>
  );
};

export default AddProfile;
