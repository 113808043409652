import React from "react";
import GridCellExpand from "../components/GridCellExpand";

const renderCellExpand = (params, customCell = false, formattedValue) => {
  return (
    <GridCellExpand width={params.colDef.width} customCell={customCell} type={params.colDef.type}>
      {customCell ? formattedValue : params.formattedValue}
    </GridCellExpand>
  );
};

export default renderCellExpand;
