import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { connect } from "react-redux";
import AgentPopupNews from "./components/news/AgentPopupNews";
import AppRouter from "./router/AppRouter";
import * as actions from "./store/actions";
import ScrollToTop from "./components/ScrollToTop";
import TrackingContext from "./contexts/TrackingContext";

const App = ({ isAuthenticated, onLoginAgent, onTryAuthSignup }) => {
  useEffect(() => {
    onTryAuthSignup();
  }, [onTryAuthSignup]);

  return (
    <BrowserRouter>
      <TrackingContext>
        <ScrollToTop />
        <AppRouter />
        {isAuthenticated && onLoginAgent && <AgentPopupNews />}
      </TrackingContext>
    </BrowserRouter>
  );
};

App.propTypes = {
  onTryAuthSignup: PropTypes.func,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.token !== null,
  onLoginAgent: state.auth.onLoginAgent,
});

const mapDispatchToProps = (dispatch) => ({
  onTryAuthSignup: () => dispatch(actions.authCheckState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
