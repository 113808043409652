import PropTypes from "prop-types";
import React from "react";
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import { Refresh as RefreshIcon, Search as SearchIcon } from "@material-ui/icons";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ControlledSelectYearsInput from "../ControlledSelectYearsInput";
import { useTrackingContext } from "../../contexts/TrackingContext";
import { ACTION_CLICK } from "../../utils/trackingConstant";

const CustomerToolbar = ({ onFilter }) => {
  const year = new Date().getFullYear();
  const defaultValues = {
    lastName: "",
    firstName: "",
    zipCode: "",
    years: [year],
    type: "",
  };

  const track = useTrackingContext()

  const schema = yup.object().shape({
    lastName: yup.string(),
    firstName: yup.string(),
    zipCode: yup.string(),
    years: yup.array().of(yup.number()),
    type: yup.string().oneOf(["", "realEstate", "investment"]),
  });

  const { control, handleSubmit, reset } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const filters = {
      ...data,
    };

    track.click(ACTION_CLICK.SET_FILTER)
    onFilter(filters);
  };

  const onReset = () => {
    reset();
    track.click(ACTION_CLICK.RESET_FILTER)
    onFilter(defaultValues);
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={6} lg={4}>
          <Controller
            name="lastName"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Nom"
                variant="filled"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} lg={4}>
          <Controller
            name="firstName"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Prénom"
                variant="filled"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} lg={4}>
          <Controller
            name="zipCode"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Code postal"
                variant="filled"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} lg={4}>
          <ControlledSelectYearsInput control={control} name="years" />
        </Grid>
        <Grid item xs={6} lg={4}>
          <FormControl fullWidth variant="filled">
            <InputLabel id="sale-state-label" shrink={true}>
              Type
            </InputLabel>
            <Controller
              name="type"
              control={control}
              render={({ field }) => (
                <Select {...field} labelId="customers-type-label">
                  <MenuItem value="">
                    <em>Tous</em>
                  </MenuItem>
                  <MenuItem value="realEstate">Immobilier</MenuItem>
                  <MenuItem value="investment">Placement</MenuItem>
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} lg={4} container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <Button fullWidth variant="contained" startIcon={<SearchIcon />} type="submit">
              Filtrer
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button fullWidth variant="outlined" startIcon={<RefreshIcon />} onClick={() => onReset()}>
              Réinitialiser
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

CustomerToolbar.propTypes = {
  onFilter: PropTypes.func,
};

export default CustomerToolbar;
