import { LoadingButton } from "@material-ui/lab";
import PropTypes from "prop-types";
import React from "react";
import { Alert, Button, DialogActions, DialogContent, DialogContentText, Divider } from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";
import { useMutation, useQueryClient } from "react-query";
import { deletePicture } from "../../http/libraryPictures";
import AppDialog from "../AppDialog";

const DeletePicture = ({ open, onClose, picture }) => {
  const queryClient = useQueryClient();

  const onConfirm = async () => {
    mutation.mutate({ picture });
  };

  const mutation = useMutation(deletePicture, {
    onSuccess: () => {
      queryClient.invalidateQueries("getLibraryPictures");
      onClose();
    },
  });

  return (
    <AppDialog open={open} onClose={onClose} title="Supprimer une image">
      <DialogContent>
        {mutation.isError ? <Alert severity="error">Une erreur est survenue : {mutation.error.message}</Alert> : null}

        <DialogContentText id="delete-reminder-dialog-description" component="div">
          <p>Confirmez-vous la suppression de l'image ?</p>
          <p>Attention : Si l'image est attachée à une actualité il sera impossible de la supprimer</p>
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Annuler
        </Button>
        <LoadingButton
          loading={mutation.isLoading}
          variant="contained"
          color="hubnup"
          onClick={onConfirm}
          startIcon={<DeleteIcon />}
        >
          Supprimer
        </LoadingButton>
      </DialogActions>
    </AppDialog>
  );
};

DeletePicture.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  picture: PropTypes.object,
};

export default DeletePicture;
