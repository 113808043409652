import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@material-ui/lab";
import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Link,
  TextField,
  Typography,
  Alert,
} from "@material-ui/core";
import { Close as CloseIcon, Mail } from "@material-ui/icons";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import * as yup from "yup";
import { resetPasswordRequest } from "../../http/user";

const ResetPassword = () => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => setOpen(true);
  const schema = yup.object().shape({
    email: yup.string().required("Ce champ est obligatoire.").email("Cette valeur n'est pas une adresse email valide."),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
    unregister,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleClose = () => {
    unregister("email");
    setOpen(false);
  };

  const onSubmit = async ({ email }) => {
    mutation.mutate({ email: email });
  };

  const mutation = useMutation(resetPasswordRequest);

  return (
    <>
      <Link component="button" variant="body1" onClick={handleClickOpen}>
        Mot de passe oublié ?
      </Link>
      <Dialog open={open} onClose={handleClose} aria-labelledby="reset-password-title">
        <DialogTitle id="reset-password-title" disableTypography>
          <Typography variant="h4" component="div">
            Réinitialisation de mot de passe
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <DialogContentText>
              Vous êtes sur le point de réinitialiser le mot de passe de votre compte. Pour cela, veuillez saisir
              l'adresse email associée à votre compte Hubn'UP.
            </DialogContentText>
            {mutation.isError ? (
              <Alert severity="error">Une erreur est survenue : {mutation.error.message}</Alert>
            ) : null}

            {mutation.isSuccess ? (
              <Alert severity="success">Lien de réinitialisation du mot de passe envoyé par mail.</Alert>
            ) : null}
            <TextField
              error={Boolean(errors.email?.message)}
              fullWidth
              helperText={errors.email?.message}
              label="Adresse email"
              margin="normal"
              type="email"
              variant="outlined"
              autoComplete="email"
              autoFocus
              defaultValue=""
              {...register("email")}
            />
          </DialogContent>
          <Divider />
          <DialogActions>
            <LoadingButton loading={mutation.isLoading} type="submit" startIcon={<Mail />} variant="contained">
              Envoyer
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default ResetPassword;
