import React, { useMemo } from "react";
import Badge from "@material-ui/core/Badge";
import { useMediaQuery, useTheme } from "@material-ui/core";
import NotificationIcon from "./NotificationIcon";

export default function AvatarWithTwoBadges({
  toggleActionNotificationDesktop,
  toggleActionNotification,
  userNotificationsData,
  children,
}) {
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("lg"));

  // create notificationsCount here.
  const { unReadNotificationsCount, unReadNotificationIds } = useMemo(() => {
    if (!userNotificationsData)
      return { unReadNotificationsCount: 0, unReadNotificationIds: [] };

    const unreadNotifications = userNotificationsData.filter(
      (item) => !item.readByUser,
    );

    const unReadNotificationIds = unreadNotifications
      .filter((item) => item.notification.urlLink === "")
      .map((item) => item.id);

    return {
      unReadNotificationsCount: unreadNotifications.length,
      unReadNotificationIds,
    };
  }, [userNotificationsData]);

  const handleNotificationClick = () => {
    if (desktop) {
      toggleActionNotificationDesktop();
    } else {
      toggleActionNotification();
    }
  };

  return (
    <div>
      {/* two badges to add the notification icon then inside it a badge for notifications count */}
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        badgeContent={
          <NotificationIcon
            onClick={handleNotificationClick}
            count={unReadNotificationsCount}
          />
        }
      >
        {children}
      </Badge>
    </div>
  );
}
