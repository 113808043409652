import { dateToIsoString } from "../utils/formatters";
import http from "../utils/http";

export const getRemindersAll = async (filters) => {
  let url = "/reminders?type=hubnup";
  if (filters?.title) {
    url += `&title=${filters.title}`;
  }
  if (filters?.remindAt && filters?.remindAt[0]) {
    url += `&date[after]=${dateToIsoString(filters.remindAt[0])}`;
  }
  if (filters?.remindAt && filters?.remindAt[1]) {
    url += `&date[before]=${dateToIsoString(filters.remindAt[1])}`;
  }

  const response = await http.get(url);

  return response.data["hydra:member"];
};

export const getUserReminders = async (filters) => {
  let url = `/reminder_list/user`;
  let hasFilter = false;

  const filtersUrl = [];
  if (filters?.title) {
    filtersUrl.push(`title=${filters.title}`);
    hasFilter = true;
  }
  if (filters?.remindAt && filters?.remindAt[0]) {
    filtersUrl.push(`date[after]=${dateToIsoString(filters.remindAt[0])}`);
    hasFilter = true;
  }
  if (filters?.remindAt && filters?.remindAt[1]) {
    filtersUrl.push(`date[before]=${dateToIsoString(filters.remindAt[1])}`);
    hasFilter = true;
  }
  if (filters?.type) {
    filtersUrl.push(`type=${filters.type}`);
    hasFilter = true;
  }
  if (filters?.firstName) {
    filtersUrl.push(`firstName=${filters.firstName}`);
    hasFilter = true;
  }
  if (filters?.lastName) {
    filtersUrl.push(`lastName=${filters.lastName}`);
    hasFilter = true;
  }

  if (hasFilter) {
    url += "?";
  }

  filtersUrl.forEach((filter, index) => {
    url += filter;
    if (index < filtersUrl.length - 1) url += "&";
  });

  const response = await http.get(url);

  return response.data;
};

export const getReminder = async (id) => {
  const response = await http.get(`/reminders/${id}`);

  return response.data;
};

export const postReminders = async (data) => {
  if (data.reminder) {
    return patchReminders(data);
  }

  return await http.post("/reminders", data);
};

export const patchReminders = async (data) => {
  return await http.patch(data.reminder["@id"], data);
};

export const deleteReminders = async (data) => {
  let response = { status: 204 };
  for (const reminder of data) {
    if (response.status === 204) {
      response = await http.delete(reminder);
    }
  }

  return response;
};
