import PropTypes from "prop-types";
import React from "react";
import { Breadcrumbs, Typography } from "@material-ui/core";
import { NavigateNext as NavigateNextIcon } from "@material-ui/icons";

const DocumentsBreadcrumbs = ({ breadcrumbs, handleUpdateBreadCrumbs }) => {
  return (
    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" sx={{ mb: 3 }}>
      {breadcrumbs.map((folder) => {
        return (
          <Typography
            color="inherit"
            sx={{
              fontWeight: 400,
              fontSize: "1rem",
              lineHeight: 1.5,
              letterSpacing: "0.00938em",
              color: "#6b778c",
              cursor: "pointer",
            }}
            onClick={() => handleUpdateBreadCrumbs(folder)}
            key={folder.id}
          >
            {folder.name}
          </Typography>
        );
      })}
    </Breadcrumbs>
  );
};

DocumentsBreadcrumbs.propTypes = {
  breadcrumbs: PropTypes.array,
  handleUpdateBreadCrumbs: PropTypes.func,
};

export default DocumentsBreadcrumbs;
