import { colors } from "@material-ui/core";

const palette = {
  background: {
    default: "#f4f6f8",
    paper: colors.common.white,
  },
  primary: {
    contrastText: colors.common.white,
    main: "#2a6975",
  },
  secondary: {
    contrastText: colors.common.white,
    main: "#00a19a",
  },
  hubnup: {
    contrastText: colors.common.white,
    main: "#FA0071",
  },
  text: {
    primary: "#172b4d",
    secondary: "#6b778c",
  },
};

export default palette;
