import { HelpOutline } from "@material-ui/icons";
import PropTypes from "prop-types";
import React from "react";
import { Box, Grid, Skeleton, Tooltip, Typography } from "@material-ui/core";

const StatsCardValue = ({ title, value, loading, tooltip }) => {
  return (
    <Grid
      item
      xs
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography color="textPrimary" gutterBottom variant="h3" sx={{ fontSize: { xs: "20px", sm: "24px" } }}>
        {loading ? <Skeleton width={120} /> : value}
      </Typography>
      <Typography color="textSecondary" variant="h6">
        {loading ? (
          <Skeleton width={70} />
        ) : (
          <Box sx={{ display: "flex" }}>
            {title}{" "}
            {tooltip ? (
              <Tooltip title={tooltip}>
                <HelpOutline sx={{ marginLeft: "2px", fontSize: "20px" }} />
              </Tooltip>
            ) : (
              ""
            )}
          </Box>
        )}
      </Typography>
    </Grid>
  );
};

StatsCardValue.defaultProps = {
  tooltip: "",
};

StatsCardValue.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  tooltip: PropTypes.string,
  loading: PropTypes.bool,
};

export default StatsCardValue;
