import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Box, Button, Card, Container, Stack } from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import { useQuery } from "react-query";
import { Link as RouterLink, useHistory } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import GuardedComponent from "../../components/GuardedComponent";
import HubnupUsersList from "../../components/user/HubnupUsersList";
import FilterPanel from "../../components/FilterPanel";
import UsersImportModal from "../../components/user/UsersImportModal";
import UsersToolbar from "../../components/user/UsersToolbar";
import { getProfiles } from "../../http/profiles";

const HubnupUsers = () => {
  const history = useHistory();
  const [filters, setFilters] = useState({});
  const [openImportUsersModal, setOpenImportUsersModal] = useState(false);
  const { isFetched: isFetchedProfiles, data: profiles } = useQuery(["getProfiles"], () => getProfiles());

  const onFilter = (filters) => {
    setFilters(filters);
  };

  return (
    <Container maxWidth={false}>
      <Breadcrumbs />

      <Stack direction="row" spacing={2}>
        <GuardedComponent componentName="users_edit_page">
          <Button
            component={RouterLink}
            to={{ pathname: "/administration/users/create", state: { goBack: history.location.pathname } }}
            variant="contained"
            startIcon={<AddIcon />}
          >
            Créer un utilisateur
          </Button>
        </GuardedComponent>
        <GuardedComponent componentName="users_imports">
          <Button
            variant="contained"
            startIcon={<FontAwesomeIcon icon={faFileExcel} />}
            onClick={() => setOpenImportUsersModal(true)}
          >
            Importer des utilisateurs
          </Button>
        </GuardedComponent>
      </Stack>

      <FilterPanel sx={{ mt: 2 }}>
        <UsersToolbar onFilter={onFilter} profiles={profiles} />
      </FilterPanel>

      <Box sx={{ mt: 2 }}>
        <Card>
          <HubnupUsersList filters={filters} isFetchedProfiles={isFetchedProfiles} profiles={profiles} />
        </Card>
      </Box>

      <UsersImportModal
        onClose={() => setOpenImportUsersModal(false)}
        open={openImportUsersModal}
        profiles={profiles}
      />
    </Container>
  );
};

export default HubnupUsers;
