import dayjs from "dayjs";
import { getAgentByCgpId } from "../http/agents";
import store from "../store";

const formatFileName = async (fileName, agentId = null, addAgentName = true) => {
  const now = dayjs();
  const state = store.getState();

  if (addAgentName) {
    if (!agentId) {
      const fullName = state.auth.fullName.split(" ");
      fileName += `_${fullName[1]}-${fullName[0]}`;
    } else {
      const user = await getAgentByCgpId(agentId);
      fileName += `_${user.agent?.lastname}-${user.agent?.firstname}`;
    }
  }

  fileName += `_${now.format("YYYY-MM-DD_HH-mm")}`;

  return fileName;
};

export default formatFileName;
