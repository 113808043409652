import PropTypes from "prop-types";
import React from "react";
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import { Refresh as RefreshIcon, Search as SearchIcon } from "@material-ui/icons";
import { MobileDateRangePicker } from "@material-ui/lab";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTrackingContext } from "../../contexts/TrackingContext";
import { ACTION_CLICK } from "../../utils/trackingConstant";

const RemindersToolbar = ({ onFilter, fromAgent }) => {
  const schema = yup.object().shape({
    title: yup.string(),
    lastName: yup.string(),
    firstName: yup.string(),
    type: yup.string().oneOf(["", "hubnup", "personal"]),
    remindAt: yup.array().of(yup.date().nullable()),
  });
  const defaultValues = {
    title: "",
    lastName: "",
    firstName: "",
    type: "",
    remindAt: [null, null],
  };

  const { control, handleSubmit, reset, setValue } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  const track = useTrackingContext()

  const onSubmit = async (data) => {
    onFilter({
      ...data,
    });

    track.click(ACTION_CLICK.RESET_FILTER)
  };

  const onReset = () => {
    reset();
    onFilter(defaultValues);
    track.click(ACTION_CLICK.SET_FILTER)
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={fromAgent ? 6 : 4} md={fromAgent ? 6 : 3} lg={3}>
          <Controller
            name="title"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Rappel"
                variant="filled"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
        {fromAgent && (
          <>
            <Grid item xs={4} lg={3}>
              <Controller
                name="lastName"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Nom client"
                    variant="filled"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4} lg={3}>
              <Controller
                name="firstName"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Prénom client"
                    variant="filled"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>
          </>
        )}
        {fromAgent && (
          <Grid item xs={12} sm={6} lg={3}>
            <FormControl fullWidth variant="filled">
              <InputLabel id="reminders-type-label" shrink={true}>
                Type
              </InputLabel>
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <Select {...field} labelId="reminders-type-label">
                    <MenuItem value="">
                      <em>Tous</em>
                    </MenuItem>
                    <MenuItem value="hubnup">Hubn'UP</MenuItem>
                    <MenuItem value="personal">Personnel</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
        )}
        <Grid item xs={12} sm={fromAgent ? 6 : 8} md={fromAgent ? 6 : 5}>
          <Controller
            name="remindAt"
            control={control}
            onChange={(event, newValue) => setValue("remindAt", newValue)}
            render={({ field }) => (
              <MobileDateRangePicker
                {...field}
                startText="Date du"
                endText="au"
                inputFormat="DD/MM/YYYY"
                allowSameDateSelection
                okText="Valider"
                cancelText="Annuler"
                toolbarTitle="Choisissez une plage de date"
                renderInput={(startProps, endProps) => (
                  <>
                    <TextField
                      {...startProps}
                      fullWidth
                      variant="filled"
                      InputLabelProps={{ shrink: true }}
                      helperText=""
                    />
                    <Typography sx={{ mx: 2 }} variant="body1">
                      {" "}
                      -{" "}
                    </Typography>
                    <TextField
                      {...endProps}
                      fullWidth
                      variant="filled"
                      InputLabelProps={{ shrink: true }}
                      helperText=""
                    />
                  </>
                )}
              />
            )}
          />
        </Grid>
        {!fromAgent && <Grid item xl={1} display={{ xs: "none", xl: "block" }} />}
        <Grid
          item
          xs={12}
          sm={6}
          md={fromAgent ? 6 : 4}
          xl={fromAgent ? 6 : 3}
          container
          spacing={2}
          alignItems="center"
        >
          <Grid item xs={6}>
            <Button fullWidth variant="contained" startIcon={<SearchIcon />} type="submit">
              Filtrer
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button fullWidth variant="outlined" startIcon={<RefreshIcon />} onClick={() => onReset()}>
              Réinitialiser
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

RemindersToolbar.defaultProps = {
  fromAgent: false,
};

RemindersToolbar.propTypes = {
  fromAgent: PropTypes.bool,
  onFilter: PropTypes.func,
};

export default RemindersToolbar;
