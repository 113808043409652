import http from "../utils/http";

export const getProfiles = async (filters = {}) => {
  let url = "/profiles";

  if (filters) {
    url += "?";
  }

  if (filters.type) {
    url += `type=${filters.type}`;
  }

  const response = await http.get(url);

  return response.data["hydra:member"];
};

export const postProfile = async (data) => {
  const response = await http.post("/profiles", data);

  return response.data;
};

export const removeProfile = async (idProfile) => {
  const response = await http.delete(`/profiles/${idProfile}`);

  return response.data;
};

export const getOdyssealMappingsRoles = () => {
  return http.get("/odysseal/roles").then((resp) => resp.data);
};

export const updateOdyssealMappingsRoles = (values) => {
  return http.patch("/odysseal/roles", values).then((resp) => resp.data);
};
