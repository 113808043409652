import React from "react";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { faFolder } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import DocumentIcon from "./DocumentIcon";

const DocumentsListFiltered = ({ documentsToShow, handleNavigateToFolderFiltered }) => {
  const classes = useStyles();

  return (
    <List
      sx={{ width: "100%", bgcolor: "background.paper", padding: "0 0 16px 0" }}
      subheader={
        <ListSubheader
          key="header"
          sx={{
            borderWidth: "0px 0px thin",
            borderStyle: "solid",
            borderColor: "rgba(0, 0, 0, 0.12)",
            paddingLeft: "36px",
            paddingRight: "36px",
            display: "flex",
            height: "55px",
            alignItems: "center",
            backgroundColor: "#00a19a",
            color: "#FFFFFF",
          }}
        >
          <ListItemText
            id="header-text"
            primary={
              <Box
                sx={{
                  display: "flex",
                  cursor: "pointer",
                  alignItems: "center",
                }}
              >
                <Typography color="inherit" sx={{ marginRight: "5px" }}>
                  Nom
                </Typography>
              </Box>
            }
          />
        </ListSubheader>
      }
    >
      {documentsToShow.map((document) => {
        const labelId = `checkbox-list-label-${document.id}`;

        return (
          <ListItem
            key={document.id}
            sx={{
              borderWidth: "0px 0px thin",
              borderStyle: "solid",
              borderColor: "rgba(0, 0, 0, 0.12)",
              paddingLeft: "36px",
              paddingRight: "36px",
              cursor: "pointer",
            }}
            onClick={() => handleNavigateToFolderFiltered(document)}
          >
            {document.contentUrl ? (
              <>
                <ListItemIcon>
                  <DocumentIcon document={document} classes={classes} />
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  primary={document.displayName ?? document.filePath}
                  secondary={document.breadcrumbsString}
                />
              </>
            ) : (
              <>
                <ListItemIcon>
                  <FontAwesomeIcon icon={faFolder} className={classes.documentIcon} />
                </ListItemIcon>
                <ListItemText id={labelId} primary={document.name} secondary={document.breadcrumbsString} />
              </>
            )}
          </ListItem>
        );
      })}
      {documentsToShow.length === 0 && (
        <ListItem
          key="no-file"
          sx={{
            borderWidth: "0px 0px thin",
            borderStyle: "solid",
            borderColor: "rgba(0, 0, 0, 0.12)",
            paddingLeft: "36px",
            paddingRight: "36px",
            paddingY: "16px",
          }}
        >
          Aucun résultat dans d'autres dossiers n'a été trouvé.
        </ListItem>
      )}
    </List>
  );
};

const useStyles = makeStyles((theme) => ({
  documentIcon: {
    fontSize: "26px",
  },
  pdfIcon: {
    color: "hsl(0, 100%, 50%)",
  },
  excelIcon: {
    color: "hsl(147, 99%, 27%)",
  },
  docIcon: {
    color: "hsl(221, 44%, 59%)",
  },
}));

DocumentsListFiltered.propTypes = {
  documentsToShow: PropTypes.array,
  handleNavigateToFolderFiltered: PropTypes.func,
};

export default DocumentsListFiltered;
