import React from "react";
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import { AppBar, Box, IconButton, Toolbar } from "@material-ui/core";
import { Menu as MenuIcon } from "@material-ui/icons";
import Logo from "../Logo";
import ImpersonateUserMenuInfo from "./ImpersonateUserMenuInfo";
import SocialMenuLink from "./SocialMenuLink";

const DashboardNavbar = ({ homePath, onMobileNavOpen, children }) => {
  const home = homePath ?? "/";

  return (
    <AppBar elevation={0} {...children}>
      <Toolbar>
        <RouterLink to={home}>
          <Logo style={{ height: 60, filter: "brightness(0) invert(1)" }} />
        </RouterLink>
        <Box flexGrow={1} />
        <Box sx={{ display: { xs: "none", lg: "flex" }, alignItems: "center" }}>
          <ImpersonateUserMenuInfo />
          <SocialMenuLink />
        </Box>
        <Box sx={{ display: { lg: "none", xs: "flex" } }}>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

DashboardNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func,
  homePath: PropTypes.string,
};

const mapStateToProps = (state) => ({
  homePath: state.auth.homePath,
  isImpersonateUser: state.auth.isImpersonateUser,
  profilName: state.auth.profilName,
});

export default connect(mapStateToProps, null)(DashboardNavbar);
