import http from "../utils/http";

export const getLibraryPictures = async (title) => {
  let url = "/image_banks";
  if (title) {
    url += `?title=${title}`;
  }
  const response = await http.get(url);

  return response.data["hydra:member"];
};

export const postPicture = async (data) => {
  let mediaObject = data.mediaObject ? data.mediaObject["@id"] : null;
  // if file
  if (data.formData) {
    // create new media
    const responseMediaObject = await http.post("/media_objects", data.formData);
    if (responseMediaObject.status !== 201) {
      return responseMediaObject.data;
    }

    mediaObject = responseMediaObject.data["@id"];
  }

  // if create mode
  if (!data.picture) {
    const response = await http.post("/image_banks", {
      title: data.title,
      mediaObject: mediaObject,
    });

    return response.data;
  }

  const response = await http.patch(data.picture["@id"], {
    title: data.title,
    mediaObject: mediaObject,
  });

  // if edit mode delete old media
  if (data.formData && data.mediaObject) {
    await http.delete(data.mediaObject["@id"]);
  }

  return response.data;
};

export const getPictureMedia = async (uri) => {
  const response = await http.get(uri);

  return response.data;
};

export const deletePicture = async (data) => {
  const response = await http.delete(data.picture["@id"]);

  return response.data;
};
