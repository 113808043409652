import PropTypes from "prop-types";
import React from "react";
import { Button, DialogActions, DialogContent, DialogContentText, Divider } from "@material-ui/core";
import AppDialog from "../AppDialog";

const DisabledModal = ({ open, onClose, user }) => {
  return (
    <AppDialog open={open} onClose={onClose} title="Compte suspendu">
      <DialogContent>
        {user && (
          <DialogContentText id="reset-password-dialog-description">
            Votre compte a été temporairement suspendu. Pour toute question, nous vous invitons à nous contacter par
            mail à l'adresse suivante : <a href="mailto:support@hubnup.fr">support@hubnup.fr</a>
          </DialogContentText>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Fermer
        </Button>
      </DialogActions>
    </AppDialog>
  );
};

DisabledModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  user: PropTypes.object,
};

export default DisabledModal;
