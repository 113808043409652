import * as actionTypes from "../actions/actionTypes";
import homePageList from "../../router/HomePageList";

const initialState = {
  token: null,
  fullName: null,
  email: null,
  picture: null,
  roles: [],
  redirectPath: null,
  components: [],
  homePath: "/login",
  userId: null,
  consultantId: null,
  cgpId: null,
  onLoginAgent: false,
  consultantValoricielId: null,
  circularPicture: true,
  isImpersonateUser: false,
  profilName: null,
  adminData: null,
  consultantSharepointLink: null,
  menuLinks: null,
};

const authSuccess = (state, action) => {
  let homePage = null;
  const item = action.auth.components?.find(
    (component) => component.search("_page") && homePageList.hasOwnProperty(component)
  );
  if (item) {
    homePage = homePageList[item];
  }

  return {
    ...state,
    token: action.auth.token,
    fullName: action.auth.fullName,
    email: action.auth.email,
    roles: action.auth.roles,
    picture: action.auth.picture,
    components: action.auth.components,
    homePath: homePage ?? "/",
    userId: action.auth.id,
    consultantId: action.auth.consultantId,
    cgpId: action.auth.cgpId,
    onLoginAgent: action.auth.onLoginAgent,
    consultantValoricielId: action.auth.consultantValoricielId,
    circularPicture: action.auth.circularPicture,
    profilName: action.auth.profilName,
    profileType: action.auth.profileType,
    isImpersonateUser: action.auth?.isImpersonateUser,
    adminData: action.auth?.adminData,
    consultantSharepointLink: action.auth?.consultantSharepointLink,
    menuLinks: action.auth?.menuLinks,
    sessionId: action.auth.sessionId,
  };
};

const authLogout = (state, action) => {
  return {
    ...initialState,
    redirectPath: state.redirectPath,
  };
};

const authSetRedirectPath = (state, action) => {
  return {
    ...state,
    redirectPath: action.path,
  };
};

const updatePicture = (state, action) => {
  return {
    ...state,
    picture: action.picture,
  };
};

const updateCircularPicture = (state, action) => {
  return {
    ...state,
    circularPicture: action.circularPicture,
  };
};

const updateLoginAgent = (state, action) => {
  return {
    ...state,
    onLoginAgent: false,
  };
};

const impersonateUser = (state, action) => {
  const currentUserState = { ...state };

  let homePage = null;
  const item = action.data.components?.find(
    (component) => component.search("_page") && homePageList.hasOwnProperty(component)
  );
  if (item) {
    homePage = homePageList[item];
  }

  const onLoginAgent = action.data.profileType === 'Mandataire';

  const auth = {
    ...state,
    isImpersonateUser: true,
    adminData: currentUserState,
    ...action.data,
    userId: action.data.id,
    onLoginAgent: onLoginAgent,
    homePath: homePage ?? "/",
  };

  localStorage.setItem("auth", JSON.stringify(auth));

  return auth;
};

const returnFromImpersonateUser = (state, action) => {
  const auth = { ...state.adminData };
  localStorage.setItem("auth", JSON.stringify(auth));

  return auth;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action);
    case actionTypes.AUTH_SET_REDIRECT_PATH:
      return authSetRedirectPath(state, action);
    case actionTypes.UPDATE_PICTURE:
      return updatePicture(state, action);
    case actionTypes.UPDATE_CIRCULAR_PICTURE:
      return updateCircularPicture(state, action);
    case actionTypes.UPDATE_LOGIN_AGENT:
      return updateLoginAgent(state, action);
    case actionTypes.IMPERSONATE_USER:
      return impersonateUser(state, action);
    case actionTypes.RETURN_FROM_IMPERSONATE_USER:
      return returnFromImpersonateUser(state, action);
    default:
      return state;
  }
};

export default reducer;
