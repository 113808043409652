import React from "react";
import Documentation from "../Documentation";
import { getTrainersClubFolders } from "../../http/documentations";

const LibraryTrainersClub = (_) => {
  return (
    <Documentation
      foldersQuery={getTrainersClubFolders}
      componentName="trainers_club_documentation_page_edit"
      type="trainers-club"
    />
  );
};

export default LibraryTrainersClub;
