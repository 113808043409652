import http from "../utils/http";

export const getAgentComponents = async () => {
  const response = await http.get("/components");

  return response.data["hydra:member"];
};

export const putComponent = async (component) => {
  const response = await http.put(`/components/${component.id}`, component);

  return response.data;
};
