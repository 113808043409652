import React from "react";
import { Tooltip } from "@material-ui/core";

const ValueLabelComponent = ({ children, value }) => (
  <Tooltip enterTouchDelay={0} placement="top" title={value}>
    {children}
  </Tooltip>
);

export default ValueLabelComponent;
