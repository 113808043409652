import React from "react";
import { Card, CardHeader, Container, Divider, Typography } from "@material-ui/core";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import AppCircularProgress from "../../components/AppCircularProgress";
import Breadcrumbs from "../../components/Breadcrumbs";
import AgentRemindersFormContent from "../../components/reminder/AgentRemindersFormContent";
import { getReminder } from "../../http/reminders";

const AgentRemindersForm = () => {
  const { id } = useParams();
  const { isFetching, data: reminder } = useQuery(["getReminder", id], () => getReminder(id), {
    enabled: !!id,
  });

  return (
    <Container maxWidth={false}>
      <Breadcrumbs
        append={<Typography color="textPrimary">{id ? "Editer un rappel" : "Créer un rappel"}</Typography>}
      />

      <Card>
        <CardHeader title={id ? "Editer un rappel" : "Créer un rappel"} />
        <Divider />
        {id ? (
          !isFetching ? (
            <AgentRemindersFormContent reminder={reminder} />
          ) : (
            <AppCircularProgress />
          )
        ) : (
          <AgentRemindersFormContent />
        )}
      </Card>
    </Container>
  );
};

export default AgentRemindersForm;
