import formatFileName from "../utils/formatFileName";
import { dateToIsoString } from "../utils/formatters";
import http from "../utils/http";
import openFile from "../utils/openFile";

export const getSalesVlps = async (filters, page, order, agentId) => {
  let url = "/vlps";

  url = applyFilters(filters, order, url, page, agentId);

  const response = await http.get(url);

  return response.data;
};

export const getExportExcelVlps = async (data) => {
  const filters = data.filters;
  const order = data.order;
  const agentId = data?.agentId;
  let url = "/vlps/export";
  url = applyFilters(filters, order, url, null, agentId);

  let fileName = await formatFileName("vlp", agentId);

  return await openFile(url, `${fileName}.xlsx`, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
};

export const getSalesVlpSummary = async (agentId) => {
  const year = new Date().getFullYear();
  let url = `/vlps/year/${year}`;

  if (agentId) {
    url += `?cgpId=${agentId}`;
  }

  const response = await http.get(url);

  return response.data["hydra:member"][0];
};

const applyFilters = (filters, order, url, page, agentId) => {
  let hasFilter = false;

  const filtersUrl = [];
  if (page) {
    filtersUrl.push(`page=${page + 1}`);
    hasFilter = true;
  }
  if (filters?.agent) {
    filtersUrl.push(`consultant.id=${filters.agent}`);
    hasFilter = true;
  }
  if (filters?.client) {
    filtersUrl.push(`client.id=${filters.client}`);
    hasFilter = true;
  }
  if (filters?.dateImportPayplan && filters?.dateImportPayplan[0]) {
    filtersUrl.push(`dateImportPayplan[after]=${dateToIsoString(filters.dateImportPayplan[0])}`);
    hasFilter = true;
  }
  if (filters?.dateImportPayplan && filters?.dateImportPayplan[1]) {
    filtersUrl.push(`dateImportPayplan[before]=${dateToIsoString(filters.dateImportPayplan[1])}`);
    hasFilter = true;
  }
  if (filters?.exercice) {
    filtersUrl.push(`exercice=${filters.exercice}`);
    hasFilter = true;
  }
  if (order && order[0]) {
    const orderField = order[0];
    const fieldName = orderField.field;

    if (fieldName === "agentFullName") {
      filtersUrl.push(`order[consultant.lastName]=${orderField.sort}`);
      filtersUrl.push(`order[consultant.firstName]=${orderField.sort}`);
    } else if (fieldName === "clientFullName") {
      filtersUrl.push(`order[client.lastName]=${orderField.sort}`);
      filtersUrl.push(`order[client.firstName]=${orderField.sort}`);
    } else {
      filtersUrl.push(`order[${orderField.field}]=${orderField.sort}`);
    }
    hasFilter = true;
  }
  if (agentId) {
    filtersUrl.push(`cgpId=${agentId}`);
    hasFilter = true;
  }

  if (hasFilter) {
    url += "?";
  }

  filtersUrl.forEach((filter, index) => {
    url += filter;
    if (index < filtersUrl.length - 1) url += "&";
  });

  return url;
};
