import PropTypes from "prop-types";
import React from "react";
import { DataGrid } from "@material-ui/data-grid";
import { CardContent, Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import checkAccessStore from "../../utils/checkAccessStore";
import { dateColumn, eurColumn, percentColumn } from "../../utils/dataGridColumns";
import renderCellExpand from "../../utils/renderCellExpand";

const CustomersDetailsInvestmentList = ({ sales, isFetching, fromAgent }) => {
  const salesInvestment = sales ? Object.values(sales).filter((sale) => !!sale.id) : [];

  const hubnupColumns = [
    {
      field: "agentFullName",
      headerName: "Mandataire",
      width: 160,
      valueGetter: (params) => params.row?.consultant?.fullName,
      renderCell: (params) =>
        renderCellExpand(
          params,
          true,
          checkAccessStore("team_detail_page") ? (
            <Link component={RouterLink} to={`/team/${params.row?.consultant?.idCgp}`}>
              {params.value}
            </Link>
          ) : (
            params.value
          )
        ),
    },
  ];

  const baseColumns = [
    {
      field: "productName",
      headerName: "Produit",
      flex: 1,
      valueGetter: (params) => params.row.typeProduitsFin?.productName,
      renderCell: renderCellExpand,
    },
    {
      field: "providerName",
      headerName: "Fournisseur",
      flex: 1,
      valueGetter: (params) => params.row.typeProduitsFin?.providerName,
      renderCell: renderCellExpand,
    },
    { field: "amount", ...eurColumn, headerName: "Montant", flex: 1, renderCell: renderCellExpand },
    { field: "fee", ...percentColumn, headerName: "Frais", flex: 1, renderCell: renderCellExpand },
    {
      field: "subscriptionDate",
      ...dateColumn,
      headerName: "Souscription fournisseur",
      flex: 1,
      renderCell: renderCellExpand,
    },
    { field: "orderNumber", headerName: "N° ordre", flex: 1, renderCell: renderCellExpand },
  ];

  const columns = fromAgent ? baseColumns : hubnupColumns.concat(baseColumns);
  const sortModel = [{ field: "subscriptionDate", sort: "desc" }];

  return (
    <CardContent>
      <DataGrid
        loading={isFetching}
        rows={salesInvestment ?? []}
        columns={columns}
        sortModel={sortModel}
        pageSize={10}
        autoHeight
        disableColumnFilter
        disableSelectionOnClick
        disableColumnMenu
        disableColumnReorder
      />
    </CardContent>
  );
};

CustomersDetailsInvestmentList.defaultProps = {
  fromAgent: false,
};

CustomersDetailsInvestmentList.propTypes = {
  sales: PropTypes.array,
  isFetching: PropTypes.bool,
  fromAgent: PropTypes.bool,
};

export default CustomersDetailsInvestmentList;
