import { LoadingButton } from "@material-ui/lab";
import React, { useState } from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  Stack,
} from "@material-ui/core";
import { Download as DownloadIcon } from "@material-ui/icons";
import { useMutation } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import FilterPanel from "../../components/FilterPanel";
import GuardedComponent from "../../components/GuardedComponent";
import TeamToolbar from "../../components/team/TeamToolbar";
import { getExportSponsorsV2 } from "../../http/agents";
import CgpSalesSummary from "../../components/team/CgpSalesSummary";
import TeamListV2 from "../../components/team/TeamListV2";
import { useTrackingContext } from "../../contexts/TrackingContext";
import { ACTION_CLICK } from "../../utils/trackingConstant";

const HubnupTeam = () => {
  const history = useHistory();
  const { agentId } = useParams();
  const [filters, setFilters] = useState({});
  const track = useTrackingContext()

  const onFilter = (filters) => {
    setFilters(filters);
  };

  const handleExportExcel = () => {
    track.click(ACTION_CLICK.EXPORT_TEAM_VA)
    mutation.mutate(filters, agentId);
  };

  const mutation = useMutation(getExportSponsorsV2);

  const goBack = () => {
    history.goBack();
  };

  return (
    <Container maxWidth={false}>
      <Breadcrumbs />

      <CgpSalesSummary agentId={agentId} />

      <GuardedComponent componentName="team_export">
        <Stack direction="row" spacing={2}>
          <LoadingButton
            loading={mutation.isLoading}
            color="primary"
            variant="contained"
            startIcon={<DownloadIcon />}
            onClick={handleExportExcel}
          >
            Exporter
          </LoadingButton>
        </Stack>
      </GuardedComponent>

      <FilterPanel sx={{ mt: 2 }}>
        <TeamToolbar onFilter={onFilter} agentId={agentId} />
      </FilterPanel>

      {agentId && (
        <GuardedComponent componentName="team_direct_godchild">
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Card>
              <CardHeader title="Ses filleuls directs" />
              <Divider />
              <CardContent sx={{ p: 1 }}>
                <TeamListV2 oneLevel cgpId={agentId} />
              </CardContent>
            </Card>
          </Grid>
        </GuardedComponent>
      )}

      <GuardedComponent componentName="team_direct_godchild">
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Card>
            <CardHeader title="Toutes les équipes" />
            <Divider />
            <CardContent sx={{ p: 1 }}>
              <TeamListV2 cgpId={agentId} />
            </CardContent>
          </Card>
        </Grid>
      </GuardedComponent>

      {agentId && (
        <Stack
          direction={{ xs: "column-reverse", sm: "row" }}
          spacing={2}
          sx={{
            p: 2,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button variant="outlined" onClick={goBack}>
            Retour
          </Button>
        </Stack>
      )}
    </Container>
  );
};

export default HubnupTeam;
