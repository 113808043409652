import React from "react";
import { CircularProgress, Container, Grid, makeStyles } from "@material-ui/core";
import { useQuery } from "react-query";
import Breadcrumbs from "../../components/Breadcrumbs";
import GuardedComponent from "../../components/GuardedComponent";
import AgentComponents from "../../components/hubnupDashboard/AgentComponents";
import HubnupComponent from "../../components/hubnupDashboard/HubnupComponent";
import PortailSettings from "../../components/hubnupDashboard/PortailSettings";
import { getProfiles } from "../../http/profiles";

const HubnupPortail = () => {
  const classes = useStyles();
  const { data, isFetched, isFetching } = useQuery("getProfiles", getProfiles);

  return (
    <Container maxWidth={false}>
      <Breadcrumbs />
      
      <Grid container spacing={2}>
        <GuardedComponent componentName="portail_settings">
          <Grid item xs={12}>
            <PortailSettings />
          </Grid>
        </GuardedComponent>
        {isFetched && !isFetching ? (
          <>
            <GuardedComponent componentName="portail_agents_components">
              <Grid item xs={12}>
                <AgentComponents profiles={data} />
              </Grid>
            </GuardedComponent>
            <GuardedComponent componentName="portail_hubnup_components">
              <Grid item xs={12}>
                <HubnupComponent profiles={data} />
              </Grid>
            </GuardedComponent>
          </>
        ) : (
          <CircularProgress className={classes.loader} />
        )}
      </Grid>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  actionWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
  loader: {
    margin: "2rem auto",
    display: "flex",
  },
}));

export default HubnupPortail;
