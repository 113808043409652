import { faLessThan } from "@fortawesome/free-solid-svg-icons";
import { isDateAfterOneWeekAgo } from "../formatters";

export const renderViewFileIcon = (document) => {
  const fileExt = document.filePath.split(".").pop();

  return ["jpeg", "png", "gif", "jpg", "svg", "pdf"].includes(fileExt);
};

export const personName = (document) => {
  if (document.person) {
    return `${document.person?.lastName} ${document.person?.firstName}`;
  } else {
    return "hubnup";
  }
};

export const folderHasRecentFile = (documentFolder, mediaObjectList) => {
  if (!documentFolder.mediaObjects) {
    return false;
  }

  return documentFolder.mediaObjects.some((mediaObject) => {
    if (!isDateAfterOneWeekAgo(mediaObject.createdAt)) {
      return false;
    }

    const mediaObjectListItem = findMediaObjectInMediaObjectList(mediaObject.id, mediaObjectList);
    if (!mediaObjectListItem) {
      return false;
    }

    return !readStatusForDocument(mediaObject.id, mediaObjectList);
  });
};

export const readStatusForDocument = (documentFileId, mediaObjectList) => {
  const mediaObjectItem = mediaObjectList.find((mediaObjetListItem) => mediaObjetListItem.mediaObject.id === documentFileId);

  if (!mediaObjectItem) {
    return false;
  }

  return mediaObjectItem.mediaObject.readByUser;
}

export const findMediaObjectInMediaObjectList = (documentFileId, mediaObjectList) => {
  return mediaObjectList.find((mediaObjetListItem) => mediaObjetListItem.mediaObject.id === documentFileId);
}


