import React from "react";
import { Container, Grid } from "@material-ui/core";
import Breadcrumbs from "../../components/Breadcrumbs";
import GuardedComponent from "../../components/GuardedComponent";
// import SalesQualification from '../../components/sales/SalesQualification';
import SalesRealEstateSummary from "../../components/sales/SalesRealEstateSummary";
import SalesInvestmentSummary from "../../components/sales/SalesInvestmentSummary";
import SalesStatistics from "../../components/sales/SalesStatistics";
import checkAccessStore from "../../utils/checkAccessStore";

const SalesSummary = () => {
  return (
    <Container maxWidth={false}>
      <Breadcrumbs />

      <Grid container spacing={2}>
        {/*// Todo*/}
        {/*<GuardedComponent componentName="sales_summary_qualification">*/}
        {/*  <Grid*/}
        {/*    item*/}
        {/*    xs={12}*/}
        {/*  >*/}
        {/*    <SalesQualification*/}
        {/*      sales={sales}*/}
        {/*      loading={loading}*/}
        {/*    />*/}
        {/*  </Grid>*/}
        {/*</GuardedComponent>*/}

        <GuardedComponent componentName="sales_summary_real_estate">
          <Grid item md={checkAccessStore("sales_summary_investment") ? 6 : 12} xs={12}>
            <SalesRealEstateSummary />
          </Grid>
        </GuardedComponent>

        <GuardedComponent componentName="sales_summary_investment">
          <Grid item md={checkAccessStore("sales_summary_real_estate") ? 6 : 12} xs={12}>
            <SalesInvestmentSummary />
          </Grid>
        </GuardedComponent>

        <GuardedComponent componentName="sales_summary_stats">
          <Grid item xs={12}>
            <SalesStatistics />
          </Grid>
        </GuardedComponent>
      </Grid>
    </Container>
  );
};

export default SalesSummary;
