import PropTypes from "prop-types";
import React from "react";
import { DataGrid } from "@material-ui/data-grid";
import { Box, IconButton, Link } from "@material-ui/core";
import { Delete as DeleteIcon, Edit as EditIcon, Visibility as VisibilityIcon } from "@material-ui/icons";
import { Link as RouterLink, useHistory } from "react-router-dom";
import checkAccessStore from "../../utils/checkAccessStore";
import { dateColumn } from "../../utils/dataGridColumns";
import renderCellExpand from "../../utils/renderCellExpand";
import GuardedComponent from "../GuardedComponent";
import clsx from "clsx";

const AgentRemindersList = ({ reminders, loading, selectedReminders, onSelect, onDelete }) => {
  const history = useHistory();

  const columns = [
    {
      field: "title",
      headerName: "Rappel",
      flex: 4,
      cellClassName: (params) =>
        clsx("grid-reminder", {
          hubnup: params.row.type === "hubnup",
          personal: params.row.type === "personal",
        }),
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      valueFormatter: (params) => {
        return params.value === "hubnup" ? "Hubn'UP" : "Personnel";
      },
    },
    {
      field: "prospect",
      headerName: "Client",
      flex: 1,
      renderCell: (params) =>
        renderCellExpand(
          params,
          true,
          params.row?.clientId && checkAccessStore("customers_detail_page") ? (
            <Link component={RouterLink} to={`/my-customers/${params.row.clientId}`}>
              {params.value}
            </Link>
          ) : (
            params.value
          )
        ),
    },
    { field: "date", ...dateColumn, headerName: "Date", flex: 1 },
    {
      field: "actions",
      headerName: " ",
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "space-around" }}>
          <GuardedComponent componentName="reminders_detail_page">
            <IconButton
              aria-label="Voir le rappel"
              size="small"
              component={RouterLink}
              to={{ pathname: `/my/reminders/${params.row.reminder.id}`, state: { goBack: history.location.pathname } }}
            >
              <VisibilityIcon />
            </IconButton>
          </GuardedComponent>
          <GuardedComponent componentName="reminders_edit_page">
            {params.row.type !== "hubnup" && (
              <IconButton
                aria-label="Modifier le rappel"
                size="small"
                component={RouterLink}
                to={{
                  pathname: `/my/reminders/${params.row.reminder.id}/edit`,
                  state: { goBack: history.location.pathname },
                }}
              >
                <EditIcon />
              </IconButton>
            )}
            <IconButton aria-label="Supprimer le rappel" size="small" onClick={() => onDelete(params.id)}>
              <DeleteIcon />
            </IconButton>
          </GuardedComponent>
        </Box>
      ),
    },
  ];

  const sortModel = [{ field: "date", sort: "desc" }];

  return (
    <DataGrid
      loading={loading}
      rows={reminders}
      columns={columns.map((column) => ({
        ...column,
        disableClickEventBubbling: true,
      }))}
      sortModel={sortModel}
      pageSize={10}
      autoHeight
      checkboxSelection
      disableColumnFilter
      disableSelectionOnClick
      disableColumnMenu
      disableColumnReorder
      onSelectionModelChange={(selection) => onSelect(selection.selectionModel)}
      selectionModel={selectedReminders}
    />
  );
};

AgentRemindersList.propTypes = {
  reminders: PropTypes.array,
  loading: PropTypes.bool,
  onSelect: PropTypes.func,
  selectedReminders: PropTypes.array,
  onDelete: PropTypes.func,
};

export default AgentRemindersList;
