import React, { useMemo } from "react";
import ReactFlow, { Handle, Position } from "react-flow-renderer";
import {
  Check as CheckIcon,
  Autorenew as AutorenewIcon,
  PriorityHigh as PriorityHeightIcon,
  Clear as ClearIcon,
} from "@material-ui/icons";
import { Box, CircularProgress, Divider, Typography } from "@material-ui/core";
import styled from "@emotion/styled";
import { useQuery } from "react-query";

import { getReportingRegister } from "../../http/reportingRegister";

const spinAnimation = `
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const CheckingIcon = styled(AutorenewIcon)`
  animation: spin 2s infinite linear;
  ${spinAnimation}
`;

const STATUS = {
  FINISHED: "Terminé",
  CURRENT: "En cours",
  WAITING: "En attente",
  ABORTED: "Annulé",
};

const STATUS_COLOR = {
  [STATUS.FINISHED]: "#FFF",
  [STATUS.CURRENT]: "#a3baf7",
  [STATUS.WAITING]: "#f5e49f",
  [STATUS.ABORTED]: "#f5af9f",
};

const ITEM_STATUS = {
  CHECK: "Validé",
  CHECKING: "En cours de validation",
  MISSING: "manquant",
  REJECT: "Refusé",
};

const ITEM_STATUS_ICONS = {
  [ITEM_STATUS.CHECK]: <CheckIcon sx={{ color: "green" }} />,
  [ITEM_STATUS.CHECKING]: <CheckingIcon />,
  [ITEM_STATUS.MISSING]: <PriorityHeightIcon sx={{ color: "orange" }} />,
  [ITEM_STATUS.REJECT]: <ClearIcon sx={{ color: "red" }} />,
};

const nodes = [
  {
    id: "1",
    position: { x: 0, y: -18.9 },
    type: "customInput",
  },
  {
    id: "2",
    position: { x: 250, y: 5 },
    type: "custom",
  },
  {
    id: "3",
    position: { x: 500, y: -110 },
    type: "customOutput",
  },
  {
    id: "4",
    position: { x: 500, y: -19 },
    type: "custom",
  },
  {
    id: "5",
    position: { x: 500, y: 160 },
    type: "customOutput",
  },
  {
    id: "6",
    position: { x: 750, y: -40 },
    type: "custom",
  },
  {
    id: "7",
    position: { x: 750, y: 110 },
    type: "custom",
  },
  {
    id: "8",
    position: { x: 1000, y: 36 },
    type: "custom",
  },
  {
    id: "9",
    position: { x: 1250, y: -10 },
    type: "custom",
  },
  {
    id: "10",
    position: { x: 1250, y: 90 },
    type: "custom",
  },
  {
    id: "c",
    position: { x: 1500, y: 55 },
    type: "connector",
  },
  {
    id: "11",
    position: { x: 1550, y: -25 },
    type: "customOutput",
  },
  {
    id: "12",
    position: { x: 1550, y: 82 },
    type: "customOutput",
  },
];

const edges = [
  { id: "e1-2", source: "1", target: "2", type: "step" },
  { id: "e2-3", source: "2", target: "3", type: "step" },
  { id: "e2-4", source: "2", target: "4", type: "step" },
  { id: "e2-5", source: "2", target: "5", type: "step" },
  { id: "e4-6", source: "4", target: "6", type: "step" },
  { id: "e4-7", source: "4", target: "7", type: "step" },
  { id: "e6-8", source: "6", target: "8", type: "step" },
  { id: "e7-8", source: "7", target: "8", type: "step" },
  { id: "e8-9", source: "8", target: "9", type: "step" },
  { id: "e8-10", source: "8", target: "10", type: "step" },
  { id: "e9-c", source: "9", target: "c", type: "step" },
  { id: "e10-c", source: "10", target: "c", type: "step" },
  { id: "ec-11", source: "c", target: "11", type: "step" },
  { id: "ec-12", source: "c", target: "12", type: "step" },
];

const CustomNode = ({ data: { title, items, status }, type }) => {
  return (
    <div
      className="react-flow__node-default"
      style={{ backgroundColor: STATUS_COLOR[status], width: "200px" }}
    >
      {type !== "customInput" && (
        <Handle type="target" position={Position.Left} />
      )}
      {type !== "customOutput" && (
        <Handle type="source" position={Position.Right} />
      )}
      <Typography component="h3">{title}</Typography>
      {items?.length && (
        <ul style={{ paddingTop: "1rem" }}>
          {items.map((item, index) => (
            <Typography
              key={index}
              component="li"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              fontSize=".8rem"
            >
              {item.label}
              {ITEM_STATUS_ICONS[item.status]}
            </Typography>
          ))}
        </ul>
      )}
    </div>
  );
};

const ConnectorNode = () => {
  return (
    <>
      <Handle
        type="source"
        position={Position.Left}
        style={{ visibility: "hidden" }}
      />
      <Handle
        type="target"
        position={Position.Right}
        style={{ visibility: "hidden" }}
      />
      <pre style={{ visibility: "hidden" }}> </pre>
    </>
  );
};

const nodeTypes = {
  custom: CustomNode,
  customInput: CustomNode,
  customOutput: CustomNode,
  connector: ConnectorNode,
};

const AgentReportingRegisterFlow = ({ agent, steps }) => {
  const query = useQuery({
    queryKey: [
      "agent-flow",
      { firstName: agent.firstName, lastName: agent.lastName },
    ],
    queryFn: () =>
      getReportingRegister(agent.firstName, agent.lastName).then(
        (resp) => resp.data,
      ),
    enabled: Boolean(agent),
  });

  const agentNodes = useMemo(() => {
    if (!steps || !query.data) return nodes;

    const concatNodes = nodes.map((node) => {
      if (node.type === "connector") return node;

      const step = steps.find((search) => search.id === node.id);

      node.data = {
        title: step?.label ?? "",
        status: STATUS.WAITING,
        default: true,
      };

      return node;
    });

    for (const agentNode of query.data) {
      const node = concatNodes.find((node) => +agentNode.step === +node.id);

      if (node.data.default)
        node.data = {
          title: agentNode.stepName,
          status: agentNode.stepStatus,
          default: false,
        };

      if (agentNode.document) {
        if (!node.data.items) node.data.items = [];

        node.data.items.push({
          label: agentNode.document,
          status: agentNode.documentStatut,
        });
      }
    }

    return concatNodes;
  }, [query.data, steps]);

  if (query.isLoading)
    return (
      <Box
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );

  const LegendColor = ({ color, label }) => (
    <Box
      display="flex"
      alignItems="center"
      gap="0.5rem"
      sx={{ userSelect: "none" }}
    >
      <Box
        sx={{
          height: { xs: ".5rem", sm: ".75rem", md: "1.4rem" },
          width: { xs: ".75rem", sm: "1rem", md: "2.5rem" },
          backgroundColor: color,
          border: "1px solid #333",
          borderRadius: ".2rem",
        }}
      />
      <Typography sx={{ fontSize: { xs: ".5rem", sm: ".75rem", md: "1rem" } }}>
        {label}
      </Typography>
    </Box>
  );

  const LegendIcon = ({ icon, label }) => (
    <Box
      display="flex"
      alignItems="center"
      gap="0.5rem"
      sx={{ userSelect: "none" }}
    >
      {icon}
      <Typography sx={{ fontSize: { xs: ".5rem", sm: ".75rem", md: "1rem" } }}>
        {label}
      </Typography>
    </Box>
  );

  const LegendContainer = ({ children }) => (
    <Box
      sx={{
        backdropFilter: "blur(5px)",
        px: { xs: ".25rem", md: "1rem" },
        py: { xs: ".2rem", md: ".75rem" },
        gap: "1rem",
        borderRadius: ".4rem",
        display: "flex",
        alignItems: "center",
        backgroundColor: "#eeeeeeab",
      }}
    >
      {children}
    </Box>
  );

  return (
    <>
      <Box height="32rem">
        <ReactFlow
          nodes={agentNodes}
          edges={edges}
          fitView
          nodeTypes={nodeTypes}
        />
      </Box>
      <Box
        sx={{ position: "absolute", top: ".5rem", left: ".5rem", zIndex: 4 }}
      >
        <LegendContainer>
          <Typography zIndex="4" fontSize="1.2rem">
            {agent.lastName} {agent.firstName}
          </Typography>
        </LegendContainer>
      </Box>
      <Box
        zIndex="4"
        display="flex"
        justifyContent="space-between"
        position="absolute"
        width="100%"
        gap=".3rem"
        sx={{
          px: { xs: ".5rem", sm: "1rem" },
          bottom: { xs: ".25rem", md: ".75rem" },
          flexWrap: { xs: "wrap", md: "no-wrap" },
        }}
      >
        <LegendContainer>
          <LegendColor color={STATUS_COLOR[STATUS.FINISHED]} label="Terminée" />
          <Divider orientation="vertical" />
          <LegendColor color={STATUS_COLOR[STATUS.CURRENT]} label="En cours" />
          <Divider orientation="vertical" />
          <LegendColor color={STATUS_COLOR[STATUS.WAITING]} label="À venir" />
          <Divider orientation="vertical" />
          <LegendColor color={STATUS_COLOR[STATUS.ABORTED]} label="Annulé" />
        </LegendContainer>
        <LegendContainer>
          <LegendIcon
            icon={ITEM_STATUS_ICONS[ITEM_STATUS.CHECK]}
            label="Validé"
          />
          <Divider orientation="vertical" />
          <LegendIcon
            icon={ITEM_STATUS_ICONS[ITEM_STATUS.CHECKING]}
            label="En validation"
          />
          <Divider orientation="vertical" />
          <LegendIcon
            icon={ITEM_STATUS_ICONS[ITEM_STATUS.MISSING]}
            label="Manquant"
          />
          <Divider orientation="vertical" />
          <LegendIcon
            icon={ITEM_STATUS_ICONS[ITEM_STATUS.REJECT]}
            label="Rejeté"
          />
        </LegendContainer>
      </Box>
    </>
  );
};

export default AgentReportingRegisterFlow;
