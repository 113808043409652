import dayjs from "dayjs";
import React from "react";
import { Card, CardContent, CardHeader, Divider, Grid, Stack, Typography } from "@material-ui/core";
import { Person as PersonIcon } from "@material-ui/icons";
import Logo from "../Logo";
import SalesStatisticsRanking from "./statistics/SalesStatisticsRanking";
import SalesStatisticsPersonal from "./statistics/SalesStatisticsPersonal";
import SalesStatisticsCorporate from "./statistics/SalesStatisticsCorporate";

const SalesStatistics = () => {
  const now = dayjs();
  const year = now.year();

  return (
    <Card>
      <CardHeader title="Mes statistiques" />
      <Divider />
      <CardContent>
        <Grid container>
          {/*// Todo*/}
          {/*<Grid item sx={{px: 3}} xs={12} md>*/}
          {/*  <Stack spacing={2} direction="row" alignItems="center" justifyContent="center" sx={{height: 60}}>*/}
          {/*    <PersonIcon/>*/}
          {/*    <Typography variant="h3">Personnel</Typography>*/}
          {/*  </Stack>*/}
          {/*  <SalesStatisticsPersonal />*/}
          {/*</Grid>*/}
          {/*<Divider orientation="vertical" variant="middle" flexItem/>*/}
          {/*<Grid item sx={{px: 3}} xs={12} md>*/}
          {/*  <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">*/}
          {/*    <Logo style={{height: 60}} />*/}
          {/*  </Stack>*/}
          {/*  <SalesStatisticsCorporate />*/}
          {/*</Grid>*/}
          {/*<Divider orientation="vertical" variant="middle" flexItem/>*/}

          <Grid
            item
            sx={{ px: 3 }}
            xs={12}
            md
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack spacing={2} direction="row" alignItems="center" justifyContent="center" sx={{ height: 60 }}>
              <Typography variant="h3">Classement {year}</Typography>
            </Stack>
            <SalesStatisticsRanking />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SalesStatistics;
