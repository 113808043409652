import { Box, Container, DialogActions, DialogContent, Divider, Typography } from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { connect } from "react-redux";
import { getNewsPopup, patchNewsReadUser } from "../../http/news";
import * as actions from "../../store/actions";
import { contentText, dateToRelativeString } from "../../utils/formatters";
import AppDialog from "../AppDialog";

const AgentPopupNews = ({ setLoginAgentComplete }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [itemNews, setItemNews] = useState(null);

  const { data: news } = useQuery(["getNewsPopup"], async () => {
    const data = await getNewsPopup();

    const news = [];
    data.forEach((newsItem) => {
      const newsTab = {
        ...newsItem,
        open: true,
      };
      news.push(newsTab);
    });

    setItemNews(news.shift());
    setOpen(true);

    return news;
  });

  const onClose = () => {
    if (news.length > 0) {
      setItemNews(news.shift());
    } else {
      setOpen(false);
      setLoginAgentComplete();
    }
  };

  const handleReadNews = () => {
    mutation.mutate({
      id: itemNews.id,
      isRead: true,
    });

    onClose();
  };

  const mutation = useMutation(patchNewsReadUser, {
    onError: () => {
      enqueueSnackbar("Erreur lors de l'enregistrement de l'actualité", { variant: "error" });
    },
  });

  const handleNewsCloseAll = () => {
    setOpen(false);
    setLoginAgentComplete();
  }

  return (
    <>
      {itemNews && (
        <AppDialog open={open} onClose={onClose} title="Actualités récentes" maxWidth="lg">
          <DialogContent>
            <Box>
              <img
                src={process.env.REACT_APP_API_URL + itemNews?.news?.imageBank?.mediaObject?.contentUrl}
                alt={itemNews?.news.imageBank.title}
                style={{ width: "100%", height: "auto", maxHeight: "400px", objectFit: "contain" }}
              />
            </Box>
            <Box sx={{ pt: 3 }}>
              <Container maxWidth="lg">
                <Box sx={{ py: 2, display: "flex", alignItems: "baseline" }}>
                  <Typography variant="h1">{itemNews?.news.title}</Typography>
                  <Typography gutterBottom variant="subtitle2" color="text.secondary" sx={{ ml: 2 }}>
                    Mis à jour {dateToRelativeString(itemNews?.news?.date)}
                  </Typography>
                </Box>
                <Box sx={{ py: 2 }}>
                  <Typography
                    gutterBottom
                    variant="body1"
                    textAlign="justify"
                    paragraph={true}
                    dangerouslySetInnerHTML={{
                      __html: contentText(itemNews?.news.content),
                    }}
                    sx={{
                      "& *": { margin: "inherit", padding: "inherit", boxSizing: "inherit" },
                      "& ul": { marginLeft: "15px" },
                    }}
                  />
                </Box>
              </Container>
            </Box>
          </DialogContent>
          <Divider />
          <DialogActions>
            <LoadingButton loading={mutation.isLoading} type="submit" variant="contained" onClick={onClose}>
              Reporter
            </LoadingButton>
            <LoadingButton loading={mutation.isLoading} type="submit" variant="contained" onClick={handleReadNews}>
              Lu
            </LoadingButton>
            <LoadingButton loading={mutation.isLoading} type="submit" variant="contained" onClick={handleNewsCloseAll}>
              Reporter tout
            </LoadingButton>
          </DialogActions>
        </AppDialog>
      )}
    </>
  );
};

AgentPopupNews.propTypes = {
  setLoginAgentComplete: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  setLoginAgentComplete: () => dispatch(actions.updateLoginAgent()),
});

export default connect(null, mapDispatchToProps)(AgentPopupNews);
