import http from "./http";

const openFolder = async (apiUrl, filename = "", mime = "application/zip") => {
  // Make an HTTP GET request to 'apiUrl' to fetch the folder content as a blob.
  const { data } = await http.get(apiUrl, {
    responseType: "blob",
  });

  // Create a URL for the blob data to use in the anchor link.
  const url = window.URL.createObjectURL(new Blob([data], { type: data.type ?? mime }));
  const link = document.createElement("a");
  link.href = url;

  link.setAttribute("download", filename);

  document.body.appendChild(link);
  // Simulate a click on the link to trigger the download
  link.click();
  // remove the link from the document body after the download has been done.
  link.parentNode.removeChild(link);
};

export default openFolder;
