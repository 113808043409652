import { LoadingButton } from "@material-ui/lab";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React from "react";
import {
  Alert,
  Button,
  DialogActions,
  DialogContent,
  TextField,
  Divider,
  makeStyles,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { faFolder, faFile } from "@fortawesome/free-solid-svg-icons";
import { Edit as EditIcon, DriveFileMove as MoveIcon } from "@material-ui/icons";
import { useMutation, useQueryClient } from "react-query";
import { Controller, useForm } from "react-hook-form";
import AppDialog from "../AppDialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DocumentsBreadcrumbs from "./DocumentsBreadcrumbs";
import { editFolder } from "../../http/documentations";
import { useTrackingContext } from "../../contexts/TrackingContext";
import { ACTION_CLICK } from "../../utils/trackingConstant";

const EditFolderModal = ({
  open,
  onClose,
  file,
  documentsToShow,
  handleNavigateToFolder,
  breadcrumbs,
  handleUpdateBreadCrumbs,
  renderViewFileIcon,
}) => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const {
    handleSubmit,
    formState: { errors },
    control,
    unregister,
  } = useForm({
    defaultValues: {
      name: "",
    },
  });

  const track = useTrackingContext()

  const handleClose = () => {
    unregister("name");
    onClose();
  };

  const onSubmit = async (data) => {
    let bread = breadcrumbs[breadcrumbs.length - 1];
    let path = bread.id;
    const files = {
      ...file,
      displayName: data.name,
      path: path,
    };
    if (file.id !== path) {
      track.click(ACTION_CLICK.DOCUMENT_EDIT_FOLDER)
      mutation.mutate(files);
    } else {
      enqueueSnackbar("Erreur : le dossier ne peut pas être son propre dossier parent", { variant: "error" });
    }
  };

  const mutation = useMutation(editFolder, {
    onSuccess: () => {
      enqueueSnackbar("Modification effectuée", { variant: "success" });
      queryClient.invalidateQueries("getFolders");
      handleClose();
    },
  });

  return (
    <AppDialog open={open} onClose={handleClose} title="Modifier un dossier">
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <DocumentsBreadcrumbs breadcrumbs={breadcrumbs} handleUpdateBreadCrumbs={handleUpdateBreadCrumbs} />
          {mutation.isError ? <Alert severity="error">Une erreur est survenue : {mutation.error.message}</Alert> : null}
          <Controller
            name="name"
            control={control}
            variant="outlined"
            shouldUnregister="true"
            render={({ field }) => (
              <TextField
                {...field}
                error={Boolean(errors.name?.message)}
                helperText={errors.name?.message}
                fullWidth
                label="Titre"
                variant="outlined"
                margin="normal"
                autoComplete="name"
                type="text"
              />
            )}
          />
        </DialogContent>
        {documentsToShow.map((document) => {
          const labelId = `checkbox-list-label-${document.id}`;
          return (
            <ListItem
              key={document.id}
              sx={{
                borderWidth: "0px 0px thin",
                borderStyle: "solid",
                borderColor: "rgba(0, 0, 0, 0.12)",
                paddingLeft: "36px",
                paddingRight: "36px",
              }}
            >
              {document.contentUrl ? (
                <>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faFile} className={classes.documentIcon} />
                  </ListItemIcon>
                  <ListItemText
                    id={labelId}
                    primary={document.displayName}
                    secondary={document.breadcrumbsString}
                    sx={{ cursor: "pointer", width: "12%" }}
                  />
                </>
              ) : (
                <>
                  <ListItemIcon sx={{ cursor: "pointer" }} onClick={() => handleNavigateToFolder(document)}>
                    <FontAwesomeIcon icon={faFolder} className={classes.documentIcon} />
                  </ListItemIcon>
                  <ListItemText
                    id={labelId}
                    primary={document.name}
                    secondary={document.breadcrumbsString}
                    sx={{ cursor: "pointer", width: "12%" }}
                    onClick={() => handleNavigateToFolder(document)}
                  />
                </>
              )}
            </ListItem>
          );
        })}
        <Divider />
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Annuler
          </Button>
          <LoadingButton loading={mutation.isLoading} variant="contained" type="submit" startIcon={<EditIcon />}>
            Modifier
          </LoadingButton>
        </DialogActions>
      </form>
    </AppDialog>
  );
};

const useStyles = makeStyles((theme) => ({
  documentIcon: {
    fontSize: "26px",
  },
  docIcon: {
    color: "hsl(221, 44%, 59%)",
  },
}));

EditFolderModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  file: PropTypes.object,
  handleNavigateToFolder: PropTypes.func,
  documentsToShow: PropTypes.array,
  breadcrumbs: PropTypes.array,
  handleUpdateBreadCrumbs: PropTypes.func,
  renderViewFileIcon: PropTypes.func,
};

export default EditFolderModal;
