import PropTypes from "prop-types";
import React, { useState } from "react";
import { DataGrid } from "@material-ui/data-grid";
import { Link, Grid } from "@material-ui/core";
import { useQuery } from "react-query";
import { Link as RouterLink } from "react-router-dom";
import { dateColumn } from "../../utils/dataGridColumns";
import renderCellExpand from "../../utils/renderCellExpand";
import { getEventsTeam } from "../../http/trainings";

const TrainingTeamListAll = ({ filters, agentId }) => {
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(0);
  const sortModel = [{ field: "exercice", sort: "asc" }];

  const { isLoading, data: training } = useQuery(["getEventsTeam", filters, agentId], async () => {
    const data = await getEventsTeam(filters, agentId);
    return data["hydra:member"];
  });

  const columns = [
    {
      field: "exercice",
      headerName: "Exercice",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "consultant",
      headerName: "Consultant",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "parrain",
      headerName: "Parrain",
      flex: 1,
      valueGetter: (params) => params.row?.parrain,
      headerAlign: "center",
    },
    {
      field: "formation",
      headerName: "Formation",
      flex: 1,
      headerAlign: "center",
    },
    { field: "event", headerName: "Evènement", flex: 1, headerAlign: "center" },
    { field: "ville", headerName: "Ville", flex: 1, headerAlign: "center" },
    {
      field: "dateDebut",
      ...dateColumn,
      headerName: "Date",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "statutParticipant",
      headerName: "Statut du participant",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "statutInscription",
      headerName: "Statut inscription",
      flex: 1,
      headerAlign: "center",
    },
  ];

  return (
    <DataGrid
      loading={isLoading}
      rows={training ? training : []}
      columns={columns}
      pageSize={pageSize}
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize.pageSize)}
      autoHeight
      disableColumnFilter
      disableSelectionOnClick
      disableColumnMenu
      disableColumnReorder
      pagination
      sortModel={sortModel}
      getRowId={(row) => row.id}
    />
  );
};

TrainingTeamListAll.propTypes = {
  filters: PropTypes.object,
  agentId: PropTypes.string,
};

export default TrainingTeamListAll;
