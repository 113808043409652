import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@material-ui/lab";
import PropTypes from "prop-types";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { Alert, Grid, TextField, Typography } from "@material-ui/core";
import * as yup from "yup";
import { patchUser } from "../../http/user";
import AppCircularProgress from "../AppCircularProgress";
import { useTrackingContext } from "../../contexts/TrackingContext";
import { ACTION_CLICK } from "../../utils/trackingConstant";

const AgentUserFormEmail = ({ user, isFetched }) => {
  const queryClient = useQueryClient();
  const track = useTrackingContext()

  const schema = yup.object().shape({
    email: yup.string().required("Ce champ est obligatoire.").email("Cette valeur n'est pas une adresse mail valide."),
    confirmEmail: yup
      .string()
      .required("Ce champ est obligatoire.")
      .email("Cette valeur n'est pas une adresse mail valide.")
      .oneOf([yup.ref("email"), null], "L'adresse mail doit correspondre."),
  });

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      email: "",
      confirmEmail: "",
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    track.click(ACTION_CLICK.EDIT_MY_EMAIL)
    mutation.mutate({
      email: data.email,
      user,
      notificationHbunup: true,
    });
  };

  const mutation = useMutation(patchUser, {
    onSuccess: () => {
      queryClient.invalidateQueries("getCurrentUser");
      reset();
    },
  });

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      {isFetched ? (
        <Grid container spacing={2} alignItems="stretch">
          {mutation.isError ? (
            <Grid item xs={12}>
              <Alert severity="error">Une erreur est survenue : {mutation.error.message}</Alert>
            </Grid>
          ) : null}

          {mutation.isSuccess ? (
            <Grid item xs={12}>
              <Alert severity="success">Modification effectuée avec succès.</Alert>
            </Grid>
          ) : null}

          <Grid item xs={10} md={8}>
            <Typography>Adresse mail actuelle : {user?.email}</Typography>

            <Controller
              name="email"
              control={control}
              shouldUnregister
              render={({ field, formState: { errors } }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="Nouvelle adresse mail"
                  margin="normal"
                  type="email"
                  autoComplete="email"
                  error={Boolean(errors.email?.message)}
                  helperText={errors.email?.message}
                />
              )}
            />

            <Controller
              name="confirmEmail"
              control={control}
              shouldUnregister
              render={({ field, formState: { errors } }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="Confirmation nouvelle adresse mail"
                  margin="normal"
                  type="email"
                  autoComplete="email"
                  error={Boolean(errors.confirmEmail?.message)}
                  helperText={errors.confirmEmail?.message}
                />
              )}
            />
          </Grid>

          <Grid item xs={2} md={4} sx={{ display: "flex", flexDirection: "row-reverse", alignItems: "flex-end" }}>
            <LoadingButton loading={mutation.isLoading} type="submit" variant="contained">
              Modifier mon adresse mail
            </LoadingButton>
          </Grid>
        </Grid>
      ) : (
        <AppCircularProgress />
      )}
    </form>
  );
};

AgentUserFormEmail.propTypes = {
  user: PropTypes.object,
  isFetched: PropTypes.bool,
};

export default AgentUserFormEmail;
