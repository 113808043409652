import http from "../utils/http";

export const getTeamReportingRegister = (filters) => {
  return http.get("/reporting_inscriptions/consultants", { params: filters });
};

export const getReportingRegisterStep = () => {
  return http.get("/reporting_inscriptions/steps");
};

export const getReportingRegister = (firstName, lastName) => {
  return http.get("/reporting_inscriptions", {
    params: { firstName, lastName },
  });
};
