import { LoadingButton } from "@material-ui/lab";
import React, { useState } from "react";
import { Box, Button, Card, Container, Stack } from "@material-ui/core";
import { Download as DownloadIcon } from "@material-ui/icons";
import { useMutation } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import GuardedComponent from "../../components/GuardedComponent";
import Breadcrumbs from "../../components/Breadcrumbs";
import FilterPanel from "../../components/FilterPanel";
import VlpToolbar from "../../components/vlp/VlpToolbar";
import VlpList from "../../components/vlp/VlpList";
import { getExportExcelVlps } from "../../http/salesVlp";
import SalesVlpSummary from "../../components/vlp/SalesVlpSummary";
import ExerciceFilter from "../../components/form/ExerciceFilter";
import { useTrackingContext } from "../../contexts/TrackingContext";
import { ACTION_CLICK } from "../../utils/trackingConstant";

const HubnupVlp = () => {
  const history = useHistory();
  const { agentId } = useParams();

  const actualYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(actualYear);
  const [filters, setFilters] = useState({ exercice: selectedYear });

  const [sortModel, setSortModel] = useState([{ field: "exercice", sort: "asc" }]);
  const track = useTrackingContext()

  const onFilter = (filters) => {
    const data = {
      ...filters,
      exercice: selectedYear,
    };
    setFilters(data);
  };

  const onSubmit = () => {
    track.click(ACTION_CLICK.SET_FILTER)
    onFilter(filters);
  };

  const handleExportExcel = () => {
    track.click(ACTION_CLICK.EXPORT_SALES_VLP)
    mutation.mutate({
      filters,
      order: sortModel,
      agentId: agentId,
    });
  };

  const goBack = () => {
    history.goBack();
  };

  const mutation = useMutation(getExportExcelVlps);

  return (
    <Container maxWidth={false}>
      <Breadcrumbs />

      <GuardedComponent componentName="sales_vlp_sum_sales">
        <Box my={2}>
          <SalesVlpSummary fromVlp agentId={agentId} />
        </Box>
      </GuardedComponent>
      <Box sx={{ mt: 3, mb: 3 }}>
        <GuardedComponent componentName="sales_vlp_export">
            <LoadingButton
              loading={mutation.isLoading}
              color="primary"
              variant="contained"
              startIcon={<DownloadIcon />}
              onClick={handleExportExcel}
            >
              Exporter
            </LoadingButton>
        </GuardedComponent>
        <ExerciceFilter selectedYear={selectedYear} setSelectedYear={setSelectedYear} actualYear={actualYear} onSubmit={onSubmit} />
      </Box>

      <FilterPanel sx={{ mt: 2 }}>
        <VlpToolbar onFilter={onFilter} fromAgent={false} exercice={selectedYear} />
      </FilterPanel>

      <Card sx={{ mt: 2 }}>
        <VlpList filters={filters} sortModel={sortModel} setSortModel={setSortModel} agentId={agentId} />
      </Card>
      
      {agentId && (
        <Stack
          direction={{ xs: "column-reverse", sm: "row" }}
          spacing={2}
          sx={{
            p: 2,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button variant="outlined" onClick={goBack}>
            Retour
          </Button>
        </Stack>
      )}
    </Container>
  );
};

export default HubnupVlp;
