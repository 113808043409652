import React from "react";
import { useMutation, useQuery } from "react-query";
import { Card, Container } from "@material-ui/core";

import {
  getOdyssealMappingsRoles,
  updateOdyssealMappingsRoles,
} from "../../http/profiles";
import AppCircularProgress from "../../components/AppCircularProgress";
import RolesMappingsForm from "../../components/odysseal/RolesMappingsForm";

const OdyssealRolesMappings = () => {
  const profilesQuery = useQuery({
    queryKey: ["profiles"],
    queryFn: () => getOdyssealMappingsRoles(),
  });

  const editMutation = useMutation({
    mutationKey: ["edit-odysseal-roles"],
    mutationFn: (values) => updateOdyssealMappingsRoles(values),
  });

  return (
    <Container maxWidth={false}>
      <Card>
        {profilesQuery.isSuccess ? (
          <RolesMappingsForm
            profiles={profilesQuery.data}
            onSubmit={(values) => editMutation.mutateAsync(values)}
          />
        ) : (
          <AppCircularProgress />
        )}
      </Card>
    </Container>
  );
};

export default OdyssealRolesMappings;
