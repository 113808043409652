import { LoadingButton } from "@material-ui/lab";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import dayjs from "dayjs";
import { FolderOpen } from "@material-ui/icons";
import { useMutation, useQueryClient } from "react-query";
import AppDialog from "../AppDialog";
import { postMediaObject } from "../../http/documentations";
import { postNotification } from "../../http/notifications";
import AutocompleteProfilesField from "../form/AutocompleteProfilesField";
import useNotificationRule from "../../hooks/useNotificationRule";
import { useTrackingContext } from "../../contexts/TrackingContext";
import { ACTION_CLICK } from "../../utils/trackingConstant";

const CreateFileModal = ({
  open,
  title,
  person,
  onClose,
  queryToInvalidate,
  currentFolder,
}) => {
  const [files, setFiles] = useState(null);
  const [notification, setNotification] = useState({
    active: true,
    profiles: [],
  });
  const queryClient = useQueryClient();
  const track = useTrackingContext()

  const handleClose = () => {
    onClose();
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    let formData = null;

    if (files) {
      formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append(`file[${i}]`, files[i]);
      }
      formData.append("person", person.id);
      formData.append("folderId", currentFolder.id);
    }

    const response = await mutation.mutateAsync(formData);

    if (notification.active) {
      const notificationPayload = {
        title: "Nouveau fichier !",
        description: `Un nouveau fichier a été ajouté à la bibliothèque "${files[0].name}"`,
        profilesToNotify: notification.profiles.map(
          (profile) => profile["@id"],
        ),
        expireAt: dayjs().add(7, "days").format("YYYY-MM-DD"),
        createdAt: dayjs().format("YYYY-MM-DD HH:mm:ss"),
        urlLink:
          process.env.REACT_APP_API_URL +
          response.data.contentUrl.replace("media", "media/bibliotheque"),
      };

      await notyMutation.mutateAsync(notificationPayload);
    }

    track.click(ACTION_CLICK.DOCUMENT_ADD)

    handleClose();
  };

  const mutation = useMutation(postMediaObject, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryToInvalidate);
    },
  });

  const notyMutation = useMutation(postNotification);

  const handleChangeFile = (event) => {
    setFiles(event.target.files);
  };

  const renderFile = () => {
    let previewFile = null;
    if (files && files.length === 1) {
      previewFile = files[0].name;
    }

    if (previewFile) {
      return (
        <Typography variant="string" sx={{ marginLeft: "10px" }}>
          {previewFile}
        </Typography>
      );
    }

    return null;
  };

  const notyActivated = useNotificationRule("activate_notifications");
  const notyDocumentActivated = useNotificationRule("notify_documents");

  useEffect(() => {
    setNotification({
      profiles: [],
      active: notyDocumentActivated && notyActivated,
    });
  }, [notyDocumentActivated, notyActivated]);

  return (
    <AppDialog open={open} onClose={handleClose} title={title}>
      <form noValidate onSubmit={onSubmit}>
        <DialogContent>
          {mutation.isError ? (
            <Alert severity="error">
              Une erreur est survenue : {mutation.error.message}
            </Alert>
          ) : null}

          <Box>
            <Button
              variant="contained"
              component="label"
              startIcon={<FolderOpen />}
            >
              Télécharger un fichier
              <input type="file" hidden multiple onChange={handleChangeFile} />
            </Button>
            {renderFile()}
          </Box>
          {notyActivated && (
            <Box
              sx={{
                pt: "1rem",
                display: notification.active ? "block" : "none",
              }}
            >
              <AutocompleteProfilesField
                defaultAll
                field={{
                  value: notification.profiles,
                  onChange: (values) =>
                    setNotification({
                      ...notification,
                      profiles: values,
                    }),
                }}
              />
            </Box>
          )}
        </DialogContent>
        <Divider />
        <DialogActions sx={{ justifyContent: "space-between", px: "2rem" }}>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={!notyActivated || !notyDocumentActivated}
                  checked={notification.active}
                  onChange={() =>
                    setNotification({
                      ...notification,
                      active: !notification.active,
                    })
                  }
                />
              }
              label="Notification"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "0.5rem",
            }}
          >
            <Button variant="outlined" onClick={onClose}>
              Annuler
            </Button>
            <LoadingButton
              loading={mutation.isLoading}
              type="submit"
              variant="contained"
              disabled={!files}
            >
              Enregistrer
            </LoadingButton>
          </Box>
        </DialogActions>
      </form>
    </AppDialog>
  );
};

CreateFileModal.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  person: PropTypes.object,
  currentFolder: PropTypes.object,
  queryToInvalidate: PropTypes.string,
  onClose: PropTypes.func,
};

export default CreateFileModal;
