import PropTypes from "prop-types";
import React from "react";
import { DataGrid } from "@material-ui/data-grid";
import { Box, CardContent, IconButton } from "@material-ui/core";
import { dateColumn } from "../../utils/dataGridColumns";
import renderCellExpand from "../../utils/renderCellExpand";
import { Download as DownloadIcon } from "@material-ui/icons";

const ProspectDetailsRicList = ({ ricCollection, isFetching }) => {
  const rics = ricCollection ? ricCollection.map((ric) => ric.ric) : [];

  const handleDownloadFile = async (document) => {
    window.open(`${process.env.REACT_APP_API_URL}media/${document.filePath}?download`, "_blank");
  };

  const columns = [
    {
      field: "displayName",
      headerName: "Nom du fichier",
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: "createdAt",
      ...dateColumn,
      headerName: "Date",
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: "ricCollection",
      headerName: " ",
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "space-around" }}>
          <IconButton aria-label="Télécharger le document" size="small" onClick={() => handleDownloadFile(params.row)}>
            <DownloadIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  const sortModel = [{ field: "createdAt", sort: "desc" }];

  return (
    <CardContent>
      <DataGrid
        loading={isFetching}
        rows={rics ?? []}
        columns={columns}
        sortModel={sortModel}
        pageSize={10}
        autoHeight
        disableColumnFilter
        disableSelectionOnClick
        disableColumnMenu
        disableColumnReorder
      />
    </CardContent>
  );
};

ProspectDetailsRicList.propTypes = {
  ricCollection: PropTypes.array,
  isFetching: PropTypes.bool,
};

export default ProspectDetailsRicList;
