import PropTypes from "prop-types";
import React, { useState } from "react";
import { DataGrid } from "@material-ui/data-grid";
import { Link } from "@material-ui/core";
import { useQuery } from "react-query";
import { Link as RouterLink } from "react-router-dom";
import { getCustomers } from "../../http/clients";
import checkAccessStore from "../../utils/checkAccessStore";
import { eurColumn } from "../../utils/dataGridColumns";
import renderCellExpand from "../../utils/renderCellExpand";
import AppCircularProgress from "../AppCircularProgress";

const HubnupCustomersList = ({ filters, sortModel, setSortModel }) => {
  const [page, setPage] = useState(0);

  const { isFetching, data: customers } = useQuery(
    ["getCustomers", filters, sortModel, page],
    async () => {
      const data = await getCustomers(filters, sortModel, page);

      return {
        customers: data["hydra:member"],
        totalItems: data["hydra:totalItems"],
      };
    },
    { keepPreviousData: true }
  );

  const handleSortModelChange = (params) => {
    if (params.sortModel !== sortModel) {
      setSortModel(params.sortModel);
    }
  };

  const columns = [
    {
      field: "clientLastName",
      headerName: "Nom",
      flex: 1,
      renderCell: (params) =>
        renderCellExpand(
          params,
          true,
          checkAccessStore("customers_detail_page") ? (
            <Link component={RouterLink} to={`/customers/${params.row.clientId}`}>
              {params.value}
            </Link>
          ) : (
            params.value
          )
        ),
    },
    {
      field: "clientFirstName",
      headerName: "Prénom",
      flex: 1,
      renderCell: (params) =>
        renderCellExpand(
          params,
          true,
          checkAccessStore("customers_detail_page") ? (
            <Link component={RouterLink} to={`/customers/${params.row.clientId}`}>
              {params.value}
            </Link>
          ) : (
            params.value
          )
        ),
    },
    { field: "zipCode", headerName: "Code postal", flex: 1, renderCell: renderCellExpand },
    {
      field: "dueRealEstateTotalAmount",
      ...eurColumn,
      sortable: false,
      headerName: "Acté immobilier",
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: "outstandingRealEstateTotalAmount",
      ...eurColumn,
      sortable: false,
      headerName: "Encours immobilier",
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: "totalRealEstateTotalAmount",
      ...eurColumn,
      sortable: false,
      headerName: "Total immobilier",
      flex: 1,
      valueGetter: (params) => params.row.dueRealEstateTotalAmount + params.row.outstandingRealEstateTotalAmount,
      renderCell: renderCellExpand,
    },
    {
      field: "actedInvestmentsTotalAmount",
      ...eurColumn,
      sortable: false,
      headerName: "Acté placement",
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: "outstandingInvestmentsTotalAmount",
      ...eurColumn,
      sortable: false,
      headerName: "Encours placement",
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: "totalInvestmentsTotalAmount",
      ...eurColumn,
      sortable: false,
      headerName: "Total placement",
      flex: 1,
      valueGetter: (params) => params.row.actedInvestmentsTotalAmount + params.row.outstandingInvestmentsTotalAmount,
      renderCell: renderCellExpand,
    },
  ];

  return (
    <>
      {customers ? (
        <DataGrid
          loading={isFetching}
          rows={customers.customers}
          columns={columns}
          pageSize={10}
          autoHeight
          disableColumnFilter
          disableSelectionOnClick
          disableColumnMenu
          disableColumnReorder
          pagination
          rowCount={customers.totalItems}
          onSortModelChange={handleSortModelChange}
          getRowId={(row) => row.clientId}
        />
      ) : (
        <AppCircularProgress />
      )}
    </>
  );
};

HubnupCustomersList.propTypes = {
  filters: PropTypes.object,
  sortModel: PropTypes.array,
  setSortModel: PropTypes.func,
};

export default HubnupCustomersList;
