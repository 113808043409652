import React, { useCallback, useEffect, useRef, useState } from "react";
import { Card, CardContent, CircularProgress, Grid, Link, makeStyles, Typography } from "@material-ui/core";
import { getCredentialO2s, getOriadysUrl } from "../../http/sso";

const PartnersInvestments = () => {
  const classes = useStyles();
  const [o2sId, setO2sId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const formO2S = useRef(null);

  const getO2sId = useCallback(async () => {
    try {
      return await getCredentialO2s();
    } catch (e) {
      throw new Error(
        e?.response ? e.response.data.message : "Authentication request could not be processed due to a system problem."
      );
    }
  }, []);

  useEffect(() => {
    getO2sId().then((r) => {
      setO2sId(r);
    });
  }, [getO2sId, setO2sId]);

  const handleO2sAuth = () => {
    formO2S.current.submit();
  };

  const handlerOriadysUrl = () => {
    setIsLoading(true);
    getOriadysUrl()
      .then((result) => {
        window.open(result);
      })
      .catch(() => {
        window.open("https://portal.oriadys.fr/web/finorion/login", "_blank");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Card sx={{ height: "100%" }}>
      <CardContent
        sx={{
          p: 3,
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          height: "100%",
          textAlign: "center",
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{ alignItems: "center", justifyContent: "center", flexDirection: { xs: "column-reverse", md: "row" }, width: "calc(100% + 32px)" }}
        >
          <Grid item xs={12} sx={{ paddingLeft: "0 !important" }}>
            <Grid
              container
              flexDirection="column"
              sx={{ marginTop: "8px", width: "auto", lineHeight: "30px", paddingX: "20px" }}
            >
              <Grid item xs={12} container spacing={2} sx={{ paddingLeft: "30px", justifyContent: "center" }}>
                <Link href="https://app.orion-canopia.fr/login" target="_blank">
                  <img
                    src="/static/01.07 - CANOPIA.png"
                    alt="Canopia"
                    style={{ 
                      border: "3px solid #FF0000", 
                      padding: "2.5px 5px", 
                      textAlign: "center", 
                      width: "100% !important",  
                      marginBottom: "2rem",
                    }}
                    className={classes.logoPicture}
                  />
                </Link>
              </Grid>
              <Grid item xs={12} container spacing={2} sx={{ alignItems: "center", justifyContent:"center" }}>
                <Link href="#" onClick={handlerOriadysUrl} sx={{ display: "contents" }}>
                  {isLoading ? (
                    <CircularProgress color="inherit" size={200} />
                  ) : (
                    <img src="/static/01.08 Oriadys.png" alt="Oriadys" className={classes.logoPicture} />
                  )}
                </Link>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2} sx={{ alignItems: "center", justifyContent: "center" }}>
                  <Grid item xs={6} sx={{ paddingX: "50px !important" }}>
                    <Link href="#" onClick={handleO2sAuth}>
                      <img src="/static/01.03 - O2S.png" alt="O2S" className={classes.logoPicture} />
                    </Link>
                  </Grid>
                  <Grid item xs={6} sx={{paddingX: '50px !important'}}>
                    <Link href="https://extranet-cgp.starinvest.com/" target="_blank">
                      <img
                        src="/static/01.06 - STAR INVEST.png"
                        alt="star invest"
                        className={classes.logoPicture}
                      />
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2} sx={{ alignItems: "center", justifyContent: "center" }}>
                  <Grid item xs={6} sx={{ paddingX: "50px !important" }}>
                    <Link href="https://www.alpheys.com/" target="_blank">
                      <img src="/static/01.04 - ALPHEYS.png" alt="alpheys" className={classes.logoPicture} />
                    </Link>
                  </Grid>
                  <Grid item xs={6} sx={{ paddingX: "50px !important" }}>
                    <Link href="https://nextstage.capitalventureapp.com/extranet/login.cv" target="_blank">
                      <img src="/static/01.05 - NEXTSTAGE.png" alt="next stage" className={classes.logoPicture} />
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
              <form
                action={process.env.REACT_APP_O2S_FDOPROXY}
                method="post"
                target="_blank"
                ref={formO2S}
                style={{ display: "none" }}
              >
                <input name="redirect" type="hidden" value={process.env.REACT_APP_API_URL} />
                <input type="text" name="login" value={o2sId?.user} readOnly />
                <input type="text" name="password" value={o2sId?.mdp} readOnly />
              </form>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const useStyles = makeStyles((theme) => ({
  logoPicture: {
    [theme.breakpoints.up('xs')]: {
      width: "100%",
    },
    [theme.breakpoints.up('md')]: {
      width: "50%",
    },
    [theme.breakpoints.up('lg')]: {
      width: "100%",
    },
  },
}));

export default PartnersInvestments;
