import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { useQuery, useQueryClient } from "react-query";
import FilterPanel from "../../components/FilterPanel";
import PicturesList from "../../components/picturesLibrary/PicturesList";
import PicturesToolbar from "../../components/picturesLibrary/PicturesToolbar";
import { getLibraryPictures } from "../../http/libraryPictures";
import AppCircularProgress from "../AppCircularProgress";

const PicturesLibraryContent = ({ modalMode, onClick }) => {
  const itemsPerPage = 9;

  const queryClient = useQueryClient();
  const [filterTitle, setFilterTitle] = useState("");
  const { isFetched, data: pictures } = useQuery(["getLibraryPictures", filterTitle], () =>
    getLibraryPictures(filterTitle)
  );

  const [paginatedPictures, setPaginatedPictures] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMorePictures, setHasMorePictures] = useState(true);

  useEffect(() => {
    if (pictures && pictures.length > 0) {
      setPaginatedPictures(pictures.slice(0, itemsPerPage));
    } else {
      setPaginatedPictures([]);
    }
  }, [pictures]);

  const onFilter = (filters) => {
    setFilterTitle(filters.title);
    queryClient.invalidateQueries("getLibraryPictures");
  };

  const fetchPictures = (picturesIndex) => {
    setLoading(true);
    const morePictures = pictures.slice(picturesIndex, picturesIndex + itemsPerPage);
    setPaginatedPictures((prevState) => prevState.concat(morePictures));
    setHasMorePictures(morePictures.length > 0);
    setLoading(false);
  };

  return (
    <>
      <FilterPanel>
        <PicturesToolbar onFilter={onFilter} />
      </FilterPanel>

      <Box sx={{ mt: 3 }}>
        <PicturesList
          pictures={paginatedPictures}
          fetchPictures={fetchPictures}
          hasMorePictures={hasMorePictures}
          modalMode={modalMode}
          onClick={onClick}
        />
      </Box>

      {(loading || !isFetched) && <AppCircularProgress />}

      {!hasMorePictures && (
        <Box sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
          <Typography variant="body1" color="text.disabled">
            Il n'y a plus d'images à afficher.
          </Typography>
        </Box>
      )}

      {pictures && pictures.length === 0 && (
        <Box sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
          <Typography variant="body1" color="text.disabled">
            Il n'y a pas d'images pour la sélection courante.
          </Typography>
        </Box>
      )}
    </>
  );
};

PicturesLibraryContent.defaultProps = {
  modalMode: false,
};

PicturesLibraryContent.propTypes = {
  onClick: PropTypes.func,
  modalMode: PropTypes.bool,
};

export default PicturesLibraryContent;
