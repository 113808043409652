import React from "react";
import { Card, CardContent, Grid, Link, makeStyles, Typography } from "@material-ui/core";
import { connect } from "react-redux";

const PartnersRealEstates = ({ consultantValoricielId }) => {
  const classes = useStyles();

  return (
    <Card sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      <CardContent
        sx={{
          p: 3,
        }}
      >
        <Grid
          container
          justifyContent="center"
          spacing={2}
          sx={{ 
            alignItems: "center", 
            justifyContent: "center",
            flexDirection: { xs: "column-reverse", md: "row" }, 
            width: "calc(100% + 32px)" }}
        >
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              paddingLeft: "0 !important",
            }}
          >
            <Grid container sx={{ marginTop: "8px", width: "auto", lineHeight: "30px", paddingX: "20px" }}>
              <Grid item xs={12}>
                <Link href={`https://valoriciel.com/sso/${consultantValoricielId}`} target="_blank">
                  <img
                    src="/static/02.01 - VALORICIEL.png"
                    alt="Valoriciel"
                    className={classes.logoPicture}
                  />
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const useStyles = makeStyles((theme) => ({
  logoPicture: {
    width: "100%",
  },
}));

const mapStateToProps = (state) => ({
  consultantValoricielId: state.auth.consultantValoricielId,
});

export default connect(mapStateToProps)(PartnersRealEstates);
