import { impersonateUser, updateCircularPicture, updatePicture } from "../store/actions/auth";
import http from "../utils/http";
import store from "../store";

export const getUsersAll = async (filters, page, order) => {
  let url = `/users?page=${page + 1}`;

  if (filters?.firstName) {
    url += `&firstName=${filters.firstName}`;
  }
  if (filters?.lastName) {
    url += `&lastName=${filters.lastName}`;
  }
  if (filters?.email) {
    url += `&email=${filters.email}`;
  }
  if (filters?.profile) {
    url += `&roles=${filters.profile}`;
  }
  if (order && order[0]) {
    const orderField = order[0];
    const fieldName = orderField.field === "profile" ? "roles" : orderField.field;
    url += `&order[${fieldName}]=${orderField.sort}`;
  }

  url += `&displayedUser=true`;

  const response = await http.get(url);

  return response.data;
};

export const importUsers = async (data) => {
  return await http.post("/users/import", data);
};

export const deleteUser = async (url) => {
  return await http.delete(url);
};

export const postUser = async (data) => {
  if (data.user) {
    return patchUser(data);
  }

  const response = await http.post("/users", data);
  if (response.status === 201) {
    await resetPasswordRequest({ email: data.email });
  }

  return response.data;
};

export const resetPasswordRequest = async ({ email }) => {
  const response = await http.post("/reset_password_tokens", {
    email: email,
  });

  return response.data;
};

export const postResetPassword = async ({ password, token }) => {
  const response = await http.post(`/reset_password_tokens/${token}`, {
    password: password,
  });

  return response.data;
};

export const getUser = async (id) => {
  const response = await http.get(`/users/${id}`);

  return response.data;
};

export const getCurrentUser = async () => {
  const state = store.getState();
  const response = await http.get(`/users/${state.auth.userId}`);

  if (response?.data?.picture) {
    store.dispatch(updatePicture(response?.data?.picture));
    state.auth.picture = response?.data?.picture;
  }

  store.dispatch(updateCircularPicture(response.data.circularPicture));
  state.auth.circularPicture = response.data.circularPicture;

  return response.data;
};

export const getImpersonateUser = async (userId) => {
  const response = await http.get(`/administration/impersonate-user/${userId}`);

  store.dispatch(impersonateUser(response.data));

  return response.data;
};

export const patchUser = async (data) => {
  if (data.notificationHbunup) {
    await http.post("/notification_hubnup", {
      email: data.email,
    });
  }

  return await http.patch(data.user["@id"], data);
};

export const changePassword = async (data) => {
  return await http.post(`/change_password/${data.user.id}`, {
    password: data.password,
  });
};
