import React from "react";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";

const ContactUs = () => {
  return (
    <Card sx={{ height: "100%" }}>
      <CardContent
        sx={{
          p: 3,
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          height: "100%",
          textAlign: "center",
        }}
      >
        <Grid item>
          <img
            src="/static/coffee.png"
            alt="La Financière d'Orion"
            style={{ width: "-webkit-fill-available", margin: "-16px" }}
          />
        </Grid>
        <Grid item>
          <Typography variant="h4" sx={{ marginBottom: "18px", paddingBottom: "6px" }}>
            Pour toutes vos demandes :
          </Typography>
          <a
            href="mailto:support@hubnup.fr"
            style={{ fontSize: "18px", marginBottom: "36px", paddingBottom: "6px", display: "inline-block" }}
          >
            support@hubnup.fr
          </a>
        </Grid>
        <Grid item>
          <Typography
            sx={{
              borderBottom: "1px solid black",
              display: "inline-block",
            }}
          >
            © Tous droits réservés
          </Typography>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ContactUs;
