import { dateToIsoString } from "../utils/formatters";
import http from "../utils/http";

export const getInvoices = async (filters, page) => {
  let url = "/invoices";

  url = applyFilters(filters, url);

  const response = await http.get(url);

  return response.data;
};

const applyFilters = (filters, url) => {
  let hasFilter = false;

  const filtersUrl = [];
  if (filters?.agent) {
    filtersUrl.push(`agent=${filters.agent}`);
    hasFilter = true;
  }
  if (filters?.client) {
    filtersUrl.push(`client=${filters.client}`);
    hasFilter = true;
  }
  if (filters?.invoice) {
    filtersUrl.push(`invoice=${filters.invoice}`);
    hasFilter = true;
  }
  if (filters?.product) {
    filtersUrl.push(`product=${filters.product}`);
    hasFilter = true;
  }
  if (filters?.type) {
    filtersUrl.push(`type=${filters.type}`);
    hasFilter = true;
  }
  if (filters?.investmentAmount && filters?.investmentAmount[0]) {
    filtersUrl.push(`investmentAmount[gte]=${filters.investmentAmount[0]}`);
    hasFilter = true;
  }
  if (filters?.investmentAmount && filters?.investmentAmount[1]) {
    filtersUrl.push(`investmentAmount[lte]=${filters.investmentAmount[1]}`);
    hasFilter = true;
  }
  if (filters?.invoiceAmount && filters?.invoiceAmount[0]) {
    filtersUrl.push(`invoiceAmount[gte]=${filters.invoiceAmount[0]}`);
    hasFilter = true;
  }
  if (filters?.invoiceAmount && filters?.invoiceAmount[1]) {
    filtersUrl.push(`invoiceAmount[lte]=${filters.invoiceAmount[1]}`);
    hasFilter = true;
  }
  if (filters?.createdAt && filters?.createdAt[0]) {
    filtersUrl.push(`createdAt[after]=${dateToIsoString(filters.createdAt[0])}`);
    hasFilter = true;
  }
  if (filters?.createdAt && filters?.createdAt[1]) {
    filtersUrl.push(`createdAt[before]=${dateToIsoString(filters.createdAt[1])}`);
    hasFilter = true;
  }

  if (hasFilter) {
    url += "?";
  }

  filtersUrl.forEach((filter, index) => {
    url += filter;
    if (index < filtersUrl.length - 1) url += "&";
  });

  return url;
};
