import React from "react";
import {
  Container,
  Grid,
  Stack,
  Typography,
  makeStyles,
} from "@material-ui/core";
import GuardedComponent from "../components/GuardedComponent";
import checkAccessStore from "../utils/checkAccessStore";
import Partners from "../components/dashboard/Partners";
import ContactUs from "../components/dashboard/ContactUs";
import PartnersInvestments from "../components/dashboard/PartnersInvestments";
import PartnersRealEstates from "../components/dashboard/PartnersRealEstates";
import NewsSliderCarousel from "../components/dashboard/NewsSliderCarousel";

const Home = (props) => {
  const classes = useStyles(props);

  return (
    <Container maxWidth={false}>

      <Grid container spacing={1} padding={0}>

        <GuardedComponent componentName="home_news">
        <Grid item xs={12}>
          <NewsSliderCarousel />
        </Grid>
        </GuardedComponent>

        {checkAccessStore("home_partners") && (
          <Grid item xs={12} lg={6}>
            <Grid item xs={12}  className={classes.titleTypoContainer}>
              <Typography variant="h3" className={classes.titleTypo}>
                MES PARTENAIRES PRINCIPAUX : IMMOBILIER & PLACEMENT 
              </Typography>
            </Grid>
            <Grid item xs={12} pt={0}>
              <Stack direction={{xs: 'column', md: "row", lg: "row"}} spacing={1}>
                <PartnersRealEstates />
                <PartnersInvestments />
              </Stack>
            </Grid>
          </Grid>
        )}
        <GuardedComponent componentName="home_partners">
          <Grid item lg={3} md={6} xs={12}>
            <Grid container item xs={12} className={classes.titleTypoContainer}>
              <Typography variant="h3" className={classes.titleTypo}>
                MES AUTRES PARTENAIRES
              </Typography>
            </Grid>
            <Grid container item xs={12}>
              <Partners />
            </Grid>
          </Grid>
        </GuardedComponent>

        <GuardedComponent componentName="home_contact_us">
          <Grid item lg={3} md={6} xs={12}>
            <ContactUs />
          </Grid>
        </GuardedComponent>
      </Grid>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  titleTypo: {
    color: "white",
    padding: "0.3rem",
  },
  titleTypoContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    backgroundColor: "#2a6975",
  },
}));

export default Home;
