import PropTypes from "prop-types";
import React, { useState } from "react";
import { DataGrid } from "@material-ui/data-grid";
import { Link } from "@material-ui/core";
import { useQuery } from "react-query";
import { Link as RouterLink } from "react-router-dom";
import { getSalesRealEstate } from "../../http/salesRealEstate";
import checkAccessStore from "../../utils/checkAccessStore";
import { dateColumn, eurColumn } from "../../utils/dataGridColumns";
import renderCellExpand from "../../utils/renderCellExpand";
import AppCircularProgress from "../AppCircularProgress";

const HubnupSalesRealEstateList = ({ filters, sortModel, setSortModel, agentId }) => {
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(0);

  const { isFetching, data: sales } = useQuery(
    ["getSalesRealEstate", filters, page, pageSize, sortModel, agentId],
    async () => {
      const data = await getSalesRealEstate(filters, page, pageSize, sortModel, agentId);
      const sales = data["hydra:member"];
      const salesRealEstate = sales.map((sale) => {
        return {
          ...sale[0],
          lastAction: sale?.lastAction,
        };
      });

      return {
        sales: salesRealEstate,
        totalItems: data["hydra:totalItems"],
      };
    },
    { keepPreviousData: true }
  );

  const handleSortModelChange = (params) => {
    if (params.sortModel !== sortModel) {
      setSortModel(params.sortModel);
    }
  };

  const getAgentFullName = (params) => {
    return `${params.row?.consultant?.lastName || ""} ${params.row?.consultant?.firstName || ""}`;
  };

  const getClientFullName = (params) => {
    return `${params.row?.client?.lastName || ""} ${params.row?.client?.firstName || ""}`;
  };

  const getUniteName = (params) => {
    return `${params.row?.lot || ""} - ${params.row?.operationName || ""}`;
  };

  const columns = [
    {
      field: "agentFullName",
      headerName: "Mandataire",
      width: 160,
      valueGetter: getAgentFullName,
      renderCell: (params) =>
        renderCellExpand(
          params,
          true,
          checkAccessStore("team_detail_page") ? (
            <Link component={RouterLink} to={`/sales/real-estate/team/${params.row?.consultant?.idCgp}`}>
              {params.value}
            </Link>
          ) : (
            params.value
          )
        ),
    },
    {
      field: "clientFullName",
      headerName: "Client",
      width: 160,
      valueGetter: getClientFullName,
      renderCell: (params) =>
        renderCellExpand(
          params,
          true,
          checkAccessStore("customers_detail_page") ? (
            <Link component={RouterLink} to={`/sales/real-estate/clients/${params.row?.client?.id}`}>
              {params.value}
            </Link>
          ) : (
            params.value
          )
        ),
    },
    {
      field: "lot",
      headerName: "Unité",
      flex: 1,
      valueGetter: getUniteName,
      renderCell: (params) =>
        renderCellExpand(
          params,
          true,
          checkAccessStore("sales_real_estate_unity_page") ? (
            <Link
              href={`https://valoriciel.com/sso/${params?.row?.valoricielId}/${params?.row?.consultant?.valoricielId}`}
              target="_blank"
            >
              {params.value}
            </Link>
          ) : (
            params.value
          )
        ),
    },
    {
      field: "baseRemuneration",
      ...eurColumn,
      headerName: "Base de rémunération",
      flex: 1,
      renderCell: renderCellExpand,
    },
    { field: "option", ...dateColumn, headerName: "Option", flex: 1, renderCell: renderCellExpand },
    {
      field: "bookingSignatureDate",
      ...dateColumn,
      headerName: "Signature réservation",
      flex: 1,
      renderCell: renderCellExpand,
    },
    { field: "bankAgreementDate", ...dateColumn, headerName: "Financé", flex: 1, renderCell: renderCellExpand },
    { field: "deedDate", ...dateColumn, headerName: "Acte", flex: 1, renderCell: renderCellExpand },
    {
      field: "cancellationDate",
      ...dateColumn,
      headerName: "Date d'annulation",
      flex: 1,
      renderCell: renderCellExpand,
    },
    { field: "exercice", headerName: "Exercice", flex: 1, renderCell: renderCellExpand },
  ];

  return (
    <>
      {sales ? (
        <DataGrid
          loading={isFetching}
          rows={sales.sales}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize.pageSize)}
          autoHeight
          disableColumnFilter
          disableSelectionOnClick
          disableColumnMenu
          disableColumnReorder
          pagination
          paginationMode="server"
          rowCount={sales.totalItems}
          onPageChange={(params) => setPage(params.page)}
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
        />
      ) : (
        <AppCircularProgress />
      )}
    </>
  );
};

HubnupSalesRealEstateList.defaultProps = {
  agentId: null,
};

HubnupSalesRealEstateList.propTypes = {
  filters: PropTypes.object,
  sortModel: PropTypes.array,
  setSortModel: PropTypes.func,
  agentId: PropTypes.string,
};

export default HubnupSalesRealEstateList;
