import React from "react";
import { Box, CircularProgress } from "@material-ui/core";

const AppCircularProgress = () => {
  return (
    <Box sx={{ mt: 3, mb: 3, display: "flex", justifyContent: "center" }}>
      <CircularProgress />
    </Box>
  );
};

export default AppCircularProgress;
