import PropTypes from "prop-types";
import React, { useState } from "react";
import { DataGrid } from "@material-ui/data-grid";
import { Box, Grid, IconButton, Link, Typography } from "@material-ui/core";
import { useQuery } from "react-query";
import { Link as RouterLink } from "react-router-dom";
import checkAccessStore from "../../utils/checkAccessStore";
import { dateColumn } from "../../utils/dataGridColumns";
import renderCellExpand from "../../utils/renderCellExpand";
import { getProspects } from "../../http/prospects";
import { dateToString } from "../../utils/formatters";
import { Download as DownloadIcon, Edit as EditIcon } from "@material-ui/icons";
import ProspectsUploadFileModal from "./ProspectsUploadFileModal";
import GuardedComponent from "../GuardedComponent";
import { useTrackingContext } from "../../contexts/TrackingContext";

const ProspectsList = ({ filters, sortModel, setSortModel, fromAgent }) => {
  const [page, setPage] = useState(0);
  const [openEditFileModal, setOpenEditFileModal] = useState(false);
  const [selectedProspect, setSelectedProspect] = useState(null);
  const [fileType, setFileType] = useState("");
  const track = useTrackingContext()

  const { isFetching, data: prospects } = useQuery(
    ["getProspects", filters, page, sortModel],
    async () => {
      const data = await getProspects(filters, page, sortModel);
      let prospects = data["hydra:member"];
      prospects = prospects.map((prospect) => {
        prospect.ricCollection = prospect.ricCollection.sort((ricA, ricB) =>
          ricA.ric.createdAt > ricB.ric.createdAt ? 1 : -1
        );
        prospect.derCollection = prospect.derCollection.sort((derA, derB) =>
          derA.der.createdAt > derB.der.createdAt ? 1 : -1
        );
        return prospect;
      });

      return {
        prospects: prospects,
        totalItems: data["hydra:totalItems"],
      };
    },
    {}
  );

  const handleSortModelChange = (params) => {
    if (params.sortModel !== sortModel) {
      setSortModel(params.sortModel);
    }
  };

  const hubnupColumns = [
    {
      field: "agentFullName",
      headerName: "Mandataire",
      width: 160,
      valueGetter: (params) => params.row?.consultant?.fullName,
      renderCell: (params) =>
        renderCellExpand(
          params,
          true,
          checkAccessStore("team_detail_page") ? (
            <Link component={RouterLink} to={`/prospects/team/${params.row?.consultant?.idCgp}`}>
              {params.value}
            </Link>
          ) : (
            params.value
          )
        ),
    },
  ];

  const handleDownloadFile = async (document) => {
    track.document(`media/${document.filePath}`)
    window.open(`${process.env.REACT_APP_API_URL}media/${document.filePath}?download`, "_blank");
  };

  const onEditRic = (prospect) => {
    setSelectedProspect(prospect);
    setFileType("ricFile");
    setOpenEditFileModal(true);
  };

  const onEditDer = (prospect) => {
    setSelectedProspect(prospect);
    setFileType("derFile");
    setOpenEditFileModal(true);
  };

  const handleCloseEditFileModal = () => {
    setOpenEditFileModal(false);
    setSelectedProspect(null);
    setFileType("");
  };

  const baseColumns = [
    {
      field: "lastName",
      headerName: "Nom",
      flex: 4,
      renderCell: (params) =>
        renderCellExpand(
          params,
          true,
          checkAccessStore("prospects_detail_page") ? (
            <Link component={RouterLink} to={`/prospects/${params.row.id}`}>
              {params.value}
            </Link>
          ) : (
            params.value
          )
        ),
    },
    {
      field: "firstName",
      headerName: "Prénom",
      flex: 4,
      renderCell: (params) =>
        renderCellExpand(
          params,
          true,
          checkAccessStore("prospects_detail_page") ? (
            <Link component={RouterLink} to={`/prospects/${params.row.id}`}>
              {params.value}
            </Link>
          ) : (
            params.value
          )
        ),
    },
    { field: "birthday", ...dateColumn, headerName: "Date de naissance", flex: 3, renderCell: renderCellExpand },
    { field: "createdAt", ...dateColumn, headerName: "Date de création", flex: 3, renderCell: renderCellExpand },
    {
      field: "updatedAt",
      ...dateColumn,
      headerName: "Date dernière mise à jour",
      flex: 3,
      renderCell: renderCellExpand,
    },
    {
      field: "ricCollection",
      headerName: "RIC",
      flex: 6,
      sortable: false,
      renderCell: (params) =>
        params.row?.ricCollection.length >= 1 ? (
          <Grid container sx={{ alignItems: "center" }}>
            <Grid item>
              <Box>
                <Typography>
                  Dernier RIC :{" "}
                  {dateToString(params.row?.ricCollection[params.row?.ricCollection.length - 1].ric?.createdAt)}
                </Typography>
                {params.row.ricCollection[1] && (
                  <Typography>Création : {dateToString(params.row?.ricCollection[0].ric?.createdAt)}</Typography>
                )}
              </Box>
            </Grid>
            <Grid item>
              <Box sx={{ width: "100%", display: "flex", justifyContent: "space-around", marginLeft: "6px" }}>
                <GuardedComponent componentName="prospects_edit">
                  <IconButton aria-label="Editer le document" size="small" onClick={() => onEditRic(params.row)}>
                    <EditIcon />
                  </IconButton>
                </GuardedComponent>
                <IconButton
                  aria-label="Télécharger le document"
                  size="small"
                  onClick={() =>
                    handleDownloadFile(params.row?.ricCollection[params.row?.ricCollection.length - 1].ric)
                  }
                >
                  <DownloadIcon />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        ) : (
          <GuardedComponent componentName="prospects_edit">
            <Box sx={{ width: "100%", display: "flex", justifyContent: "space-around", marginLeft: "6px" }}>
              <IconButton aria-label="Editer le document" size="small" onClick={() => onEditRic(params.row)}>
                <EditIcon />
              </IconButton>
            </Box>
          </GuardedComponent>
        ),
    },
    {
      field: "derCollection",
      headerName: "DER",
      flex: 6,
      sortable: false,
      renderCell: (params) =>
        params.row?.derCollection.length >= 1 ? (
          <Grid container sx={{ alignItems: "center" }}>
            <Grid item>
              <Box>
                <Typography>
                  Dernier DER :{" "}
                  {dateToString(params.row?.derCollection[params.row?.derCollection.length - 1].der?.createdAt)}
                </Typography>
                {params.row.derCollection[1] && (
                  <Typography>Création : {dateToString(params.row?.derCollection[0].der?.createdAt)}</Typography>
                )}
              </Box>
            </Grid>
            <Grid item>
              <Box sx={{ width: "100%", display: "flex", justifyContent: "space-around", marginLeft: "6px" }}>
                <GuardedComponent componentName="prospects_edit">
                  <IconButton aria-label="Editer le document" size="small" onClick={() => onEditDer(params.row)}>
                    <EditIcon />
                  </IconButton>
                </GuardedComponent>
                <IconButton
                  aria-label="Télécharger le document"
                  size="small"
                  onClick={() =>
                    handleDownloadFile(params.row?.derCollection[params.row?.derCollection.length - 1].der)
                  }
                >
                  <DownloadIcon />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        ) : (
          <GuardedComponent componentName="prospects_edit">
            <Box sx={{ width: "100%", display: "flex", justifyContent: "space-around", marginLeft: "6px" }}>
              <IconButton aria-label="Editer le document" size="small" onClick={() => onEditDer(params.row)}>
                <EditIcon />
              </IconButton>
            </Box>
          </GuardedComponent>
        ),
    },
  ];

  const columns = fromAgent ? baseColumns : hubnupColumns.concat(baseColumns);

  return (
    <>
      <DataGrid
        loading={isFetching}
        rows={prospects?.prospects ?? []}
        columns={columns}
        pageSize={10}
        autoHeight
        disableColumnFilter
        disableSelectionOnClick
        disableColumnMenu
        disableColumnReorder
        pagination
        paginationMode="server"
        rowCount={prospects?.totalItems ?? 0}
        onPageChange={(params) => setPage(params.page)}
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
        getRowId={(row) => row.id}
      />

      {openEditFileModal && (
        <ProspectsUploadFileModal
          open={openEditFileModal}
          prospect={selectedProspect}
          onClose={handleCloseEditFileModal}
          type={fileType}
        />
      )}
    </>
  );
};

ProspectsList.defaultProps = {
  fromAgent: false,
};

ProspectsList.propTypes = {
  filters: PropTypes.object,
  sortModel: PropTypes.array,
  setSortModel: PropTypes.func,
  fromAgent: PropTypes.bool,
};

export default ProspectsList;
