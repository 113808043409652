import { faClone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { DataGrid } from "@material-ui/data-grid";
import { Box, Checkbox, IconButton } from "@material-ui/core";
import { Delete as DeleteIcon, Edit as EditIcon, Visibility as VisibilityIcon } from "@material-ui/icons";
import { useMutation } from "react-query";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { postReminders } from "../../http/reminders";
import checkAccessStore from "../../utils/checkAccessStore";
import { booleanColumn, dateColumn } from "../../utils/dataGridColumns";
import GuardedComponent from "../GuardedComponent";
import RemindersDuplicateModal from "./RemindersDuplicateModal";

const HubnupRemindersList = ({ reminders, loading, onSelect, selectedReminders, onDelete }) => {
  const history = useHistory();
  const [openDuplicateModal, setOpenDuplicateModal] = useState(false);
  const [reminderToDuplicate, setReminderToDuplicate] = useState(null);
  const handleClickOpenDuplicateModal = (reminder) => {
    setOpenDuplicateModal(true);
    setReminderToDuplicate(reminder);
  };
  const handleCloseDuplicateModal = () => {
    setOpenDuplicateModal(false);
    setReminderToDuplicate(null);
  };

  const columns = [
    { field: "title", headerName: "Rappel", flex: 4 },
    { field: "date", ...dateColumn, headerName: "Date", flex: 1 },
    {
      field: "displayed",
      ...booleanColumn,
      headerName: "Accessible mandataires",
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "space-around" }}>
          <Checkbox
            checked={params.row.displayed}
            onChange={() => (checkAccessStore("reminders_edit_page") ? handleChange(params.row) : {})}
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        </Box>
      ),
    },
    {
      field: "actions",
      headerName: " ",
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "space-around" }}>
          <GuardedComponent componentName="reminders_detail_page">
            <IconButton
              aria-label="Voir le rappel"
              size="small"
              component={RouterLink}
              to={{ pathname: `/communication/reminders/${params.id}`, state: { goBack: history.location.pathname } }}
            >
              <VisibilityIcon />
            </IconButton>
          </GuardedComponent>
          <GuardedComponent componentName="reminders_edit_page">
            <IconButton
              aria-label="Modifier le rappel"
              size="small"
              component={RouterLink}
              to={{
                pathname: `/communication/reminders/${params.id}/edit`,
                state: { goBack: history.location.pathname },
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              aria-label="Dupliquer le rappel"
              size="small"
              onClick={() => handleClickOpenDuplicateModal(params.row)}
            >
              <FontAwesomeIcon icon={faClone} />
            </IconButton>
            <IconButton aria-label="Supprimer le rappel" size="small" onClick={() => onDelete(params.id)}>
              <DeleteIcon />
            </IconButton>
          </GuardedComponent>
        </Box>
      ),
    },
  ];

  const handleChange = (data) => {
    data.displayed = !data.displayed;
    mutation.mutate({
      displayed: data.displayed,
      reminder: data,
    });
  };

  const mutation = useMutation(postReminders);

  const sortModel = [{ field: "date", sort: "desc" }];

  return (
    <>
      <DataGrid
        loading={loading}
        rows={reminders}
        columns={columns}
        sortModel={sortModel}
        pageSize={10}
        autoHeight
        disableColumnFilter
        disableSelectionOnClick
        disableColumnMenu
        disableColumnReorder
        checkboxSelection
        onSelectionModelChange={(newSelection) => {
          onSelect(newSelection.selectionModel);
        }}
        selectionModel={selectedReminders}
      />

      {openDuplicateModal && (
        <RemindersDuplicateModal
          reminder={reminderToDuplicate}
          open={openDuplicateModal}
          onClose={handleCloseDuplicateModal}
        />
      )}
    </>
  );
};

HubnupRemindersList.propTypes = {
  reminders: PropTypes.array,
  loading: PropTypes.bool,
  onSelect: PropTypes.func,
  selectedReminders: PropTypes.array,
  onDelete: PropTypes.func,
};

export default HubnupRemindersList;
