import PropTypes from "prop-types";
import React from "react";
import { Checkbox, FormControlLabel, Grid, Skeleton, Typography } from "@material-ui/core";
import { dateToString } from "../../utils/formatters";

const UserAuthorities = ({ agent, loading }) => {
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={6}>
        <Typography textAlign="right">Immobilier :</Typography>
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          disabled
          control={<Checkbox checked={!loading && agent?.habilitationIobsp} />}
          label="IOBSP"
        />
      </Grid>
      <Grid item xs={6}>
        <Typography textAlign="right">Placement :</Typography>
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel disabled control={<Checkbox checked={!loading && agent?.habilitationIas} />} label="IAS" />
        <FormControlLabel disabled control={<Checkbox checked={!loading && agent?.habilitationCif} />} label="CIF" />
      </Grid>
      <Grid item xs={6}>
        <Typography textAlign="right">Numéro ORIAS :</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography component="div">{loading ? <Skeleton width={100} /> : agent?.oriasNumber}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography textAlign="right">Date de fin de validité ORIAS :</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography component="div">
          {loading ? <Skeleton width={100} /> : dateToString(agent?.oriasEndOfValidity)}
        </Typography>
      </Grid>
    </Grid>
  );
};

UserAuthorities.propTypes = {
  agent: PropTypes.object,
  loading: PropTypes.bool,
};

export default UserAuthorities;
