import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Stack,
  Typography,
  Tooltip,
} from "@material-ui/core";
import {
  AccountCircle as AccountCircleIcon,
  Delete as DeleteIcon,
  Create as CreateIcon,
  Mail as MailIcon,
  NoAccounts as DisableIcon,
} from "@material-ui/icons";
import { Link as RouterLink, useHistory } from "react-router-dom";
import GuardedComponent from "../GuardedComponent";
import UserDeleteModal from "./UserDeleteModal";
import UserDisableModal from "./UserDisableModal";
import UserResetPasswordModal from "./UserResetPasswordModal";

const HubnupUsersDetailsContent = ({ user, profiles }) => {
  const history = useHistory();
  const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openDisableModal, setOpenDisableModal] = useState(false);

  const goBack = () => {
    history.push("/administration/users");
  };

  return (
    <>
      <CardHeader
        title={
          <Box sx={{ py: 2, display: "flex", alignItems: "center" }}>
            <Typography variant="h1">
              {user.firstName} {user.lastName}
            </Typography>
          </Box>
        }
      />
      <Divider />
      <CardContent>
        <Grid container spacing={6}>
          <Grid item xs={12} sm={6}>
            <Box sx={{ py: 2 }}>
              <Typography gutterBottom variant="body1" textAlign="justify">
                Mail (login) : {user.email}
              </Typography>
            </Box>
            <Box sx={{ py: 2 }}>
              <Typography gutterBottom variant="body1" textAlign="justify">
                Profil : {profiles.find((profile) => profile.name === user.profile).title}
              </Typography>
            </Box>
            {user?.profile.includes("ROLE_MANDATAIRE") && (
              <Box sx={{ py: 2 }}>
                <Typography gutterBottom variant="body1" textAlign="justify">
                  ID CGP : {user.cgpId}
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid item sm={1} />
          <Grid item xs={12} sm={5}>
            <Typography>Photo : </Typography>
            <Avatar sx={{ width: 200, height: 200 }}>
              {user?.picture ? <img alt="preview" src={user.picture} /> : <AccountCircleIcon sx={{ fontSize: 200 }} />}
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <Stack
        direction={{ xs: "column-reverse", sm: "row" }}
        spacing={2}
        sx={{
          p: 2,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button variant="outlined" onClick={goBack}>
          Retour
        </Button>
        <Button
          margin="normal"
          variant="contained"
          startIcon={<MailIcon />}
          sx={{ marginTop: "18px", marginBottom: "8px" }}
          onClick={() => setOpenResetPasswordModal(true)}
        >
          Réinitialiser le mot de passe
        </Button>
        <GuardedComponent componentName="users_edit_page">
          <Button
            variant="contained"
            color="hubnup"
            startIcon={<DeleteIcon />}
            onClick={() => setOpenDeleteModal(true)}
          >
            Supprimer
          </Button>
          <Tooltip title={"Pour réactiver le compte : modifier le profil et réattribuer le rôle mandataire"}>
            <Button
              variant="contained"
              color="hubnup"
              startIcon={<DisableIcon />}
              onClick={() => setOpenDisableModal(true)}
            >
              Désactiver
            </Button>
          </Tooltip>
          <Button
            variant="contained"
            startIcon={<CreateIcon />}
            component={RouterLink}
            to={{ pathname: `/administration/users/${user.id}/edit`, state: { goBack: history.location.pathname } }}
          >
            Editer
          </Button>
        </GuardedComponent>

        <UserDeleteModal user={user} onClose={() => setOpenDeleteModal(false)} open={openDeleteModal} goBack={goBack} />

        <UserDisableModal
          user={user}
          onClose={() => setOpenDisableModal(false)}
          open={openDisableModal}
          goBack={goBack}
        />

        <UserResetPasswordModal
          open={openResetPasswordModal}
          onClose={() => setOpenResetPasswordModal(false)}
          user={user}
        />
      </Stack>
    </>
  );
};

HubnupUsersDetailsContent.propTypes = {
  user: PropTypes.object,
  profiles: PropTypes.array,
};

export default HubnupUsersDetailsContent;
