import React from "react";
import { Card, CardContent, Grid, Link, makeStyles } from "@material-ui/core";
// import { Link as RouterLink } from "react-router-dom";

const Partners = () => {
  const classes = useStyles();

  return (
    <Card>
      <CardContent
        sx={{
          p: 3,
          alignSelf: "center",
        }}
      >
        <Grid
          container
          justifyContent="space-around"
          spacing={2}
          alignItems="center"
          sx={{
            alignItems: "stretch",
            flexDirection: { xs: "column-reverse", md: "row" },
            width: "calc(100% + 32px)",
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              paddingLeft: "0 !important",
            }}
          >
            <Grid
              container
              flexDirection="column"
              sx={{
                marginTop: "8px",
                width: "auto",
                lineHeight: "30px",
                paddingX: "50px",
              }}
            >
              <Grid item xs={12}>
                <Grid container spacing={2} sx={{ alignItems: "center" }}>
                  <Grid item xs={12} sx={{ paddingX: "5px !important" }}>
                    {/* TODO : A réactiver */}
                    {/*<RouterLink to="/redirect/odysseal">*/}
                    {/*  <img*/}
                    {/*    src="/static/03.03 ODYSSEAL.png"*/}
                    {/*    alt="odysseal"*/}
                    {/*    className={classes.logoPicture}*/}
                    {/*  />*/}
                    {/*</RouterLink>*/}
                    <Link href="https://odysseal.com" target="_blank">
                      <img
                        src="/static/03.03 ODYSSEAL.png"
                        alt="odysseal"
                        className={classes.logoPicture}
                      />
                    </Link>
                  </Grid>
                  <Grid item xs={6} sx={{ paddingX: "15px !important" }}>
                    <Link href="https://www.anacofi.asso.fr/" target="_blank">
                      <img
                        src="/static/03.01 - ANACOFI.png"
                        alt="Anacofi"
                        className={classes.logoPicture}
                      />
                    </Link>
                  </Grid>
                  <Grid item xs={6} sx={{ paddingX: "15px !important" }}>
                    <Link
                      href="https://e.sumatraformationconseil.com/"
                      target="_blank"
                    >
                      <img
                        src="/static/03.02 - SUMATRA.png"
                        alt="Sumatra"
                        className={classes.logoPicture}
                      />
                    </Link>
                  </Grid>
                  <Grid item xs={6} sx={{ paddingX: "15px !important" }}>
                    <Link href="https://www.assoedc.com/" target="_blank">
                      <img
                        src="/static/02.03 - EDC.png"
                        alt="EDC"
                        className={classes.logoPicture}
                      />
                    </Link>
                  </Grid>
                  <Grid item xs={6} sx={{ paddingX: "15px !important" }}>
                    <Link href="https://allora.fr/hubnup/" target="_blank">
                      <img
                        src="/static/02.02 - ALLORA.png"
                        alt="Allora"
                        className={classes.logoPicture}
                      />
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const useStyles = makeStyles((theme) => ({
  logoPicture: {
    width: "100%",
  },
}));

export default Partners;
