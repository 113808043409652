import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { TextField, Autocomplete, CircularProgress } from "@material-ui/core";
import { useQuery } from "react-query";
import { getClientsAutocomplete } from "../http/clients";

const AutocompleteClientsField = ({
  setClient,
  setData,
  margin,
  freeSolo,
  initialClient,
  initialData,
  styleType,
  reset,
}) => {
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState(initialData);
  const [value, setValue] = useState(initialClient);

  const { isFetching, data: clients } = useQuery(
    ["getClientsAutocomplete", inputValue],
    () => getClientsAutocomplete(inputValue),
    {
      keepPreviousData: true,
      enabled: !!inputValue,
    }
  );

  useEffect(() => {
    if (clients) {
      setOptions(clients);
    }
  }, [clients]);

  useEffect(() => {
    setInputValue("");
    setValue("");
  }, [reset]);

  return (
    <Autocomplete
      id="client-field"
      getOptionLabel={(option) => (option ? option?.fullName : "")}
      getOptionSelected={(option, value) => option?.clientId === value?.clientId}
      options={options}
      autoComplete
      freeSolo={freeSolo}
      inputValue={inputValue}
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(event, newValue) => {
        setClient(newValue?.clientId);
        setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        if (event) {
          if (setData) {
            setClient(null);
            setData(newInputValue);
          }
          setInputValue(newInputValue);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Client"
          variant={styleType}
          fullWidth
          margin={margin ? "normal" : "none"}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isFetching ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

AutocompleteClientsField.defaultProps = {
  freeSolo: false,
  initialClient: null,
  initialData: "",
  styleType: "outlined",
  reset: false,
};

AutocompleteClientsField.propTypes = {
  setClient: PropTypes.func,
  setData: PropTypes.func,
  margin: PropTypes.bool,
  freeSolo: PropTypes.bool,
  reset: PropTypes.bool,
  initialClient: PropTypes.string,
  initialData: PropTypes.string,
  styleType: PropTypes.string,
};

export default AutocompleteClientsField;
