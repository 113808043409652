import { Button, ButtonGroup } from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import { yearsByRange } from "../../utils/formatters";

function ExerciceFilter({selectedYear, setSelectedYear, actualYear, onSubmit}) {
  // get list of last 5 years
  const years = yearsByRange(5, actualYear);

  const BtnAllVariantProp = !selectedYear ? "contained" : "outlined"

  return (
    <>
      <ButtonGroup variant="outlined" aria-label="outlined primary button group" sx={{ marginLeft: "10px" }}>
            {years.map((year) => (
              <Button
                variant={selectedYear === year ? "contained" : "outlined"}
                key={year}
                onClick={() => setSelectedYear(year)}
              >
                {year}
              </Button>
            ))}
            <Button variant={BtnAllVariantProp} onClick={() => setSelectedYear()}>
              Tous
            </Button>
          </ButtonGroup>
        <Button variant="contained" startIcon={<SearchIcon />} onClick={onSubmit} sx={{ marginLeft: "10px" }}>
            Filtrer
        </Button>
    </>
    )
}

export default ExerciceFilter;
