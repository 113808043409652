import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import { useQuery } from "react-query";
import { getCgpSalesSummary } from "../../http/agents";
import GuardedComponent from "../GuardedComponent";
import CgpSalesInvestmentSummary from "./CgpSalesInvestmentSummary";
import CgpSalesRealEstateSummary from "./CgpSalesRealEstateSummary";

const CgpSalesSummary = ({ agentId }) => {
  const { isFetching, data: sales } = useQuery(["getCgpSalesSummary", agentId], () => getCgpSalesSummary(agentId), {
    keepPreviousData: true,
  });

  return (
    <GuardedComponent componentName="sales_cgp_sum_sales">
      <Grid container spacing={1} sx={{ marginBottom: "12px" }}>
        <Grid item xs={12} md={6}>
          <CgpSalesRealEstateSummary isFetching={isFetching} sales={sales?.[0]} />
        </Grid>
        <Grid item xs={12} md={6}>
          <CgpSalesInvestmentSummary isFetching={isFetching} sales={sales?.[1]} />
        </Grid>
      </Grid>
    </GuardedComponent>
  );
};

CgpSalesSummary.propTypes = {
  agentId: PropTypes.string,
};

export default CgpSalesSummary;
