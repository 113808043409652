import formatFileName from "../utils/formatFileName";
import http from "../utils/http";
import openFile from "../utils/openFile";

export const getClientsAutocomplete = async (value) => {
  let url = `/clients?fullName=${value}&pagination=false`;
  const response = await http.get(url);

  return response.data["hydra:member"];
};

export const getCustomers = async (filters, order, page) => {
  let url = "/clients/clients";

  url = applyFilters(filters, order, url, page);

  const response = await http.get(url);

  return response.data;
};

export const getCustomerDetails = async (id, years) => {
  let url = `/clients/${id}`;

  if (years && years.length > 0) {
    url += `?years=${years.join(",")}`;
  }

  const response = await http.get(url);

  return response.data;
};

export const getExportCustomers = async (data) => {
  const filters = data.filters;
  const order = data.order;
  let url = "/clients/export";
  url = applyFilters(filters, order, url);

  let fileName = await formatFileName("customers");

  return await openFile(url, `${fileName}.xlsx`, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
};

export const getExportCustomerDetails = async (data) => {
  const id = data.id;
  const years = data.years;
  const customer = data.customer;
  let url = `/clients/export/${id}`;

  if (years && years.length > 0) {
    url += `?years=${years.join(",")}`;
  }

  let fileName = await formatFileName(`customer_${customer?.clientLastName}-${customer?.clientFirstName}`, null, false);

  return await openFile(url, `${fileName}.xlsx`, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
};

const applyFilters = (filters, order, url, page) => {
  let hasFilter = false;

  const filtersUrl = [];
  if (page) {
    filtersUrl.push(`page=${page + 1}`);
    hasFilter = true;
  }
  if (filters?.lastName) {
    filtersUrl.push(`lastName=${filters.lastName}`);
    hasFilter = true;
  }
  if (filters?.firstName) {
    filtersUrl.push(`firstName=${filters.firstName}`);
    hasFilter = true;
  }
  if (filters?.zipCode) {
    filtersUrl.push(`zipCode=${filters.zipCode}`);
    hasFilter = true;
  }
  if (filters?.years) {
    filtersUrl.push(`years=${filters.years.join(",")}`);
    hasFilter = true;
  }
  if (filters?.type) {
    filtersUrl.push(`type=${filters.type}`);
    hasFilter = true;
  }
  if (order) {
    order.forEach((orderField) => {
      filtersUrl.push(`order[${orderField.field}]=${orderField.sort}`);
    });
    hasFilter = true;
  }

  if (hasFilter) {
    url += "?";
  }

  filtersUrl.forEach((filter, index) => {
    url += filter;
    if (index < filtersUrl.length - 1) url += "&";
  });

  return url;
};
