const components = {
  MuiCardHeader: {
    styleOverrides: {
      root: {
        paddingBottom: 12,
        paddingTop: 12,
        height: 64,
      },
    },
  },
  MuiDataGrid: {
    styleOverrides: {
      root: {
        "& .MuiDataGrid-columnsContainer": {
          lineHeight: "initial !important",
        },
        "& .MuiDataGrid-columnHeaderWrapper": {
          whiteSpace: "initial",
          backgroundColor: "#00a19a",
          color: "white",
          height: "56px",
          "& .MuiSvgIcon-root": {
            color: "white",
          },
        },
        "& .MuiDataGrid-columnHeaderTitle": {
          whiteSpace: "initial",
        },
        "& .MuiDataGrid-iconButtonContainer": {
          visibility: "hidden",
          width: 0,
        },
        "& .MuiDataGrid-columnHeader:hover": {
          "& .MuiDataGrid-iconButtonContainer": {
            visibility: "visible",
            width: "auto",
          },
        },
        "& .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeaderSorted):hover .MuiDataGrid-sortIcon": {
          opacity: "0.6",
        },
        "& .MuiDataGrid-columnHeader.MuiDataGrid-columnHeaderSorted": {
          "& .MuiDataGrid-iconButtonContainer": {
            visibility: "visible",
            width: "auto",
          },
        },
      },
    },
  },
  MuiPickersStaticWrapper: {
    styleOverrides: {
      root: {
        height: "calc(100% - 65px)",
        justifyContent: "center",
        "& .MuiPicker-root": {
          height: "100%",
        },
        "& .MuiPickerView-root": {
          width: "100%",
          height: "100%",
          maxHeight: 450,
        },
        "&>div>div": {
          minWidth: 370,
          minHeight: 410,
        },
        "& .MuiCalendarPicker-root": {
          width: "100%",
          minHeight: 410,
        },
        "& .MuiCalendarPicker-viewTransitionContainer>div+div": {
          justifyContent: "space-around",
          margin: "0 5px",
        },
        "& .MuiCalendarPicker-viewTransitionContainer>div>div:last-child": {
          overflow: "visible",
          height: "calc(100% - 40px)",
          minHeight: "307px",

          "&>div": {
            overflow: "visible",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            "&>div": {
              justifyContent: "space-around",
              margin: "0 5px",
            },
          },
        },
        "& .MuiCalendarPicker-viewTransitionContainer": {
          height: "calc(100% - 54px)",
          overflowY: "initial",
          "& > div": {
            height: "100%",
            "& > div": {
              justifyContent: "space-around",
            },
          },
        },
        "& .MuiPickersCalendar-root": {
          height: "calc(100% - 38px)",
        },
        "& .MuiPickersCalendar-weekContainer": {
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
        },
        "& .MuiPickersCalendar-daysHeader": {
          justifyContent: "space-around",
          height: 38,
        },
        "& .MuiPickersCalendar-week": {
          justifyContent: "space-around",
        },
        "& .MuiPickersCalendar-loadingContainer": {
          height: "calc(100% - 38px)",
        },
        "& .MuiPickersDay-root": {
          width: 50,
          height: 52,
          borderRadius: "50%",
        },
        "& .MuiPickersDay-dayWithMargin": {
          margin: 0,
        },
        "& .MuiCalendarPickerSkeleton-root": {
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
        },
        "& .MuiCalendarPickerSkeleton-week": {
          justifyContent: "space-around",
        },
        "& .MuiCalendarPickerSkeleton-daySkeleton": {
          width: "54px !important",
          height: "54px !important",
        },
      },
    },
  },
};

export default components;
