import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import {
  Alert,
  CardContent,
  CardMedia,
  Typography,
  Container,
  makeStyles,
  Box,
  IconButton,
} from "@material-ui/core";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { postNews } from "../../http/news";
import { contentText, dateToRelativeString, dateToString } from "../../utils/formatters";
import GuardedComponent from "../GuardedComponent";
import NewsAction from "./NewsActions";

const NewsCard = ({ news, detailsPath, goBack, reset, fromAgent }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleOpenDetails = () => {
    if (detailsPath) {
      history.push({ pathname: detailsPath, state: { goBack: goBack } });
    }
  };

  const handleClickDisplayed = () => {
    news.displayed = !news.displayed;

    mutation.mutate({
      displayed: news.displayed,
      imageBank: news?.imageBank["@id"],
      news,
    });
  };

  const mutation = useMutation(postNews);

  return (
    <>
      <Box className={classes.cardWrapper}>
        <CardMedia
          sx={{ height: 200, backgroundSize: "contain" }}
          image={
            process.env.REACT_APP_API_URL +
            news?.imageBank?.mediaObject?.contentUrl
          }
          title={news?.imageBank?.title}
          onClick={handleOpenDetails}
        />
        <CardContent className={fromAgent ? "" : classes.contentCard}>
          <Box sx={{ display: "flex" }}>
            <Typography variant="h3" component="h2" onClick={handleOpenDetails}>
              {news.title}
            </Typography>
            <GuardedComponent componentName="news_edit_page">
              {mutation.isError ? (
                <Alert severity="error">
                  Une erreur est survenue : {mutation.error.message}
                </Alert>
              ) : null}

              <IconButton
                color="inherit"
                onClick={handleClickDisplayed}
                size="small"
                sx={{ marginLeft: "1rem" }}
              >
                {news.displayed ? (
                  <FontAwesomeIcon icon={faEye} />
                ) : (
                  <FontAwesomeIcon icon={faEyeSlash} />
                )}
              </IconButton>
            </GuardedComponent>
          </Box>
          {history.location.pathname.includes("/communication") ? (
            <Typography
              gutterBottom
              variant="caption"
              color="text.secondary"
              display="block"
              onClick={handleOpenDetails}
            >
              {dateToString(news.date)}
            </Typography>
          ) : (
            <Typography
              gutterBottom
              variant="caption"
              color="text.secondary"
              display="block"
              onClick={handleOpenDetails}
            >
              Mis à jour {dateToRelativeString(news.date)}
            </Typography>
          )}
          <Typography
            className="editor-content"
            gutterBottom
            variant="body1"
            textAlign="justify"
            paragraph={true}
            onClick={handleOpenDetails}
            dangerouslySetInnerHTML={{
              __html: contentText(news.content ?? ""),
            }}
            sx={{
              "& *": {
                margin: "inherit",
                padding: "inherit",
                boxSizing: "inherit",
              },
              "& ul": { marginLeft: "15px" },
              display: "-webkit-box",
              "-webkit-line-clamp": "10",
              "-webkit-box-orient": "vertical",
              overflow: "hidden",
            }}
          />
        </CardContent>
      </Box>
      <GuardedComponent componentName="news_edit_page">
        <Container className={classes.cardActionsWrapper}>
          <NewsAction
            news={news}
            goBack="/communication/news"
            reinitData={reset}
          />
        </Container>
      </GuardedComponent>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    cursor: "pointer",
  },
  cardActionsWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
  contentCard: {
    "&:last-child": {
      paddingBottom: "0",
    },
  },
}));

NewsCard.propTypes = {
  news: PropTypes.object,
  detailsPath: PropTypes.string,
  goBack: PropTypes.string,
  reset: PropTypes.func,
  fromAgent: PropTypes.bool,
};

export default NewsCard;
